export const GET_CARDIAC_HISTORY_LIST_DATA = 'GET_CARDIAC_HISTORY_LIST_DATA'
export const SET_CARDIAC_HISTORY_LIST_LOADING = 'SET_CARDIAC_HISTORY_LIST_LOADING'
export const GET_CARDIAC_HISTORY_DIAGNOSIS_DATA = 'GET_CARDIAC_HISTORY_DIAGNOSIS_DATA'
export const SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING = 'SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING'
export const CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA = 'CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA'
export const CLEAR_CARDIAC_HISTORY_LIST_DATA = 'CLEAR_CARDIAC_HISTORY_LIST_DATA'
export const CLEAR_CARDIAC_HISTORY_DATA = 'CLEAR_CARDIAC_HISTORY_DATA'
export const SET_CURRENT_CARDIAC_HISTORY = 'SET_CURRENT_CARDIAC_HISTORY'
export const DELETE_CARDIAC_HISTORY = 'DELETE_CARDIAC_HISTORY'
export const SET_CURRENT_SUB_DIAGNOSIS = 'SET_CURRENT_SUB_DIAGNOSIS'
export const GET_PAST_CARDIAC_HISTORY_LIST_DATA = 'GET_PAST_CARDIAC_HISTORY_LIST_DATA'
export const SET_PAST_CARDIAC_HISTORY_LIST_LOADING = 'SET_PAST_CARDIAC_HISTORY_LIST_LOADING'
export const GET_PAST_CARDIAC_HISTORY_DIAGNOSIS_DATA = 'GET_PAST_CARDIAC_HISTORY_DIAGNOSIS_DATA'
export const SET_PAST_CARDIAC_HISTORY_DIAGNOSIS_LOADING = 'SET_PAST_CARDIAC_HISTORY_DIAGNOSIS_LOADING'
export const SET_CARDIAC_HISTORY_MODAL_TYPE = 'SET_CARDIAC_HISTORY_MODAL_TYPE'
