import {
    CLEAR_ECHO,
    ECHO_DOC_LOADING,
    GET_ECHO_DATA,
    GET_ECHO_DOCUMENT,
    GET_ECHO_LIST_DATA,
    RESET_ECHO_MODAL,
    SET_ECHO_LOADING,
} from './echoList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'

export const getEchoListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ECHO_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/echo/list`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_ECHO_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getEchoData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ECHO_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/echo/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_ECHO_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ECHO_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getEchoDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: ECHO_DOC_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/echo/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_ECHO_DOCUMENT,
                    payload: response.data.output,
                })
                dispatch({
                    type: ECHO_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: ECHO_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: ECHO_DOC_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const resetEchoDocument = () => (dispatch) => {
    dispatch({
        type: RESET_ECHO_MODAL,
    })
}
export const clearEcho = () => (dispatch) => {
    dispatch({
        type: CLEAR_ECHO,
    })
}
