import {
    FAILED_APPOINTMENT_LIST,
    GET_ALL_APPOINTMENT_LIST,
    GET_APPOINTMENT_LIST,
    TOGGLE_LOADING,
    SET_CURRENT_APPOINTMENT_DATE,
    GET_APPOINTMENT_LIST_WO_PAGINATION,
} from './appointment.type'
import moment from 'moment'
import { DATE_FORMAT } from '../../util/dateTimeFormats'

const INITIAL_STATE = {
    loading: true,
    appointmentData: {
        list: [],
        hasResult: false,
        resultCount: 0,
    },
    AllAppointmentData: {
        list: [],
        hasResult: false,
        resultCount: 0,
    },
    currentAppointmentDate: moment().format(DATE_FORMAT.YYYY_MM_DD),
    appointmentDataWoPagination: [],
}

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case GET_APPOINTMENT_LIST:
            return {
                ...state,
                appointmentData: action.payload,
            }
        case GET_ALL_APPOINTMENT_LIST:
            // console.log("action.payload", action.payload)
            return {
                ...state,
                AllAppointmentData: action.payload,
            }
        case FAILED_APPOINTMENT_LIST:
            return {
                ...state,
                appointmentData: {
                    list: [],
                    hasResult: false,
                    resultCount: 0,
                },
                AllAppointmentData: {
                    list: [],
                    hasResult: false,
                    resultCount: 0,
                },
            }
        case TOGGLE_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case SET_CURRENT_APPOINTMENT_DATE:
            return {
                ...state,
                currentAppointmentDate: action?.payload,
            }
        case GET_APPOINTMENT_LIST_WO_PAGINATION:
            return {
                ...state,
                appointmentDataWoPagination: action.payload,
            }
        default:
            return state
    }
}

export default reducer
