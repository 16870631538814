import React from 'react'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

const MainInfo = ({ trialData }) => {
    return (
        <Stack
            sx={{ minWidth: 768 }}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.75}
        >
            <Box>
                <Typography className="fs-7 gray-900 fw-bold ">Study Title</Typography>
                <Typography className="fs-8 gray-900 fw-light text-justify">
                    {trialData?.info?.title ?? '-'}
                </Typography>
            </Box>
            <Box>
                <Typography className="fs-7 gray-900 fw-bold">Sponsor</Typography>
                <Typography className="fs-8 gray-900 fw-light"> {trialData?.info?.sponsor ?? '-'}</Typography>
            </Box>
            <Box>
                <Typography className="fs-7 gray-900 fw-bold ">Main Objective</Typography>
                <Typography className="fs-8 gray-900 fw-light text-justify">
                    {trialData?.info?.objective ?? '-'}
                </Typography>
            </Box>
            <Box>
                <Typography className="fs-7 gray-900 fw-bold">Study Arm</Typography>
                {_.isEmpty(trialData?.info?.studyArm) ? (
                    '-'
                ) : (
                    <ul>
                        {trialData?.info?.studyArm?.map((study, idx) => (
                            <li key={idx} className="fs-8 gray-900 fw-light">
                                {study}
                            </li>
                        ))}
                    </ul>
                )}
            </Box>
        </Stack>
    )
}

export default MainInfo
