import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import SummaryText from '../../physical-exam/physical-modal/summary-text'

const PhysicalExamInfo = ({ physicalExamData }) => {
    const showExamHandler = (examType) => {
        return !!_.find(physicalExamData?.physicalExam, { type: examType, active: true })
    }
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal">Physical Exam</Typography>
            {_.filter(physicalExamData?.physicalExam, { active: true })?.length > 0 ? null : (
                <Typography className="gray-900 fw-light fs-9 ">Physical Exam Not Available</Typography>
            )}
            <Grid container spacing={3} sx={{ pt: 1 }}>
                {showExamHandler('Cardiac') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Cardiac</Typography>
                        <SummaryText
                            values={physicalExamData?.physicalExam}
                            expanded={'Cardiac'}
                            consultLetterFlag={true}
                        />
                    </Grid>
                )}
                {showExamHandler('Peripheral') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Peripheral</Typography>
                        <SummaryText
                            values={physicalExamData?.physicalExam}
                            expanded={'Peripheral'}
                            consultLetterFlag={true}
                        />
                    </Grid>
                )}
                {showExamHandler('Respiratory') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Respiratory</Typography>
                        <SummaryText
                            values={physicalExamData?.physicalExam}
                            expanded={'Respiratory'}
                            consultLetterFlag={true}
                        />
                    </Grid>
                )}
                {showExamHandler('Abdominal') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Abdominal</Typography>
                        <SummaryText
                            values={physicalExamData?.physicalExam}
                            expanded={'Abdominal'}
                            consultLetterFlag={true}
                        />
                    </Grid>
                )}
                {showExamHandler('Procedural') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Procedural</Typography>
                        <SummaryText
                            values={physicalExamData?.physicalExam}
                            expanded={'Procedural'}
                            consultLetterFlag={true}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container sx={{ pt: 3 }}>
                <Grid item xs={12}>
                    <Typography className="gray-900 fs-8 fw-normal pb-1">Additional Notes</Typography>
                    <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                        {physicalExamData?.physicalExam[5]?.additionalNotes}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        lastUpdatedPhysical: state?.physicalExam?.lud,
        physicalExamData: state?.physicalExam?.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalExamInfo)
