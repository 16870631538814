import * as React from 'react'
import { styled } from '@mui/material/styles'
import variables from '../../../../theme/variables.scss'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ErrorMessage, useFormikContext } from 'formik'
import _ from 'lodash'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        border: `1px solid ${variables.purple}`,
        borderRadius: '6px',
        color: variables.purple,
        '&.Mui-disabled': {
            border: 0,
        },
    },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    padding: '2px 8px 2px',
    '&.Mui-selected:hover': {
        color: 'white',
        backgroundColor: variables.purple,
    },
    '&.Mui-selected': { color: 'white', backgroundColor: variables.purple },
}))

export default function ColorToggleButton({ btnName, btnVal, ...props }) {
    const { setFieldValue } = useFormikContext()

    const handleChange = (event, newAlignment) => {
        setFieldValue(btnName, newAlignment)

        if (!newAlignment) {
            event.stopPropagation()
        }
    }

    return (
        <div>
            <StyledToggleButtonGroup
                size="small"
                orientation="horizontal"
                exclusive
                name={btnName}
                value={btnVal ?? ''}
                onChange={handleChange}
                id={btnName}
            >
                <StyledToggleButton name={'yes'} value={true}>
                    Yes
                </StyledToggleButton>
                <StyledToggleButton name={'no'} value={false}>
                    No
                </StyledToggleButton>
            </StyledToggleButtonGroup>
            <ErrorMessage
                name={btnName}
                render={(msg) => <div className="form-error-message fs-10">{msg}</div>}
            />
        </div>
    )
}
