import React from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../../theme/variables.scss'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'
import axios from 'axios'
import Typography from '@mui/material/Typography'
import { Formik, Form, ErrorMessage } from 'formik'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as Yup from 'yup'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleScreenFlowModal,
} from '../../../../redux/modals/toggleModal.actions'
import {
    getTrialCriteriaData,
    getTrialListData,
    setCurrentTrial,
} from '../../../../redux/screenFlow/screenFlow.action'
import { setAlert } from '../../../../redux/alert/alert.action'

const TrialForm = ({ token, patientId, setLoading, setAlignment, currentTrial }) => {
    const dispatch = useDispatch()
    const validationSchema = Yup.object().shape({
        trialData: Yup.object().shape({
            consent: Yup.string().required('Consent is required').nullable(),
        }),
    })

    const onSumbitHandler = (values) => {
        setLoading(true)
        const payload = {
            id: currentTrial?._id,
            patientCollectionName: currentTrial?.patientCollectionName,
            patientId: patientId,
            name: currentTrial?.name,
            form: {
                consent: values?.trialData?.consent ?? 'NA',
                consentNote: values?.trialData?.consentNote,
                otherConsent: values?.trialData?.otherConsent ?? 'NA',
                otherConsentNote: values?.trialData?.otherConsentNote,
            },
        }

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_CARD_URL}/patient-studies`,
            data: payload,
            headers: {
                accessToken: token,
            },
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    dispatch(
                        setAlert({
                            alertType: 'success',
                            alertMsg: response?.data?.message ?? `Consent processed successfully.`,
                            alertOpen: true,
                        })
                    )
                    const payloadClinicalTrials = {
                        patientId: patientId,
                    }
                    dispatch(getTrialListData(payloadClinicalTrials, token))
                    dispatch(setCurrentTrial(response?.data?.output))

                    const payloadTrialCriteriaDetails = {
                        id: currentTrial?._id,
                        patientId: patientId,
                        patientCollectionName: currentTrial?.patientCollectionName,
                    }
                    dispatch(getTrialCriteriaData(payloadTrialCriteriaDetails, token))
                    if (
                        response?.data?.output?.consent === 'No' ||
                        response?.data?.output?.consent === 'NA'
                    ) {
                        dispatch(toggleScreenFlowModal(false))
                    }
                } else if (response?.data?.status === 400) {
                    dispatch(
                        setAlert({
                            alertType: 'error',
                            alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                                response?.data?.error?.message
                            }`,
                            alertOpen: true,
                        })
                    )
                } else if (response?.data?.status === 401) {
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response?.data?.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                }
            })
            .catch((err) => {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `${
                            err?.message ?? 'Something went wrong'
                        }. Unable to process data. Please! try again.`,
                        alertOpen: true,
                    })
                )
            })
            .finally(() => {
                setLoading(false)
                setAlignment('Details')
            })
    }

    return (
        <div className="px-2">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    trialData: {
                        consent: currentTrial?.consent === 'NA' ? null : currentTrial?.consent,
                        consentNote: currentTrial?.consentNote ?? '',
                        otherConsent: currentTrial?.otherConsent === 'NA' ? null : currentTrial?.otherConsent,
                        otherConsentNote: currentTrial?.otherConsentNote ?? '',
                    },
                }}
                validationSchema={validationSchema}
                onSubmit={onSumbitHandler}
                validateOnChange={true}
            >
                {({ values, handleChange, handleBlur, setFieldValue }) => (
                    <Form noValidate autoComplete="off" id="trial-consent-form">
                        <ol className="fs-8" style={{ paddingLeft: '1em' }}>
                            <Stack spacing={1}>
                                <li>
                                    <Box>
                                        <Typography
                                            id="permission-question-label"
                                            className="fs-8 gray-900 fw-bold"
                                            gutterBottom
                                        >
                                            Does the patient grant permission to be contacted regarding{' '}
                                            {currentTrial?.name ?? 'this clinical trial'}? <sup>*</sup>
                                        </Typography>
                                        <RadioGroup
                                            row
                                            aria-labelledby="permission-question-label"
                                            name="trialData.consent"
                                            value={values?.trialData?.consent}
                                            onChange={(event, newValue) => {
                                                setFieldValue('trialData.consent', newValue)
                                            }}
                                            onBlur={handleBlur}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 12,
                                                },
                                                '.Mui-checked .MuiSvgIcon-root': {
                                                    color: variables.purple,
                                                },
                                            }}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                size="small"
                                                control={<Radio />}
                                                label={
                                                    <Typography className="fs-8 gray-900 fw-light">
                                                        Yes
                                                    </Typography>
                                                }
                                            />
                                            <FormControlLabel
                                                value="No"
                                                size="small"
                                                control={<Radio />}
                                                label={
                                                    <Typography className="fs-8 gray-900 fw-light">
                                                        No
                                                    </Typography>
                                                }
                                            />
                                        </RadioGroup>

                                        <ErrorMessage
                                            name="trialData.consent"
                                            render={(msg) => <div className="form-error-message">{msg}</div>}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography className="fs-8 gray-900 fw-normal mt-2" gutterBottom>
                                            Additional Notes / reason for declining permission (If any)
                                        </Typography>
                                        <Box>
                                            <TextareaAutosize
                                                minRows={2}
                                                maxRows={6}
                                                placeholder="Add Additional Details..."
                                                name={`trialData.consentNote`}
                                                id={`trialData.consentNote`}
                                                value={values?.trialData?.consentNote}
                                                onChange={handleChange}
                                                style={{
                                                    width: '100%',
                                                    minHeight: '2rem',
                                                    maxHeight: '6rem',
                                                    fontSize: '12px',
                                                    borderRadius: '6px',
                                                    padding: '6px',
                                                    borderColor: variables.lightGray1,
                                                    background: variables.white100,
                                                    overflow: 'auto',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </li>

                                <li>
                                    <Box>
                                        <Typography
                                            id="previous-trial-question-label"
                                            className="fs-8 gray-900 fw-bold"
                                            gutterBottom
                                        >
                                            Has the patient previously participated in other clinical trials?
                                        </Typography>
                                        <RadioGroup
                                            size="small"
                                            row
                                            aria-labelledby="previous-trial-question-label"
                                            name="trialData.otherConsent"
                                            value={values?.trialData?.otherConsent}
                                            onChange={(event, newValue) => {
                                                setFieldValue('trialData.otherConsent', newValue)
                                            }}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 12,
                                                },
                                                '.Mui-checked .MuiSvgIcon-root': {
                                                    color: variables.purple,
                                                },
                                            }}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio />}
                                                label={
                                                    <Typography className="fs-8 gray-900 fw-light">
                                                        Yes
                                                    </Typography>
                                                }
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio />}
                                                label={
                                                    <Typography className="fs-8 gray-900 fw-light">
                                                        No
                                                    </Typography>
                                                }
                                            />
                                        </RadioGroup>
                                    </Box>
                                    <Box>
                                        <Typography className="fs-8 gray-900 fw-normal mt-2" gutterBottom>
                                            Additional Notes / names of any previous trials they participated
                                            in (If any)
                                        </Typography>
                                        <Box>
                                            <TextareaAutosize
                                                minRows={2}
                                                maxRows={6}
                                                placeholder="Add Additional Details..."
                                                name={`trialData.otherConsentNote`}
                                                id={`trialData.otherConsentNote`}
                                                value={values?.trialData?.otherConsentNote}
                                                onChange={handleChange}
                                                style={{
                                                    width: '100%',
                                                    minHeight: '2rem',
                                                    maxHeight: '6rem',
                                                    fontSize: '12px',
                                                    borderRadius: '6px',
                                                    padding: '6px',
                                                    borderColor: variables.lightGray1,
                                                    background: variables.white100,
                                                    overflow: 'auto',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </li>
                            </Stack>
                        </ol>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleScreenFlowModal: (payload) => dispatch(toggleScreenFlowModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialForm)
