import React from 'react'
import _ from 'lodash'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'
import Typography from '@mui/material/Typography'

import CustomizedAccordions from '../../../interval-history/interval-modal/interval-components/Accordian'
import ColorToggleButton from '../../../interval-history/interval-modal/interval-components/Toggler'

import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const AbdominalAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Abdominal'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`physicalExam[2].active`}
                        btnVal={values?.physicalExam[2]?.active}
                    />
                }
            >
                <Typography className="fs-8 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                    Palpation
                </Typography>

                <StackWrapper title="Tension" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="medium"
                            inputName={`physicalExam[2].diagnosisType.palpation.tension`}
                            inputVal={values?.physicalExam[2]?.diagnosisType?.palpation?.tension}
                            toggleBtnArr={['Soft', 'Firm']}
                        />
                    </BoxWrapper>
                </StackWrapper>
                {/* tenderness */}
                <StackWrapper title="Tenderness" direction="row" spacing={2}>
                    <BoxWrapper
                        styles={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Type
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[2].diagnosisType.palpation.tenderness.type`}
                                inputVal={values?.physicalExam[2]?.diagnosisType?.palpation?.tenderness?.type}
                                toggleBtnArr={['None', 'Gentle', 'Deep']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Horizontal
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[2].diagnosisType.palpation.tenderness.horizontalSide`}
                                inputVal={
                                    values?.physicalExam[2]?.diagnosisType?.palpation?.tenderness
                                        ?.horizontalSide
                                }
                                toggleBtnArr={['Right', 'Left']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Vertical
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[2].diagnosisType.palpation.tenderness.verticalSide`}
                                inputVal={
                                    values?.physicalExam[2]?.diagnosisType?.palpation?.tenderness
                                        ?.verticalSide
                                }
                                toggleBtnArr={['Upper', 'Lower']}
                            />
                        </BoxWrapper>
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Mass" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="medium"
                            inputName={`physicalExam[2].diagnosisType.palpation.mass`}
                            inputVal={values?.physicalExam[2]?.diagnosisType?.palpation?.mass}
                            toggleBtnArr={['None', 'Present']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <Typography className="fs-8 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                    Auscultation
                </Typography>

                <StackWrapper title="Bowel sounds" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="xxl"
                            inputName={`physicalExam[2].diagnosisType.auscultation.bowelSounds`}
                            inputVal={values?.physicalExam[2]?.diagnosisType?.auscultation?.bowelSounds}
                            toggleBtnArr={['Normal', 'High-Pitched', 'Absent']}
                        />
                    </BoxWrapper>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default AbdominalAccordion
