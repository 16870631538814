import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import DummyChart from '../../dummy-chart'
import LoadingChart from '../../labs-component/labs-charts/loading-chart'
import BaseChart from './Base-chart'

export const Index = ({ currentEcgs, ecgLoading, ecgData, type }) => {
    /* for setting desired title/name for chart  */
    const placeholderHelper = () => {
        const palceholderObj = [
            { name: 'HR', placeholder: 'HR' },
            { name: 'P(ms)', placeholder: 'P(ms)' },
            { name: 'PR(ms)', placeholder: 'PR(ms)' },
            { name: 'QRS(ms)', placeholder: 'QRS(ms)' },
            { name: 'QT/QTc(ms)', placeholder: 'QT/QTc(ms)' },
            { name: 'PAxis', placeholder: 'PAxis' },
            { name: 'QRS Axis', placeholder: 'QRS Axis' },
        ]
        return _.find(palceholderObj, ['name', currentEcgs])?.placeholder ?? ''
    }

    const ecgChart = () => {
        if (['HR', 'P(ms)', 'PR(ms)', 'QRS(ms)', 'QT/QTc(ms)', 'PAxis', 'QRS Axis'].includes(currentEcgs)) {
            return <BaseChart type={type} name={currentEcgs} />
        } else {
            return <DummyChart name={`${currentEcgs} Ecg chart not supported `} />
        }
    }

    const showChart = () => {
        if (ecgData?.length > 0) {
            return ecgChart()
        } else {
            return <DummyChart type={type} name={placeholderHelper()} />
        }
    }

    return <div>{!!currentEcgs && <>{ecgLoading ? <LoadingChart type={type} /> : showChart()}</>}</div>
}

const mapStateToProps = (state) => ({
    currentEcgs: state.ecg.currentEcgs,
    ecgData: state.ecgLists.ecgData,
    ecgLoading: state.ecgLists.ecgLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
