import { SET_CURRENT_CARDIAC, SET_CARDIAC_LOADING } from './cardiac.type'

const INITIAL_STATE = {
    currentCardiac: 'HR',
    loading: false,
}

const cardiacReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_CARDIAC:
            return {
                ...state,
                currentCardiac: action.payload,
                loading: false,
            }
        case SET_CARDIAC_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default cardiacReducer
