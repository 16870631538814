import {
    GET_ADVERSE_EVENT_LIST_DATA,
    SET_ADVERSE_EVENT_LIST_LOADING,
    GET_ADVERSE_EVENT_SUBSTANCE_DATA,
    SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
    CLEAR_ADVERSE_EVENT_SUBSTANCE_DATA,
    CLEAR_ADVERSE_EVENT_LIST_DATA,
    CLEAR_ADVERSE_EVENT_DATA,
    SET_CURRENT_ADVERSE_EVENT,
    DELETE_ADVERSE_EVENT,
} from './adverseEvent.type'

const INITIAL_STATE = {
    adverseEventListData: [],
    adverseEventListLoading: false,
    adverseEventSubstanceData: [],
    adverseEventSubstanceLoading: false,
    currentAdverseEvent: 'allergy',
}

const adverseEventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ADVERSE_EVENT_LIST_DATA:
            return {
                ...state,
                // list: action?.payload?.list,
                adverseEventListData: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                // adverseEventListLoading:false
            }
        case GET_ADVERSE_EVENT_SUBSTANCE_DATA:
            return {
                ...state,
                adverseEventSubstanceData: action?.payload,
                // adverseEventSubstanceLoading:false
            }

        case SET_ADVERSE_EVENT_LIST_LOADING:
            return {
                ...state,
                adverseEventListLoading: action.payload,
            }
        case SET_ADVERSE_EVENT_SUBSTANCE_LOADING:
            return {
                ...state,
                adverseEventSubstanceLoading: action.payload,
            }

        case SET_CURRENT_ADVERSE_EVENT:
            return {
                ...state,
                currentAdverseEvent: action?.payload,
                // adverseEventListLoading: false,
            }
        case CLEAR_ADVERSE_EVENT_LIST_DATA:
            return {
                ...state,
                adverseEventListData: [],
                // adverseEventListLoading: false,
            }
        case CLEAR_ADVERSE_EVENT_SUBSTANCE_DATA:
            return {
                ...state,
                adverseEventSubstanceData: [],
                // adverseEventSubstanceLoading: false,
            }
        case CLEAR_ADVERSE_EVENT_DATA:
            return {
                adverseEventListData: [],
                adverseEventListLoading: false,
                adverseEventSubstanceData: [],
                adverseEventSubstanceLoading: false,
                lud: '',
                currentAdverseEvent: 'allergy',
            }
        case DELETE_ADVERSE_EVENT:
            return state
        default:
            return state
    }
}

export default adverseEventReducer
