import React from 'react'
import { connect, useDispatch } from 'react-redux'
import EchoPills from '../echo-pills'
import EchoChart from '../echo-charts'
import { getEchoData, getEchoListData } from '../../../../redux/ESN/echoList/echoList.action'
import moment from 'moment'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'

export const Index = ({ patientId, token, echoLoading, currentEcho }) => {
    const dispatch = useDispatch()

    /* this triggers force re-renders */
    // React.useEffect(() => {
    //     const payload = {
    //         patientId: patientId,
    //     }
    //     dispatch(getEchoListData(payload, token))
    //     const sixMonth = moment().subtract(60, 'months').calendar()
    //     const proposedDate = moment(sixMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
    //     const payloadData = {
    //         patientId: patientId,
    //         type: currentEcho,
    //         date: proposedDate,
    //     }
    //     dispatch(getEchoData(payloadData, token))

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [patientId, token, currentEcho])
    /* --- */

    return (
        <div>
            <div className="d-flex">
                <div className="small-chart">
                    {echoLoading ? (
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    ) : (
                        <EchoChart />
                    )}
                </div>
                <div className="ms-3 w-100">
                    <EchoPills name="dashboard-pills" time="" />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    echoLoading: state.echo.loading,
    currentEcho: state.echo.currentEcho,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getEchoListData: (payload, token) => {
            dispatch(getEchoListData(payload, token))
        },
        getEchoData: (payload, token) => {
            dispatch(getEchoData(payload, token))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
