import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { getEchoData } from '../../../../redux/ESN/echoList/echoList.action'
import { setCurrentEcho } from '../../../../redux/ESN/echo/echo.action'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import _ from 'lodash'

export const Index = ({
    currentEcho,
    echoData,
    patientId,
    token,
    name,
    time,
    consultLetterFlag = false,
    patient,
}) => {
    const dispatch = useDispatch()

    const handleCurrentEcho = (current) => {
        dispatch(setCurrentEcho(current))
        updateGraphs(current)
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getEchoData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getEchoData(payload, token))
        }
    }

    useEffect(() => {
        time !== '' && updateGraphs(currentEcho)
    }, [time])
    /*  */

    function search(nameKey, myArray) {
        let temp = ''
        for (var i = 0; i < myArray?.length; i++) {
            if (myArray[i].name === nameKey) {
                temp = myArray[i].value
                return myArray[i].value
            }
        }
        if (temp === '') {
            return ''
        }
    }

    function searchFlag(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i].flag
            }
        }
    }
    // style classes for absolute ranges
    const pillStyle = (range) => {
        switch (range) {
            case 'mild':
                return 'mild action-pills'
            case 'moderate':
                return 'moderate action-pills'
            case 'severe':
                return 'severe action-pills'
            default:
                return 'action-pills'
        }
    }

    /* border and shading style function based on absolute range calculation */
    const pillRangeStyle = (pillName) => {
        const pillValue = search(pillName, echoData?.list)
        /* empty check */
        if (_.isNil(pillValue) || pillValue === '') return pillStyle()
        switch (pillName) {
            case 'EF (%)':
                if (_.inRange(pillValue, 41, 53)) return pillStyle('mild')
                if (_.inRange(pillValue, 30, 41)) return pillStyle('moderate')
                if (pillValue < 30) return pillStyle('severe')
                return pillStyle()

            case 'LA':
                if (pillValue < 20 || pillValue > 40) return pillStyle('mild')
                return pillStyle()

            case 'Ao R':
                if (
                    ((pillValue < 3.1 || pillValue > 3.4) && patient?.gender === 1) ||
                    ((pillValue < 3.1 || pillValue > 3.2) && patient?.gender === 2)
                )
                    return pillStyle('mild')
                return pillStyle()

            case 'Ao Asc':
                if (pillValue < 3.0 || pillValue > 4.0) return pillStyle('mild')
                return pillStyle()

            case 'RVSP':
                if (pillValue < 33 || pillValue > 40) return pillStyle('mild')
                return pillStyle()

            case 'AV Peak':
                if (pillValue >= 2.5 && pillValue <= 2.9) return pillStyle('mild')
                if (pillValue > 2.9 && pillValue <= 4) return pillStyle('moderate')
                if (pillValue > 4.0) return pillStyle('severe')
                return pillStyle()

            case 'AV Mean':
                if (pillValue < 4.0 || pillValue > 6.0) return pillStyle('mild')
                return pillStyle()

            case 'AV PHT':
                if (pillValue > 5 && pillValue <= 25) return pillStyle('mild')
                if (pillValue > 25 && pillValue <= 50) return pillStyle('moderate')
                if (pillValue > 50) return pillStyle('severe')
                return pillStyle()

            case 'MV Mean':
                if (_.inRange(pillValue, 2.5, 4.0)) return pillStyle('mild')
                if (_.inRange(pillValue, 1, 2.5)) return pillStyle('moderate')
                if (pillValue < 1) return pillStyle('severe')
                return pillStyle()

            case 'MV Area PHT':
                if (pillValue >= 1 && pillValue <= 5) return pillStyle('mild')
                if (pillValue > 5 && pillValue <= 10) return pillStyle('moderate')
                if (pillValue > 10) return pillStyle('severe')
                return pillStyle()

            default:
                return pillStyle()
        }
    }

    const pillflagStyle = (pillName) => {
        const pillFlag = searchFlag(pillName, echoData?.list)
        if (
            [
                'EF (%)',
                'LA',
                'Ao R',
                'Ao Asc',
                'RVSP',
                'AV Peak',
                'AV Mean',
                'AV PHT',
                'MV Mean',
                'MV Area PHT',
            ].includes(pillName)
        ) {
            return `flag-${pillFlag}`
        } else {
            return ''
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('EF (%)')
                        }}
                        className={clsx(
                            currentEcho === 'EF (%)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('EF (%)'),
                            pillflagStyle('EF (%)')
                        )}
                    >
                        EF (%) {search('EF (%)', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('LA')
                        }}
                        className={clsx(
                            currentEcho === 'LA' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('LA'),
                            pillflagStyle('LA')
                        )}
                    >
                        LA {search('LA', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('Ao R')
                        }}
                        className={clsx(
                            currentEcho === 'Ao R' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Ao R'),
                            pillflagStyle('Ao R')
                        )}
                    >
                        Ao R {search('Ao R', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('Ao Asc')
                        }}
                        className={clsx(
                            currentEcho === 'Ao Asc' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Ao Asc'),
                            pillflagStyle('Ao Asc')
                        )}
                    >
                        Ao Asc {search('Ao Asc', echoData?.list)}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('RVSP')
                        }}
                        className={clsx(
                            currentEcho === 'RVSP' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('RVSP'),
                            pillflagStyle('RVSP')
                        )}
                    >
                        RVSP {search('RVSP', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('AV Peak')
                        }}
                        className={clsx(
                            currentEcho === 'AV Peak' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('AV Peak'),
                            pillflagStyle('AV Peak')
                        )}
                    >
                        AV Peak {search('AV Peak', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('AV Mean')
                        }}
                        className={clsx(
                            currentEcho === 'AV Mean' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('AV Mean'),
                            pillflagStyle('AV Mean')
                        )}
                    >
                        AV MG {search('AV Mean', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('AV PHT')
                        }}
                        className={clsx(
                            currentEcho === 'AV PHT' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('AV PHT'),
                            pillflagStyle('AV PHT')
                        )}
                    >
                        AV PHT {search('AV PHT', echoData?.list)}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('MV Mean')
                        }}
                        className={clsx(
                            currentEcho === 'MV Mean' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('MV Mean'),
                            pillflagStyle('MV Mean')
                        )}
                    >
                        MV MG {search('MV Mean', echoData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcho('MV Area PHT')
                        }}
                        className={clsx(
                            currentEcho === 'MV Area PHT' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('MV Area PHT'),
                            pillflagStyle('MV Area PHT')
                        )}
                    >
                        MVA PHT {search('MV Area PHT', echoData?.list)}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentEcho: state.echo.currentEcho,
    echoData: state.echoList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    patient: state.patient.patientData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentEcho: (payload) => dispatch(setCurrentEcho(payload)),
        getEchoData: (payload, token) => dispatch(getEchoData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
