import * as React from 'react'
import './inputs.scss'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import variables from '../../../../theme/variables.scss'
import moment from 'moment'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useFormikContext } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextareaAutosize from '@mui/base/TextareaAutosize'

const Inputs = ({
    inputType,
    inputName,
    inputVal,
    inputSize,
    placeholderText,
    toggleBtnArr = [],
    associateFlag = false,
    minRow = 2,
    maxRow = 4,
    resize = true,
    ...props
}) => {
    const { setFieldValue, values, handleChange, handleBlur } = useFormikContext()

    /* Sizes */
    const inputSizeHandler = () => {
        switch (inputSize) {
            case 'small':
                return '20px !important'
            case 'medium':
                return '60px !important'
            case 'large':
                return '80px !important'
            case 'xl':
                return '90px !important'
            case 'xxl':
                return '110px !important'
            case 'xxxl':
                return '120px !important'
            default:
                return '40px !important'
        }
    }

    switch (inputType) {
        case 'toggleBtn':
            return (
                <div className="interval-class">
                    <ToggleButtonGroup
                        orientation="horizontal"
                        exclusive
                        fullWidth={true}
                        name={inputName}
                        value={inputVal}
                        onChange={(e, nextView) => setFieldValue(inputName, nextView)}
                    >
                        {toggleBtnArr.map((btn, idx) => (
                            <ToggleButton
                                key={idx}
                                name={btn}
                                value={btn}
                                aria-label={btn}
                                sx={{
                                    textTransform: 'capitalize !important',
                                    minWidth: inputSizeHandler(),
                                }}
                            >
                                {btn}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>
            )
        case 'counter':
            return (
                <>
                    <div
                        className="d-flex justify-content-between align-items-center my-1"
                        style={{
                            width: 'fit-content',
                            borderRadius: '30px',
                            height: '30px',
                            outline: 'none',
                            padding: '2px',
                            border: '1px solid',
                            borderColor: variables.lightGray1,
                            fontSize: '0.85rem',
                        }}
                    >
                        <RemoveCircleOutlineIcon
                            sx={{
                                color: variables.purple200,
                                cursor: inputVal <= 0 ? 'not-allowed' : 'pointer',
                            }}
                            onClick={() => {
                                if (inputVal > 0) setFieldValue(inputName, +inputVal - 1)
                            }}
                        />

                        <input
                            name={inputName}
                            value={inputVal}
                            onChange={handleChange}
                            type="number"
                            min="0"
                            className="small-rounded text-center"
                            style={{
                                borderWidth: '0px',
                                border: 'none',
                            }}
                        />
                        <AddCircleIcon
                            sx={{ color: variables.purple, cursor: 'pointer' }}
                            onClick={() => setFieldValue(inputName, +inputVal + 1)}
                        />
                    </div>
                </>
            )
        case 'dowpdown':
            return (
                <>
                    <FormControl size="small" className="m-1">
                        <Select
                            onClose={() => {
                                setTimeout(() => {
                                    document.activeElement.blur()
                                }, 0)
                            }}
                            value={inputVal}
                            name={inputName}
                            onChange={handleChange}
                            sx={{
                                width: 'fit-content',
                                height: '30px',
                                fontSize: '12px',

                                padding: '2px 6px 2px !important',
                                borderColor: variables.gray200,
                                borderRadius: '80px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    '&& fieldset': {
                                        borderColor: variables.lightGray1,
                                        outline: 'none',
                                    },
                                },
                                '&:focus': {
                                    '&& fieldset': {
                                        borderColor: variables.lightGray1,
                                        outline: 'none',
                                    },
                                },
                            }}
                        >
                            {toggleBtnArr.map((btn, idx) => (
                                <MenuItem
                                    key={idx}
                                    name={btn}
                                    value={btn}
                                    aria-label={btn}
                                    sx={{
                                        textTransform: 'capitalize !important',
                                        minWidth: inputSizeHandler(),
                                    }}
                                >
                                    {btn}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )
        case 'textInput':
            return (
                <>
                    <TextField
                        className="m-1"
                        id={inputName}
                        name={inputName}
                        value={inputVal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholderText}
                        InputProps={{
                            style: {
                                fontSize: '14px',
                                height: '30px',
                                borderColor: variables.lightGray1,
                            },
                        }}
                        sx={{
                            borderRadius: '80px !important',
                            borderColor: variables.lightGray1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: variables.lightGray1,
                                    borderRadius: '80px !important',
                                    fontSize: '14px',
                                },
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '80px !important',
                                '&.Mui-focused fieldset': {
                                    borderRadius: '80px !important',
                                },
                                '&.Mui-hover fieldset': {
                                    borderColor: variables.gray600,
                                    borderRadius: '80px !important',
                                },
                            },
                            '&:hover': {
                                '&& fieldset': {
                                    borderColor: variables.lightGray1,
                                    borderWidth: '1px',

                                    outline: 'none !important',
                                },
                            },
                            '&:focus': {
                                '&& fieldset': {
                                    borderColor: variables.purple,
                                    borderWidth: 1,
                                    outline: 'none !important',
                                },
                            },
                        }}
                    />
                </>
            )

        case 'dateInput':
            return (
                <>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            disableFuture={true}
                            inputFormat="MMM, YYYY"
                            views={['year', 'month']}
                            openTo="month"
                            disableMaskedInput
                            name={inputName}
                            value={inputVal}
                            onChange={(newValue) => {
                                setFieldValue(inputName, new Date(newValue))
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    sx={{
                                        maxWidth: '10rem !important',
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: '',
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </>
            )

        case 'textArea':
            return (
                <>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={minRow}
                        maxRows={maxRow}
                        placeholder="Add Details..."
                        name={inputName}
                        value={inputVal}
                        onChange={handleChange}
                        style={{
                            width: '100%',
                            resize: !!resize ? '' : 'none',
                            // cursor: 'not-allowed',
                            fontSize: '12px',
                            borderRadius: '6px',
                            padding: '6px',
                            borderColor: variables.lightGray1,
                            background: variables.white100,
                        }}
                        // disabled
                    />
                </>
            )
        default:
            return <p> Invalid Input </p>
    }
}
export default Inputs
