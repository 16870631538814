import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import DummyChart from '../../dummy-chart'
import ExDur from './ExDur'
import Charts from './Charts'
import PeakBPCharts from './bpPeakCharts'
import RestBPCharts from './bpRestCharts'
import LoadingChart from '../../labs-component/labs-charts/loading-chart'

export const Index = ({ currentGxt, gxtLoading, gxtData, type }) => {
    const placeholderHelper = () => {
        const palceholderObj = [
            { name: 'Ex Dur', placeholder: 'Ex Dur' },
            { name: 'Peak BP', placeholder: 'Peak BP' },
            { name: 'Rest BP', placeholder: 'Rest BP' },
            { name: 'METS', placeholder: 'METS' },
            { name: '% THR', placeholder: '% THR' },
            { name: 'Peak HR', placeholder: 'Peak HR' },
            { name: 'Rest HR', placeholder: 'Rest HR' },
            { name: 'Double Product', placeholder: 'Double Product' },
            { name: 'DTS', placeholder: 'DTS' },
        ]
        return _.find(palceholderObj, ['name', currentGxt])?.placeholder ?? ''
    }
    const gxtChart = () => {
        switch (currentGxt) {
            case 'Ex Dur':
                return <ExDur name={currentGxt} type={type} />
            case 'Peak BP':
                return <PeakBPCharts name={currentGxt} type={type} />
            case 'Rest BP':
                return <RestBPCharts name={currentGxt} type={type} />
            default:
                if (['METS', '% THR', 'Peak HR', 'Rest HR', 'Double Product', 'DTS'].includes(currentGxt))
                    return <Charts name={currentGxt} type={type} />
                return <DummyChart name={`${currentGxt} Gxt chart not supported `} />
        }
    }

    const showChart = () => {
        if (gxtData.length > 0) {
            return gxtChart()
        } else {
            return <DummyChart name={placeholderHelper()} />
        }
    }
    return <div>{!!currentGxt && <>{gxtLoading ? <LoadingChart type={type} /> : showChart()}</>}</div>
}

const mapStateToProps = (state) => ({
    currentGxt: state.gxt.currentGxt,
    gxtData: state.gxtList.gxtData,
    gxtLoading: state.gxtList.gxtLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
