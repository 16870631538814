import React, { createContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useSelector } from 'react-redux'

export const TranscribeContext = createContext({})

const TranscribeProvider = ({ children }) => {
    const [awsTranscribe, setAwsTranscribe] = useState(null)
    const loggedIn = useSelector((state) => state.loginData.loginResponse)

    useEffect(() => {
        // eslint-disable-next-line valid-typeof
        if (typeof window !== 'undefined' || typeof window !== undefined) {
            const fetchUser = async () => {
                // Get current authenticated user
                const user = await Auth.currentCredentials()
                // Get user's credentials
                const credentials = await Auth.essentialCredentials(user)

                const language = 'en-US'
                const region = process.env.REACT_APP_AWS_REGION
                const accessKey = credentials.accessKeyId
                const secretKey = credentials.secretAccessKey
                const sessionToken = credentials.sessionToken
                const sampleRate = 44100
                const specialty = 'PRIMARYCARE'
                const type = 'DICTATION'

                const awsTranscribe = new window.AWSTranscribe(
                    language,
                    region,
                    accessKey,
                    secretKey,
                    sessionToken,
                    sampleRate,
                    specialty,
                    type
                )

                setAwsTranscribe(awsTranscribe)
            }

            fetchUser()
        }
    }, [loggedIn])

    return (
        <TranscribeContext.Provider
            value={{
                awsTranscribe,
            }}
        >
            {children}
        </TranscribeContext.Provider>
    )
}

export default TranscribeProvider
