import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { toggleMriModal } from '../../../../redux/modals/toggleModal.actions'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import PdfViewer from '../../../pdf-viewer'
import FeedIcon from '@mui/icons-material/Feed'
import { blobToBase64, getS3File, getTrimedFilename, hasTifExtension } from '../../../../util'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import { getMriDocument, resetMriDocument } from '../../../../redux/CCM/MRIList/MRIList.action'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ mriModal, mriLUD, patientId, token, mriDocument, mriLoading }) => {
    const [alignment, setAlignment] = React.useState('Reports')
    const [time, setTime] = React.useState('60')
    const [showPdf, setShowPdf] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [isTif, setIsTif] = React.useState(false)
    const [fileName, setFileName] = React.useState('')
    const [detailsTime, setDetailsTime] = React.useState('60')
    const [fileID, setFileID] = React.useState('')
    const [pdfLoading, setPdfLoading] = React.useState(false)
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        dispatch(toggleMriModal(false))
        // dispatch(resetMriDocument())
    }

    const handleChangeTime = (value) => {
        setShowPdf(false)
        setFileID('')
        setTime(value)
        setUrl('')
        if (value !== 'lastvisit') {
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                date: proposedDate,
            }
            dispatch(getMriDocument(payload, token))
        } else {
            const payload = {
                patientId: patientId,
                date: value,
            }
            dispatch(getMriDocument(payload, token))
        }
    }

    /* handle doc */
    const handleDocument = (report) => {
        setPdfLoading(true)
        setUrl('')
        setShowPdf(true)
        setFileID(report?._id)
        setIsTif(hasTifExtension(report?.compressedKey || report?.key))
        getS3File(report?.compressedKey || report?.key)
            .then((res) => {
                blobToBase64(res?.Body).then((usablePdf) => {
                    setUrl(usablePdf)
                    setShowPdf(true)
                    setPdfLoading(false)
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                setPdfLoading(false)
            })
    }

    useEffect(() => {
        if (mriDocument?.length > 0) {
            handleDocument(mriDocument[0])
        }
    }, [mriDocument])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={mriModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">MRI</Typography>
                        <Typography className="gray-600 fs-9" gutterBottom>
                            {mriLUD !== ''
                                ? `Last refreshed ${moment(mriLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
                                : null}
                        </Typography>
                        <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                            Timeline
                        </Typography>
                        <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={time}
                                defaultValue="Select Timeline"
                                onChange={(e) => handleChangeTime(e.target.value)}
                                input={<BootstrapInput />}
                            >
                                <MenuItem className="fs-9" value="lastvisit">
                                    Since Last Visit
                                </MenuItem>
                                <MenuItem className="fs-9" value="1">
                                    Last 1 Month
                                </MenuItem>
                                <MenuItem className="fs-9" value="6">
                                    Last 6 Months
                                </MenuItem>
                                <MenuItem className="fs-9" value="12">
                                    Last 1 Year
                                </MenuItem>
                                <MenuItem className="fs-9" value="60">
                                    Last 5 Years
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {mriDocument?.length > 0 && mriLoading === false ? (
                            <Typography className="gray-600 fs-9 mt-3">
                                {' '}
                                Found {mriDocument?.length} result(s)
                            </Typography>
                        ) : null}
                        <div
                            className="overflow-auto mt-2"
                            style={{
                                maxHeight: '160px',
                                backgroundColor: variables.white100,
                                borderRadius: '5px',
                                border: '1px solid',
                                borderColor: variables.lightGray1,
                                minHeight: '100px',
                                overflow: 'auto',
                            }}
                        >
                            {mriLoading === false ? (
                                mriDocument?.length > 0 ? (
                                    mriDocument?.map((report, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                className={clsx(
                                                    fileID === report?._id && 'bg-indigo-50 fw-bold',
                                                    'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer'
                                                )}
                                            >
                                                <Box
                                                    className="cursor-pointer"
                                                    display="flex"
                                                    alignItems={'center'}
                                                    gap={1}
                                                    onClick={() => handleDocument(report)}
                                                >
                                                    <FeedIcon className="fs-8" />
                                                    {getTrimedFilename(report?.name)}
                                                </Box>
                                            </Box>
                                        )
                                    })
                                ) : (
                                    <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                )
                            ) : (
                                <div className="loader">
                                    <img src="/loader.gif" alt="loader" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-75 p-3 overflow-auto">
                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">Report</Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* pdf */}
                        {/* No Data Found Right side */}
                        {mriDocument?.length === 0 && !showPdf && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {mriLoading === true ? (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {mriDocument?.length > 0 && !url && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {pdfLoading === true ? (
                                        <div>
                                            <img src="/loader.gif" width="35" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {!!url && !pdfLoading && !!showPdf && !mriLoading && (
                            <PdfViewer url={url} isTif={isTif} />
                        )}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        mriModal: state.toggleModals.mriModal,
        mriLUD: state.MRI.lud,
        mriDocument: state.MRI.mriDocuments,
        mriLoading: state.MRI.mriLoading,
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleMriModal: (payload) => dispatch(toggleMriModal(payload)),
        resetMriDocument: () => dispatch(resetMriDocument()),
        getMriDocument: (payload, token) => dispatch(getMriDocument(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
