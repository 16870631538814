import React from 'react'
import variables from '../../../../theme/variables.scss'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import { useFormikContext } from 'formik'
import { generateSymptom } from '../../../../util/index'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    padding: '2px 8px 2px',
    border: `1px solid ${variables.purple}`,
    borderRadius: '6px',
    color: variables.purple,
    '&.Mui-selected:hover': {
        color: 'white',
        backgroundColor: variables.purple,
    },
    '&.Mui-selected': { color: 'white', backgroundColor: variables.purple },
}))

export default function NoToAllToggle({ standaloneSelected, setStandaloneSelected, setExpanded, values }) {
    const { setFieldValue } = useFormikContext()

    const handleOnChange = () => {
        if (!standaloneSelected) {
            setExpanded(false)
            setFieldValue('intervalHistory', [
                generateSymptom('Pain', {
                    _id: values?.intervalHistory[0]?._id ?? null,
                    paUid: values?.intervalHistory[0]?.paUid ?? null,
                }),
                generateSymptom('Shortness of Breath', {
                    _id: values?.intervalHistory[1]?._id ?? null,
                    paUid: values?.intervalHistory[1]?.paUid ?? null,
                    nyhaClass: null,
                    ccsClass: null,
                }),
                generateSymptom('Palpitations', {
                    _id: values?.intervalHistory[2]?._id ?? null,
                    paUid: values?.intervalHistory[2]?.paUid ?? null,
                }),
                generateSymptom('(Pre)syncope', {
                    ccsClass: null,
                    _id: values?.intervalHistory[3]?._id ?? null,
                    paUid: values?.intervalHistory[3]?.paUid ?? null,
                }),
                generateSymptom('Edema', {
                    ccsClass: null,
                    _id: values?.intervalHistory[4]?._id ?? null,
                    paUid: values?.intervalHistory[4]?.paUid ?? null,
                }),
                {
                    symptom: 'Description',
                    _id: values?.intervalHistory[5]?._id ?? null,
                    paUid: values?.intervalHistory[5]?.paUid ?? null,
                    additionalNotes: '',
                    active: false,
                },
            ])
        }
        setStandaloneSelected(!standaloneSelected)
    }

    return (
        <StyledToggleButton value="check" selected={standaloneSelected} onChange={handleOnChange}>
            No to all
        </StyledToggleButton>
    )
}
