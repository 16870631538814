import axios from 'axios'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import TuneIcon from '@mui/icons-material/Tune'
import Tooltip from '@mui/material/Tooltip'
import { toggleDeactivateModal, toggleSessionModal } from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'

const FilterBox = ({ token, filterOptions, setFilterOptions }) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    /* Chapters */
    const [chapterSearchValue, setChapterSearchValue] = React.useState(null)
    const [chapterSearchOptions, setChapterSearchOptions] = React.useState([])

    /*  Sections  */
    const [sectionSearchValue, setSectionSearchValue] = React.useState(null)
    const [sectionSearchOptions, setSectionSearchOptions] = React.useState([])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    /* get chapter list  from api */
    const fetchChapterOptions = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_CARD_URL}/diagnosis-history/chapters`, {
                headers: {
                    accessToken: token,
                },
            })
            if (result?.data?.status === 200) {
                setChapterSearchOptions(result?.data?.output ?? [])
            } else if (result?.data?.status === 401) {
                dispatch(toggleSessionModal(true, result?.data?.error))
            } else if (result?.data?.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (_.isEmpty(chapterSearchOptions)) {
            fetchChapterOptions()
        }
    }, [anchorEl])

    /* get Section list from api */
    const fetchSectionOptions = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_CARD_URL}/diagnosis-history/sections?chapter=${chapterSearchValue}`,
                {
                    headers: {
                        accessToken: token,
                    },
                }
            )
            if (result?.data?.status === 200) {
                setSectionSearchOptions(result?.data?.output ?? [])
            } else if (result?.data?.status === 401) {
                dispatch(toggleSessionModal(true, result?.data?.error))
            } else if (result?.data?.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (!_.isEmpty(chapterSearchValue)) {
            fetchSectionOptions()
        }
    }, [chapterSearchValue])

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Advanced Search Filter">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <TuneIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        //width control
                        maxWidth: '800px',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {/* filter options */}
                <Typography className="mt-2 px-2 gray-600 fs-6 fw-bold" gutterBottom>
                    Advanced Search Filter
                </Typography>
                <Grid className="p-2" container direction="column" rowSpacing={2} columnSpacing={4}>
                    {/* chapter */}
                    <Grid item>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Chapter
                        </Typography>
                        <div className="d-flex">
                            <Autocomplete
                                size="small"
                                value={chapterSearchValue}
                                onChange={(event, newValue) => {
                                    setChapterSearchValue(newValue)
                                }}
                                disablePortal
                                sx={{
                                    maxHeight: 140,
                                }}
                                id="combo-box-demo"
                                options={chapterSearchOptions}
                                fullWidth
                                getOptionLabel={(option) => `${option}`}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Search Chapters"
                                        sx={{ minWidth: '400px' }}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return option === value
                                }}
                            />
                        </div>
                    </Grid>
                    {/* sections */}
                    {!!chapterSearchValue ? (
                        <Grid item>
                            <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                Section
                            </Typography>
                            <div className="d-flex">
                                <Autocomplete
                                    // disabled
                                    size="small"
                                    value={sectionSearchValue}
                                    onChange={(event, newValue) => {
                                        setSectionSearchValue(newValue)
                                    }}
                                    disablePortal
                                    sx={{
                                        maxHeight: 140,
                                    }}
                                    id="combo-box-demo"
                                    options={sectionSearchOptions}
                                    fullWidth
                                    getOptionLabel={(option) => `${option}`}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search Sections"
                                            sx={{ minWidth: '400px' }}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                        return option === value
                                    }}
                                />
                            </div>
                        </Grid>
                    ) : null}

                    {/* Apply filter */}
                    {!!chapterSearchValue || !!sectionSearchValue ? (
                        <Grid item sx={{ mt: '2px' }}>
                            <Button
                                variant="contained"
                                size="small"
                                className="btn-primary"
                                onClick={() => {
                                    setFilterOptions({
                                        ...filterOptions,
                                        chapterCode: chapterSearchValue,
                                        sectionCode: sectionSearchValue,
                                    })
                                    handleClose()
                                }}
                                fullWidth
                                disabled={!!chapterSearchValue && !!sectionSearchValue ? false : true}
                            >
                                Apply Filter
                            </Button>
                        </Grid>
                    ) : null}

                    {/*  Reset filter */}
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => {
                                setChapterSearchValue(null)
                                setSectionSearchValue(null)
                                setFilterOptions({
                                    ...filterOptions,
                                    sectionCode: null,
                                    chapterCode: null,
                                })
                                handleClose()
                            }}
                            fullWidth
                            disabled={
                                !!chapterSearchValue ||
                                !!sectionSearchValue ||
                                !!filterOptions ||
                                !!filterOptions
                                    ? false
                                    : true
                            }
                        >
                            Reset Filter
                        </Button>
                    </Grid>
                </Grid>
            </Menu>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterBox)
