import { SET_CURRENT_ECGS, SET_ECG_LOADING } from './ecg.type'

const INITIAL_STATE = {
    currentEcgs: 'HR',
    loading: false,
}

const ecgsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_ECGS:
            return {
                ...state,
                currentEcgs: action.payload,
                loading: false,
            }
        case SET_ECG_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default ecgsReducer
