import { SET_CURRENT_HOLTER, SET_HOLTER_LOADING } from './holter.type'

const INITIAL_STATE = {
    currentHolter: 'Max HR',
    loading: false,
}

const holterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_HOLTER:
            return {
                ...state,
                currentHolter: action.payload,
                loading: false,
            }
        case SET_HOLTER_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default holterReducer
