import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ConsultDetails from '../consult-details'
import { toggleConsultModal } from '../../../redux/modals/toggleModal.actions'
import { getConsultDocuments } from '../../../redux/consultNotes/consultNotes.action'

export const Index = ({ patientId, token }) => {
    const dispatch = useDispatch()

    const [expanded, setExpanded] = React.useState(true)
    return (
        <div>
            <Accordion className="dashboard-accordion" sx={{ boxShadow: 'none' }} expanded={expanded}>
                <AccordionSummary
                    sx={{
                        background: '#F3F4F6',
                        boxShadow: 'none',
                        borderRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        borderBottomRightRadius: '0.25rem',
                        padding: '0px 2px 0px !important',
                        cursor: 'unset !important',
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}
                        />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className=" cursor-default gray-900 fs-8 fw-normal "
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            // textAlign: 'right',
                            fontSize: '0.85rem',
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            padding: '4px 2px 0px  !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontWeight: 600,
                        }}
                    >
                        Consult Notes
                        <span className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={(event) => {
                                    const payload = {
                                        patientId: patientId,
                                        type: 'MY LETTERS',
                                    }
                                    dispatch(getConsultDocuments(payload, token))
                                    dispatch(toggleConsultModal(true))
                                    event.stopPropagation()
                                }}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0px 0px 4px !important',
                    }}
                >
                    <Box sx={{ padding: 1 }}>
                        <ConsultDetails />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleConsultModal: (payload) => dispatch(toggleConsultModal(payload)),
        getConsultDocuments: (payload, token) => dispatch(getConsultDocuments(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
