import React from 'react'
import PropTypes from 'prop-types'
import '../../interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { toggleCardiacTimelineModal } from '../../../redux/modals/toggleModal.actions'
import CardiacHistoryTimeline from '../cardiac-modal/CardiacHistoryTimeline'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const GridWrapper = ({ children, classNames }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
                py: 2,
            }}
            className={classNames}
        >
            {children}
        </Grid>
    )
}

export const CardiacTimelineModal = ({ cardiacTimelineModal, cardiacHistoryListData }) => {
    const dispatch = useDispatch()

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return

        dispatch(toggleCardiacTimelineModal(false))
    }

    const CardaicHistoryTimelineArr =
        cardiacHistoryListData
            ?.find((el, idx) => el?.displayedDiagnosisCreateSource === 'generatedletters')
            ?.rawText?.split('\n') ?? []

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="small-modal"
                open={cardiacTimelineModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className="fs-5 fw-bold">Cardiac History Timeline</Typography>
                </BootstrapDialogTitle>

                <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
                    <Box sx={{ minWidth: '100%' }}>
                        <GridWrapper>
                            <Grid item xs={11}>
                                <Box>
                                    <>
                                        <Typography className="gray-900 cursor-default fw-bold fs-7 mt-1 mb-2 text-capitalize">
                                            Cardiac History Break-up (from latest Generated letter)
                                        </Typography>
                                        {_.isEmpty(CardaicHistoryTimelineArr) ? (
                                            <Box
                                                className="d-flex justify-content-center align-items-center p-4"
                                                style={{
                                                    border: '1px solid',
                                                    borderColor: variables.gray200,
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                <Typography className="fs-9">No Data Available</Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    backgroundColor: variables.gray100,
                                                    minWidth: '100%',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                <CardiacHistoryTimeline
                                                    timelineData={CardaicHistoryTimelineArr}
                                                />
                                            </Box>
                                        )}
                                    </>
                                </Box>
                            </Grid>
                        </GridWrapper>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cardiacTimelineModal: state?.toggleModals?.cardiacTimelineModal,
        cardiacHistoryListData: state?.cardiacHistory?.cardiacHistoryListData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacTimelineModal: (payload) => dispatch(toggleCardiacTimelineModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardiacTimelineModal)
