import * as React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import variables from '../../../theme/variables.scss'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: variables.purple,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textTransform: 'capitalize',
    },
}))

const MedicalHistoryTable = ({ tableData }) => {
    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 450,
                        marginBottom: 0.5,
                    }}
                    size="small"
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                    width: '40%',
                                }}
                            >
                                {tableData?.subDiagnosis ?? 'Sub-Diagnosis: N/A'}
                            </StyledTableCell>

                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                    width: '20%',
                                }}
                                align="center"
                            >
                                {tableData?.displayedDiagnosisCreateSource === 'generatedletters'
                                    ? `Dx ${tableData?.diagnoseYear ?? tableData?.diagnoseDateRaw}`
                                    : !!tableData?.diagnoseDate
                                    ? `Dx ${moment.utc(tableData?.diagnoseDate).year()}`
                                    : '-'}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                    width: '20%',
                                }}
                                align="center"
                            >
                                {!_.isEmpty(tableData?.treatment) ? tableData?.treatment : '-'}
                            </StyledTableCell>

                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="center"
                            >
                                {tableData?.cardiacState?.name ?? '-'} {tableData?.cardiacState?.value ?? '-'}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryTable)
