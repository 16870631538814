import PropTypes from 'prop-types'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { toggleSessionModal } from '../../redux/modals/toggleModal.actions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ sessionModal, sessionModalMessage }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(
            toggleSessionModal(false, {
                errorCode: 44,
                message: 'Session expired please re-login again',
            })
        )
        localStorage.clear()
        window.location.replace('/')
    }

    const handleOpenSignIn = () => {
        dispatch(
            toggleSessionModal(false, {
                errorCode: 44,
                message: 'Session expired please re-login again',
            })
        )
        localStorage.clear()
        window.location.replace('/')
    }

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="small-modal" open={sessionModal}>
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Session Expired
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography className="fw-bold fs-5" gutterBottom>
                        {sessionModalMessage?.message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenSignIn} variant="contained" className="btn-primary">
                        Ok
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionModal: state.toggleModals.sessionModal,
        sessionModalMessage: state.toggleModals.sessionModalData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
