import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import { getEcgsData } from '../../../redux/ECH/ecgList/ecgList.action'
import { setCurrentEcg } from '../../../redux/ECH/ecg/ecg.action'
import moment from 'moment'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'

export const Index = ({
    currentEcgs,
    ecgData,
    patientId,
    token,
    name,
    time,
    consultLetterFlag = false,
    patient,
}) => {
    const dispatch = useDispatch()
    const handleCurrentEcg = (current) => {
        updateGraphs(current)
        dispatch(setCurrentEcg(current))
    }

    function search(nameKey, myArray) {
        let temp = ''
        for (var i = 0; i < myArray?.length; i++) {
            if (nameKey === 'BPdia') {
                if (myArray[i].name === 'bp') {
                    temp = myArray[i].diaValue
                    return myArray[i].diaValue
                }
            } else {
                if (myArray[i].name === nameKey) {
                    temp = myArray[i].value
                    return myArray[i].value
                }
            }
        }
        if (temp === '') {
            return ''
        }
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getEcgsData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getEcgsData(payload, token))
        }
    }

    useEffect(() => {
        time !== '' && updateGraphs(currentEcgs)
    }, [time])

    function searchFlag(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i].flag
            }
        }
    }
    // style classes for absolute ranges
    const pillStyle = (range) => {
        switch (range) {
            case 'mild':
                return 'mild action-pills'
            case 'moderate':
                return 'moderate action-pills'
            case 'severe':
                return 'severe action-pills'
            default:
                return 'action-pills'
        }
    }
    /* border and shading style function based on absolute range calculation */
    const pillRangeStyle = (pillName) => {
        const pillValue = search(pillName, ecgData?.list)
        if (_.isNil(pillValue) || pillValue === '') return pillStyle()
        switch (pillName) {
            case 'HR':
                if ((pillValue >= 81 && pillValue <= 89) || _.inRange(pillValue, 50, 60))
                    return pillStyle('mild')
                if ((pillValue > 89 && pillValue < 125) || _.inRange(pillValue, 40, 50))
                    return pillStyle('moderate')
                if (pillValue >= 125 || pillValue < 40) return pillStyle('severe')
                return pillStyle()
            case 'PR(ms)':
                if (pillValue < 120 || pillValue > 200) return pillStyle('mild')
                return pillStyle()

            case 'QRS(ms)':
                if (pillValue >= 111 && pillValue <= 120) return pillStyle('mild')
                if (pillValue > 120 && pillValue <= 150) return pillStyle('moderate')
                if (pillValue > 150) return pillStyle('severe')
                return pillStyle()

            case 'QT/QTc(ms)':
                if (patient?.gender === 1) {
                    if (pillValue < 350 || pillValue > 450) return pillStyle('mild')
                } else {
                    if (pillValue > 360 || pillValue > 460) return pillStyle('mild')
                }
                return pillStyle()

            case 'P(ms)':
                if (pillValue > 80) return pillStyle('mild')
                return pillStyle()

            case 'PAxis':
                if (pillValue < 30 || pillValue > 90) return pillStyle('mild')
                return pillStyle()

            case 'QRS Axis':
                if (_.inRange(pillValue, -45, -30) || (pillValue > 90 && pillValue <= 105))
                    return pillStyle('mild')
                if (_.inRange(pillValue, -90, -45) || (pillValue > 105 && pillValue <= 180))
                    return pillStyle('moderate')
                if (pillValue > 180 && pillValue <= 270) return pillStyle('severe')
                return pillStyle()

            default:
                return pillStyle()
        }
    }

    const pillflagStyle = (pillName) => {
        const pillFlag = searchFlag(pillName, ecgData?.list)
        if (['HR', 'PR(ms)', 'QRS(ms)', 'QT/QTc(ms)', 'P(ms)', 'PAxis', 'QRS Axis'].includes(pillName)) {
            return `flag-${pillFlag}`
        } else {
            return ''
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={3.5}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('HR')
                        }}
                        className={clsx(
                            currentEcgs === 'HR' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('HR'),
                            pillflagStyle('HR')
                        )}
                    >
                        HR {search('HR', ecgData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('PR(ms)')
                        }}
                        className={clsx(
                            currentEcgs === 'PR(ms)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('PR(ms)'),
                            pillflagStyle('PR(ms)')
                        )}
                    >
                        PR(ms) {search('PR(ms)', ecgData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('QRS(ms)')
                        }}
                        className={clsx(
                            currentEcgs === 'QRS(ms)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('QRS(ms)'),
                            pillflagStyle('QRS(ms)')
                        )}
                    >
                        QRS {search('QRS(ms)', ecgData?.list)}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('QT/QTc(ms)')
                        }}
                        className={clsx(
                            currentEcgs === 'QT/QTc(ms)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('QT/QTc(m)'),
                            pillflagStyle('QT/QTc(ms)')
                        )}
                    >
                        QTc(ms) {search('QT/QTc(ms)', ecgData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('P(ms)')
                        }}
                        className={clsx(
                            currentEcgs === 'P(ms)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('P(ms)'),
                            pillflagStyle('P(ms)')
                        )}
                    >
                        P(ms) {search('P(ms)', ecgData?.list)}
                    </div>

                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('PAxis')
                        }}
                        className={clsx(
                            currentEcgs === 'PAxis' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('PAxis'),
                            pillflagStyle('PAxis')
                        )}
                    >
                        P Ax {search('PAxis', ecgData?.list)}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentEcg('QRS Axis')
                        }}
                        className={clsx(
                            currentEcgs === 'QRS Axis' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('QRS Axis'),
                            pillflagStyle('QRS Axis')
                        )}
                    >
                        QRS Ax {search('QRS Axis', ecgData?.list)}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentEcgs: state.ecg.currentEcgs,
    ecgData: state.ecgLists,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    patient: state.patient.patientData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentEcg: (payload) => dispatch(setCurrentEcg(payload)),
        getEcgsData: (payload, token) => dispatch(getEcgsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
