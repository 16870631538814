import { CLEAR_MRI, GET_MRI_DOCUMENT, MRI_DOC_LOADING, RESET_MRI_MODAL } from './MRIList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'

export const getMriDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: MRI_DOC_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/mri/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_MRI_DOCUMENT,
                    payload: response.data.output,
                })
                dispatch({
                    type: MRI_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: MRI_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: MRI_DOC_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const resetMriDocument = () => (dispatch) => {
    dispatch({
        type: RESET_MRI_MODAL,
    })
}

export const clearMri = () => async (dispatch) => {
    dispatch({
        type: CLEAR_MRI,
        payload: true,
    })
}
