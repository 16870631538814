import {
    GET_HOLTER_DATA,
    GET_HOLTER_DOCUMENT,
    GET_HOLTER_LIST_DATA,
    HOLTER_DOC_LOADING,
    RESET_HOLTER_DOCUMENT,
    SET_ECG_LOADING,
    CLEAR_HOLTER,
} from './holterList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'

export const getHolterListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ECG_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/holter/list`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_HOLTER_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getHolterData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ECG_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/holter/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_HOLTER_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ECG_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getHolterDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: HOLTER_DOC_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/holter/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_HOLTER_DOCUMENT,
                    payload: response.data.output,
                })
                dispatch({
                    type: HOLTER_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: HOLTER_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: HOLTER_DOC_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const resetHolterDocument = () => (dispatch) => {
    dispatch({
        type: RESET_HOLTER_DOCUMENT,
    })
}

export const clearHolter = () => (dispatch) => {
    dispatch({
        type: CLEAR_HOLTER,
    })
}
