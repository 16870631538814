import React from 'react'
import variables from '../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SocialHistory from '../../social-history/social-details/index'

const SocialHistoryInfo = () => {
    return (
        <Box sx={{ border: 1, borderRadius: '0.25rem', p: 1, borderColor: variables.lightGray1 }}>
            <Typography className="gray-600 fs-7 fw-normal pb-1">Patient Social History</Typography>
            <SocialHistory />
        </Box>
    )
}

export default SocialHistoryInfo
