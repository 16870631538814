export const GET_LABS_LIST_DATA = 'GET_LABS_LIST_DATA'
export const GET_LABS_DATA = 'GET_LABS_DATA'
export const SET_LABS_LOADING = 'SET_LABS_LOADING'
export const SET_LABS_CATEGORY = 'SET_LABS_CATEGORY'
export const SET_LABS_CATEGORY_LOADING = 'SET_LABS_CATEGORY_LOADING'
export const SET_LABS_REPORTS = 'SET_LABS_REPORTS'
export const SET_LABS_REPORTS_LOADING = 'SET_LABS_REPORTS_LOADING'
export const CLEAR_LAB_REPORTS = 'CLEAR_LAB_REPORTS'
export const CLEAR_LABS = 'CLER_LABS'
export const GET_LABS_MODAL_DATA = 'GET_LABS_MODAL_DATA'
export const GET_LABS_MODAL_CHART_LODING = 'GET_LABS_MODAL_CHART_LODING'
