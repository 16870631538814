import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import VitalChart from '../vitals-charts/index'
import VitalPills from '../vital-pills'
import { toggleVitalModal } from '../../../redux/modals/toggleModal.actions'
import moment from 'moment'
import { getVitalListData } from '../../../redux/vitalList/vitalList.action'
import InfoBox from './InfoBox'

export const Index = ({ vitalLoading, vital, patientId, token }) => {
    const dispatch = useDispatch()

    return (
        <div>
            <Accordion className="dashboard-accordion" sx={{ boxShadow: 'none' }} expanded={true}>
                <AccordionSummary
                    sx={{ background: '#F3F4F6', boxShadow: 'none', borderRadius: '0.25rem' }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-900 fs-8 fw-normal cursor-default"
                        sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}
                    >
                        Vitals
                    </Typography>
                    <Box
                        className="d-flex justify-content-end gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.85rem',
                        }}
                    >
                        <Box className="ms-2 me-1 cursor-pointer">
                            <InfoBox />
                        </Box>
                        {vital?.lud !== ''
                            ? `Last updated ${moment.parseZone(vital?.lud).format('MMM DD, YYYY')}`
                            : null}
                        <div className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={() => dispatch(toggleVitalModal(true))}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </div>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex">
                        <div className="small-chart">
                            {vitalLoading ? (
                                <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                            ) : (
                                <VitalChart />
                            )}
                        </div>
                        <div className="mt-3 ms-3 w-100">
                            <VitalPills name="dashboard-pills" time="" />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentVital: state.vital.currentVital,
    vital: state.vitalList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    vitalLoading: state.vitalList.vitalLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleVitalModal: (payload) => dispatch(toggleVitalModal(payload)),
        getVitalListData: (payload, token) => dispatch(getVitalListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
