import React from 'react'
import './dummy-chart.scss'

export const Index = (props) => {
    return (
        <div>
            <div style={{ border: '1px solid #E5E7EB', borderRadius: '5px', height: '100%' }}>
                <div className="p-2 pb-0">
                    <div className="d-flex justify-content-between">
                        <span className="fw-normal fs-10 gray-600">
                            {props.type !== 'desktop' && props.name}
                        </span>
                        <span className="fs-9 gray-400"></span>
                    </div>
                    <p className="fw-bold mb-0 fs-8"></p>
                </div>
                {props.type !== 'desktop' ? (
                    <div className="dummy-chart" style={{ height: '99.6px' }}>
                        {/* <div className='dummy-line'></div> */}
                        <div style={{ marginTop: '-25px' }} className="gray-400">
                            N/A
                        </div>
                    </div>
                ) : (
                    <div className="dummy-chart" style={{ height: '340px' }}>
                        {/* <div className='dummy-line'></div> */}
                        <div style={{ marginTop: '-10px' }} className="gray-400">
                            N/A
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Index
