const awsConfig = {
    Auth: {
        identityPoolId: `${process.env.REACT_APP_AWS_IDENTITY_POOL_ID}`,
        region: `${process.env.REACT_APP_AWS_REGION}`,
        identityPoolRegion: `${process.env.REACT_APP_AWS_REGION}`,
        userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
        userPoolWebClientId: `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    },
    Storage: {
        AWSS3: {
            bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}`,
            region: `${process.env.REACT_APP_AWS_REGION}`,
        },
    },
}

export default awsConfig
