import React from 'react'
import LoginForm from '../../components/authentications/login-verifcation'
import './login.scss'

const Login = () => {
    return (
        <>
            <div className="login-bg px-3">
                <div className="login-form-container">
                    <div className="text-center mb-3">
                        <img className="image-fluid main-logo" src="/images/logo.svg" alt="flow logo" />
                    </div>
                    <div className="text-center mb-3">
                        <img
                            className="image-fluid logo-slogan"
                            src="/images/logo-slogan.svg"
                            alt="flow logo slogan"
                        />
                    </div>
                    <LoginForm />
                    <div className="my-4 fw-bold d-flex justify-content-evenly">
                        <a
                            href="https://cardeahealth.io/#cardea-team"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <span style={{ textDecoration: 'underline' }}>FAQs</span>
                            <span>{' >'}</span>
                        </a>
                        <div>
                            <a
                                href="mailto:flow-support@cardeahealth.io"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                rel="noreferrer"
                            >
                                <span style={{ textDecoration: 'underline' }}>Customer Support</span>
                                <span>{' >'}</span>
                            </a>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center fw-bold">
                        <img src="/images/logo-icon-small.svg" alt="logo icon small" />
                        <div className="mx-3 text-cardia-health">
                            A product by <span className="pl-2 tx-primary">CARDEA HEALTH</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
