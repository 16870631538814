import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import DummyChart from '../../../dummy-chart'
import LoadingChart from '../../../labs-component/labs-charts/loading-chart'
import BaseChart from './Base-chart'

export const Index = ({
    cardiacMedicationDosages,
    cardiacMedLoading,
    measurementLoading,
    measurementData,
    type,
}) => {
    const doseChart = () => {
        return <BaseChart type={type} name="Dose-Effect Analysis" />
    }

    const showChart = () => {
        if (
            cardiacMedicationDosages?.length > 0 ||
            cardiacMedLoading === true ||
            measurementLoading === true
        ) {
            return doseChart()
        } else {
            return <DummyChart type="desktop" name="Dose-Effect Analysis" />
        }
    }
    return <div> {showChart()}</div>
}

const mapStateToProps = (state) => ({
    cardiacMedicationDosages: state.cardiacList.cardiacData[0]?.dosages,
    cardiacMedLoading: state?.cardiacList?.cardiacMedLoading,
    measurementLoading: state?.cardiacList?.measurementLoading,
    measurementData: state?.cardiacList?.measurementData,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
