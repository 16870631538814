import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { summaryTextGenerator } from '../../../util'

const IntervalHistoryInfo = ({ intervalHistoryData }) => {
    /* summary state handler */
    const summaryHandlerFunction = (option) => {
        return summaryTextGenerator(_.find(intervalHistoryData?.intervalHistory, ['symptom', option])) ?? ''
    }

    const showIntervalHandler = (option) => {
        return !!_.find(intervalHistoryData?.intervalHistory, { symptom: option, active: true })
    }

    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal">Interval History</Typography>
            {_.filter(intervalHistoryData?.intervalHistory, { active: true })?.length > 0 ? null : (
                <Typography className="gray-900 fw-light fs-9 ">Interval History Not Available</Typography>
            )}
            <Grid container spacing={3} sx={{ pt: 1 }}>
                {showIntervalHandler('Pain') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Pain</Typography>
                        <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                            {summaryHandlerFunction('Pain')}
                        </Typography>
                    </Grid>
                )}
                {showIntervalHandler('Shortness of Breath') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Shortness of Breath</Typography>
                        <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                            {summaryHandlerFunction('Shortness of Breath')}
                        </Typography>
                    </Grid>
                )}
                {showIntervalHandler('Palpitations') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Palpitations</Typography>
                        <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                            {summaryHandlerFunction('Palpitations')}
                        </Typography>
                    </Grid>
                )}
                {showIntervalHandler('(Pre)syncope') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">(Pre)syncope</Typography>
                        <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                            {summaryHandlerFunction('(Pre)syncope')}
                        </Typography>
                    </Grid>
                )}
                {showIntervalHandler('Edema') && (
                    <Grid item xs={4}>
                        <Typography className="gray-900 fs-8 fw-normal pb-1">Edema</Typography>
                        <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                            {summaryHandlerFunction('Edema')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container sx={{ pt: 3 }}>
                <Grid item xs={12}>
                    <Typography className="gray-900 fs-8 fw-normal pb-1">Additional Notes</Typography>
                    <Typography className="gray-900 fs-9 fw-light" sx={{ textAlign: 'justify' }}>
                        {intervalHistoryData?.intervalHistory[5]?.additionalNotes}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        intervalHistoryData: state?.intervalHistory?.list,
        lastUpdatedInterval: state?.intervalHistory?.lud,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(IntervalHistoryInfo)
