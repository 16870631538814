import { CATH_DOC_LOADING, CLEAR_CATH, GET_CATH_DOCUMENT, RESET_CATH_MODAL } from './cathList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'

export const getCathDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: CATH_DOC_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/cath/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CATH_DOCUMENT,
                    payload: response.data.output,
                })
                dispatch({
                    type: CATH_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: CATH_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: CATH_DOC_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const resetCathDocument = () => (dispatch) => {
    dispatch({
        type: RESET_CATH_MODAL,
    })
}

export const clearCath = () => async (dispatch) => {
    dispatch({
        type: CLEAR_CATH,
        payload: true,
    })
}
