import { REMOVE_ALERT, SET_ALERT } from './alert.type'

export const setAlert = (payload) => async (dispatch) => {
    dispatch({
        type: SET_ALERT,
        payload: payload,
    })
}

export const removeAlert = () => async (dispatch) => {
    dispatch({
        type: REMOVE_ALERT,
    })
}
