import React from 'react'
import PropTypes from 'prop-types'
import '../../interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toggleScreenFlowModal } from '../../../redux/modals/toggleModal.actions'
import { trialList } from '../../../util/screen-flow-data'
import MainInfo from './internal-components/MainInfo'
import TrialForm from './internal-components/TrialForm'
import CustomizedAccordions from '../../interval-history/interval-modal/interval-components/Accordian'
import CriteriaDetails from './internal-components/CriteriaDetails'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const ScreenFlowModal = ({
    patientId,
    screenFlowModal,
    currentTrial,
    trialListData,
    trialListLoading,
    trialCriteriaData,
    trialCriteriaLoading,
    isDemoPatient,
}) => {
    const [value, setValue] = React.useState('1')
    const [pageValue, setPageValue] = React.useState('1')
    const [alignment, setAlignment] = React.useState('Update')
    const [expanded, setExpanded] = React.useState(false)
    const [loading, setLoading] = React.useState(false) // local form loader
    const dispatch = useDispatch()

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return

        dispatch(toggleScreenFlowModal(false))
    }

    /* accordian state handler */
    const handleAccordianChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    const trialListHandler = () => {
        switch (currentTrial?.name) {
            case 'Zeus':
                return trialList[0]
            case 'Hermes':
                return trialList[1]
            case 'Oceanic':
                return trialList[2]

            default:
                return {}
        }
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="lg"
                fullWidth={true}
                open={screenFlowModal}
                PaperProps={{
                    style: {
                        minHeight: '90%',
                        maxHeight: '90%',
                    },
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className="fs-7 gray-600 fw-light">ScreenFlow</Typography>
                    <Typography className="fs-6 fw-bold text-uppercase">{currentTrial?.name}</Typography>
                </BootstrapDialogTitle>

                <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
                    <Box sx={{ px: 2, py: 2, width: '100%' }}>
                        {alignment === 'Update' ? (
                            <>
                                <CustomizedAccordions
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    handleAccordianChange={handleAccordianChange}
                                    title={'trial info'}
                                    toggleBtn={null}
                                    summaryStyle="primary"
                                >
                                    <Box
                                        sx={{
                                            border: `1px solid ${variables.gray200}`,
                                            borderRadius: 2,
                                            px: 1.2,
                                            py: 0.5,
                                        }}
                                    >
                                        <MainInfo trialData={trialListHandler()} />
                                    </Box>
                                </CustomizedAccordions>

                                <Box className="py-1">
                                    <TrialForm
                                        pageValue={pageValue}
                                        setPageValue={setPageValue}
                                        loading={loading}
                                        setLoading={setLoading}
                                        alignment={alignment}
                                        setAlignment={setAlignment}
                                        currentTrial={currentTrial}
                                    />
                                </Box>
                            </>
                        ) : alignment === 'Details' ? (
                            <Box className="px-2">
                                <CriteriaDetails
                                    trialListLoading={trialListLoading}
                                    currentTrial={currentTrial}
                                    trialData={trialListHandler()}
                                    trialCriteriaData={trialCriteriaData}
                                    trialCriteriaLoading={trialCriteriaLoading}
                                />
                            </Box>
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ my: 0.5, mx: 1 }}>
                    {alignment === 'Details' && (
                        <Button
                            type="button"
                            variant="contained"
                            className="btn-white-outline"
                            onClick={() => setAlignment('Update')}
                            disabled={!!trialListLoading}
                        >
                            Back
                        </Button>
                    )}
                    {alignment === 'Update' && (
                        <Button
                            form="trial-consent-form"
                            type="submit"
                            variant="contained"
                            className="btn-primary"
                            disabled={!!loading || !!isDemoPatient}
                        >
                            {!!loading ? 'Processing Details...' : 'Save / Review Details'}
                        </Button>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        screenFlowModal: state?.toggleModals?.screenFlowModal,
        currentTrial: state?.screenFlow?.currentTrial,
        trialListData: state?.screenFlow?.trialList,
        trialListLoading: state?.screenFlow?.trialListLoading,
        trialCriteriaData: state?.screenFlow?.trialCriteria,
        trialCriteriaLoading: state?.screenFlow?.trialCriteriaLoading,
        isDemoPatient: state.patient.patientData.isDemoPatient,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleScreenFlowModal: (payload) => dispatch(toggleScreenFlowModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFlowModal)
