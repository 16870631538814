import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Adverse from '../adverse-event/adverse'

import { toggleAdverseEventModal } from '../../redux/modals/toggleModal.actions'
import { getAdverseEventListData, setCurrentAdverseEvent } from '../../redux/adverseEvent/adverseEvent.action'

const AntTabs = styled(Tabs)({
    marginTop: '-0.625rem',
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    // lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

export const Index = ({ patientId, token, currentAdverseEvent }) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(currentAdverseEvent ?? 'allergy')

    const [expanded, setExpanded] = React.useState(true)

    useEffect(() => {
        setValue(currentAdverseEvent)
    }, [currentAdverseEvent])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case 'allergy':
                const payloadAdverseAllergyList = {
                    patientId: patientId,
                    type: 'allergy',
                }
                dispatch(getAdverseEventListData(payloadAdverseAllergyList, token))
                dispatch(setCurrentAdverseEvent('allergy'))
                break
            case 'intolerance':
                const payloadAdverseIntoleranceList = {
                    patientId: patientId,
                    type: 'intolerance',
                }
                dispatch(getAdverseEventListData(payloadAdverseIntoleranceList, token))
                dispatch(setCurrentAdverseEvent('intolerance'))
                break

            default:
                return null
        }
    }

    const OpenModal = (val) => {
        switch (val) {
            case 'allergy':
                const payload0 = {
                    patientId: patientId,
                    type: 'allergy',
                }

                dispatch(getAdverseEventListData(payload0, token))
                dispatch(toggleAdverseEventModal(true))
                dispatch(setCurrentAdverseEvent('allergy'))
                break
            case 'intolerance':
                const payload1 = {
                    patientId: patientId,
                    type: 'intolerance',
                }
                dispatch(getAdverseEventListData(payload1, token))
                dispatch(toggleAdverseEventModal(true))
                dispatch(setCurrentAdverseEvent('intolerance'))
                break

            default:
                dispatch(toggleAdverseEventModal(true))
        }
    }

    return (
        <div>
            <Accordion className="dashboard-accordion" sx={{ boxShadow: 'none' }} expanded={expanded}>
                <AccordionSummary
                    sx={{
                        background: '#F3F4F6',
                        boxShadow: 'none',
                        borderRadius: '0.25rem',
                        borderBottomLeftRadius: !!expanded ? '0rem' : '0.25rem',
                        borderBottomRightRadius: !!expanded ? '0rem' : '0.25rem',
                        padding: '0px 2px 0px !important',
                        cursor: 'unset !important',
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}
                        />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-900 cursor-default fs-8 fw-normal"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            // textAlign: 'right',
                            fontSize: '0.85rem',
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            padding: '4px 2px 0px  !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontWeight: 600,
                        }}
                    >
                        Adverse Events
                        <span className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={(event) => {
                                    OpenModal(value)
                                    event.stopPropagation()
                                }}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0px 0px 4px !important',
                    }}
                >
                    <Typography
                        className="gray-600 cursor-default"
                        sx={{
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            // borderRadius: '0.25rem',
                            borderBottomLeftRadius: '0.25rem',
                            borderBottomRightRadius: '0.25rem',
                            padding: '0px 2px 0px !important',
                        }}
                    >
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            {/* value need to be added here */}
                            <AntTab label="Allergies" value="allergy" />
                            <AntTab label="Intolerances" value="intolerance" />
                        </AntTabs>
                    </Typography>

                    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        {value === 'allergy' && (
                            <>
                                <Adverse detail="allergy" />
                            </>
                        )}
                        {value === 'intolerance' && (
                            <>
                                <Adverse detail="intolerance" />
                            </>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAdverseEventModal: (payload) => dispatch(toggleAdverseEventModal(payload)),
        getAdverseEventListData: (payload, token) => dispatch(getAdverseEventListData(payload, token)),
        setCurrentAdverseEvent: (payload) => dispatch(setCurrentAdverseEvent(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
