/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import { setCurrentLabs } from '../../../redux/labs/labs.action'
import { getLabsData } from '../../../redux/labsList/labsList.action'
import moment from 'moment'
import './../../vital-component/vital-pills/action-pills.scss'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import _ from 'lodash'

export const Index = ({
    currentLabs,
    labs,
    patientId,
    token,
    time,
    name,
    labsModal,
    consultLetterFlag,
    patient,
}) => {
    const dispatch = useDispatch()
    const handleCurrentLabs = (current) => {
        updateGraphs(current)
        dispatch(setCurrentLabs(current))
    }

    function search(nameKey, myArray) {
        let temp = ''
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                temp = myArray[i].value
                return myArray[i].value
            }
        }
        if (temp === '') {
            return ''
        }
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getLabsData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getLabsData(payload, token))
        }
    }

    function searchFlag(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i].flag
            }
        }
    }
    // style classes for absolute ranges
    const pillStyle = (range) => {
        switch (range) {
            case 'mild':
                return 'mild action-pills'
            case 'moderate':
                return 'moderate action-pills'
            case 'severe':
                return 'severe action-pills'
            default:
                return 'action-pills'
        }
    }
    /* border and shading style function based on absolute range calculation */
    const pillRangeStyle = (pillName) => {
        const pillValue = search(pillName, labs?.list)
        if (_.isNil(pillValue) || pillValue === '') return pillStyle()
        switch (pillName) {
            case 'LDL':
                if (pillValue >= 1.8 && pillValue <= 3.5) return pillStyle('mild')
                if (pillValue > 3.5 && pillValue <= 4.9) return pillStyle('moderate')
                if (pillValue > 4.9) return pillStyle('severe')
                return pillStyle()

            case 'HDL':
                if (
                    ((pillValue < 1.9 || pillValue > 3.6) && patient?.gender === 1) ||
                    ((pillValue < 1.9 || pillValue > 4.4) && patient?.gender === 2)
                )
                    return pillStyle('mild')
                return pillStyle()

            case 'TG':
                if (pillValue >= 1.69 && pillValue <= 2.25) return pillStyle('mild')
                if (pillValue > 2.25 && pillValue <= 5.63) return pillStyle('moderate')
                if (pillValue > 5.63) return pillStyle('severe')
                return pillStyle()

            case 'TC':
                if (pillValue >= 3 && pillValue <= 5.2) return pillStyle('mild')
                if (pillValue > 5.2 && pillValue <= 6.1) return pillStyle('moderate')
                if (pillValue > 6.1) return pillStyle('severe')
                return pillStyle()

            case 'NA':
                if (pillValue >= 146 && pillValue <= 150) return pillStyle('mild')
                if (pillValue > 150 && pillValue <= 160) return pillStyle('moderate')
                if (pillValue > 160) return pillStyle('severe')
                return pillStyle()

            case 'K':
                if ((pillValue >= 3 && pillValue < 3.5) || (pillValue > 5 && pillValue <= 5.5))
                    return pillStyle('mild')
                if ((pillValue >= 2.5 && pillValue < 3) || (pillValue > 5.5 && pillValue <= 5.9))
                    return pillStyle('moderate')
                if (pillValue < 2.5 || pillValue > 5.9) return pillStyle('severe')
                return pillStyle()

            case 'Cr':
                if (pillValue < 46 || pillValue > 92) return pillStyle('mild')
                return pillStyle()

            case 'GFR':
                if (pillValue < 15 || pillValue > 120) return pillStyle('mild')
                return pillStyle()

            case 'Hb':
                if ((pillValue < 130 && patient?.gender === 1) || (pillValue < 115 && patient?.gender === 2))
                    return pillStyle('mild')
                return pillStyle()

            case 'Plt':
                if (pillValue < 150 || pillValue > 400) return pillStyle('mild')
                return pillStyle()

            case 'INR':
                if (pillValue >= 3.1 && pillValue <= 3.5) return pillStyle('mild')
                if (pillValue > 3.5 && pillValue <= 4) return pillStyle('moderate')
                if (pillValue > 4) return pillStyle('severe')
                return pillStyle()

            case 'BNP':
                if (pillValue < 100) return pillStyle('mild')
                return pillStyle()

            case 'CK':
                if (pillValue < 24 || pillValue > 204) return pillStyle('mild')
                return pillStyle()

            case 'ALT':
                if (pillValue > 36) return pillStyle('mild')
                return pillStyle()
            case 'Lipoprotein(a)':
                if (pillValue > 100) return pillStyle('mild')
                return pillStyle()

            case 'CRP':
                if (pillValue > 5) return pillStyle('mild')
                return pillStyle()

            default:
                return pillStyle()
        }
    }
    // pill shading flag based on % worsening sent by backend
    const pillflagStyle = (pillName) => {
        const pillFlag = searchFlag(pillName, labs?.list)
        if (
            [
                'LDL',
                'HDL',
                'TG',
                'TC',
                'NA',
                'K',
                'Cr',
                'GFR',
                'Hb',
                'Plt',
                'INR',
                'BNP',
                'CK',
                'ALT',
                'Lipoprotein(a)',
                'CRP',
            ].includes(pillName)
        ) {
            return `flag-${pillFlag}`
        } else {
            return ''
        }
    }

    useEffect(() => {
        time !== '' && updateGraphs(currentLabs)
    }, [time])

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('LDL')
                        }}
                        className={clsx(
                            currentLabs === 'LDL' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('LDL'),
                            pillflagStyle('LDL')
                        )}
                    >
                        LDL {search('LDL', labs?.list) !== 'N/A' ? search('LDL', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('HDL')
                        }}
                        className={clsx(
                            currentLabs === 'HDL' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('HDL'),
                            pillflagStyle('HDL')
                        )}
                    >
                        HDL {search('HDL', labs?.list) !== 'N/A' ? search('HDL', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('TG')
                        }}
                        className={clsx(
                            currentLabs === 'TG' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('TG'),
                            pillflagStyle('TG')
                        )}
                    >
                        TG {search('TG', labs?.list) !== 'N/A' ? search('TG', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('TC')
                        }}
                        className={clsx(
                            currentLabs === 'TC' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('TC'),
                            pillflagStyle('TC')
                        )}
                    >
                        TC {search('TC', labs?.list) !== 'N/A' ? search('TC', labs?.list) : ' '}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('NA')
                        }}
                        className={clsx(
                            currentLabs === 'NA' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('NA'),
                            pillflagStyle('NA')
                        )}
                    >
                        Na {search('NA', labs?.list) !== 'N/A' ? search('NA', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('K')
                        }}
                        className={clsx(
                            currentLabs === 'K' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('K'),
                            pillflagStyle('K')
                        )}
                    >
                        K {search('K', labs?.list) !== 'N/A' ? search('K', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('Cr')
                        }}
                        className={clsx(
                            currentLabs === 'Cr' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Cr'),
                            pillflagStyle('Cr')
                        )}
                    >
                        Cr {search('Cr', labs?.list) !== 'N/A' ? search('Cr', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('GFR')
                        }}
                        className={clsx(
                            currentLabs === 'GFR' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('GFR'),
                            pillflagStyle('GFR')
                        )}
                    >
                        GFR {search('GFR', labs?.list) !== 'N/A' ? search('GFR', labs?.list) : ' '}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('Hb')
                        }}
                        className={clsx(
                            currentLabs === 'Hb' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Hb'),
                            pillflagStyle('Hb')
                        )}
                    >
                        Hb {search('Hb', labs?.list) !== 'N/A' ? search('Hb', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('Plt')
                        }}
                        className={clsx(
                            currentLabs === 'Plt' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Plt'),
                            pillflagStyle('Plt')
                        )}
                    >
                        Plt {search('Plt', labs?.list) !== 'N/A' ? search('Plt', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('INR')
                        }}
                        className={clsx(
                            currentLabs === 'INR' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('INR'),
                            pillflagStyle('INR')
                        )}
                    >
                        INR {search('INR', labs?.list) !== 'N/A' ? search('INR', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('BNP')
                        }}
                        className={clsx(
                            currentLabs === 'BNP' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('BNP'),
                            pillflagStyle('BNP')
                        )}
                    >
                        BNP {search('BNP', labs?.list) !== 'N/A' ? search('BNP', labs?.list) : ' '}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('CK')
                        }}
                        className={clsx(
                            currentLabs === 'CK' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('CK'),
                            pillflagStyle('CK')
                        )}
                    >
                        CK {search('CK', labs?.list) !== 'N/A' ? search('CK', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('ALT')
                        }}
                        className={clsx(
                            currentLabs === 'ALT' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('ALT'),
                            pillflagStyle('ALT')
                        )}
                    >
                        ALT {search('ALT', labs?.list) !== 'N/A' ? search('ALT', labs?.list) : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('Lipoprotein(a)')
                        }}
                        className={clsx(
                            currentLabs === 'Lipoprotein(a)' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('Lipoprotein(a)'),
                            pillflagStyle('Lipoprotein(a)')
                        )}
                    >
                        LP(a){' '}
                        {search('Lipoprotein(a)', labs?.list) !== 'N/A'
                            ? search('Lipoprotein(a)', labs?.list)
                            : ' '}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentLabs('CRP')
                        }}
                        className={clsx(
                            currentLabs === 'CRP' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('CRP'),
                            pillflagStyle('CRP')
                        )}
                    >
                        CRP {search('CRP', labs?.list) !== 'N/A' ? search('CRP', labs?.list) : ' '}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentLabs: state.labs.currentLabs,
    labs: state.labsList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    labsModal: state.toggleModals.labsModal,
    patient: state.patient.patientData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLabs: (payload) => dispatch(setCurrentLabs(payload)),
        getLabsData: (payload, token) => dispatch(getLabsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
