import { CATH_DOC_LOADING, CLEAR_CATH, GET_CATH_DOCUMENT, RESET_CATH_MODAL } from './cathList.type'

const INITIAL_STATE = {
    cathLoading: false,
    lud: '',
    cathDocuments: [],
}

const cathListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CATH_DOCUMENT:
            return {
                ...state,
                cathDocuments: action?.payload?.list,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case CATH_DOC_LOADING:
            return {
                ...state,
                cathLoading: action.payload,
            }

        case RESET_CATH_MODAL:
            return {
                ...state,
                cathDocuments: [],
            }
        case CLEAR_CATH:
            return {
                cathLoading: false,
                lud: '',
                cathDocuments: [],
            }
        default:
            return state
    }
}

export default cathListReducer
