/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { connect, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Badge from '@mui/material/Badge'
import { BootstrapDialog, BootstrapDialogTitle } from '../../pages/home'
import Loading from '../Loading'
import moment from 'moment'
import Dashboard from '../dashboard'
import './appointment.scss'
import { togglePatientProfileModal } from '../../redux/modals/toggleModal.actions'
import {
    fetchAppointmentData,
    fetchAllAppointmentWoPagination,
} from '../../redux/appointment/appointment.action'
import { getPatientDetails } from '../../redux/patient/patient.action'
import { getVitalData, getVitalListData } from '../../redux/vitalList/vitalList.action'
import { getLabsData, getLabsListData } from '../../redux/labsList/labsList.action'
import { getEcgsData, getEcgsListData } from '../../redux/ECH/ecgList/ecgList.action'
import { setCurrentEcg } from '../../redux/ECH/ecg/ecg.action'
import { setCurrentGtx } from '../../redux/ECH/GXT/gxt.action'
import { getGxtData, getGxtListData } from '../../redux/ECH/gxtList/gxtList.action'
import { setCurrentVital } from '../../redux/vitals/vitals.action'
import { setCurrentLabs } from '../../redux/labs/labs.action'
import { setCurrentEcho } from '../../redux/ESN/echo/echo.action'
import { setCurrentHolter } from '../../redux/ECH/Holter/holter.action'
import { getHolterData, getHolterListData } from '../../redux/ECH/holterList/holterList.action'
import { getEchoData, getEchoListData } from '../../redux/ESN/echoList/echoList.action'
import {
    getCardiacData,
    setCurrentMedication,
    getCardiacListData,
} from '../../redux/COP/cardiacList/cardiacList.action'
import { getSocialListData } from '../../redux/socialList/socialList.action'
import { getFamilyListData } from '../../redux/familyList/familyList.action'
import { getConsultList } from '../../redux/consultNotes/consultNotes.action'
import { getAdverseEventListData } from '../../redux/adverseEvent/adverseEvent.action'
import { getIntervalListData } from '../../redux/intervalHistory/intervalHistory.action'
import { getPhysicalListData } from '../../redux/physicalExam/physicalExam.action'
import {
    getMedicalHistoryListData,
    getPastMedicalHistoryListData,
} from '../../redux/medicalHistory/medicalHistory.action'
import {
    getCardiacHistoryListData,
    getPastCardiacHistoryListData,
} from '../../redux/cardiacHistory/cardiacHistory.action'
import { getRecommendationsListData } from '../../redux/recommendations/recommendations.action'
import { getTrialListData } from '../../redux/screenFlow/screenFlow.action'
export function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const TotalPage = Math.max(0, Math.ceil(count / rowsPerPage))
    const pageArrNum = []
    const pagesArr = function () {
        for (var i = 1; i <= TotalPage; i++) {
            if (i <= 5 || Math.abs(page - i) <= 1 || i == TotalPage) pageArrNum.push(i)
        }
    }
    pagesArr()

    const handlePageButtonClick = (event, i) => {
        onPageChange(event, i - 1)
    }

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1)
    }

    return (
        <Box className="d-flex ms-auto" sx={{ flexShrink: 0, ml: 2.5, align: 'right' }}>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ width: '55px', height: '55px' }}
            >
                {theme.direction === 'rtl' ? (
                    <img src="/images/right-icon.svg" alt="right icon" />
                ) : (
                    <img src="/images/left-icon.svg" alt="left icon" />
                )}
            </IconButton>
            <div className="d-flex">
                {pageArrNum.map((i, index) => {
                    return (
                        <div
                            key={index}
                            className={`${
                                page === i - 1 ? 'active-page' : ''
                            } page-number me-2 ms-2 cursor-pointer`}
                            onClick={(e) => handlePageButtonClick(e, i)}
                        >
                            {i}
                        </div>
                    )
                })}
            </div>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ width: '55px', height: '55px' }}
            >
                {theme.direction === 'rtl' ? (
                    <img src="/images/left-icon.svg" alt="left icon" />
                ) : (
                    <img src="/images/right-icon.svg" alt="right icon" />
                )}
            </IconButton>
        </Box>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -30,
        top: 12,
        background: '#E0E7FF',
        color: '#4F46E5',
        fontSize: '0.75rem',
        fontFamily: 'Inter',
        borderRadius: '60px',
        border: `0px solid ${theme.palette.background.paper}`,
        padding: '10px 10px',
    },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F7F8F9',
        color: '#6B7280',
        fontFamily: 'Inter',
        fontWeight: 600,
        borderBottom: '1px solid #E5E7EB !important',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#6B7280',
        fontFamily: 'Inter',
        border: 0,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#F7F8F9',
        border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))
export const initialValueFetchHandler = (pid, id, dispatch, token) => {
    const sixtyMonthsAgo = moment().subtract(60, 'months').calendar()
    const proposedDate = moment(sixtyMonthsAgo).format('YYYY-MM-DD') + 'T00:00:00.000Z'

    const data = {
        patientId: pid,
        id: id,
    }
    const dataVital = {
        patientId: pid,
        type: 'bp',
        date: proposedDate,
    }
    const dataLabs = {
        patientId: pid,
        type: 'LDL',
        date: proposedDate,
    }
    const dataEcgs = {
        patientId: pid,
        type: 'HR',
        date: proposedDate,
    }

    const payloadPid = {
        patientId: pid,
    }
    const dataGxt = {
        patientId: pid,
        type: 'Ex Dur',
        date: proposedDate,
    }

    const dataHolter = {
        patientId: pid,
        type: 'Max HR',
        date: proposedDate,
    }

    const dataEcho = {
        patientId: pid,
        type: 'EF (%)',
        date: proposedDate,
    }

    const payloadPidCardiac = {
        patientId: pid,
        type: 'cardiac',
    }

    const payloadSocial = {
        patientId: pid,
    }
    const payloadFamily = {
        patientId: pid,
    }
    const payloadConsult = {
        patientId: pid,
    }

    const payloadAdverseEventList = {
        patientId: pid,
        type: 'allergy',
    }
    const payloadIntervalHistory = {
        patientId: pid,
    }

    const payloadPhysicalExam = {
        patientId: pid,
    }
    const payloadMedicalHistory = {
        patientId: pid,
        type: 'other',
        status: 'active',
    }
    const payloadPastMedicalHistory = {
        patientId: pid,
        type: 'other',
        status: 'past',
    }

    const payloadCardiacHistory = {
        patientId: pid,
        type: 'cardiac',
        status: 'active',
    }
    const payloadPastCardiacHistory = {
        patientId: pid,
        type: 'cardiac',
        status: 'past',
    }
    const payloadRecommendations = {
        patientId: pid,
    }
    const payloadClinicalTrials = {
        patientId: pid,
    }

    if (pid) {
        dispatch(getPatientDetails(data, token))
        dispatch(togglePatientProfileModal(true))
        dispatch(getVitalListData(payloadPid, token))
        dispatch(getVitalData(dataVital, token))
        dispatch(getLabsData(dataLabs, token))
        dispatch(getLabsListData(payloadPid, token))
        dispatch(setCurrentEcg('HR'))
        dispatch(setCurrentLabs('LDL'))
        dispatch(setCurrentEcho('EF (%)'))
        dispatch(setCurrentVital('bp'))
        dispatch(getEcgsData(dataEcgs, token))
        dispatch(getEcgsListData(payloadPid, token))
        dispatch(setCurrentGtx('Ex Dur'))
        dispatch(getHolterData(dataHolter, token))
        dispatch(getHolterListData(payloadPid, token))
        dispatch(setCurrentHolter('Max HR'))
        dispatch(getGxtData(dataGxt, token))
        dispatch(getGxtListData(payloadPid, token))
        dispatch(getEchoData(dataEcho, token))
        dispatch(getEchoListData(payloadPid, token))
        dispatch(setCurrentMedication('cardiac'))
        dispatch(getCardiacListData(payloadPidCardiac, token))
        dispatch(getSocialListData(payloadSocial, token))
        dispatch(getFamilyListData(payloadFamily, token))
        dispatch(getConsultList(payloadConsult, token))
        dispatch(getAdverseEventListData(payloadAdverseEventList, token))
        dispatch(getIntervalListData(payloadIntervalHistory, token))
        dispatch(getPhysicalListData(payloadPhysicalExam, token))
        dispatch(getMedicalHistoryListData(payloadMedicalHistory, token))
        dispatch(getPastMedicalHistoryListData(payloadPastMedicalHistory, token))
        dispatch(getCardiacHistoryListData(payloadCardiacHistory, token))
        dispatch(getPastCardiacHistoryListData(payloadPastCardiacHistory, token))
        dispatch(getRecommendationsListData(payloadRecommendations, token))
        dispatch(getTrialListData(payloadClinicalTrials, token))
    }
}

const Index = ({ token, reduxAppointmentData, loading, maxData, currentAppointmentDate }) => {
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0)
    const [reason, setReason] = React.useState('')
    const [reasonModal, setReasonModal] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [pageNo, setPageNo] = React.useState(1)
    let rows

    React.useEffect(() => {
        const payloadData = {
            page: pageNo,
            pageLimit: rowsPerPage,
            searchKey: '',
            date: currentAppointmentDate,
        }
        dispatch(fetchAppointmentData(payloadData, token))
        dispatch(fetchAllAppointmentWoPagination(currentAppointmentDate, token))
    }, [])

    React.useEffect(() => {
        setPage(0)
    }, [currentAppointmentDate])

    const handleClose = () => {
        setReasonModal(false)
    }
    const handleChangePage = (event, newPage) => {
        // console.log(event, newPage)
        setPage(newPage)
        const payloadData = {
            page: newPage + 1,
            pageLimit: 10,
            searchKey: '',
            date: currentAppointmentDate,
        }
        dispatch(fetchAppointmentData(payloadData, token))
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handlePatientProfile = (pid, id) => {
        initialValueFetchHandler(pid, id, dispatch, token)
    }

    return (
        <>
            <Dashboard />
            <TableContainer
                sx={{
                    borderRadius: '11px',
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                    minHeight: 'calc(100vH - 250px)',
                }}
                component={Paper}
            >
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className="cursor-default" style={{ width: 230 }}>
                                FULL NAME
                            </StyledTableCell>
                            <StyledTableCell className="cursor-default">TIME</StyledTableCell>
                            <StyledTableCell className="cursor-default" style={{ width: 250, color: '#ccc' }}>
                                APPOINTMENT REASON
                            </StyledTableCell>
                            <StyledTableCell className="cursor-default" style={{ width: 250, color: '#ccc' }}>
                                LAST APPOINTMENT DATE
                            </StyledTableCell>
                            <StyledTableCell className="cursor-default">DATE OF BIRTH</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <StyledTableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>
                                    <Loading />
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </StyledTableRow>
                        ) : (
                            <>
                                {reduxAppointmentData?.list && reduxAppointmentData?.list.length > 0 ? (
                                    reduxAppointmentData?.list.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell
                                                className="cursor-default"
                                                style={{ color: '#111827', fontWeight: 600, width: 230 }}
                                            >
                                                {/* {!row.hasOwnProperty("lastAppointmentDate") ? <StyledBadge badgeContent={'New'} color="secondary"> <span className='cursor-pointer' onClick={() => handlePatientProfile(row.patientId, row.id)}>
                  {row.name}
                  </span>
                  </StyledBadge> : <span className='cursor-pointer' onClick={() => handlePatientProfile(row.patientId, row.id)}>
                  {row.name}
                  </span>
                  } */}
                                                {
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            handlePatientProfile(row.patientId, row.id)
                                                        }
                                                    >
                                                        {row.name}
                                                    </span>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell className="cursor-default">
                                                {moment(row.time, 'h:mm:ss A').format('HH:mm')}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="cursor-default"
                                                style={{ width: 250 }}
                                            >
                                                {/* <span className="appointment-reason cursor-pointer" onClick={() => {setReason(row.note); setReasonModal(true)}}>
                   {row.note}
                    </span>  */}
                                            </StyledTableCell>
                                            <StyledTableCell className="cursor-default">
                                                {/* {row.hasOwnProperty("lastAppointmentDate") ? row.lastAppointmentDate : 'N/A' } */}
                                            </StyledTableCell>
                                            <StyledTableCell className="cursor-default">
                                                {row.hasOwnProperty('birthday') ? row.birthday : 'N/A'}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <>
                                        <StyledTableRow style={{ height: 53 * 2, background: '#fff' }}>
                                            <StyledTableCell colSpan={6} />
                                        </StyledTableRow>
                                        <StyledTableRow style={{ background: '#fff' }}>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell
                                                style={{ textAlign: 'center', cursor: 'default' }}
                                            >
                                                <img
                                                    width={300}
                                                    src="/No_appointment_available.svg"
                                                    alt="no appointment available"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow style={{ height: 53 * 3, background: '#fff' }}>
                                            <StyledTableCell colSpan={6} />
                                        </StyledTableRow>
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {reduxAppointmentData?.list?.length > 0 ? (
                <TablePagination
                    labelDisplayedRows={(from = page) =>
                        `Displaying ${from.count > 10 ? `${from.from} - ` : ''}${
                            from.to === -1 ? from.count : from.to
                        } out of ${from.count} results`
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    component="div"
                    count={reduxAppointmentData?.resultCount}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Displaying"
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'Displaying',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                />
            ) : (
                <div className="pt-4 pb-4"></div>
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="small-modal"
                open={reasonModal}
                maxWidth="sm"
            >
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Appointment Reason
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography sx={{ fontWeight: 500, fontSize: '0.9rem' }} gutterBottom>
                        {reason}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        patientProfileModal: state.toggleModals.patientProfileModal,
        token: state.loginData.loginResponse.accessToken,
        reduxAppointmentData: state.appointment.appointmentData,
        maxData: state.appointment.appointmentData.resultCount,
        loading: state.appointment.loading,
        currentAppointmentDate: state.appointment.currentAppointmentDate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        togglePatientProfileModal: (payload) => dispatch(togglePatientProfileModal(payload)),
        fetchAppointmentData: (payload, token) => dispatch(fetchAppointmentData(payload, token)),
        fetchAllAppointmentWoPagination: (payload, token) =>
            dispatch(fetchAllAppointmentWoPagination(payload, token)),
        getEcgsData: (payload, token) => dispatch(getEcgsData(payload, token)),
        getEcgsListData: (payload, token) => dispatch(getEcgsListData(payload, token)),
        setCurrentVital: (payload) => dispatch(setCurrentVital(payload)),
        setCurrentEcg: (payload) => dispatch(setCurrentEcg(payload)),
        setCurrentGtx: (payload) => dispatch(setCurrentGtx(payload)),
        setCurrentLabs: (payload) => dispatch(setCurrentLabs(payload)),
        setCurrentEcho: (payload) => dispatch(setCurrentEcho(payload)),
        setCurrentHolter: (payload) => dispatch(setCurrentHolter(payload)),
        getGxtData: (payload, token) => dispatch(getGxtData(payload, token)),
        getGxtListData: (payload, token) => dispatch(getGxtListData(payload, token)),
        getVitalListData: (payload, token) => dispatch(getVitalListData(payload, token)),
        getLabsListData: (payload, token) => dispatch(getLabsListData(payload, token)),
        getHolterData: (payload, token) => dispatch(getHolterData(payload, token)),
        getHolterListData: (payload, token) => dispatch(getHolterListData(payload, token)),
        getEchoData: (payload, token) => dispatch(getEchoData(payload, token)),
        getEchoListData: (payload, token) => dispatch(getEchoListData(payload, token)),
        setCurrentMedication: (payload) => dispatch(setCurrentMedication(payload)),
        getCardiacData: (payload, token) => dispatch(getCardiacData(payload, token)),
        getCardiacListData: (payload, token) => dispatch(getCardiacListData(payload, token)),
        getSocialListData: (payload, token) => dispatch(getSocialListData(payload, token)),
        getFamilyListData: (payload, token) => dispatch(getFamilyListData(payload, token)),
        getConsultList: (payload, token) => dispatch(getConsultList(payload, token)),
        getAdverseEventListData: (payload, token) => dispatch(getAdverseEventListData(payload, token)),
        getIntervalListData: (payload, token) => dispatch(getIntervalListData(payload, token)),
        getPhysicalListData: (payload, token) => dispatch(getPhysicalListData(payload, token)),
        getMedicalHistoryListData: (payload, token) => dispatch(getMedicalHistoryListData(payload, token)),
        getPastMedicalHistoryListData: (payload, token) =>
            dispatch(getPastMedicalHistoryListData(payload, token)),
        getCardiacHistoryListData: (payload, token) => dispatch(getCardiacHistoryListData(payload, token)),
        getPastCardiacHistoryListData: (payload, token) =>
            dispatch(getPastCardiacHistoryListData(payload, token)),
        getRecommendationsListData: (payload, token) => dispatch(getRecommendationsListData(payload, token)),
        getTrialListData: (payload, token) => dispatch(getTrialListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
