import { SET_CURRENT_GXT, SET_GXT_LOADING } from './gxt.type'

const INITIAL_STATE = {
    currentGxt: 'Max HR',
    loading: false,
}

const gxtReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_GXT:
            return {
                ...state,
                currentGxt: action.payload,
                loading: false,
            }
        case SET_GXT_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default gxtReducer
