import {
    GET_STRESS_ECHO_DOCUMENT,
    STRESS_ECHO_DOC_LOADING,
    RESET_STRESS_ECHO_MODAL,
    CLEAR_STRESS_ECHO,
    STRESS_ECHO_GXT_TRIGGER,
} from './stressEchoList.type'

const INITIAL_STATE = {
    stressEchoLoading: false,
    lud: '',
    stressEchoDocuments: [],
    stressEchoGxtTrigger: null,
}

const stressEchoListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_STRESS_ECHO_DOCUMENT:
            return {
                ...state,
                stressEchoDocuments: action?.payload?.list,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case STRESS_ECHO_DOC_LOADING:
            return {
                ...state,
                stressEchoLoading: action.payload,
            }

        case STRESS_ECHO_GXT_TRIGGER:
            return {
                ...state,
                stressEchoGxtTrigger: action.payload,
            }
        case RESET_STRESS_ECHO_MODAL:
            return {
                ...state,
                stressEchoDocuments: [],
            }
        case CLEAR_STRESS_ECHO:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}

export default stressEchoListReducer
