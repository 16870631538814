import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
export const MedicalHistoryList = ({
    patientId,
    token,
    detail,
    medicalHistoryListData,
    medicalHistoryListLoading,
    currentMedicalHistory,
    pastMedicalHistoryListData,
    pastMedicalHistoryListLoading,
}) => {
    return (
        <>
            <Grid
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
            >
                {!!(currentMedicalHistory === 'other'
                    ? medicalHistoryListLoading
                    : pastMedicalHistoryListLoading) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(
                      currentMedicalHistory === 'other' ? medicalHistoryListData : pastMedicalHistoryListData
                  ) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">
                                {`No ${detail === 'other' ? 'Active' : 'Past'} Medical History found`}
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    (currentMedicalHistory === 'other'
                        ? medicalHistoryListData
                        : pastMedicalHistoryListData
                    )?.map((diagnosisDetail, idx) => (
                        <Grid
                            item
                            key={idx}
                            sx={{
                                overflow: 'clip',
                            }}
                        >
                            <Box
                                className="action-pills"
                                sx={{
                                    cursor: 'default !important',
                                    textTransform: 'capitalize',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <Tooltip
                                    title={
                                        <>
                                            <Typography sx={{ fontSize: '11px' }}>
                                                Dx Date:{' '}
                                                {!_.isEmpty(diagnosisDetail?.diagnoseDate)
                                                    ? moment
                                                          .utc(diagnosisDetail?.diagnoseDate)
                                                          .format(DATE_FORMAT.MMM_YYYY)
                                                    : 'N/A'}
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: '11px', textTransform: 'capitalize ' }}
                                            >
                                                Diagnosis Name: {diagnosisDetail?.name ?? 'N/A'}
                                            </Typography>
                                        </>
                                    }
                                >
                                    <Typography sx={{ fontSize: '12px' }} noWrap>
                                        {!!diagnosisDetail?.customLabel
                                            ? diagnosisDetail?.customLabel ?? '-'
                                            : diagnosisDetail?.name ?? '-'}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    medicalHistoryListData: state?.medicalHistory?.medicalHistoryListData,
    medicalHistoryListLoading: state?.medicalHistory?.medicalHistoryListLoading,
    currentMedicalHistory: state?.medicalHistory?.currentMedicalHistory,
    pastMedicalHistoryListData: state?.medicalHistory?.pastMedicalHistoryListData,
    pastMedicalHistoryListLoading: state?.medicalHistory?.pastMedicalHistoryListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryList)
