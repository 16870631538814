import React from 'react'
import { connect } from 'react-redux'
import LDLChart from './LDL-chart'
import HDLChart from './HDL-chart'
import TGChart from './TG-chart'
import TCChart from './TC-chart'
import NAChart from './NA-chart'
import Kchart from './K-chart'
import CrChart from './Cr-chart'
import GFRChart from './GFR-chart'
import HbChart from './Hb-chart'
import PLTChart from './Plt-chart'
import INRChart from './INR-chart '
import BNPChart from './BNP-chart '
import CKChart from './CK-chart'
import ALTChart from './ALT-chart'
import LPa from './LPa'
import CRP from './CRP'
import DummyChart from '../../dummy-chart'
import LoadingChart from './loading-chart'

export const Index = ({ currentLabs, labsLoading, labsModalData, type }) => {
    return (
        <div>
            {currentLabs === 'LDL' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <LDLChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'HDL' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <HDLChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'TG' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <TGChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'TC' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <TCChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'NA' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <NAChart type={type} />
                    ) : (
                        <DummyChart type={type} name={'Na'} />
                    )}
                </>
            )}
            {currentLabs === 'K' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <Kchart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'Cr' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <CrChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'GFR' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <GFRChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'Hb' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <HbChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'Plt' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <PLTChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'INR' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <INRChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'BNP' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <BNPChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'CK' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <CKChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'ALT' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <ALTChart type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'Lipoprotein(a)' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <LPa type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
            {currentLabs === 'CRP' && (
                <>
                    {labsLoading ? (
                        <LoadingChart type={type} />
                    ) : labsModalData && labsModalData.length > 0 ? (
                        <CRP type={type} />
                    ) : (
                        <DummyChart type={type} name={currentLabs} />
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentLabs: state.labs.currentLabs,
    labsLoading: state.labsList.labsLoading,
    labsModalData: state.labsList.labsModalData,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
