import React from 'react'
import { connect } from 'react-redux'
import AdverseDetails from '../adverse-details'

export const Index = ({ detail }) => {
    return (
        <div>
            <AdverseDetails name="dashboard-pills" detail={detail} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
