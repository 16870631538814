import React from 'react'
import { connect } from 'react-redux'
import CardiacPills from '../cardiac-pills'

export const Index = () => {
    return (
        <div>
            <CardiacPills name="dashboard-pills" time="" />
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
