export const GET_FAMILY_LIST_DATA = 'GET_FAMILY_LIST_DATA'
export const SET_FAMILY_LIST_LOADING = 'SET_FAMILY_LIST_LOADING'
export const GET_FAMILY_VIEW_DATA = 'GET_FAMILY_VIEW_DATA'
export const SET_FAMILY_VIEW_LOADING = 'SET_FAMILY_VIEW_LOADING'
export const GET_FAMILY_EVENTS_DATA = 'GET_FAMILY_EVENTS_DATA'
export const SET_FAMILY_EVENTS_LOADING = 'SET_FAMILY_EVENTS_LOADING'
export const CLEAR_FAMILY_VIEW_HISTORY = 'CLEAR_FAMILY_VIEW_HISTORY'
export const CLEAR_FAMILY_LIST_HISTORY = 'CLEAR_FAMILY_LIST_HISTORY'
export const CLEAR_FAMILY_HISTORY = 'CLEAR_FAMILY_HISTORY'
export const DELETE_FAMILY_MEMBER = 'DELETE_FAMILY_MEMBER'
