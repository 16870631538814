import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import {
    FAILED_APPOINTMENT_LIST,
    GET_ALL_APPOINTMENT_LIST,
    GET_APPOINTMENT_LIST,
    TOGGLE_LOADING,
    SET_CURRENT_APPOINTMENT_DATE,
    GET_APPOINTMENT_LIST_WO_PAGINATION,
} from './appointment.type'

const fetchAppointments = async (payload, token, dispatch, successType) => {
    if (!token) {
        dispatch({ type: FAILED_APPOINTMENT_LIST })
        return
    }

    dispatch({ type: TOGGLE_LOADING, payload: true })

    try {
        const response = await axios.post(`${process.env.REACT_APP_CARD_URL}/appointments/list`, payload, {
            headers: { accessToken: token },
        })

        const { data } = response
        const { status, output, error } = data

        if (status === 200) {
            dispatch({ type: successType, payload: output })
        } else if (status === 401) {
            dispatch(toggleSessionModal(true, error))
            dispatch({ type: FAILED_APPOINTMENT_LIST })
        } else if (status === 403) {
            dispatch(toggleDeactivateModal(true))
            dispatch({ type: FAILED_APPOINTMENT_LIST })
        }
    } catch (err) {
        // console.log(err)
    } finally {
        dispatch({ type: TOGGLE_LOADING, payload: false })
    }
}

export const fetchAppointmentData = (payload, token) => async (dispatch) => {
    if (!payload) {
        dispatch({ type: FAILED_APPOINTMENT_LIST })
        return
    }

    await fetchAppointments(payload, token, dispatch, GET_APPOINTMENT_LIST)
}

export const fetchAllAppointmentData = (payload, token) => async (dispatch) => {
    await fetchAppointments(payload, token, dispatch, GET_ALL_APPOINTMENT_LIST)
}

export const setCurrentAppointmentDate = (data, token) => async (dispatch) => {
    await dispatch({
        type: SET_CURRENT_APPOINTMENT_DATE,
        payload: data,
    })
    await fetchAppointments(
        { page: 1, pageLimit: 10, searchKey: '', date: data },
        token,
        dispatch,
        GET_APPOINTMENT_LIST
    )
    await fetchAppointments(
        { date: data, showAll: true },
        token,
        dispatch,
        GET_APPOINTMENT_LIST_WO_PAGINATION
    )
}

/* For fetching appointments without pagination  */
export const fetchAllAppointmentWoPagination = (payload, token) => async (dispatch) => {
    await fetchAppointments(
        { date: payload, showAll: true },
        token,
        dispatch,
        GET_APPOINTMENT_LIST_WO_PAGINATION
    )
}
