import PropTypes from 'prop-types'
import variables from '../../../theme/variables.scss'
import React, { useEffect } from 'react'
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { alpha, styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import { toggleVitalModal } from '../../../redux/modals/toggleModal.actions'
import { getVitalData } from '../../../redux/vitalList/vitalList.action'
import UpdateVital from '../Update-vitals'
import moment from 'moment'
import './vital-modal.scss'
import VitalPills from '../vital-pills'
import VitalChart from '../vitals-charts/index'
import AddIcon from '@mui/icons-material/Add'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import { dispatchResetActionWrapper } from '../../../util'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid ',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ vitalsModal, currentVital, vital, vitalLoading, token, patientId }) => {
    const submit = React.useRef(null)
    const [time, setTime] = React.useState('60') //default time 60 months
    const [alignment, setAlignment] = React.useState('Details')

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        {
            dispatchResetActionWrapper(
                time,
                currentVital,
                patientId,
                token,
                dispatch,
                toggleVitalModal,
                getVitalData,
                null
            )
        }
    }

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment)
        }
    }

    const handleChangeTime = (value) => {
        setTime(value)
        if (value !== 'lastvisit') {
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: currentVital,
                date: proposedDate,
            }
            dispatch(getVitalData(payload, token))
        } else {
            const payload = {
                patientId: patientId,
                type: currentVital,
                date: value,
            }
            dispatch(getVitalData(payload, token))
        }
    }

    useEffect(() => {
        handleChangeTime(time)
    }, [currentVital])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={vitalsModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">Vitals</Typography>
                        <Typography className="gray-600 fs-9" gutterBottom>
                            {vital?.lud !== ''
                                ? `Last updated ${moment
                                      .parseZone(vital?.lud)
                                      .format(DATE_FORMAT.MMM_DD_YYYY)}`
                                : null}
                        </Typography>

                        {/* add left details timeline */}
                        {alignment === 'Details' && (
                            <>
                                <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                                    Timeline
                                </Typography>
                                <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                                    <Select
                                        labelId="demo-customized-select-label1"
                                        id="demo-customized-select1"
                                        value={time}
                                        defaultValue="Select Timeline"
                                        onChange={(e) => handleChangeTime(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="lastvisit">
                                            Since Last Visit
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="1">
                                            Last 1 Month
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="6">
                                            Last 6 Months
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="12">
                                            Last 1 Year
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="60">
                                            Last 5 Years
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {/* bottom buttons */}
                        {alignment === 'Details' ? (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={
                                        !!_.isEmpty(vital?.list) ||
                                        !moment(vital?.lud).isSame(new Date(), 'day')
                                    }
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => setAlignment('Add')}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        ) : null}
                    </div>

                    <div className="w-75 p-3">
                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">
                            {alignment === 'Add'
                                ? 'Add Vitals'
                                : alignment === 'Update'
                                ? 'Update Vitals'
                                : alignment === 'Details'
                                ? 'Vitals'
                                : 'NA'}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* Right hand modal form or graph */}
                        {alignment === 'Update' || alignment === 'Add' ? (
                            <UpdateVital submit={submit} setAlignment={setAlignment} alignment={alignment} />
                        ) : alignment === 'Details' ? (
                            <>
                                <VitalChart type="desktop" />
                                <div className="p-1 ps-0 pe-0">
                                    <p
                                        className="color-primary fw-bold"
                                        style={{ marginBottom: '5px', marginTop: '8px' }}
                                    >
                                        Select Vitals{' '}
                                    </p>
                                    <VitalPills name="Modal-pills" time={time} />
                                </div>
                            </>
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vitalsModal: state.toggleModals.vitalsModal,
        currentVital: state.vital.currentVital,
        vital: state.vitalList,
        //additional
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        vitalLoading: state.vitalList.vitalLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleVitalModal: (payload) => dispatch(toggleVitalModal(payload)),
        getVitalData: (payload, token) => dispatch(getVitalData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
