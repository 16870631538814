import React from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import variables from '../../../theme/variables.scss'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
//icons
import WorkIcon from '@mui/icons-material/Work'
import ForumIcon from '@mui/icons-material/Forum'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import HomeIcon from '@mui/icons-material/Home'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import WineBarIcon from '@mui/icons-material/WineBar'
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball'
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms'

export const Index = ({ socialListData, socialLoading, patientId, token, name, time }) => {
    const dispatch = useDispatch()

    return (
        <>
            <Grid
                rowSpacing={0.2}
                columnSpacing={1}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
                sx={{ overflow: 'hidden' }}
            >
                {!!socialLoading ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem
                                    sx={{
                                        padding: '1px !important',
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <WorkIcon sx={{ color: variables.blue500 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={socialListData?.occupation ?? '-'}
                                        secondary="Occupation"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <ForumIcon sx={{ color: variables.rose400 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            overflow: 'hidden',
                                            whiteSpace: 'no-wrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                        noWrap={true}
                                        primary={
                                            !!socialListData?.languages ? socialListData?.languages[0] : '-'
                                        }
                                        secondary="Language"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <FamilyRestroomIcon sx={{ color: variables.orange500 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            _.isNil(socialListData?.maritalStatus) &&
                                            _.isNil(socialListData?.totalKids)
                                                ? '-'
                                                : `${socialListData?.maritalStatus ?? '-'}, ${
                                                      socialListData?.totalKids ?? '-'
                                                  } kid(s)`
                                        }
                                        secondary="Family"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <HomeIcon sx={{ color: variables.purple }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={socialListData?.residence ?? '-'}
                                        secondary="Residence"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <FastfoodIcon sx={{ color: variables.red500 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={socialListData?.diet ?? '-'}
                                        secondary="Diet"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <WineBarIcon sx={{ color: variables.red600 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            _.isNil(socialListData?.alcoholUse?.number) &&
                                            _.isNil(socialListData?.alcoholUse?.unit)
                                                ? '-'
                                                : `${socialListData?.alcoholUse?.number ?? '-'}/${
                                                      socialListData?.alcoholUse?.unit ?? '-'
                                                  }`
                                        }
                                        secondary="Alcohol"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <SportsBasketballIcon sx={{ color: variables.green500 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={socialListData?.activity ?? '-'}
                                        secondary="Activity"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                                disablePadding={true}
                            >
                                <ListItem sx={{ padding: '1px !important' }}>
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '46px !important',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.white100,
                                                border: '1px solid',
                                                borderColor: variables.gray200,
                                                borderRadius: '4px',
                                            }}
                                            variant="rounded"
                                        >
                                            <SmokingRoomsIcon sx={{ color: variables.gray600 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            _.isNil(socialListData?.smokeUse?.number) &&
                                            _.isNil(socialListData?.smokeUse?.unit) &&
                                            _.isNil(socialListData?.smokeUse?.since)
                                                ? '-'
                                                : `${socialListData?.smokeUse?.number ?? '-'}/${
                                                      socialListData?.smokeUse?.unit ?? '-'
                                                  } - ${socialListData?.smokeUse?.since ?? '-'}yr`
                                        }
                                        secondary="Smoking"
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray900,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                            color: variables.gray600,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    socialListData: state.socialList.list,
    socialLoading: state.socialList.socialLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
