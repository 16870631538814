import {
    GET_FAMILY_LIST_DATA,
    SET_FAMILY_LIST_LOADING,
    GET_FAMILY_VIEW_DATA,
    SET_FAMILY_VIEW_LOADING,
    CLEAR_FAMILY_VIEW_HISTORY,
    CLEAR_FAMILY_LIST_HISTORY,
    CLEAR_FAMILY_HISTORY,
    DELETE_FAMILY_MEMBER,
    GET_FAMILY_EVENTS_DATA,
    SET_FAMILY_EVENTS_LOADING,
} from './familyList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const getFamilyListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_FAMILY_LIST_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/family-info/list?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_FAMILY_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_FAMILY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Error in fetching family history .Please! try Again',
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_FAMILY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_FAMILY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_FAMILY_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getFamilyViewData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_FAMILY_VIEW_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/family-info/view?patientId=${payload?.patientId}&type=${payload?.type}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_FAMILY_VIEW_DATA,
                    payload: response.data.output,
                })

                dispatch({
                    type: SET_FAMILY_VIEW_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Error in fetching family event history .Please! try Again',
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_FAMILY_VIEW_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))

                dispatch({
                    type: SET_FAMILY_VIEW_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))

                dispatch({
                    type: SET_FAMILY_VIEW_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getFamilyEventsData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_FAMILY_EVENTS_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/family-info/disease?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_FAMILY_EVENTS_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_FAMILY_EVENTS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Error in fetching family events .Please! try Again',
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_FAMILY_EVENTS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_FAMILY_EVENTS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_FAMILY_EVENTS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const clearFamilyViewData = () => (dispatch) => {
    dispatch({
        type: CLEAR_FAMILY_VIEW_HISTORY,
    })
}
export const clearFamilyListData = () => (dispatch) => {
    dispatch({
        type: CLEAR_FAMILY_LIST_HISTORY,
    })
}

export const clearFamilyData = () => (dispatch) => {
    dispatch({
        type: CLEAR_FAMILY_HISTORY,
    })
}

export const deleteFamilyMember = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/family-info/?id=${payload?.id}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_FAMILY_MEMBER,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Family Member Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
