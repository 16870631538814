import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

const MedicalHistoryInfo = ({ medicalHistoryListData }) => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-2">Medical History</Typography>

            <Box
                sx={{
                    border: 1,
                    borderRadius: '0.25rem',
                    borderColor: variables.lightGray1,
                    overflow: 'hidden',
                }}
            >
                <Typography
                    className="fs-9 fw-normal"
                    sx={{
                        background: variables.purple,
                        p: 0.5,
                        textAlign: 'center',
                        color: variables.white100,
                    }}
                >
                    Active
                </Typography>
                <Divider
                    sx={{
                        borderColor: variables.lightGray1,
                        border: 0.5,
                    }}
                />
                <Box sx={{ p: 0.5, overflowWrap: 'break-word' }}>
                    {_.isEmpty(medicalHistoryListData) ? (
                        <Typography className="gray-900 fw-light fs-9 text-center">
                            Medical History Not Available
                        </Typography>
                    ) : (
                        <>
                            {medicalHistoryListData?.map((diagnosisDetail, idx) => (
                                <Typography
                                    className="fs-9 fw-light py-1 gray-900"
                                    sx={{ textTransform: 'capitalize' }}
                                    key={idx}
                                >
                                    {!!diagnosisDetail?.customLabel
                                        ? diagnosisDetail?.customLabel ?? '-'
                                        : diagnosisDetail?.name ?? '-'}
                                </Typography>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        medicalHistoryListData: state?.medicalHistory?.medicalHistoryListData,
        medicalHistoryListLoading: state?.medicalHistory?.medicalHistoryListLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryInfo)
