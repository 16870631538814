import { GET_INTERVAL_LIST_DATA, SET_INTERVAL_LOADING, CLEAR_INTERVAL } from './intervalHistory.type'

const INITIAL_STATE = {
    list: [],
    intervalLoading: false,
    lud: null,
}

const intervalListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_INTERVAL_LIST_DATA:
            return {
                ...state,
                list: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : null,
                loading: false,
            }

        case SET_INTERVAL_LOADING:
            return {
                ...state,
                intervalLoading: action.payload,
            }

        case CLEAR_INTERVAL:
            return {
                list: [],
                intervalLoading: false,
                lud: null,
            }
        default:
            return state
    }
}

export default intervalListReducer
