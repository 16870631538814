import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import Box from '@mui/material/Box'
import { toggleIntervalModal } from '../../../redux/modals/toggleModal.actions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

export const Index = ({ patientId, token, lastUpdatedInterval, intervalLoading }) => {
    const dispatch = useDispatch()
    const [intervalComplete, setIntervalComplete] = React.useState(lastUpdatedInterval)

    useEffect(() => {
        setIntervalComplete(lastUpdatedInterval)
    }, [lastUpdatedInterval])

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                columns={{ xs: 12, md: 12 }}
                sx={{
                    background: '#fff !important',
                    boxShadow: 'none',
                    borderRadius: '0.25rem',
                    padding: '8px 12px 8px !important',
                }}
            >
                <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box>
                            {intervalLoading ? (
                                <div style={{ height: '24px', width: '26px' }}>
                                    <img
                                        src="/loader.gif"
                                        style={{ height: '100%', width: '100%' }}
                                        alt="loader"
                                    />
                                </div>
                            ) : intervalComplete ? (
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                    }}
                                />
                            ) : (
                                <RadioButtonUncheckedIcon
                                    sx={{
                                        color: variables.lightGray1,
                                    }}
                                />
                            )}
                        </Box>
                        <Box>
                            <Typography className=" cursor-default gray-900  fw-normal ">
                                Interval History
                            </Typography>
                            <Typography
                                className=" cursor-default gray-600 fs-9"
                                sx={{
                                    fontWeight: 400,
                                }}
                            >
                                {!!lastUpdatedInterval
                                    ? ` Last Updated: ${moment(lastUpdatedInterval).fromNow()}`
                                    : 'Add Interval History'}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item alignItems="center">
                    <Button
                        type="button"
                        variant="contained"
                        className={clsx(
                            intervalComplete ? 'btn-white-outline' : 'btn-primary',
                            'fs-9  cursor-pointer'
                        )}
                        disabled={intervalLoading}
                        onClick={(event) => {
                            dispatch(toggleIntervalModal(true))
                            event.stopPropagation()
                        }}
                    >
                        {intervalComplete ? 'Edit' : 'Update'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    lastUpdatedInterval: state?.intervalHistory?.lud,
    intervalLoading: state?.intervalHistory?.intervalLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleIntervalModal: (payload) => dispatch(toggleIntervalModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
