import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { GET_PATIENT_DETAILS, SET_PATIENT_LOADING } from './patient.type'

export const getPatientDetails = (id, token) => async (dispatch) => {
    dispatch({
        type: SET_PATIENT_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/patient-details`, id, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            // console.log("response", response)
            if (response.data.status === 200) {
                dispatch({
                    type: GET_PATIENT_DETAILS,
                    payload: response?.data?.output,
                })
            } else if (response.data.status === 401) {
                dispatch({
                    type: SET_PATIENT_LOADING,
                    payload: false,
                })
                dispatch(toggleSessionModal(true))
            } else if (response.data.status === 403) {
                dispatch({
                    type: SET_PATIENT_LOADING,
                    payload: false,
                })
                dispatch(toggleDeactivateModal(true))
            }
        })
}
