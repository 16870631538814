import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { toggleConsultLetterModal } from '../../../redux/modals/toggleModal.actions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

export const Index = ({ recommendationList, recommendationLoading, lastUpdatedRecommendations }) => {
    const dispatch = useDispatch()
    const [letterComplete, setLetterComplete] = React.useState(lastUpdatedRecommendations)

    useEffect(() => {
        setLetterComplete(lastUpdatedRecommendations)
    }, [lastUpdatedRecommendations])

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                columns={{ xs: 12, md: 12 }}
                sx={{
                    background: '#fff !important',
                    boxShadow: 'none',
                    borderRadius: '0.25rem',
                    padding: '8px 12px 8px !important',
                }}
            >
                <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box>
                            {recommendationLoading ? (
                                <div style={{ height: '24px', width: '26px' }}>
                                    <img
                                        src="/loader.gif"
                                        style={{ height: '100%', width: '100%' }}
                                        alt="loader"
                                    />
                                </div>
                            ) : letterComplete ? (
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                    }}
                                />
                            ) : (
                                <RadioButtonUncheckedIcon
                                    sx={{
                                        color: variables.lightGray1,
                                    }}
                                />
                            )}
                        </Box>
                        <Box>
                            <Typography className=" cursor-default gray-900  fw-normal ">
                                Consult Letter
                            </Typography>
                            <Typography
                                className=" cursor-default gray-600 fs-9"
                                sx={{
                                    fontWeight: 400,
                                }}
                            >
                                View/Print Consult Letter
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item alignItems="center">
                    <Button
                        type="button"
                        variant="contained"
                        className={clsx('btn-primary', 'fs-9  cursor-pointer')}
                        /* condition disabled for now  disabled={recommendationLoading || !recommendationList?.length > 0} */
                        disabled={recommendationLoading}
                        onClick={(event) => {
                            dispatch(toggleConsultLetterModal(true))
                            event.stopPropagation()
                        }}
                    >
                        Generate Consult Letter
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    recommendationList: state?.recommendations?.list?.recommendation,
    recommendationLoading: state?.recommendations?.recommendationLoading,
    lastUpdatedRecommendations: state?.recommendations?.lud,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleConsultLetterModal: (payload) => dispatch(toggleConsultLetterModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
