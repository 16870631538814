import {
    GET_ADVERSE_EVENT_LIST_DATA,
    SET_ADVERSE_EVENT_LIST_LOADING,
    GET_ADVERSE_EVENT_SUBSTANCE_DATA,
    SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
    CLEAR_ADVERSE_EVENT_SUBSTANCE_DATA,
    CLEAR_ADVERSE_EVENT_LIST_DATA,
    CLEAR_ADVERSE_EVENT_DATA,
    SET_CURRENT_ADVERSE_EVENT,
    DELETE_ADVERSE_EVENT,
} from './adverseEvent.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const getAdverseEventListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ADVERSE_EVENT_LIST_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/adverse-event/list?patientId=${payload?.patientId}&type=${payload?.type}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_ADVERSE_EVENT_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_ADVERSE_EVENT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Adverse Event ${payload?.type ?? ''}. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_ADVERSE_EVENT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ADVERSE_EVENT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ADVERSE_EVENT_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getAdverseEventSubstanceData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/adverse-event/view?patientId=${payload?.patientId}&type=${payload?.type}&substance=${payload?.substance}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_ADVERSE_EVENT_SUBSTANCE_DATA,
                    payload: response.data.output,
                })

                dispatch({
                    type: SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Adverse Event ${
                            payload?.substance ?? ''
                        }. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_ADVERSE_EVENT_SUBSTANCE_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const clearAdverseEventSubstanceData = () => (dispatch) => {
    dispatch({
        type: CLEAR_ADVERSE_EVENT_SUBSTANCE_DATA,
    })
}
export const clearAdverseEventListData = () => (dispatch) => {
    dispatch({
        type: CLEAR_ADVERSE_EVENT_LIST_DATA,
    })
}
export const clearAdverseEventData = () => (dispatch) => {
    dispatch({
        type: CLEAR_ADVERSE_EVENT_DATA,
    })
}

export const setCurrentAdverseEvent = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_ADVERSE_EVENT,
        payload: data,
    })
}

export const deleteAdverseEvent = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/adverse-event?id=${payload?.id}&type=presentations`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_ADVERSE_EVENT,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Adverse Event Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
