export const frequencyData = [
    { id: 'QD', val: 'qd' },
    { id: 'BID', val: 'bid' },
    { id: 'TID', val: 'tid' },
    { id: 'QID', val: 'qid' },
    { id: 'Q2HR', val: 'q2hr' },
    { id: 'Q4HR', val: 'q4hr' },
    { id: 'PRN', val: 'prn' },
    { id: 'STAT', val: 'stat' },
]

export const indicationData = [
    'CAD',
    'HFrEF',
    'HFpEF',
    'AF/Afib',
    'OSA',
    'HTN',
    'DLD',
    'Thyrotoxicosis',
    'COPD',
    'SOB',
    'CP',
]

export const measurementDropdownData = [
    {
        moduleKey: 'vitals',
        moduleValue: [
            { id: 'BP', val: 'bp' },
            { id: 'HR', val: 'hr' },
            { id: 'O2', val: 'o2' },
            { id: 'Height', val: 'height' },
            { id: 'curr Wt', val: 'currentWeight' },
            { id: 'dry Wt', val: 'dryWeight' },
            { id: 'diff in Wt', val: 'diffWeight' },
            { id: 'BMI', val: 'bmi' },
            { id: 'BSA', val: 'bsa' },
        ],
    },
    {
        moduleKey: 'labs',
        moduleValue: [
            { id: 'LDL', val: 'LDL' },
            { id: 'HDL', val: 'HDL' },
            { id: 'TG', val: 'TG' },
            { id: 'TC', val: 'TC' },
            { id: 'NA', val: 'NA' },
            { id: 'K', val: 'K' },
            { id: 'Cr', val: 'Cr' },
            { id: 'GFR', val: 'GFR' },
            { id: 'Hb', val: 'Hb' },
            { id: 'Plt', val: 'Plt' },
            { id: 'INR', val: 'INR' },
            { id: 'BNP', val: 'BNP' },
            { id: 'CK', val: 'CK' },
            { id: 'ALT', val: 'ALT' },
        ],
    },
    {
        moduleKey: 'ecg',
        moduleValue: [
            { id: 'HR', val: 'HR' },
            { id: 'PR', val: 'PR(ms)' },
            { id: 'QRS', val: 'QRS(ms)' },
            { id: 'QT/QTc', val: 'QT/QTc(ms)' },
            { id: 'P', val: 'P(ms)' },
            { id: 'PAxis', val: 'PAxis' },
            { id: 'QRS Axis', val: 'QRS Axis' },
        ],
    },
    {
        moduleKey: 'holter',
        moduleValue: [
            { id: 'Max HR', val: 'Max HR' },
            { id: 'Min HR', val: 'Min HR' },
            { id: 'Mean HR', val: 'Mean HR' },
            // { id: 'pauses', val: 'pauses' }, //commented for now
            // { id: 'long', val: 'long' },
            { id: 'PAC', val: 'PAC' },
            { id: 'Pac Long', val: 'PacLong' },
            { id: 'Pac Fast', val: 'PacFast' },
            { id: 'PVC', val: 'PVC' },
            { id: 'Pvc Long', val: 'PvcLong' },
            { id: 'Pvc Fast', val: 'PvcFast' },
        ],
    },
    {
        moduleKey: 'gxt',
        moduleValue: [
            { id: 'Ex Dur', val: 'Ex Dur' },
            { id: 'METS', val: 'METS' },
            { id: 'Double Product', val: 'Double Product' },
            { id: 'Rest HR', val: 'Rest HR' },
            { id: 'Peak HR', val: 'Peak HR' },
            { id: '% THR', val: '% THR' },
            { id: 'Rest BP', val: 'Rest BP' }, //will have to be changed
            { id: 'Peak BP', val: 'Peak BP' },
            { id: 'DTS', val: 'DTS' },
        ],
    },
    {
        moduleKey: 'echo',
        moduleValue: [
            { id: 'EF (%)', val: 'EF (%)' },
            { id: 'LA', val: 'LA' },
            { id: 'Ao R', val: 'Ao R' },
            { id: 'Ao Asc', val: 'Ao Asc' },
            { id: 'RVSP', val: 'RVSP' },
            { id: 'AV Peak', val: 'AV Peak' },
            { id: 'AV Mean', val: 'AV Mean' },
            { id: 'AV PHT', val: 'AV PHT' },
            { id: 'MV Mean', val: 'MV Mean' },
            { id: 'MV Area PHT', val: 'MV Area PHT' },
        ],
    },
]

export const occupationData = [
    { id: 'Engineer', val: 'Engineer' },
    { id: 'Doctor', val: 'Doctor' },
    { id: 'Lawyer', val: 'Lawyer' },
    { id: 'Nurse', val: 'Nurse' },
    { id: 'Teacher', val: 'Teacher' },
    { id: 'Mechanic', val: 'Mechanic' },
    { id: 'Secretary ', val: 'Secretary ' },
    { id: 'Cashier ', val: 'Cashier ' },
    { id: 'Other', val: 'other' },
]

export const dietData = [
    { id: 'Regular Diet', val: 'Regular Diet' },
    { id: 'Clear Liquid Diet', val: 'Clear Liquid Diet' },
    { id: 'Full Liquid Diet', val: 'Full Liquid Diet' },
    { id: 'GI Soft Diet', val: 'GI Soft Diet' },
    { id: 'Low Residue/Lower Fiber Diet', val: 'Low Residue/Lower Fiber Diet' },
    { id: 'Low Sodium Diet', val: 'Low Sodium Diet' },
    { id: 'Fat Restricted Diet', val: 'Fat Restricted Diet' },
    { id: 'Cholesterol-Restricted Diet', val: 'Cholesterol-Restricted Diet' },
    { id: 'Vegetarian Diet', val: 'Vegetarian Diet' },
    { id: 'Diabetic Diet', val: 'Diabetic Diet' },
    { id: 'Renal Diet', val: 'Renal Diet' },
    { id: 'Other', val: 'other' },
]

export const activityData = [
    { id: 'Sedentary', val: 'Sedentary' },
    { id: 'Moderate', val: 'Moderate' },
    { id: 'Vigorous', val: 'Vigorous' },
]
export const languageData = ['English', 'French', 'Chinese ', 'Spanish', 'German', 'Arabic', 'Hindi', 'Tamil']

export const homeResidenceData = [
    { id: 'Independent', val: 'Independent' },
    { id: 'Nursing home', val: 'Nursing home' },
    { id: 'In-home care', val: 'In-home care' },
    { id: 'Hospice care', val: 'Hospice care' },
]

export const cardiacEvents = [
    { id: 'Heart Attack', val: 'Heart Attack' },
    { id: 'Coronary Artery Disease', val: 'Coronary Artery Disease' },
    { id: 'Stroke', val: 'Stroke' },
    { id: 'Heart Failure', val: 'Heart Failure' },
    { id: 'Cardiac Arrest', val: 'Cardiac Arrest' },
    { id: 'Aneurysm', val: 'Aneurysm' },
]

export const adverseEventFormData = [
    { id: 'Penicillin', val: 'Penicillin' },
    { id: 'Antibiotics', val: 'Antibiotics' },
    { id: 'Anticonvulsants', val: 'Anticonvulsants' },
    { id: 'Aspirin', val: 'Aspirin' },
    { id: 'Ibuprofen', val: 'Ibuprofen' },
    { id: 'NSAIDs', val: 'NSAIDs' },
    { id: 'Chemotherapy drugs', val: 'Chemotherapy drugs' },
]

export const adverseReactionFormData = [
    { id: 'Rash', val: 'Rash' },
    { id: 'Skin rash', val: 'Skin rash' },
    { id: 'Hives', val: 'Hives' },
    { id: 'Itching', val: 'Itching' },
    { id: 'Fever', val: 'Fever' },
    { id: 'Swelling', val: 'Swelling' },
    { id: 'Shortness of Breath', val: 'Shortness of Breath' },
    { id: 'Wheezing', val: 'Wheezing' },
    { id: 'Running nose', val: 'Running nose' },
    { id: 'Itchy watery eyes', val: 'Itchy watery eyes' },
    { id: 'Anaphylaxis', val: 'Anaphylaxis' },
]

export const cardiacTreatments = [
    { id: 'Monitor', type: 'Monitor' },
    { id: 'Medications', type: 'Medications' },
    { id: 'Procedure', type: 'Procedure' },
    { id: 'Surgery', type: 'Surgery' },
]

export const cardiacRiskFactors = ['HTN', 'DL', 'DM', 'FHx', 'Smk', 'BMI']
