import { CLEAR_MRI, GET_MRI_DOCUMENT, MRI_DOC_LOADING, RESET_MRI_MODAL } from './MRIList.type'

const INITIAL_STATE = {
    mriLoading: false,
    lud: '',
    mriDocuments: [],
}

const MRIListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MRI_DOCUMENT:
            return {
                ...state,
                mriDocuments: action?.payload?.list,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case MRI_DOC_LOADING:
            return {
                ...state,
                mriLoading: action.payload,
            }
        case RESET_MRI_MODAL:
            return {
                ...state,
                mriDocuments: [],
            }
        case CLEAR_MRI:
            return {
                mriLoading: false,
                lud: '',
                mriDocuments: [],
            }
        default:
            return state
    }
}

export default MRIListReducer
