import {
    CLEAR_LAB_REPORTS,
    CLEAR_LABS,
    GET_LABS_DATA,
    GET_LABS_LIST_DATA,
    GET_LABS_MODAL_CHART_LODING,
    GET_LABS_MODAL_DATA,
    SET_LABS_CATEGORY,
    SET_LABS_CATEGORY_LOADING,
    SET_LABS_LOADING,
    SET_LABS_REPORTS,
    SET_LABS_REPORTS_LOADING,
} from './labsList.type'

const INITIAL_STATE = {
    loading: false,
    list: [],
    labsData: [],
    labsModalData: [],
    labsLoading: false,
    labsModalChartLoading: false,
    labsReportCategories: [],
    labsReportLoading: false,
    labsCategoryLoading: false,
    labsReport: [],
    lud: '',
}

const labsListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LABS_LIST_DATA:
            return {
                ...state,
                list: action?.payload?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }
        case GET_LABS_DATA:
            return {
                ...state,
                labsData: action?.payload,
            }
        case GET_LABS_MODAL_DATA:
            return {
                ...state,
                labsModalData: action?.payload,
            }
        case SET_LABS_LOADING:
            return {
                ...state,
                labsLoading: action.payload,
            }
        case SET_LABS_CATEGORY:
            return {
                ...state,
                labsReportCategories: action.payload,
            }
        case SET_LABS_REPORTS:
            return {
                ...state,
                labsReport: action.payload,
            }
        case SET_LABS_REPORTS_LOADING:
            return {
                ...state,
                labsReportLoading: action.payload,
            }
        case SET_LABS_CATEGORY_LOADING:
            return {
                ...state,
                labsCategoryLoading: action.payload,
            }
        case GET_LABS_MODAL_CHART_LODING:
            return {
                ...state,
                labsModalChartLoading: action.payload,
            }
        case CLEAR_LAB_REPORTS:
            return {
                ...state,
                labsReport: [],
                labsReportCategories: [],
            }
        case CLEAR_LABS:
            return {
                loading: false,
                list: [],
                labsData: [],
                labsLoading: false,
                labsReportCategories: [],
                labsReportLoading: false,
                labsCategoryLoading: false,
                labsReport: [],
                lud: '',
            }
        default:
            return state
    }
}

export default labsListReducer
