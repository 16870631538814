import * as React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import variables from '../../theme/variables.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: '20px',
    minHeight: '218px',
    minWidth: '218px',
    ':hover': {
        cursor: 'pointer',
        border: `1px solid ${variables.purple}`,
    },
    ':hover .icon-check': {
        opacity: '1 !important',
    },
}))
const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.125rem',
    fontWeight: '500',
    color: variables.darkGray,
    textTransform: 'capitalize',
}))

export default function Card({ title, icon, onClickHandler, disableCard = false }) {
    return (
        <Item
            sx={{
                backgroundColor: disableCard && variables.lightGray1,
                pointerEvents: disableCard && 'none !important',
                pointer: disableCard && 'not-allowed !important',
            }}
            onClick={onClickHandler}
        >
            <Box className="opacity-0 d-flex justify-content-end align-items-center icon-check">
                <CheckCircleIcon sx={{ color: variables.purple }} fontSize="small" />
            </Box>
            <Box className="d-flex flex-column justify-content-start align-items-center p-4 gap-3">
                <Avatar
                    alt={title ?? 'portal icon'}
                    src={icon ?? '/images/workFlow-icon.svg'}
                    variant="square"
                    sx={{ width: 80, height: 80 }}
                />
                <Title>{title ?? 'portal'}</Title>
            </Box>
        </Item>
    )
}
