import { GET_LAB_CATEGORY, SET_CURRENT_LABS, SET_LOADING } from './labs.type'

const INITIAL_STATE = {
    currentLabs: 'LDL',
    categories: [],
    loading: true,
}

const labsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_LABS:
            return {
                ...state,
                currentLabs: action.payload,
                loading: false,
            }
        case GET_LAB_CATEGORY:
            return {
                ...state,
                categories: action.payload,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default labsReducer
