import React from 'react'
import variables from '../../../../theme/variables.scss'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'
import Typography from '@mui/material/Typography'
import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const commonBorderStyles = {
    padding: 2,
    border: '1px solid',
    borderColor: variables.lightGray1,
    borderRadius: 1.5,
    minHeight: '10.36rem',
}

const RecommendationForm = ({
    values,
    planId,
    planValue,
    assessmentId,
    assessmentValue,
    diagnosisId,
    diagnosisValue,
}) => {
    return (
        <>
            <BoxWrapper
                styles={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                    gap: 0,
                    ...commonBorderStyles,
                }}
            >
                <Typography className="fs-8 mt-1 fw-bold gray-900" sx={{ minWidth: '150px' }}>
                    {diagnosisValue ?? '-N/A-'}
                </Typography>

                <StackWrapper
                    title="Assessment"
                    titleWidth="90px"
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Inputs
                        inputType={'toggleBtn'}
                        inputSize="xxl"
                        inputName={assessmentId}
                        inputVal={assessmentValue}
                        toggleBtnArr={['Optimal', 'Symptomatic', 'Not at Target']}
                    />
                </StackWrapper>

                <StackWrapper title="Plan" titleWidth="90px" direction="row" spacing={2}>
                    <BoxWrapper
                        styles={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <BoxWrapper>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="xxl"
                                inputName={planId}
                                inputVal={planValue}
                                toggleBtnArr={['Monitor', 'Modify']}
                            />
                        </BoxWrapper>

                        <Typography className="fs-10 mx-1 fw-normal gray-600" sx={{ minWidth: '150px' }}>
                            {planValue === 'Modify'
                                ? 'Add plan to Additional Notes if applicable.'
                                : planValue === 'Monitor'
                                ? 'Continue Current Management.'
                                : null}
                        </Typography>
                    </BoxWrapper>
                </StackWrapper>
            </BoxWrapper>
        </>
    )
}

export default RecommendationForm
