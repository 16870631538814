import {
    GET_HOLTER_DATA,
    GET_HOLTER_DOCUMENT,
    GET_HOLTER_LIST_DATA,
    HOLTER_DOC_LOADING,
    RESET_HOLTER_DOCUMENT,
    SET_ECG_LOADING,
    CLEAR_HOLTER,
} from './holterList.type'

const INITIAL_STATE = {
    list: [],
    holterData: [],
    holterLoading: false,
    lud: '',
    holterDocuments: {
        list: [],
        lastRefreshDate: '',
    },
    holterDocLoading: false,
}

const holterListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_HOLTER_LIST_DATA:
            return {
                ...state,
                list: action?.payload?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }
        case GET_HOLTER_DATA:
            return {
                ...state,
                holterData: action?.payload,
            }
        case GET_HOLTER_DOCUMENT:
            return {
                ...state,
                holterDocuments: action?.payload,
            }
        case SET_ECG_LOADING:
            return {
                ...state,
                holterLoading: action.payload,
            }
        case HOLTER_DOC_LOADING:
            return {
                ...state,
                holterDocLoading: action.payload,
            }
        case RESET_HOLTER_DOCUMENT:
            return {
                ...state,
                holterDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
            }
        case CLEAR_HOLTER:
            return {
                list: [],
                holterData: [],
                holterLoading: false,
                lud: '',
                holterDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
                holterDocLoading: false,
            }
        default:
            return state
    }
}

export default holterListReducer
