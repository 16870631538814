import React from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../theme/variables.scss'
import clsx from 'clsx'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import Box from '@mui/material/Box'
import { toggleRecommendationsModal } from '../../redux/modals/toggleModal.actions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RecommendationDetails from './recommendation-details/RecommendationDetails'

export const Recommendations = ({
    patientId,
    token,
    recommendationList,
    recommendationListLoading,
    lastUpdatedRecommendations,
    cardiacHistoryListData,
    cardiacHistoryListLoading,
    isCreatedNowFlag,
}) => {
    const dispatch = useDispatch()
    return (
        <Box
            sx={{
                background: '#fff !important',
                boxShadow: 'none',
                borderRadius: '0.25rem',
                padding: '8px 12px 8px !important',
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                columns={{ xs: 12, md: 12 }}
            >
                <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box>
                            {recommendationListLoading ? (
                                <div style={{ height: '24px', width: '26px' }}>
                                    <img
                                        src="/loader.gif"
                                        style={{ height: '100%', width: '100%' }}
                                        alt="loader"
                                    />
                                </div>
                            ) : isCreatedNowFlag ? (
                                <RadioButtonUncheckedIcon
                                    sx={{
                                        color: variables.lightGray1,
                                    }}
                                />
                            ) : _.isEmpty(recommendationList) ? (
                                <RadioButtonUncheckedIcon
                                    sx={{
                                        color: variables.lightGray1,
                                    }}
                                />
                            ) : (
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                    }}
                                />
                            )}
                        </Box>
                        <Box>
                            <Typography className=" cursor-default gray-900  fw-normal ">
                                Recommendations
                            </Typography>
                            <Typography
                                className=" cursor-default gray-600 fs-9"
                                sx={{
                                    fontWeight: 400,
                                }}
                            >
                                {isCreatedNowFlag
                                    ? 'Update Recommendations'
                                    : `${
                                          lastUpdatedRecommendations
                                              ? `Last Updated: ${moment(
                                                    lastUpdatedRecommendations
                                                )?.fromNow()}`
                                              : 'Update Recommendations'
                                      }`}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item alignItems="center">
                    <Button
                        type="button"
                        variant="contained"
                        className={clsx(
                            isCreatedNowFlag ? 'btn-primary' : 'btn-white-outline',
                            'fs-9  cursor-pointer'
                        )}
                        disabled={
                            recommendationListLoading ||
                            cardiacHistoryListLoading ||
                            !!_.isEmpty(cardiacHistoryListData) ||
                            !!_.isEmpty(recommendationList)
                        }
                        onClick={(event) => {
                            dispatch(toggleRecommendationsModal(true))
                            event.stopPropagation()
                        }}
                    >
                        {isCreatedNowFlag ? 'Update' : 'Edit'}
                    </Button>
                </Grid>
            </Grid>

            {recommendationListLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justify: 'flex-start',
                        background: variables.gray100,
                        borderRadius: '0.25rem',
                        p: 1,
                        my: 1,
                    }}
                >
                    <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    <Typography
                        className=" cursor-default gray-600 fs-9"
                        sx={{
                            fontWeight: 400,
                        }}
                    >
                        Loading Recommendations...
                    </Typography>
                </Box>
            ) : !_.isEmpty(recommendationList) ? (
                <RecommendationDetails />
            ) : null}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    recommendationList: state?.recommendations?.list?.recommendation,
    recommendationListLoading: state?.recommendations?.recommendationLoading,
    lastUpdatedRecommendations: state?.recommendations?.lud,
    isCreatedNowFlag: state?.recommendations?.isCreatedNow,
    cardiacHistoryListData: state?.cardiacHistory?.cardiacHistoryListData,
    cardiacHistoryListLoading: state?.cardiacHistory?.cardiacHistoryListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleRecommendationsModal: (payload) => dispatch(toggleRecommendationsModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations)
