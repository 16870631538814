import React from 'react'
import { connect } from 'react-redux'
import DummyChart from '../../../dummy-chart'
import LoadingChart from '../../../labs-component/labs-charts/loading-chart'
import Charts from './Charts'

export const Index = ({ currentEcho, echoLoading, echoData, type }) => {
    return (
        <div>
            {echoLoading === true ? (
                <LoadingChart type={type} />
            ) : echoData.length > 0 ? (
                <Charts
                    type={type}
                    name={
                        currentEcho === 'AV Mean'
                            ? 'AV MG'
                            : currentEcho === 'MV Mean'
                            ? 'MV MG'
                            : currentEcho === 'MV Area PHT'
                            ? 'MVA PHT'
                            : currentEcho
                    }
                />
            ) : (
                <DummyChart
                    type={type}
                    name={
                        currentEcho === 'AV Mean'
                            ? 'AV MG'
                            : currentEcho === 'MV Mean'
                            ? 'MV MG'
                            : currentEcho === 'MV Area PHT'
                            ? 'MVA PHT'
                            : currentEcho
                    }
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentEcho: state.echo.currentEcho,
    echoData: state.echoList.echoData,
    echoLoading: state.echoList.echoLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
