export const GET_CARDIAC_LIST_DATA = 'GET_CARDIAC_LIST_DATA'
export const GET_CARDIAC_DATA = 'GET_CARDIAC_DATA'
export const GET_CARDIAC_DOCUMENT = 'GET_CARDIAC_DOCUMENT'
export const CARDIAC_DOC_LOADING = 'CARDIAC_DOC_LOADING'
export const SET_CARDIAC_LOADING = 'SET_CARDIAC_LOADING'
export const RESET_CARDIAC_MODAL = 'RESET_CARDIAC_MODAL'
export const CLEAR_CARDIAC = 'CLEAR_CARDIAC'
export const SET_CARDIAC_MED_LOADING = 'CARDIAC_MED_LOADING'
export const GET_MEASUREMENT_DATA = 'GET_MEASUREMENT_DATA'
export const SET_MEASUREMENT_LOADING = 'SET_MEASUREMENT_LOADING'
export const CLEAR_MEASUREMENT_DATA = 'CLEAR_MEASUREMENT_DATA'
export const GET_OTHER_LIST_DATA = 'GET_OTHER_LIST_DATA'
export const SET_OTHER_LOADING = 'SET_OTHER_LOADING'
export const GET_PAST_LIST_DATA = 'GET_PAST_LIST_DATA'
export const SET_PAST_LOADING = 'SET_PAST_LOADING'
export const SET_CURRENT_MEDICATION = 'SET_CURRENT_MEDICATION'
