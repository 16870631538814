/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { toggleLabsModal } from '../../../redux/modals/toggleModal.actions'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import LabsPills from '../labs-pills'
import {
    clearLabReports,
    getLabsReportCategories,
    getLabsReports,
    getModalLabsData,
    getLabsData,
} from '../../../redux/labsList/labsList.action'
import FeedIcon from '@mui/icons-material/Feed'
import { getLabsCategory } from '../../../redux/labs/labs.action'
import PdfViewer from '../../pdf-viewer'
import {
    blobToBase64,
    getS3File,
    getTrimedCategory,
    getTrimedFilename,
    dispatchResetActionWrapper,
    hasTifExtension,
} from '../../../util'
import LabsModalChart from '../labs-modal-charts/index'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    labCategory,
    labsModal,
    currentLabs,
    labs,
    patientId,
    token,
    labsReportCategories,
    labReports,
    labsReportLoading,
    isDemoPatient,
}) => {
    const [alignment, setAlignment] = React.useState('Details')
    const [time, setTime] = React.useState('60')
    const [detailsTime, setDetailsTime] = React.useState('60')
    const [category, setCategory] = React.useState(!!isDemoPatient ? '638819f02ca09da55493777b' : 'all') //biochemistry as default category for demo patient
    const [subCategory, setSubCategory] = React.useState('none')
    const [selectLab, setSelectLab] = React.useState('none')
    const [showPdf, setShowPdf] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [isTif, setIsTif] = React.useState(false)
    const [fileID, setFileID] = React.useState('')
    const [reportCatName, setReportCatName] = React.useState('')
    const [pdfLoading, setPdfLoading] = React.useState(false)
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(getLabsCategory(token))
    }, [token])

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        {
            dispatchResetActionWrapper(
                detailsTime,
                currentLabs,
                patientId,
                token,
                dispatch,
                toggleLabsModal,
                getLabsData,
                clearLabReports
            )
        }
    }

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            if (newAlignment === 'Details') {
                // setCategory('none')
                // setSelectLab('none')
                setUrl('')
                setFileID('')
            }
            setAlignment(newAlignment)
            setShowPdf(false)
        }
    }

    useEffect(() => {
        handleChangeDetailsTime(detailsTime)
    }, [currentLabs])

    // useEffect(() => {
    //   const payload = {
    //     "category": ''
    // }
    //   dispatch(getLabsReportCategories(payload, token))
    // },[dispatch, token])

    const handleChangeTime = (value) => {
        setShowPdf(false)
        setFileID('')
        setTime(value)
        if (value !== 'lastvisit') {
            setShowPdf(false)
            setFileID('')
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format('YYYY-MM-DD') + 'T00:00:00.000Z'
            const payload = {
                categoryId: selectLab === 'none' ? (category === 'all' ? '' : category) : selectLab,
                patientId: patientId,
                date: proposedDate,
            }
            category !== 'none' && dispatch(getLabsReports(payload, token))
        } else {
            setShowPdf(false)
            const payload = {
                categoryId: selectLab === 'none' ? (category === 'all' ? '' : category) : selectLab,
                patientId: patientId,
                date: 'lastvisit',
            }
            category !== 'none' && dispatch(getLabsReports(payload, token))
        }
    }
    const handleChangeDetailsTime = (value) => {
        setShowPdf(false)
        setFileID('')
        setDetailsTime(value)
        if (value !== 'lastvisit') {
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format('YYYY-MM-DD') + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: currentLabs,
                date: proposedDate,
            }
            dispatch(getModalLabsData(payload, token))
        } else {
            const payload = {
                patientId: patientId,
                type: currentLabs,
                date: value,
            }
            dispatch(getModalLabsData(payload, token))
        }
    }
    const handleChangeCategory = (val) => {
        setShowPdf(false)
        setFileID('')
        setShowPdf(false)
        setSelectLab('none')
        setCategory(val)
        if (val !== 'none') {
            const payload = {
                categoryId: val === 'all' ? '' : val,
            }
            dispatch(getLabsReportCategories(payload, token))
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format('YYYY-MM-DD') + 'T00:00:00.000Z'
            const payloadReport = {
                categoryId: val === 'all' ? '' : val,
                patientId: patientId,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getLabsReports(payloadReport, token))
        }
    }
    const handleChangeSubCategory = (event) => {
        setFileID('')
        setShowPdf(false)
        setSubCategory(event.target.value)
    }
    const handleChangeLab = (event) => {
        setSelectLab(event.target.value)
        setFileID('')
        setShowPdf(false)
        if (event.target.value !== 'none') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format('YYYY-MM-DD') + 'T00:00:00.000Z'
            const payload = {
                categoryId: event.target.value,
                patientId: patientId,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getLabsReports(payload, token))
        }
    }

    const handleDocument = (report) => {
        setPdfLoading(true)
        setUrl('')
        setShowPdf(true)
        setFileID(report?._id)
        setIsTif(hasTifExtension(report?.compressedKey || report?.key))
        getS3File(report?.compressedKey || report?.key)
            .then((res) => {
                blobToBase64(res?.Body).then((usablePdf) => {
                    setUrl(usablePdf)
                    setShowPdf(true)
                    setPdfLoading(false)
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                setPdfLoading(false)
            })
    }

    /* Load inital report pdf */
    useEffect(() => {
        if (labReports?.length > 0 && alignment === 'Reports') {
            handleDocument(labReports[0])
        }
    }, [labReports, alignment])

    useEffect(() => {
        handleChangeCategory(category)
    }, [])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={labsModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    {/* left Section of Modal */}
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">Labs</Typography>
                        <Typography className="gray-600 fs-9" gutterBottom>
                            {labs?.lud !== ''
                                ? `Last refreshed ${moment.parseZone(labs?.lud).format('MMM DD, YYYY')}`
                                : null}
                        </Typography>
                        <ToggleButtonGroup
                            color="primary"
                            className="vital-switch-btn mt-3"
                            value={alignment}
                            size="large"
                            exclusive
                            onChange={handleChange}
                            fullWidth
                        >
                            <ToggleButton
                                fullWidth
                                sx={{ textTransform: 'capitalize', width: '100%!important' }}
                                value="Details"
                            >
                                Details
                            </ToggleButton>
                            <ToggleButton
                                fullWidth
                                sx={{ textTransform: 'capitalize', width: '100%!important' }}
                                value="Reports"
                            >
                                Reports
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                            Timeline
                        </Typography>
                        <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                            {alignment === 'Reports' && (
                                <>
                                    <Select
                                        labelId="demo-customized-select-label1"
                                        id="demo-customized-select1"
                                        value={time}
                                        defaultValue="Select Timeline"
                                        onChange={(e) => handleChangeTime(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="lastvisit">
                                            Since Last Visit
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="1">
                                            Last 1 Month
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="6">
                                            Last 6 Months
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="12">
                                            Last 1 Year
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="60">
                                            Last 5 Years
                                        </MenuItem>
                                    </Select>
                                </>
                            )}
                            {alignment === 'Details' && (
                                <>
                                    <Select
                                        labelId="demo-customized-select-label0"
                                        id="demo-customized-select0"
                                        value={detailsTime}
                                        defaultValue="Select Timeline"
                                        onChange={(e) => handleChangeDetailsTime(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="lastvisit">
                                            Since Last Visit
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="1">
                                            Last 1 Month
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="6">
                                            Last 6 Months
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="12">
                                            Last 1 Year
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="60">
                                            Last 5 Years
                                        </MenuItem>
                                    </Select>
                                </>
                            )}
                        </FormControl>
                        {alignment === 'Reports' && (
                            <>
                                <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                                    Category
                                </Typography>
                                <FormControl sx={{ width: 1 }} variant="standard">
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={category}
                                        defaultValue="none"
                                        onChange={(e) => handleChangeCategory(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        {category === 'none' && (
                                            <MenuItem className="fs-9" value="none" disabled>
                                                Select Category
                                            </MenuItem>
                                        )}
                                        <MenuItem className="fs-9" value="all">
                                            All Category
                                        </MenuItem>
                                        {labCategory?.length > 0 &&
                                            labCategory?.map((cat) => {
                                                return (
                                                    <MenuItem className="fs-9" value={cat._id}>
                                                        {cat.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                <FormControl className="mt-2" sx={{ width: 1 }} variant="standard">
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={subCategory}
                                        disabled
                                        defaultValue="select"
                                        onChange={handleChangeSubCategory}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="none" disabled>
                                            Select Sub Category
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className="mt-2" sx={{ width: 1 }} variant="standard">
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={selectLab}
                                        defaultValue="none"
                                        onChange={handleChangeLab}
                                        input={<BootstrapInput />}
                                    >
                                        {selectLab === 'none' && (
                                            <MenuItem className="fs-9" value="none" disabled>
                                                Select
                                            </MenuItem>
                                        )}
                                        {labsReportCategories?.length > 0 &&
                                            labsReportCategories.map((cat, index) => {
                                                return (
                                                    <MenuItem
                                                        className="fs-9"
                                                        onClick={() => setReportCatName(cat?.name)}
                                                        key={index}
                                                        value={cat?._id}
                                                    >
                                                        {getTrimedCategory(cat?.name)}
                                                        {/* {cat?.name} */}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>

                                {labReports?.length > 0 && labsReportLoading === false ? (
                                    <Typography className="gray-600 fs-9 mt-3">
                                        {' '}
                                        Found {labReports?.length} result(s)
                                    </Typography>
                                ) : null}
                                {category !== 'none' && (
                                    <div
                                        className="overflow-auto mt-2"
                                        style={{
                                            maxHeight: '160px',
                                            backgroundColor: variables.white100,
                                            borderRadius: '5px',
                                            border: '1px solid',
                                            borderColor: variables.lightGray1,
                                            minHeight: '100px',
                                            overflow: 'auto',
                                        }}
                                    >
                                        {labsReportLoading === false ? (
                                            labReports?.length > 0 ? (
                                                labReports?.map((report, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            className={clsx(
                                                                fileID === report?._id &&
                                                                    'bg-indigo-50 fw-bold',
                                                                'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer'
                                                            )}
                                                        >
                                                            <Box
                                                                className="cursor-pointer"
                                                                display="flex"
                                                                alignItems={'center'}
                                                                gap={1}
                                                                onClick={() => handleDocument(report)}
                                                            >
                                                                <FeedIcon className="fs-8" />
                                                                {getTrimedFilename(report?.name)}
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            ) : (
                                                category !== 'none' && (
                                                    <p className="mt-3 text-center p-1 fs-8 gray-900">
                                                        No records found
                                                    </p>
                                                )
                                            )
                                        ) : (
                                            <div className="loader">
                                                <img src="/loader.gif" alt="loader" />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {/* Right Section of Modal */}
                    <div className="w-75 p-3 overflow-auto">
                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">
                            {alignment === 'Reports' && <> {selectLab !== 'none' && reportCatName} Report</>}
                            {alignment === 'Details' && <>{currentLabs === 'NA' ? 'Na' : currentLabs}</>}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {alignment === 'Details' && (
                            <>
                                <LabsModalChart type="desktop" />
                                <div className="p-1 ps-0 pe-0">
                                    <p
                                        className="color-primary fw-bold"
                                        style={{ marginBottom: '5px', marginTop: '8px' }}
                                    >
                                        Select Labs
                                    </p>
                                    <LabsPills name="Modal-pills" time={detailsTime} />
                                </div>
                            </>
                        )}
                        {/* No Data Found Right side */}
                        {alignment === 'Reports' && labReports?.length === 0 && !showPdf && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {labsReportLoading === true ? (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {alignment === 'Reports' && labReports?.length > 0 && !url && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {pdfLoading === true ? (
                                        <div>
                                            <img src="/loader.gif" width="35" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {alignment === 'Reports' && !!showPdf && !!url && !pdfLoading && (
                            <PdfViewer url={url} isTif={isTif} />
                        )}
                    </div>
                    {/* <Pdf /> */}
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        labsModal: state.toggleModals.labsModal,
        currentLabs: state.labs.currentLabs,
        labsReportCategories: state.labsList.labsReportCategories,
        labReports: state.labsList.labsReport,
        labs: state.labsList,
        labsReportLoading: state.labsList.labsReportLoading,
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        labCategory: state.labs.categories,
        isDemoPatient: state.patient.patientData.isDemoPatient,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLabsModal: (payload) => dispatch(toggleLabsModal(payload)),
        getModalLabsData: (payload, token) => dispatch(getModalLabsData(payload, token)),
        getLabsReportCategories: (payload, token) => dispatch(getLabsReportCategories(payload, token)),
        getLabsReports: (payload, token) => dispatch(getLabsReports(payload, token)),
        getLabsCategory: (token) => dispatch(getLabsCategory(token)),
        clearLabReports: () => dispatch(clearLabReports()),
        getLabsData: (payload, token) => dispatch(getLabsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
