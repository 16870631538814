import { CLEAR_VITALS, GET_VITAL_DATA, GET_VITAL_LIST_DATA, SET_VITAL_LOADING } from './vitalList.type'

const INITIAL_STATE = {
    loading: true,
    list: [],
    vitalData: [],
    vitalLoading: false,
    lud: '',
}

const vitalListReducer = (state = INITIAL_STATE, action) => {
    const newVal = action.payload
    switch (action.type) {
        case GET_VITAL_LIST_DATA:
            return {
                ...state,
                list: newVal?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
            }
        case GET_VITAL_DATA:
            return {
                ...state,
                vitalData: newVal,
            }
        case SET_VITAL_LOADING:
            return {
                ...state,
                vitalLoading: newVal,
            }
        case CLEAR_VITALS:
            return {
                loading: false,
                list: [],
                vitalData: [],
                vitalLoading: false,
                lud: '',
            }
        default:
            return state
    }
}

export default vitalListReducer
