import { GET_SOCIAL_LIST_DATA, SET_SOCIAL_LOADING, CLEAR_SOCIAL } from './socialList.type'

const INITIAL_STATE = {
    list: [],
    socialLoading: false,
    lud: '',
}

const socialListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SOCIAL_LIST_DATA:
            return {
                ...state,
                // list: action?.payload?.list,
                list: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }

        case SET_SOCIAL_LOADING:
            return {
                ...state,
                socialLoading: action.payload,
            }

        case CLEAR_SOCIAL:
            return {
                list: [],
                socialLoading: false,
                lud: '',
            }
        default:
            return state
    }
}

export default socialListReducer
