import React from 'react'
import { useLocation } from 'react-router-dom'
import { Amplify, Storage } from 'aws-amplify'
import awsConfig from '../aws-config'
import _ from 'lodash'
import variables from '../theme/variables.scss'
import moment from 'moment'
import { DATE_FORMAT } from './dateTimeFormats'
import { toggleDeactivateModal, toggleSessionModal } from '../redux/modals/toggleModal.actions'
import { setAlert } from '../redux/alert/alert.action'

export const getTrimedFilename = (name) => {
    const nameExt = name?.split('.')
    const data = name.length > 15 ? name?.slice(0, 15) + '...' + nameExt[1] : name
    return data
}

export const getTrimedCategory = (name) => {
    const nameExt = name?.split(' (')
    const data = nameExt[0]?.length > 15 ? nameExt[0].slice(0, 15) + '...(' + nameExt[1] : name
    return data
}

export const getS3File = async (fileName) => {
    Amplify.configure(awsConfig)
    const result = await Storage.get(fileName.replace(/^(public\/)/, ''), { download: true })
    return result
}

export const base64ToArrayBuffer = async (base64) => {
    return fetch(base64)
        .then((b) => b.arrayBuffer())
        .then((buff) => new Int8Array(buff))
}

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {Blob} pdf
 * @return {void}
 */

export const blobToPdf = (pdf, name) => {
    const linkSource = URL.createObjectURL(pdf)
    const downloadLink = document.createElement('a')
    const fileName = name

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
}

export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            const base64data = reader.result
            resolve(base64data)
        }
    })
}

/* 'minutes', 'hours', 'days' */
const unitHelper = (unit) => {
    switch (unit) {
        case 'minutes':
            return 'minute'
        case 'hours':
            return 'hour'
        case 'days':
            return 'day'
        default:
            return unit
    }
}

//heart beats placeholder
const beatsHelper = (unit) => {
    switch (unit) {
        case 'extra beats':
            return 'extra'
        case 'skipped beats':
            return 'skipped'
        default:
            return unit
    }
}

/* Generate Interval History summary text */
export const summaryTextGenerator = (valObj) => {
    if (valObj?.symptom === 'Pain') {
        let symptom = ''
        let location = ''
        let CCS1 = ''
        let since = ''
        let lastLong = ''
        let severity = ''
        let worsened = ''
        let alleviated = ''
        let occurs = ''
        if (valObj?.location) location = `The patient has been having ${valObj?.location} pain`
        if (valObj?.ccsClass !== null && valObj?.ccsClass !== '' && valObj?.ccsClass !== undefined)
            CCS1 = `(CCS ${valObj?.ccsClass ?? '-'})`
        if (valObj?.since?.unit && valObj?.since?.value)
            since = `since the last ${valObj?.since?.value} ${valObj?.since?.unit}`
        if (valObj?.lastLong?.value)
            lastLong = `that lasts ${valObj?.lastLong?.value} ${valObj?.lastLong?.unit}`
        if (valObj?.symptom && valObj?.lastLong?.value) symptom = `of sharp ${valObj?.symptom}`

        if (valObj?.severity) severity = `with a severity score of ${valObj?.severity},`

        if (valObj?.exacerbatedBy) worsened = `that is worsened by ${valObj?.exacerbatedBy},`
        if (valObj?.alleviatedBy) alleviated = `and alleviated at ${valObj?.alleviatedBy},`

        if (valObj?.frequency?.text && valObj?.frequency?.unit && valObj?.frequency?.value)
            occurs = `and occurs ${valObj?.frequency?.value} times a ${unitHelper(
                valObj?.frequency?.unit
            )} (Total freq. ${valObj?.frequency?.text}) .`
        return `${location} ${CCS1} ${since} ${severity} ${lastLong} ${symptom} ${worsened} ${alleviated} ${occurs}`
    }

    if (valObj?.symptom === 'Shortness of Breath') {
        let nyhaClass = ''
        let since = ''
        let feeling = ''
        let worsened = ''
        let alleviated = ''
        let occurs = ''
        if (!_.isNil(valObj?.nyhaClass))
            nyhaClass = `The patient has been having an increased shortness of breath (NYHA ${
                valObj?.nyhaClass ?? '-'
            }) ,`
        if (valObj?.since?.unit && !_.isNil(valObj?.since?.value) && valObj?.since?.date)
            since = `that had started ${valObj?.since?.value} ${valObj?.since?.unit} ago  ${
                !!valObj?.since?.date
                    ? `dated ${moment(valObj?.since?.date).format(DATE_FORMAT.MMM_YYYY)}`
                    : ''
            }`

        if (valObj?.feeling) feeling = `with ${beatsHelper(valObj?.feeling)} heart beats,`
        if (valObj?.exacerbatedBy) worsened = `that is worsened by ${valObj?.exacerbatedBy},`
        if (valObj?.alleviatedBy) alleviated = `and alleviated at ${valObj?.alleviatedBy},`

        if (
            valObj?.frequency?.totalTime &&
            valObj?.frequency?.unit &&
            valObj?.frequency?.value &&
            valObj?.frequency?.times
        )
            occurs = `It typically lasts for ${valObj?.frequency?.value} ${
                valObj?.frequency?.unit
            } and occurs ${valObj?.frequency?.times} times ${unitHelper(valObj?.frequency?.totalTime)}.`

        return `${nyhaClass} ${since} ${feeling} ${worsened} ${alleviated} ${occurs}`
    }

    if (valObj?.symptom === 'Palpitations') {
        let since = ''
        let feeling = ''
        let worsened = ''
        let alleviated = ''
        let occurs = ''

        if (valObj?.since?.unit && !_.isNil(valObj?.since?.value))
            since = `The patient has been having intermittent palpitations that had started ${
                valObj?.since?.value
            } ${valObj?.since?.unit} ago  ${
                !!valObj?.since?.date
                    ? `dated ${moment(valObj?.since?.date).format(DATE_FORMAT.MMM_YYYY)}`
                    : ''
            }`

        if (valObj?.feeling) feeling = `with ${beatsHelper(valObj?.feeling)} heart beats,`
        if (valObj?.exacerbatedBy) worsened = `that is worsened by ${valObj?.exacerbatedBy},`
        if (valObj?.alleviatedBy) alleviated = `and alleviated at ${valObj?.alleviatedBy},`
        if (
            valObj?.frequency?.totalTime &&
            valObj?.frequency?.unit &&
            valObj?.frequency?.value &&
            valObj?.frequency?.times
        )
            occurs = `It typically lasts for ${valObj?.frequency?.value} ${
                valObj?.frequency?.unit
            } and occurs ${valObj?.frequency?.times} times ${unitHelper(valObj?.frequency?.totalTime)}.`

        return `${since} ${feeling} ${worsened} ${alleviated} ${occurs} `
    }
    if (valObj?.symptom === '(Pre)syncope') {
        let since = ''
        let feeling = ''
        let worsened = ''
        let alleviated = ''
        let occurs = ''

        if (valObj?.since?.unit && !_.isNil(valObj?.since?.value))
            since = `The patient has been feeling lightheaded for the past ${valObj?.since?.value} ${
                valObj?.since?.unit
            } ${
                !!valObj?.since?.date
                    ? `dated ${moment(valObj?.since?.date).format(DATE_FORMAT.MMM_YYYY)}`
                    : ''
            }`

        if (valObj?.feeling) feeling = `with ${beatsHelper(valObj?.feeling)} heart beats,`
        if (valObj?.exacerbatedBy) worsened = `that is worsened by ${valObj?.exacerbatedBy},`
        if (valObj?.alleviatedBy) alleviated = `and alleviated at ${valObj?.alleviatedBy},`
        if (
            valObj?.frequency?.totalTime &&
            valObj?.frequency?.unit &&
            valObj?.frequency?.value &&
            valObj?.frequency?.times
        )
            occurs = `It typically lasts for ${valObj?.frequency?.value} ${
                valObj?.frequency?.unit
            } and occurs ${valObj?.frequency?.times} times ${unitHelper(valObj?.frequency?.totalTime)}.`
        return `${since} ${feeling} ${worsened} ${alleviated} ${occurs}`
    }

    if (valObj?.symptom === 'Edema') {
        let since = ''
        let feeling = ''
        let worsened = ''
        let alleviated = ''
        let occurs = ''

        if (valObj?.since?.unit && !_.isNil(valObj?.since?.value))
            since = `The patient has been having swelling on both legs for the past ${valObj?.since?.value} ${
                valObj?.since?.unit
            } ${
                !!valObj?.since?.date
                    ? `dated ${moment(valObj?.since?.date).format(DATE_FORMAT.MMM_YYYY)}`
                    : ''
            }`

        if (valObj?.feeling) feeling = `with ${beatsHelper(valObj?.feeling)} heart beats,`
        if (valObj?.exacerbatedBy) worsened = `that is worsened by ${valObj?.exacerbatedBy},`
        if (valObj?.alleviatedBy) alleviated = `and alleviated at ${valObj?.alleviatedBy},`
        if (
            valObj?.frequency?.totalTime &&
            valObj?.frequency?.unit &&
            valObj?.frequency?.value &&
            valObj?.frequency?.times
        )
            occurs = `It typically lasts for ${valObj?.frequency?.value} ${
                valObj?.frequency?.unit
            } and occurs ${valObj?.frequency?.times} times ${unitHelper(valObj?.frequency?.totalTime)}.`

        return `${since} ${feeling} ${worsened} ${alleviated} ${occurs}`
    }
}

//Generate Physical Exam summary
export const physicalExamsummaryGenerator = (valObj) => {
    const createSummaryItem = (label, value, hyphenFlag = false) => {
        if (_.isEmpty(value)) return
        return `${label} ${hyphenFlag ? ':-' : ':'} ${value ?? ''}`
    }

    const handleCardiac = () => {
        /* Heart Sound */
        let heartSoundS1 = createSummaryItem('S1', valObj?.diagnosisType?.auscultaion?.heartSounds?.s1)
        let heartSoundS2 = createSummaryItem('S2', valObj?.diagnosisType?.auscultaion?.heartSounds?.s2)
        let heartSoundOther = createSummaryItem(
            'Other',
            valObj?.diagnosisType?.auscultaion?.heartSounds?.other
        )
        let heartSoundText =
            _.isEmpty(heartSoundS1) && _.isEmpty(heartSoundS2) && _.isEmpty(heartSoundOther)
                ? null
                : `Heart Sounds`

        /* Murmur */
        let murmurIntense = createSummaryItem(
            'Intensity',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.intesity
        )
        let murmurPitch = createSummaryItem('Pitch', valObj?.diagnosisType?.auscultaion?.murmurs[0]?.pitch)
        let murmurTiming = createSummaryItem('Timing', valObj?.diagnosisType?.auscultaion?.murmurs[0]?.timing)
        let murmurPeak = createSummaryItem('Peak', valObj?.diagnosisType?.auscultaion?.murmurs[0]?.peak)
        let murmurProfile = createSummaryItem(
            'Profile',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.profile
        )
        let murmurLocation = createSummaryItem(
            'Location',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.location
        )
        let murmurRadiation = createSummaryItem(
            'Radiation',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.radiation
        )
        let murmurManeuversDegree = createSummaryItem(
            'Maneuvers Degrees',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.maneuvers?.degree
        )
        let murmurManeuversValue = createSummaryItem(
            'Maneuvers Value',
            valObj?.diagnosisType?.auscultaion?.murmurs[0]?.maneuvers?.value
        )
        let murmurText =
            _.isEmpty(murmurIntense) &&
            _.isEmpty(murmurPitch) &&
            _.isEmpty(murmurTiming) &&
            _.isEmpty(murmurPeak) &&
            _.isEmpty(murmurProfile) &&
            _.isEmpty(murmurLocation) &&
            _.isEmpty(murmurRadiation) &&
            _.isEmpty(murmurManeuversDegree) &&
            _.isEmpty(murmurManeuversValue)
                ? null
                : 'Murmur'

        /* Palpitations-Parasternum */
        let parasternumCharacter = createSummaryItem(
            'Character',
            valObj?.diagnosisType?.palpitations?.parasternum?.character
        )
        let parasternumDistribution = createSummaryItem(
            'Distribution',
            valObj?.diagnosisType?.palpitations?.parasternum?.distribution
        )
        let parasternumPlace = createSummaryItem(
            'Place',
            valObj?.diagnosisType?.palpitations?.parasternum?.place
        )
        let palpitationsParasternumText =
            _.isEmpty(parasternumCharacter) &&
            _.isEmpty(parasternumDistribution) &&
            _.isEmpty(parasternumPlace)
                ? null
                : 'Palpitations-Parasternum'

        /* Palpitations-Apex */
        let palpitationsApexImpulse = createSummaryItem(
            'Impulse',
            valObj?.diagnosisType?.palpitations?.apex?.impulse
        )
        let palpitationsApexPosition = createSummaryItem(
            'Position',
            valObj?.diagnosisType?.palpitations?.apex?.position
        )
        let palpitationsApexCharacter = createSummaryItem(
            'Character',
            valObj?.diagnosisType?.palpitations?.apex?.character
        )
        let palpitationsApexDistribution = createSummaryItem(
            'Distribution',
            valObj?.diagnosisType?.palpitations?.apex?.distribution
        )
        let palpitationsApexText =
            _.isEmpty(palpitationsApexImpulse) &&
            _.isEmpty(palpitationsApexPosition) &&
            _.isEmpty(palpitationsApexCharacter) &&
            _.isEmpty(palpitationsApexDistribution)
                ? null
                : 'Palpitations-Apex'

        /* Palpitations-Tenderness */
        let palpitationsTendernessValue = createSummaryItem(
            'Value',
            valObj?.diagnosisType?.palpitations?.tenderness?.value
        )
        let palpitationsTendernessDetails = createSummaryItem(
            'Add. Details',
            valObj?.diagnosisType?.palpitations?.tenderness?.additionalNotes,
            true
        )
        let palpitationsTendernessText =
            _.isEmpty(palpitationsTendernessValue) && _.isEmpty(palpitationsTendernessDetails)
                ? null
                : 'Palpitations-Tenderness'

        return [
            heartSoundText,
            heartSoundS1,
            heartSoundS2,
            heartSoundOther,
            murmurText,
            murmurIntense,
            murmurPitch,
            murmurTiming,
            murmurPeak,
            murmurProfile,
            murmurLocation,
            murmurRadiation,
            murmurManeuversDegree,
            murmurManeuversValue,
            palpitationsParasternumText,
            parasternumCharacter,
            parasternumDistribution,
            parasternumPlace,
            palpitationsApexText,

            palpitationsApexImpulse,
            palpitationsApexPosition,
            palpitationsApexCharacter,
            palpitationsApexDistribution,

            palpitationsTendernessText,
            palpitationsTendernessValue,
            palpitationsTendernessDetails,
        ].filter((n) => n)
    }

    const handleRespiratory = () => {
        let respiratorySide = createSummaryItem('Side', valObj?.diagnosisType?.side)
        let respiratoryArea = createSummaryItem('Area', valObj?.diagnosisType?.area)
        let respiratoryIntensity = createSummaryItem('Intensity', valObj?.diagnosisType?.intensity)
        let respiratoryCharacter = createSummaryItem('Character', valObj?.diagnosisType?.charater)
        let respiratoryOtherSounds = createSummaryItem('Other Sounds', valObj?.diagnosisType?.otherSounds)
        return [
            respiratorySide,
            respiratoryArea,
            respiratoryIntensity,
            respiratoryCharacter,
            respiratoryOtherSounds,
        ].filter((n) => n)
    }

    const handleAbdominal = () => {
        /* Palpation */
        let palpationTension = createSummaryItem('Tension', valObj?.diagnosisType?.palpation?.tension)
        let palpationText = _.isEmpty(palpationTension) ? null : `Palpation`

        /* Palpation-Tenderness */
        let palpationTendernessType = createSummaryItem(
            'Type',
            valObj?.diagnosisType?.palpation?.tenderness?.type
        )
        let palpationTendernessHorizontal = createSummaryItem(
            'Horizontal',
            valObj?.diagnosisType?.palpation?.tenderness?.horizontalSide
        )
        let palpationTendernessVertical = createSummaryItem(
            'Vertical',
            valObj?.diagnosisType?.palpation?.tenderness?.verticalSide
        )
        let palpationTendernessText =
            _.isEmpty(palpationTendernessType) &&
            _.isEmpty(palpationTendernessHorizontal) &&
            _.isEmpty(palpationTendernessVertical)
                ? null
                : `Palpation-Tenderness`

        /* Palpation-Mass */
        let palpationMass = createSummaryItem('Mass', valObj?.diagnosisType?.palpation?.mass)
        let palpationMassText = _.isEmpty(palpationMass) ? null : `Palpation-Mass`
        /* Auscultation */
        let auscultationBowel = createSummaryItem(
            'Bowel Sounds',
            valObj?.diagnosisType?.auscultation?.bowelSounds
        )
        let auscultationText = _.isEmpty(auscultationBowel) ? null : 'Auscultation'
        return [
            palpationText,
            palpationTension,
            palpationTendernessText,
            palpationTendernessType,
            palpationTendernessHorizontal,
            palpationTendernessVertical,
            palpationMassText,
            palpationMass,
            auscultationText,
            auscultationBowel,
        ].filter((n) => n)
    }

    const handlePeripheral = () => {
        /* Palpation - Carotid */
        let carotidSide = createSummaryItem('Side', valObj?.diagnosisType?.pulses?.carotid?.side)
        let carotidPulse = createSummaryItem('Pulse', valObj?.diagnosisType?.pulses?.carotid?.pulse)
        let carotidBruit = createSummaryItem('Bruit', valObj?.diagnosisType?.pulses?.carotid?.bruit)
        let CarotidText =
            _.isEmpty(carotidSide) && _.isEmpty(carotidPulse) && _.isEmpty(carotidBruit)
                ? null
                : `Palpation - Carotid`

        /* Palpation - Radial */
        let radialSide = createSummaryItem('Side', valObj?.diagnosisType?.pulses?.radial?.side)
        let radialPulse = createSummaryItem('Pulse', valObj?.diagnosisType?.pulses?.radial?.pulse)
        let radialText = _.isEmpty(radialSide) && _.isEmpty(radialPulse) ? null : `Palpation - Radial`

        /* Palpation - Femoral */
        let femoralSide = createSummaryItem('Side', valObj?.diagnosisType?.pulses?.femoral?.side)
        let femoralPulse = createSummaryItem('Pulse', valObj?.diagnosisType?.pulses?.femoral?.pulse)
        let femoralBruit = createSummaryItem('Bruit', valObj?.diagnosisType?.pulses?.femoral?.bruit)
        let femoralText =
            _.isEmpty(femoralSide) && _.isEmpty(femoralPulse) && _.isEmpty(femoralBruit)
                ? null
                : `Palpation - Femoral`

        /* Palpation - Pedal */
        let pedalSide = createSummaryItem('Side', valObj?.diagnosisType?.pulses?.pedal?.side)
        let pedalPulse = createSummaryItem('Pulse', valObj?.diagnosisType?.pulses?.pedal?.pulse)
        let pedalText = _.isEmpty(pedalSide) && _.isEmpty(pedalPulse) ? null : `Palpation - Pedal`

        /* Palpation - Renal */
        let renalSide = createSummaryItem('Side', valObj?.diagnosisType?.pulses?.renal?.side)
        let renalBruit = createSummaryItem('Bruit', valObj?.diagnosisType?.pulses?.renal?.bruit)
        let renalText = _.isEmpty(renalSide) && _.isEmpty(renalBruit) ? null : `Palpation - Renal`

        /* JVP */
        let jvp = createSummaryItem('JVP', valObj?.diagnosisType?.edema?.side)
        let jvpText = _.isEmpty(jvp) ? null : `JVP`

        /* Edema */
        let edemaDegree = createSummaryItem('Degree', valObj?.diagnosisType?.edema?.degree)
        let edemaExtent = createSummaryItem('Extent', valObj?.diagnosisType?.edema?.extent)
        let edemaSide = createSummaryItem('Side', valObj?.diagnosisType?.edema?.side)
        let edemaText =
            _.isEmpty(edemaDegree) && _.isEmpty(edemaExtent) && _.isEmpty(edemaSide) ? null : 'Edema'

        return [
            CarotidText,
            carotidSide,
            carotidPulse,
            carotidBruit,
            radialText,
            radialSide,
            radialPulse,
            femoralText,
            femoralSide,
            femoralPulse,
            femoralBruit,
            pedalText,
            pedalSide,
            pedalPulse,
            renalText,
            renalSide,
            renalBruit,
            jvpText,
            jvp,
            edemaText,
            edemaDegree,
            edemaExtent,
            edemaSide,
        ].filter((n) => n)
    }

    const handleProcedural = () => {
        /* Access site - Location */
        let accessSiteLocationSide = createSummaryItem(
            'Side',
            valObj?.diagnosisType?.accessSite?.location?.side
        )
        let accessSiteLocationArea = createSummaryItem(
            'Area',
            valObj?.diagnosisType?.accessSite?.location?.area
        )
        let accessSiteLocationText =
            _.isEmpty(accessSiteLocationSide) && _.isEmpty(accessSiteLocationArea)
                ? null
                : `Access site - Location`

        /* Access site - Appearance */
        let accessSiteAppearanceType = createSummaryItem(
            'Type',
            valObj?.diagnosisType?.accessSite?.appearance?.type
        )
        let accessSiteAppearanceInjury = createSummaryItem(
            'Injury',
            valObj?.diagnosisType?.accessSite?.appearance?.injury
        )
        let accessSiteAppearanceText =
            _.isEmpty(accessSiteAppearanceType) && _.isEmpty(accessSiteAppearanceInjury)
                ? null
                : `Access site - Appearance`

        let accessSiteDetails = createSummaryItem(
            'Add. Details',
            valObj?.diagnosisType?.additionalDetails,
            true
        )
        return [
            accessSiteLocationText,
            accessSiteLocationSide,
            accessSiteLocationArea,
            accessSiteAppearanceText,
            accessSiteAppearanceType,
            accessSiteAppearanceInjury,
            accessSiteDetails,
        ].filter((n) => n)
    }

    switch (valObj?.type) {
        case 'Cardiac':
            return handleCardiac()
        case 'Respiratory':
            return handleRespiratory()
        case 'Abdominal':
            return handleAbdominal()
        case 'Peripheral':
            return handlePeripheral()
        case 'Procedural':
            return handleProcedural()
        default:
            return []
    }
}

//Interval History Intial value generator
export const generateSymptom = (symptom, extraProps) => {
    if (symptom === 'Pain') {
        return {
            symptom,
            active: false,
            location: '',
            ccsClass: null,
            since: {
                unit: 'minutes',
                value: 0,
            },
            severity: 0,
            lastLong: {
                unit: 'minutes',
                value: 0,
            },
            exacerbatedBy: '',
            alleviatedBy: '',
            frequency: {
                text: '',
                unit: 'minutes',
                value: 0,
            },
            ...extraProps,
        }
    } else {
        return {
            symptom,
            since: {
                unit: 'minutes',
                value: 0,
                date: null,
            },
            feeling: '',
            exacerbatedBy: '',
            alleviatedBy: '',
            frequency: {
                times: 0,
                value: 0,
                unit: 'minutes',
                totalTime: 'weekly',
            },
            associatedSymptoms: '',
            active: false,
            ...extraProps,
        }
    }
}

export const MEDICATION_TYPE = {
    CARDIAC: 'cardiac',
    OTHER: 'other',
    PAST: 'past',
}

export const STATUS_CODE = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
}

export const dispatchResetActionWrapper = (
    detailsTime,
    dataType,
    patientId,
    token,
    dispatch,
    toggleModal,
    getData,
    resetDocument = null
) => {
    dispatch(toggleModal(false))
    if (detailsTime !== '60') {
        const sixtyMonthsAgo = moment().subtract(60, 'months')
        const proposedDate = `${sixtyMonthsAgo.format(DATE_FORMAT.YYYY_MM_DD)}T00:00:00.000Z`
        const payload = {
            patientId,
            type: dataType,
            date: proposedDate,
        }
        dispatch(getData(payload, token))
    }
    if (!!resetDocument) {
        dispatch(resetDocument())
    }
}

/*  Graph refrerence range annotation value object */
export const graphAnnotationObj = {
    type: 'line',
    mode: 'horizontal',
    borderWidth: 1,
    borderColor: variables.purple,
    borderDash: [1, 1],
    label: {
        position: 'start',
        display: true,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        displayColors: false,
        color: variables.gray700,
        textAlign: 'start',
        font: {
            size: 10,
        },
    },
}

export const commonErrorHandler = (dispatch, status, error, title) => {
    switch (status) {
        case STATUS_CODE.BAD_REQUEST:
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `Error in fetching ${title ?? 'list'}. Please try again`,
                    alertOpen: true,
                })
            )
            break
        case STATUS_CODE.UNAUTHORIZED:
            dispatch(toggleSessionModal(true, error))
            break
        case STATUS_CODE.FORBIDDEN:
            dispatch(toggleDeactivateModal(true))
            break
        default:
            // other status code cases as needed
            break
    }
}

export const networkErrorHandler = (dispatch, err) => {
    dispatch(
        setAlert({
            alertType: 'error',
            alertMsg: `${
                err?.message ?? 'Something went wrong!'
            }. Unable to fetch results. Please! try again.`,
            alertOpen: true,
        })
    )
}

// delay handler function
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

// custom hook for query string
export function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const hasTifExtension = (url) => {
    if (_.isEmpty(url)) return false
    const res = (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0])
        .split('#')[0]
        .substr(url.lastIndexOf('.'))
    return !!(res === '.tif' || res === '.tiff')
}
