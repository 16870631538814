import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import AdverseEventListTable from '../../adverse-event/adverse-details/AdverseEventListTable'

const AdverseEventInfo = ({ adverseEventListData }) => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-2">Adverse Event</Typography>

            {/* Allergies */}
            <Box
                sx={{
                    border: 1,
                    borderRadius: '0.25rem',
                    borderColor: variables.lightGray1,
                    overflow: 'hidden',
                }}
            >
                <Typography
                    className="fs-9 fw-normal"
                    sx={{
                        background: variables.purple,
                        p: 0.5,
                        textAlign: 'center',
                        color: variables.white100,
                    }}
                >
                    Allergies
                </Typography>
                <Divider
                    sx={{
                        borderColor: variables.lightGray1,
                        border: 0.5,
                    }}
                />
                <Box sx={{ p: 0.5, overflowWrap: 'break-word' }}>
                    {_.isEmpty(adverseEventListData) ? (
                        <Typography className="gray-900 fw-light fs-9 text-center">
                            Adverse Event History Not Available
                        </Typography>
                    ) : (
                        <AdverseEventListTable
                            adverseEventListData={adverseEventListData}
                            consultLetterFlag={true}
                        />
                    )}
                </Box>
            </Box>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        adverseEventListData: state?.adverseEvent?.adverseEventListData,
        adverseEventListLoading: state?.adverseEvent?.adverseEventListLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdverseEventInfo)
