import { GET_PATIENT_DETAILS, SET_PATIENT_LOADING } from './patient.type'

const INITIAL_STATE = {
    patientData: {
        phone: '',
        referringMd: '',
        address: {
            street: '',
            city: '',
            postalZip: '',
        },
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        gender: '',
        birthday: '',
        patientId: '',
        age: '',
    },
    loading: true,
}

const patientReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PATIENT_DETAILS:
            return {
                ...state,
                patientData: action.payload,
                loading: false,
            }
        case SET_PATIENT_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default patientReducer
