import React from 'react'
import variables from '../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import VitalPills from '../../vital-component/vital-pills'

const VitalsInfo = () => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-2">Vitals</Typography>
            <Box
                sx={{
                    border: 1,
                    borderRadius: '0.25rem',
                    borderColor: variables.lightGray1,
                    overflow: 'hidden',
                }}
            >
                <div className="mt-3 ms-3 w-100">
                    <VitalPills name="dashboard-pills" time="" consultLetterFlag={true} />
                </div>
            </Box>
        </>
    )
}

export default VitalsInfo
