import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import AppBar from '../components/app-bar'
import AppointmentTable from '../components/appointment-list'
import Container from '@mui/material/Container'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DATE_FORMAT } from '../util/dateTimeFormats'
import { setCurrentAppointmentDate } from '../redux/appointment/appointment.action'
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Home = ({ login, token, currentAppointmentDate }) => {
    const IS_UAT_ENV = process.env.REACT_APP_ENV === 'uat'
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })

    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar />
                <Container maxWidth="lg">
                    <div className="d-flex justify-content-between align-items-center mb-3 mt-1">
                        <p className="cursor-default fw-bold fs-5 mb-0">Upcoming Appointments</p>
                        {IS_UAT_ENV ? (
                            <Box
                                sx={{
                                    fontSize: '0.9rem',
                                }}
                                className="cursor-not-allowed fw-normal bg-white border rounded"
                            >
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        inputFormat="MMM DD, YYYY"
                                        views={['year', 'month', 'day']}
                                        openTo="day"
                                        disableMaskedInput
                                        defaultValue={`${moment().format(DATE_FORMAT.MMM_DD_YYYY)}`}
                                        value={currentAppointmentDate}
                                        onChange={(newValue) => {
                                            if (!!newValue?.isValid()) {
                                                dispatch(
                                                    setCurrentAppointmentDate(
                                                        moment(newValue).format(DATE_FORMAT.YYYY_MM_DD),
                                                        token
                                                    )
                                                )
                                            }
                                        }}
                                        minDate={`${moment()
                                            .subtract(30, 'd')
                                            .format(DATE_FORMAT.MMM_DD_YYYY)}`}
                                        maxDate={`${moment().add(30, 'd').format(DATE_FORMAT.MMM_DD_YYYY)}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                sx={{
                                                    maxWidth: '12.18rem !important',
                                                }}
                                                error={false}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: '',
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        ) : (
                            <div className="cursor-not-allowed">
                                <div
                                    style={{ fontSize: '0.9rem', padding: '9px 12px' }}
                                    className="cursor-not-allowed fw-normal bg-white border border-2 
                                rounded"
                                >
                                    {moment().format('ll')}
                                </div>
                            </div>
                        )}
                    </div>
                    <AppointmentTable />
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.accessToken,
    currentAppointmentDate: state.appointment.currentAppointmentDate,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentAppointmentDate: (payload, token) => dispatch(setCurrentAppointmentDate(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
