import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { getNuclearDocument } from '../../redux/ESN/nuclearList/nuclearList.action'
import {
    getStressEchoDocument,
    setStressEchoGxtTrigger,
} from '../../redux/ESN/stressEchoList/stressEchoList.action'
import Nuclear from './Nuclear'
import { getEchoDocument } from '../../redux/ESN/echoList/echoList.action'
import {
    toggleEchoModal,
    toggleNuclearModal,
    toggleStressEchoModal,
} from '../../redux/modals/toggleModal.actions'
import Echo from './echo-component/echo'
import StressEcho from './StressEcho'
import { DATE_FORMAT } from '../../util/dateTimeFormats'
import InfoBox from '../vital-component/vitals/InfoBox'

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

export const Index = ({ echoLUD, nuclearLUD, stressEchoLUD, patientId, token }) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0)

    // const [expanded, setExpanded] = React.useState(false);

    // const handleChange = (panel) => (event, isExpanded) => {
    //   // // console.log("event", isExpanded)
    //   setExpanded(isExpanded ? panel : false);
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        const sixtyMonth = moment().subtract(60, 'months').calendar()
        const proposedDate = moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
        const payload = {
            patientId: patientId,
            date: proposedDate,
        }
        switch (val) {
            case 0:
                // dispatch(getEcgsListData(payload, token))
                dispatch(setStressEchoGxtTrigger(false))
                break
            case 1:
                dispatch(getStressEchoDocument(payload, token))
                dispatch(setStressEchoGxtTrigger(true))
                break
            case 2:
                dispatch(getNuclearDocument(payload, token))
                dispatch(setStressEchoGxtTrigger(false))
                break
            default:
                return null
        }
    }
    const GetLastUpdated = (val) => {
        switch (val) {
            case 0:
                return echoLUD !== ''
                    ? `Refreshed ${moment.parseZone(echoLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
                    : null
            case 1:
                return stressEchoLUD !== ''
                    ? `Refreshed ${moment.parseZone(stressEchoLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
                    : null
            case 2:
                return nuclearLUD !== ''
                    ? `Refreshed ${moment.parseZone(nuclearLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
                    : null
            default:
                return null
        }
    }

    const OpenModal = (val) => {
        const sixtyMonth = moment().subtract(60, 'months').calendar()
        const proposedDate = moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
        const payload = {
            patientId: patientId,
            date: proposedDate,
        }
        switch (val) {
            case 0:
                dispatch(getEchoDocument(payload, token))
                dispatch(toggleEchoModal(true))
                break
            case 1:
                dispatch(getStressEchoDocument(payload, token))
                dispatch(toggleStressEchoModal(true))
                break
            case 2:
                dispatch(getNuclearDocument(payload, token))
                dispatch(toggleNuclearModal(true))
                break
            default:
        }
    }

    return (
        <div>
            <Accordion
                className="dashboard-accordion"
                sx={{ boxShadow: 'none' }}
                expanded={true}
                // onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    sx={{ background: '#F3F4F6', boxShadow: 'none', borderRadius: '0.25rem' }}
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.875rem',
                        }}
                    >
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="Echo" />
                            <AntTab label="Stress Echo" />
                            <AntTab label="Nuclear" />
                        </AntTabs>
                    </Typography>

                    <Box
                        className="d-flex justify-content-end align-items-center gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.85rem',
                        }}
                    >
                        {value === 0 && (
                            <Box className="me-1 cursor-pointer" sx={{ mb: '2px' }}>
                                <InfoBox />
                            </Box>
                        )}
                        {GetLastUpdated(value)}
                        <span className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={() => OpenModal(value)}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {value === 0 && (
                        <>
                            <Echo />
                        </>
                    )}
                    {value === 1 && (
                        <>
                            <StressEcho />
                        </>
                    )}
                    {value === 2 && (
                        <>
                            <Nuclear />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    echoLUD: state.echoList.lud,
    nuclearLUD: state.nuclear.lud,
    stressEchoLUD: state.stressEcho.lud,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getEchoDocument: (payload, token) => dispatch(getEchoDocument(payload, token)),
        toggleEchoModal: (payload) => dispatch(toggleEchoModal(payload)),
        toggleNuclearModal: (payload) => dispatch(toggleNuclearModal(payload)),
        // getEcgsListData:(payload, token) => dispatch(getEcgsListData(payload, token)),
        // getHolterListData:(payload, token) => dispatch(getHolterListData(payload, token)),
        // getGxtListData:(payload, token) => dispatch(getGxtListData(payload, token)),
        getNuclearDocument: (payload, token) => dispatch(getNuclearDocument(payload, token)),
        getStressEchoDocument: (payload, token) => dispatch(getStressEchoDocument(payload, token)),
        setStressEchoGxtTrigger: (payload) => dispatch(setStressEchoGxtTrigger(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
