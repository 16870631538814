export const GET_MEDICAL_HISTORY_LIST_DATA = 'GET_MEDICAL_HISTORY_LIST_DATA'
export const SET_MEDICAL_HISTORY_LIST_LOADING = 'SET_MEDICAL_HISTORY_LIST_LOADING'
export const GET_MEDICAL_HISTORY_DIAGNOSIS_DATA = 'GET_MEDICAL_HISTORY_DIAGNOSIS_DATA'
export const SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING = 'SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING'
export const CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA = 'CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA' //INDIVIDUAL DATA IN LIST
export const CLEAR_MEDICAL_HISTORY_LIST_DATA = 'CLEAR_MEDICAL_HISTORY_LIST_DATA' //CLEAR WHOLE LIST DATA
export const CLEAR_MEDICAL_HISTORY_DATA = 'CLEAR_MEDICAL_HISTORY_DATA' // CLEAR COMPLETE DATA
export const SET_CURRENT_MEDICAL_HISTORY = 'SET_CURRENT_MEDICAL_HISTORY' // FOR TABS
export const DELETE_MEDICAL_HISTORY = 'DELETE_MEDICAL_HISTORY' //DELETE TABLE ENTRY
export const GET_PAST_MEDICAL_HISTORY_LIST_DATA = 'GET_PAST_MEDICAL_HISTORY_LIST_DATA'
export const SET_PAST_MEDICAL_HISTORY_LIST_LOADING = 'SET_PAST_MEDICAL_HISTORY_LIST_LOADING'
export const GET_PAST_MEDICAL_HISTORY_DIAGNOSIS_DATA = 'GET_PAST_MEDICAL_HISTORY_DIAGNOSIS_DATA'
export const SET_PAST_MEDICAL_HISTORY_DIAGNOSIS_LOADING = 'SET_PAST_MEDICAL_HISTORY_DIAGNOSIS_LOADING'
