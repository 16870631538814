import {
    GET_RECOMMENDATIONS_LIST_DATA,
    SET_RECOMMENDATIONS_LOADING,
    CLEAR_RECOMMENDATIONS,
} from './recommendations.type'

const INITIAL_STATE = {
    list: [],
    recommendationLoading: false,
    lud: null,
}

const recommendationListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_RECOMMENDATIONS_LIST_DATA:
            return {
                ...state,
                list: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : null,
                isCreatedNow: action?.payload?.hasOwnProperty('isCreatedNow')
                    ? action?.payload?.isCreatedNow
                    : null,
                loading: false,
            }

        case SET_RECOMMENDATIONS_LOADING:
            return {
                ...state,
                recommendationLoading: action.payload,
            }

        case CLEAR_RECOMMENDATIONS:
            return {
                list: [],
                recommendationLoading: false,
                lud: null,
            }
        default:
            return state
    }
}

export default recommendationListReducer
