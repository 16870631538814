import {
    GET_FAMILY_LIST_DATA,
    SET_FAMILY_LIST_LOADING,
    GET_FAMILY_VIEW_DATA,
    SET_FAMILY_VIEW_LOADING,
    CLEAR_FAMILY_VIEW_HISTORY,
    CLEAR_FAMILY_LIST_HISTORY,
    CLEAR_FAMILY_HISTORY,
    DELETE_FAMILY_MEMBER,
    GET_FAMILY_EVENTS_DATA,
    SET_FAMILY_EVENTS_LOADING,
} from './familyList.type'

const INITIAL_STATE = {
    // list: [],
    familyListData: [],
    familyListLoading: false,
    lud: '',
    familyViewData: [],
    familyViewLoading: false,
    familyEventsData: [],
    familyEventsLoading: false,
}

const familyListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FAMILY_LIST_DATA:
            return {
                ...state,
                // list: action?.payload?.list,
                familyListData: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                familyListLoading: false,
            }
        case GET_FAMILY_VIEW_DATA:
            return {
                ...state,
                familyViewData: action?.payload,
            }
        case GET_FAMILY_EVENTS_DATA:
            return {
                ...state,
                familyEventsData: action?.payload,
            }
        case SET_FAMILY_EVENTS_LOADING:
            return {
                ...state,
                familyEventsLoading: action.payload,
            }

        case SET_FAMILY_LIST_LOADING:
            return {
                ...state,
                familyListLoading: action.payload,
            }
        case SET_FAMILY_VIEW_LOADING:
            return {
                ...state,
                familyViewLoading: action.payload,
            }
        case CLEAR_FAMILY_VIEW_HISTORY:
            return {
                ...state,
                FamilyViewData: [],
                familyViewLoading: false,
            }
        case CLEAR_FAMILY_LIST_HISTORY:
            return { ...state, familyListData: [], familyListLoading: false }

        case CLEAR_FAMILY_HISTORY:
            return {
                familyListData: [],
                familyListLoading: false,
                lud: '',
                FamilyViewData: [],
                familyViewLoading: false,
                familyEventsData: [],
                familyEventsLoading: false,
            }
        case DELETE_FAMILY_MEMBER:
            return state
        default:
            return state
    }
}

export default familyListReducer
