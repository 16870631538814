import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import 'chartjs-adapter-moment'
import variables from '../../../theme/variables.scss'
import classnames from 'classnames'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

export const Index = ({ holterData, holterLoading, type, name }) => {
    const [state, setState] = useState([])
    const [labels, setlabels] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [currentDate, setCurrentDate] = useState([])
    useEffect(() => {
        if (holterData.length > 0) {
            const arrData = holterData.map((data, index) => {
                return data.value
            })
            const new_Label = holterData.map((data, index) => {
                return moment.parseZone(data.date).format(DATE_FORMAT.MMM_DD_YYYY)
            })

            setlabels(new_Label)
            setState(arrData)
            setCurrentData(arrData?.length > 0 && arrData[arrData?.length - 1])
            setCurrentDate(new_Label?.length > 0 && new_Label[new_Label?.length - 1])
        }
    }, [holterData, type])

    const getParsedValue = (ctx, val1, val2) => {
        let index = ctx.dataIndex
        let value = ctx.dataset.data[index]

        return value === currentData && index === state.length - 1 ? val1 : val2
    }

    const options = {
        responsive: true,
        borderColor: variables.purple,
        backgroundColor: variables.purple,
        tension: 0.4,
        onClick: function (evt, element) {
            if (element.length > 0) {
                var ind = element[0].index
                const value = data.datasets[0].data[ind]
                const label = data.labels[ind]
                setCurrentData(value)
                setCurrentDate(label)
            }
        },
        scales: {
            xAxes: {
                display: true, //this will show all the x-axis grid lines,
                type: 'time',
                time: {
                    displayFormats: {
                        year: type === 'desktop' ? 'MMM YYYY' : `YY'`,
                    },
                    unit: type === 'desktop' ? 'month' : 'year',
                },
                suggestedMax: Date.now(),
                grid: {
                    drawBorder: true,
                    display: true,
                    color: variables.gray100,
                    borderColor: variables.gray100,
                },
                ticks: {
                    beginAtZero: true,
                    color: variables.gray600,
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
            },
            yAxes: {
                display: type === 'desktop' ? true : false, //this will remove all the x-axis grid lines
                grid: {
                    drawBorder: true,
                    display: true,
                    color: variables.gray100,
                    borderColor: variables.gray100,
                },
                ticks: {
                    beginAtZero: true,
                    color: '#000',
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
                beginAtZero: type === 'desktop' ? true : false,
                afterDataLimits(scale) {
                    if (type === 'desktop') {
                        scale.max += 1
                        scale.min -= 0
                    } else {
                        scale.max += 0.5
                        scale.min -= 0.5
                    }
                },
            },
        },
        plugins: {
            tooltip: {
                displayColors: false,
                padding: 8,
                backgroundColor: variables.gray700,
                cornerRadius: 5,
                bodySpacing: 10,
                titleAlign: 'center',
                callbacks: {
                    label: function (ctx) {
                        const data = moment(ctx.label).format(DATE_FORMAT.MMM_DD_YYYY)
                        return data === 'Invalid date' ? '0' : data
                    },
                    title: function (ctx) {
                        const data = ctx[0]?.formattedValue
                        return data
                    },
                },
            },
            legend: {
                position: 'top',
                display: false,
            },
        },
    }

    const data = {
        labels,
        datasets: [
            {
                label: name,
                data: state,
                pointBackgroundColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.white100, variables.purple100)
                        : variables.purple100,
                hoverRadius: type === 'desktop' ? 12 : 8,
                pointRadius:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, 12, 6)
                        : (ctx) => getParsedValue(ctx, 6, 3),
                pointBorderWidth: type === 'desktop' ? 1 : (ctx) => getParsedValue(ctx, 4, 1),
                pointHoverBackgroundColor: variables.white100,
                pointHoverBorderColor: type === 'desktop' ? variables.purple200 : variables.purple,
                pointBorderColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.purple200, variables.purple)
                        : variables.purple,
                pointHoverBorderWidth: type === 'desktop' ? 1 : 4,
                fill: true,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, type === 'desktop' ? 300 : 100)
                    gradient.addColorStop(0, variables.purple500)
                    gradient.addColorStop(1, variables.whiteTransparent)
                    return gradient
                },
            },
        ],
    }
    const chartBoxClasses = classnames('chart-box', {
        'p-2 pb-3 pt-0': type === 'desktop',
    })

    return (
        <div>
            <div className={chartBoxClasses}>
                <div className="p-2 pb-0">
                    <div className="d-flex justify-content-between">
                        <Stack spacing={{ xs: 0.5 }} direction="row">
                            <Typography className="fw-normal fs-9 gray-600">{name}</Typography>
                            <Typography className="fw-bold mb-0 fs-9">{currentData}</Typography>
                        </Stack>
                        {type !== 'desktop' && (
                            <span className="fs-9 gray-400">
                                {moment(currentDate).format(DATE_FORMAT.DD_MMM_YY)}
                            </span>
                        )}
                    </div>
                </div>
                {holterLoading === true ? (
                    <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                ) : (
                    <div className={type === 'desktop' ? 'pe-1 pt-2' : 'pe-2 pb-2'}>
                        <Line options={options} data={data} />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    holterData: state.holterList.holterData,
    holterLoading: state.holterList.holterLoading,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
