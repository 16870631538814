import PropTypes from 'prop-types'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import { fetchProfileModal } from '../../redux/modals/toggleModal.actions'
import './profile.scss'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2, 4),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3, 4),
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(2, 4),
        background: '#F3F4F6',
        fontWeight: 600,
        boxShadow: 'none',
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ profileModal, profileData }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(fetchProfileModal(false, ''))
    }

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="small-modal" open={profileModal}>
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Profile
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">First Name</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.firstName === null || profileData?.firstName === ''
                                    ? 'N/A'
                                    : profileData?.firstName}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Last Name</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.lastName === null || profileData?.lastName === ''
                                    ? 'N/A'
                                    : profileData?.lastName}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">License Number</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.licenseNumber === null || profileData?.licenseNumber === ''
                                    ? 'N/A'
                                    : profileData?.licenseNumber}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Hospital/Clinic Name</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.hospitalName === null || profileData?.hospitalName === ''
                                    ? 'N/A'
                                    : profileData?.hospitalName}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Phone Number</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.phone === null || profileData?.phone === ''
                                    ? 'N/A'
                                    : profileData?.phone}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Email Address</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.email === null || profileData?.email === ''
                                    ? 'N/A'
                                    : profileData?.email}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">City</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.city === null || profileData?.city === ''
                                    ? 'N/A'
                                    : profileData?.city}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Province</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.province === null || profileData?.province === ''
                                    ? 'N/A'
                                    : profileData?.province}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p className="profile-title fw-bold">Postal Code</p>
                            <p className="fs-7 mb-0 color-gray">
                                {profileData?.postalCode === null || profileData?.postalCode === ''
                                    ? 'N/A'
                                    : profileData?.postalCode}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profileModal: state.toggleModals.profileModal,
        login: state.loginData.loginResponse,
        profileData: state.toggleModals.profileData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfileModal: (payload, token) => dispatch(fetchProfileModal(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
