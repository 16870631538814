import { GET_LAB_CATEGORY, SET_CURRENT_LABS, SET_LOADING } from './labs.type'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import axios from 'axios'

export const setCurrentLabs = (data) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_LABS,
        payload: data,
    })
}

export const getLabsCategory = (token) => async (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/labs/list-category`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_LAB_CATEGORY,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
