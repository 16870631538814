import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../redux/alert/alert.action'
import { login } from '../../../redux/auth/auth.action'
import { Auth } from 'aws-amplify'

const STATUS_CODES = {
    OK: 200,
    BAD_REQUEST: 400,
}

export const Index = ({ isLoggedIn, loginData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const showAlert = (alertType, alertMsg) => {
        dispatch(
            setAlert({
                alertType,
                alertMsg,
                alertOpen: true,
            })
        )
    }

    useEffect(() => {
        const doLogin = async () => {
            try {
                const res = await Auth.currentSession()
                const token = res.getAccessToken()
                const jwtToken = token.getJwtToken()
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/cardiologist/auth`, {
                    accessToken: jwtToken,
                })

                if (response.data.status === STATUS_CODES.OK) {
                    dispatch(login(response?.data?.output))
                } else if (response.data.status >= STATUS_CODES.BAD_REQUEST) {
                    throw 44
                } else {
                    throw 44
                }
            } catch (err) {
                console.error(err)
                throw err
            }
        }

        if (isLoggedIn) {
            navigate('/portals')
        } else {
            doLogin().catch(console.error)
        }
    }, [loginData, isLoggedIn])

    return <></>
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.loginData.isLoggedIn,
        loginData: state.loginData.loginResponse,
        resetPasswordModal: state.toggleModals.resetPasswordModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(login(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
