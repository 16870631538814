import React from 'react'
import Typography from '@mui/material/Typography'
import RecommendationDetails from '../../recommendations/recommendation-details/RecommendationDetails'

const RecommendationInfo = () => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal">Recommendations</Typography>
            <RecommendationDetails consultLetterFlag={true} />
        </>
    )
}

export default RecommendationInfo
