import {
    CLEAR_ECHO,
    ECHO_DOC_LOADING,
    GET_ECHO_DATA,
    GET_ECHO_DOCUMENT,
    GET_ECHO_LIST_DATA,
    RESET_ECHO_MODAL,
    SET_ECHO_LOADING,
} from './echoList.type'

const INITIAL_STATE = {
    list: [],
    echoData: [],
    echoLoading: false,
    lud: '',
    echoDocuments: {
        list: [],
        lastRefreshDate: '',
    },
    echoDocLoading: false,
}

const echoListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ECHO_LIST_DATA:
            return {
                ...state,
                list: action?.payload?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }
        case GET_ECHO_DATA:
            return {
                ...state,
                echoData: action?.payload,
            }
        case GET_ECHO_DOCUMENT:
            return {
                ...state,
                echoDocuments: action?.payload,
            }
        case SET_ECHO_LOADING:
            return {
                ...state,
                echoLoading: action.payload,
            }
        case ECHO_DOC_LOADING:
            return {
                ...state,
                echoDocLoading: action.payload,
            }
        case RESET_ECHO_MODAL:
            return {
                ...state,
                echoDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
            }
        case CLEAR_ECHO:
            return {
                list: [],
                echoData: [],
                echoLoading: false,
                lud: '',
                echoDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
                echoDocLoading: false,
            }
        default:
            return state
    }
}

export default echoListReducer
