import React from 'react'
import { connect } from 'react-redux'
import HolterPills from '../holter-pills'
import HolterChart from '../holter-charts'
// import moment from 'moment';

export const Index = ({ patientId, token, holterLoading }) => {
    // const dispatch = useDispatch()

    return (
        <div>
            <div className="d-flex">
                <div className="small-chart">
                    {holterLoading ? (
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    ) : (
                        <HolterChart />
                    )}
                </div>
                <div className="ms-3 w-100">
                    <HolterPills name="dashboard-pills" time="" />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    holterLoading: state.holter.loading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
