import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { getHolterData } from '../../../redux/ECH/holterList/holterList.action'
import { setCurrentHolter } from '../../../redux/ECH/Holter/holter.action'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import _ from 'lodash'

export const Index = ({
    currentHolter,
    holterData,
    patientId,
    token,
    name,
    time,
    consultLetterFlag = false,
}) => {
    const dispatch = useDispatch()
    const handleCurrentHolter = (current) => {
        dispatch(setCurrentHolter(current))
        updateGraphs(current)
    }

    function search(nameKey, myArray) {
        let temp = ''
        if (nameKey === 'HR') {
            temp = _.find(myArray, { name: 'HR' })
            return _.mapValues(_.keyBy(temp?.values, 'name'), 'value')
        }
        for (var i = 0; i < myArray?.length; i++) {
            if (myArray[i].name === nameKey) {
                temp = myArray[i].value
                return myArray[i].value
            }
        }
        if (temp === '') {
            return ' '
        }
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getHolterData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getHolterData(payload, token))
        }
    }

    useEffect(() => {
        if (name === 'Modal-pills' && time !== '') {
            if (['Min HR', 'Mean HR', 'Max HR'].includes(currentHolter)) {
                handleCurrentHolter('HR')
            }
        }
        time !== '' && updateGraphs(currentHolter)
    }, [time, name])

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={3.3}>
                    {/* HR */}
                    {name === 'Modal-pills' && (
                        <div
                            onClick={() => {
                                if (!consultLetterFlag) return handleCurrentHolter('HR')
                            }}
                            className={currentHolter === 'HR' ? `active action-pills` : `action-pills`}
                        >
                            HR {search('HR', holterData?.list)['Max HR'] ?? '-'}/
                            {search('HR', holterData?.list)['Mean HR'] ?? '-'}/
                            {search('HR', holterData?.list)['Min HR'] ?? '-'}
                        </div>
                    )}

                    {/*  */}
                    {name === 'dashboard-pills' ? (
                        <>
                            <div
                                onClick={() => {
                                    if (!consultLetterFlag) return handleCurrentHolter('Max HR')
                                }}
                                className={clsx(
                                    currentHolter === 'Max HR' && !consultLetterFlag ? 'active' : null,
                                    'action-pills'
                                )}
                            >
                                Max HR {search('Max HR', holterData?.list)}
                            </div>
                            <div
                                onClick={() => {
                                    if (!consultLetterFlag) return handleCurrentHolter('Min HR')
                                }}
                                className={clsx(
                                    currentHolter === 'Min HR' && !consultLetterFlag ? 'active' : null,
                                    'action-pills'
                                )}
                            >
                                Min HR {search('Min HR', holterData?.list)}
                            </div>
                            <div
                                onClick={() => {
                                    if (!consultLetterFlag) return handleCurrentHolter('Mean HR')
                                }}
                                className={clsx(
                                    currentHolter === 'Mean HR' && !consultLetterFlag ? 'active' : null,
                                    'action-pills'
                                )}
                            >
                                Mean HR {search('Mean HR', holterData?.list)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    if (!consultLetterFlag) return handleCurrentHolter('pauses')
                                }}
                                className={clsx(
                                    currentHolter === 'pauses' && !consultLetterFlag ? 'active' : null,
                                    'action-pills'
                                )}
                            >
                                SECS {search('pauses', holterData?.list)} s
                            </div>
                            <div
                                onClick={() => {
                                    if (!consultLetterFlag) return handleCurrentHolter('long')
                                }}
                                className={clsx(
                                    currentHolter === 'long' && !consultLetterFlag ? 'active' : null,
                                    'action-pills'
                                )}
                            >
                                Long {search('long', holterData?.list)}
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PAC')
                        }}
                        className={clsx(
                            currentHolter === 'PAC' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        PAC{' '}
                        {parseFloat(search('PAC', holterData?.list)) < 1
                            ? '<1'
                            : search('PAC', holterData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PacLong')
                        }}
                        className={clsx(
                            currentHolter === 'PacLong' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Long {search('PacLong', holterData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PacFast')
                        }}
                        className={clsx(
                            currentHolter === 'PacFast' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Fast {search('PacFast', holterData?.list)}
                    </div>
                </Grid>
                <Grid item xs={2.5}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PVC')
                        }}
                        className={clsx(
                            currentHolter === 'PVC' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        PVC{' '}
                        {parseFloat(search('PVC', holterData?.list)) < 1
                            ? '<1'
                            : search('PVC', holterData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PvcLong')
                        }}
                        className={clsx(
                            currentHolter === 'PvcLong' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Long {search('PvcLong', holterData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentHolter('PvcFast')
                        }}
                        className={clsx(
                            currentHolter === 'PvcFast' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Fast {search('PvcFast', holterData?.list)}
                    </div>
                </Grid>
                {name === 'dashboard-pills' && (
                    <Grid item xs={2.5}>
                        <div
                            onClick={() => {
                                if (!consultLetterFlag) return handleCurrentHolter('pauses')
                            }}
                            className={clsx(
                                currentHolter === 'pauses' && !consultLetterFlag ? 'active' : null,
                                'action-pills'
                            )}
                        >
                            SECS {search('pauses', holterData?.list)} s
                        </div>
                        <div
                            onClick={() => {
                                if (!consultLetterFlag) return handleCurrentHolter('long')
                            }}
                            className={clsx(
                                currentHolter === 'long' && !consultLetterFlag ? 'active' : null,
                                'action-pills'
                            )}
                        >
                            Long {search('long', holterData?.list)}
                        </div>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentHolter: state.holter.currentHolter,
    holterData: state.holterList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentHolter: (payload) => dispatch(setCurrentHolter(payload)),
        getHolterData: (payload, token) => dispatch(getHolterData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
