import * as React from 'react'
import variables from '../../../../theme/variables.scss'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Stack } from '@mui/system'

export default function CriteriaDetails({
    trialData,
    trialListLoading,
    trialCriteriaLoading,
    trialCriteriaData,
}) {
    const criteriaCheckhandler = (criteriaId) => {
        let flag = false
        Object.entries(trialCriteriaData)?.forEach(([key, value]) => {
            if (key === criteriaId) {
                if (typeof value === 'object') {
                    flag = !!value?.exclusive
                    return
                } else if (typeof value === 'boolean') {
                    flag = !!value
                    return
                }
            }
        })

        return flag
    }

    return (
        <>
            {!!trialListLoading || trialCriteriaLoading ? (
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    <img style={{ width: '45px' }} src="/loader.gif" alt="loader" />
                </Grid>
            ) : _.isEmpty(trialData) ? (
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    <Box>
                        <Typography className="gray-900 fw-normal fs-8 ">
                            No Inclusion/Exclusion data found
                        </Typography>
                    </Box>
                </Grid>
            ) : (
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={6}>
                        <Typography className="fw-bold text-capitalize">Inclusion Criteria</Typography>
                        <Divider component="p" className="mb-3" />
                        {_.isEmpty(trialData?.inclusionCriteriaData) ? (
                            <Typography className="fs-8 fw-normal mx-2">
                                Inclusion Criteria not available.
                            </Typography>
                        ) : (
                            <ul
                                style={{
                                    listStyleType: 'none',
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                {trialData?.inclusionCriteriaData?.map((row, idx) => (
                                    <Stack direction="row" spacing={2} key={`${idx}-${row?.id}`}>
                                        {criteriaCheckhandler(row?.id) ? (
                                            <CheckCircleIcon
                                                sx={{
                                                    color: variables.green500,
                                                }}
                                            />
                                        ) : (
                                            <CancelIcon
                                                sx={{
                                                    color: variables.red600,
                                                }}
                                            />
                                        )}
                                        <li className="fs-8 fw-normal">{row?.criteria}</li>
                                    </Stack>
                                ))}
                            </ul>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="fw-bold text-capitalize">Exclusion Criteria</Typography>
                        <Divider component="p" className="mb-3" />
                        {_.isEmpty(trialData?.exclusionCriteriaData) ? (
                            <Typography className="fs-8 fw-normal mx-2">
                                Exclusion Criteria not available.
                            </Typography>
                        ) : (
                            <ol
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                {trialData?.exclusionCriteriaData?.map((row, idx) => (
                                    <Stack direction="row" spacing={3} key={idx}>
                                        <CheckCircleIcon
                                            sx={{
                                                color: variables.green500,
                                            }}
                                        />
                                        <li className="fs-8 fw-normal">{row?.criteria}</li>
                                    </Stack>
                                ))}
                            </ol>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    )
}
