import React from 'react'
import { connect } from 'react-redux'

export const index = (props) => {
    return (
        <div className="loader">
            <img src="/loader.gif" alt="loader" />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)
