import { CLEAR_CT, CT_DOC_LOADING, GET_CT_DOCUMENT, RESET_CT_MODAL } from './CTList.type'

const INITIAL_STATE = {
    CTLoading: false,
    lud: '',
    CTDocuments: [],
}

const CTListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CT_DOCUMENT:
            return {
                ...state,
                CTDocuments: action?.payload?.list,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case CT_DOC_LOADING:
            return {
                ...state,
                CTLoading: action.payload,
            }
        case RESET_CT_MODAL:
            return {
                ...state,
                CTDocuments: [],
            }
        case CLEAR_CT:
            return {
                CTLoading: false,
                lud: '',
                CTDocuments: [],
            }
        default:
            return state
    }
}

export default CTListReducer
