import React from 'react'
import ReactDOM from 'react-dom/client'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import App from './App'
import './index.scss'
import reportWebVitals from './reportWebVitals'

// REDUX
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store'

const theme = createTheme({
    palette: {
        primary: {
            main: '#3F48CC',
        },
        secondary: {
            main: '#11cb5f',
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <MuiThemeProvider theme={theme}>
                    <App />
                </MuiThemeProvider>
            </React.StrictMode>
        </PersistGate>
    </Provider>
)

reportWebVitals()
