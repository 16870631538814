import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import { TextField } from '@material-ui/core'
import axios from 'axios'
import * as yup from 'yup'
import EmailSent from '../email-sent'
import {
    toggleResetPasswordEmailSentModal,
    toggleResetPasswordModal,
} from '../../../redux/modals/toggleModal.actions'
import './reset-password.scss'
import { setAlert } from '../../../redux/alert/alert.action'

const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    toggleResetPasswordModal,
    resetPasswordModal,
    toggleResetPasswordEmailSentModal,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClose = () => {
        toggleResetPasswordModal(false)
    }
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {},
    })
    const handleResetPasssword = async () => {
        var pattern = '[a-z0-9]+@[a-z]+.[a-z]{2,3}'
        formik.handleSubmit()
        if (formik.values.email.match(pattern)) {
            setLoading(true)
            const data = {
                email: formik.values.email,
                type: 'cardiologist',
            }
            axios
                .patch(`${process.env.REACT_APP_BASE_URL}/forgot-password`, data)
                .then((response) => {
                    if (response?.data?.status === 200) {
                        setLoading(false)
                        navigate('/', { state: formik.values })
                        dispatch(toggleResetPasswordModal(false))
                        dispatch(toggleResetPasswordEmailSentModal(true))
                    } else if (response?.data?.status >= 400) {
                        setLoading(false)
                        dispatch(
                            setAlert({
                                alertType: 'error',
                                alertMsg: response.data.error.message,
                                alertOpen: true,
                            })
                        )
                    }
                })
                .catch((err) => {
                    // console.log(err, 'err')
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'error',
                            alertMsg: 'something went wrong! Please try again.',
                            alertOpen: true,
                        })
                    )
                })
        } else {
            // console.log("err")
        }
    }

    return (
        <>
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="small-modal"
                    open={resetPasswordModal}
                    maxWidth="sm"
                >
                    <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                        Reset a New Password
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography className="fw-bold fs-5" gutterBottom>
                            Enter the email address associated with your account and we will send you the new
                            password.
                        </Typography>

                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                variant="filled"
                                className="my-4 login-field"
                                InputProps={{ disableUnderline: true }}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className="btn-white-outline"
                            type="submit"
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={loading}
                            onClick={handleResetPasssword}
                            variant="contained"
                            className="btn-primary"
                        >
                            Reset Password
                        </LoadingButton>
                    </DialogActions>
                </BootstrapDialog>
            </div>
            <EmailSent />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        resetPasswordModal: state.toggleModals.resetPasswordModal,
        toggleResetPasswordEmailSentModal: state.toggleModals.toggleResetPasswordEmailSentModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleResetPasswordModal: (payload) => dispatch(toggleResetPasswordModal(payload)),
        toggleResetPasswordEmailSentModal: (payload) => dispatch(toggleResetPasswordEmailSentModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
