import {
    GET_CONSULT_LIST,
    SET_CONSULT_LIST_LOADING,
    GET_CONSULT_DOCUMENTS,
    SET_CONSULT_DOCS_LOADING,
    CLEAR_CONSULT,
    RESET_CONSULT_MODAL,
} from './consultNotes.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'
export const getConsultList = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_CONSULT_LIST_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/consult/count?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CONSULT_LIST,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_CONSULT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Error in fetching Consult Notes .Please! try Again',
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_CONSULT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_CONSULT_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_CONSULT_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getConsultDocuments = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_CONSULT_DOCS_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/consult?patientId=${payload?.patientId}&type=${payload?.type}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CONSULT_DOCUMENTS,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_CONSULT_DOCS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Error in fetching Consult Document pdf .Please! try Again',
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_CONSULT_DOCS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_CONSULT_DOCS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_CONSULT_DOCS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const resetConsultDocument = () => (dispatch) => {
    dispatch({
        type: RESET_CONSULT_MODAL,
    })
}

export const clearConsult = () => async (dispatch) => {
    dispatch({
        type: CLEAR_CONSULT,
        payload: true,
    })
}
