import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'
import TranscribeIcon from '@mui/icons-material/Transcribe'

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
    color: red;
  }
  50% {
    opacity: 0;
    color: transparent;
  }
  100% {
    opacity: 1;
    color: red;
  }
`

const BlinkingIcon = styled('div')({
    animation: `${blinkAnimation} 1s infinite`,
    color: 'red',
})

export const BlinkingTranscribeIcon = ({ ...rest }) => (
    <BlinkingIcon>
        <TranscribeIcon {...rest} />
    </BlinkingIcon>
)
