import {
    GET_RECOMMENDATIONS_LIST_DATA,
    SET_RECOMMENDATIONS_LOADING,
    CLEAR_RECOMMENDATIONS,
} from './recommendations.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const getRecommendationsListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_RECOMMENDATIONS_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/recommendation?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_RECOMMENDATIONS_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_RECOMMENDATIONS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_RECOMMENDATIONS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_RECOMMENDATIONS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_RECOMMENDATIONS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: err?.message ?? 'Something went wrong. Please! try again.',
                    alertOpen: true,
                })
            )
        })
}

export const clearRecommendations = () => (dispatch) => {
    dispatch({
        type: CLEAR_RECOMMENDATIONS,
    })
}
