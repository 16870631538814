import {
    CLEAR_NUCLEAR,
    GET_NUCLEAR_DOCUMENT,
    NUCLEAR_DOC_LOADING,
    RESET_NUCLEAR_MODAL,
} from './nuclearList.type'

const INITIAL_STATE = {
    nuclearLoading: false,
    lud: '',
    nuclearDocuments: [],
}

const nuclearListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NUCLEAR_DOCUMENT:
            return {
                ...state,
                nuclearDocuments: action?.payload?.list,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case NUCLEAR_DOC_LOADING:
            return {
                ...state,
                nuclearLoading: action.payload,
            }
        case RESET_NUCLEAR_MODAL:
            return {
                ...state,
                nuclearDocuments: [],
            }
        case CLEAR_NUCLEAR:
            return {
                nuclearLoading: false,
                lud: '',
                nuclearDocuments: [],
            }

        default:
            return state
    }
}

export default nuclearListReducer
