import {
    CLEAR_CARDIAC,
    CARDIAC_DOC_LOADING,
    GET_CARDIAC_DATA,
    GET_CARDIAC_DOCUMENT,
    GET_CARDIAC_LIST_DATA,
    RESET_CARDIAC_MODAL,
    SET_CARDIAC_LOADING,
    SET_CARDIAC_MED_LOADING,
    GET_MEASUREMENT_DATA,
    SET_MEASUREMENT_LOADING,
    CLEAR_MEASUREMENT_DATA,
    GET_OTHER_LIST_DATA,
    SET_OTHER_LOADING,
    GET_PAST_LIST_DATA,
    SET_PAST_LOADING,
    SET_CURRENT_MEDICATION,
} from './cardiacList.type'

const INITIAL_STATE = {
    list: [],
    cardiacData: [],
    cardiacLoading: false,
    lud: '',
    cardiacDocLoading: false,
    cardiacMedLoading: false,
    measurementLoading: false,
    measurementData: [],
    otherData: [],
    otherLoading: false,
    otherMedsLud: '',
    pastData: [],
    pastLoading: false,
    pastMedsLud: '',
    currentMedication: 'cardiac',
}

const getLastUpdatedDate = (payload) =>
    payload?.hasOwnProperty('lastUpdatedDate') ? payload?.lastUpdatedDate : ''

const cardiacListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CARDIAC_LIST_DATA:
            return {
                ...state,
                list: action?.payload,
                lud: getLastUpdatedDate(action.payload),
                loading: false,
            }

        case GET_OTHER_LIST_DATA:
            return {
                ...state,
                otherData: action?.payload,
                otherMedsLud: getLastUpdatedDate(action.payload),
            }

        case GET_PAST_LIST_DATA:
            return {
                ...state,
                pastData: action?.payload,
                pastMedsLud: getLastUpdatedDate(action.payload),
            }

        case GET_CARDIAC_DATA:
            return {
                ...state,
                cardiacData: action?.payload,
            }

        case GET_MEASUREMENT_DATA:
            return {
                ...state,
                measurementData: action?.payload,
            }
        case GET_CARDIAC_DOCUMENT:
            return {
                ...state,
                cardiacDocuments: action?.payload,
            }
        case SET_CARDIAC_LOADING:
            return {
                ...state,
                cardiacLoading: action.payload,
            }
        case SET_OTHER_LOADING:
            return {
                ...state,
                otherLoading: action.payload,
            }
        case SET_PAST_LOADING:
            return {
                ...state,
                pastLoading: action.payload,
            }
        case CARDIAC_DOC_LOADING:
            return {
                ...state,
                cardiacDocLoading: action.payload,
            }
        case SET_CARDIAC_MED_LOADING:
            return {
                ...state,
                cardiacMedLoading: action.payload,
            }
        case SET_MEASUREMENT_LOADING:
            return {
                ...state,
                measurementLoading: action.payload,
            }

        case CLEAR_MEASUREMENT_DATA:
            return {
                ...state,
                measurementData: [],
            }
        case RESET_CARDIAC_MODAL:
            return {
                ...state,
                measurementData: [],
                measurementLoading: false,
                cardiacLoading: false,
                cardiacMedLoading: false,
                cardiacData: [],
            }

        case CLEAR_CARDIAC:
            return {
                ...INITIAL_STATE,
            }

        case SET_CURRENT_MEDICATION:
            return {
                ...state,
                currentMedication: action?.payload,
            }
        default:
            return state
    }
}

export default cardiacListReducer
