import {
    SET_PROFILE_LOADING,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    SET_CATH_MODAL,
    SET_CT_MODAL,
    SET_ECG_MODAL,
    SET_ECHO_MODAL,
    SET_STRESS_ECHO_MODAL,
    SET_GXT_MODAL,
    SET_HOLTER_MODAL,
    SET_LABS_MODAL,
    SET_MRI_MODAL,
    SET_NUCLEAR_MODAL,
    SET_VITALS_MODAL,
    SET_CARDIAC_MODAL,
    SET_SOCIAL_MODAL,
    SET_FAMILY_MODAL,
    SET_CONSULT_MODAL,
    SET_ADVERSE_EVENT_MODAL,
    SET_INTERVAL_MODAL,
    SET_PHYSICAL_MODAL,
    SET_MEDICAL_HISTORY_MODAL,
    SET_CARDIAC_HISTORY_MODAL,
    SET_CARDIAC_TIMELINE_MODAL,
    SET_RECOMMENDATIONS_MODAL,
    SET_CONSULT_LETTER_MODAL,
    SET_SCREEN_FLOW_MODAL,
    SET_ELIGIBILITY_CRITERIA_MODAL,
    SET_APPOINTMENT_MODAL,
    SET_PATIENT_INFO_MODAL,
    TOGGLE_ACCOUNT_DEACTIVATE_MODAL,
    TOGGLE_CHANGE_PASSWORD_MODAL,
    TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL,
    TOGGLE_OTP_MODAL,
    TOGGLE_PATIENT_PROFILE_MODAL,
    TOGGLE_PROFILE_MODAL,
    TOGGLE_RESET_PASSWORD_MODAL,
    TOGGLE_SESSION_MODAL,
} from './toggleModal.types'

const INITIAL_STATE = {
    otpModal: false,
    resetPasswordModal: false,
    EmailSentResetPasswordModal: false,
    profileLoading: false,
    profileModal: false,
    sessionModal: false,
    changePasswordModal: false,
    patientProfileModal: false,
    deactivateModal: false,
    vitalsModal: false,
    labsModal: false,
    ecgModal: false,
    holterModal: false,
    echoModal: false,
    stressEchoModal: false,
    nuclearModal: false,
    cathModal: false,
    ctModal: false,
    mriModal: false,
    cardiacModal: false,
    socialModal: false,
    familyModal: false,
    consultModal: false,
    adverseEventModal: false,
    intervalModal: false,
    physicalModal: false,
    medicalHistoryModal: false,
    cardiacHistoryModal: false,
    cardiacTimelineModal: false,
    recommendationsModal: false,
    consultLetterModal: false,
    screenFlowModal: false,
    eligibilityCriteriaModal: false,
    appointmentModal: false,
    patientInfoModal: false,
    profileData: {
        city: '',
        province: '',
        postalCode: '',
        _id: '',
        providerId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        licenseNumber: '',
        hospitalName: '',
    },
}

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case TOGGLE_OTP_MODAL:
            return {
                ...state,
                otpModal: action.payload,
            }
        case TOGGLE_RESET_PASSWORD_MODAL:
            return {
                ...state,
                resetPasswordModal: action.payload,
            }
        case TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL:
            return {
                ...state,
                EmailSentResetPasswordModal: action.payload,
            }
        case SET_PROFILE_LOADING:
            return {
                ...state,
                profileLoading: action.payload,
            }
        case PROFILE_SUCCESS:
            return {
                ...state,
                profileData: action.payload,
            }
        case TOGGLE_PROFILE_MODAL:
            return {
                ...state,
                profileModal: action.payload,
            }
        case PROFILE_FAILED:
            return {
                ...state,
                profileModal: false,
            }
        case TOGGLE_SESSION_MODAL:
            return {
                ...state,
                sessionModal: action.payload.payload,
                sessionModalData: action.payload.data,
            }
        case TOGGLE_CHANGE_PASSWORD_MODAL:
            return {
                ...state,
                changePasswordModal: action.payload,
            }
        case TOGGLE_PATIENT_PROFILE_MODAL:
            return {
                ...state,
                patientProfileModal: action.payload,
            }
        case TOGGLE_ACCOUNT_DEACTIVATE_MODAL:
            return {
                ...state,
                deactivateModal: action.payload,
            }
        case SET_VITALS_MODAL:
            return {
                ...state,
                vitalsModal: action.payload,
            }
        case SET_LABS_MODAL:
            return {
                ...state,
                labsModal: action.payload,
            }
        case SET_ECG_MODAL:
            return {
                ...state,
                ecgModal: action.payload,
            }
        case SET_HOLTER_MODAL:
            return {
                ...state,
                holterModal: action.payload,
            }
        case SET_GXT_MODAL:
            return {
                ...state,
                gxtModal: action.payload,
            }
        case SET_ECHO_MODAL:
            return {
                ...state,
                echoModal: action.payload,
            }
        case SET_STRESS_ECHO_MODAL:
            return {
                ...state,
                stressEchoModal: action.payload,
            }
        case SET_NUCLEAR_MODAL:
            return {
                ...state,
                nuclearModal: action.payload,
            }
        case SET_CATH_MODAL:
            return {
                ...state,
                cathModal: action.payload,
            }
        case SET_CT_MODAL:
            return {
                ...state,
                ctModal: action.payload,
            }
        case SET_MRI_MODAL:
            return {
                ...state,
                mriModal: action.payload,
            }
        case SET_CARDIAC_MODAL:
            return {
                ...state,
                cardiacModal: action.payload,
            }
        case SET_SOCIAL_MODAL:
            return {
                ...state,
                socialModal: action.payload,
            }
        case SET_FAMILY_MODAL:
            return {
                ...state,
                familyModal: action.payload,
            }
        case SET_CONSULT_MODAL:
            return {
                ...state,
                consultModal: action.payload,
            }
        case SET_ADVERSE_EVENT_MODAL:
            return {
                ...state,
                adverseEventModal: action.payload,
            }
        case SET_INTERVAL_MODAL:
            return {
                ...state,
                intervalModal: action.payload,
            }

        case SET_PHYSICAL_MODAL:
            return {
                ...state,
                physicalModal: action.payload,
            }

        case SET_MEDICAL_HISTORY_MODAL:
            return {
                ...state,
                medicalHistoryModal: action.payload,
            }

        case SET_CARDIAC_HISTORY_MODAL:
            return {
                ...state,
                cardiacHistoryModal: action.payload,
            }

        case SET_CARDIAC_TIMELINE_MODAL:
            return {
                ...state,
                cardiacTimelineModal: action.payload,
            }

        case SET_RECOMMENDATIONS_MODAL:
            return {
                ...state,
                recommendationsModal: action.payload,
            }
        case SET_CONSULT_LETTER_MODAL:
            return {
                ...state,
                consultLetterModal: action.payload,
            }
        case SET_SCREEN_FLOW_MODAL:
            return {
                ...state,
                screenFlowModal: action.payload,
            }
        case SET_ELIGIBILITY_CRITERIA_MODAL:
            return {
                ...state,
                eligibilityCriteriaModal: action.payload,
            }
        case SET_APPOINTMENT_MODAL:
            return {
                ...state,
                appointmentModal: action.payload,
            }
        case SET_PATIENT_INFO_MODAL:
            return {
                ...state,
                patientInfoModal: action.payload,
            }

        default:
            return state
    }
}

export default reducer
