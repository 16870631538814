export const TOGGLE_OTP_MODAL = 'TOGGLE_OTP_MODAL'
export const TOGGLE_RESET_PASSWORD_MODAL = 'TOGGLE_RESET_PASSWORD_MODAL'
export const TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL = 'TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL'
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL'
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILED = 'PROFILE_FAILED'
export const TOGGLE_SESSION_MODAL = 'TOGGLE_SESSION_MODAL'
export const TOGGLE_CHANGE_PASSWORD_MODAL = 'TOGGLE_CHANGE_PASSWORD_MODAL'
export const TOGGLE_PATIENT_PROFILE_MODAL = 'TOGGLE_PATIENT_PROFILE_MODAL'
export const TOGGLE_ACCOUNT_DEACTIVATE_MODAL = 'TOGGLE_ACCOUNT_DEACTIVATE_MODAL'
export const SET_VITALS_MODAL = 'SET_VITALS_MODAL'
export const SET_LABS_MODAL = 'SET_LABS_MODAL'
export const SET_ECG_MODAL = 'SET_ECG_MODAL'
export const SET_HOLTER_MODAL = 'SET_HOLTER_MODAL'
export const SET_GXT_MODAL = 'SET_GXT_MODAL'
export const SET_ECHO_MODAL = 'SET_ECHO_MODAL'
export const SET_STRESS_ECHO_MODAL = 'SET_STRESS_ECHO_MODAL'
export const SET_NUCLEAR_MODAL = 'SET_NUCLEAR_MODAL'
export const SET_CATH_MODAL = 'SET_CATH_MODAL'
export const SET_CT_MODAL = 'SET_CT_MODAL'
export const SET_MRI_MODAL = 'SET_MRI_MODAL'
export const SET_CARDIAC_MODAL = 'SET_CARDIAC_MODAL'
export const SET_SOCIAL_MODAL = 'SET_SOCIAL_MODAL'
export const SET_FAMILY_MODAL = 'SET_FAMILY_MODAL'
export const SET_CONSULT_MODAL = 'SET_CONSULT_MODAL'
export const SET_ADVERSE_EVENT_MODAL = 'SET_ADVERSE_EVENT_MODAL'
export const SET_INTERVAL_MODAL = 'SET_INTERVAL_MODAL'
export const SET_PHYSICAL_MODAL = 'SET_PHYSICAL_MODAL'
export const SET_MEDICAL_HISTORY_MODAL = 'SET_MEDICAL_HISTORY_MODAL'
export const SET_CARDIAC_HISTORY_MODAL = 'SET_CARDIAC_HISTORY_MODAL'
export const SET_CARDIAC_TIMELINE_MODAL = 'SET_CARDIAC_TIMELINE_MODAL'
export const SET_RECOMMENDATIONS_MODAL = 'SET_RECOMMENDATIONS_MODAL'
export const SET_CONSULT_LETTER_MODAL = 'SET_CONSULT_LETTER_MODAL'
export const SET_SCREEN_FLOW_MODAL = 'SET_SCREEN_FLOW_MODAL'
export const SET_ELIGIBILITY_CRITERIA_MODAL = 'SET_ELIGIBILITY_CRITERIA_MODAL'
export const SET_APPOINTMENT_MODAL = 'SET_APPOINTMENT_MODAL'
export const SET_PATIENT_INFO_MODAL = 'SET_PATIENT_INFO_MODAL'
