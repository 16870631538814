import PropTypes from 'prop-types'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import {
    toggleResetPasswordEmailSentModal,
    toggleResetPasswordModal,
} from '../../../redux/modals/toggleModal.actions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ EmailSentResetPasswordModal }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(toggleResetPasswordEmailSentModal(false))
    }

    const handleOpenSignIn = () => {
        dispatch(toggleResetPasswordEmailSentModal(false))
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="small-modal"
                open={EmailSentResetPasswordModal}
            >
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Email has Been Sent
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography className="fw-bold fs-5" gutterBottom>
                        Please check your inbox. We have sent you a new password to your email address.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleOpenSignIn} variant="contained" className="btn-primary">
                        Sign in
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        resetPasswordModal: state.toggleModals.resetPasswordModal,
        EmailSentResetPasswordModal: state.toggleModals.EmailSentResetPasswordModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleResetPasswordModal: (payload) => dispatch(toggleResetPasswordModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
