import {
    GET_CONSULT_LIST,
    SET_CONSULT_LIST_LOADING,
    GET_CONSULT_DOCUMENTS,
    SET_CONSULT_DOCS_LOADING,
    CLEAR_CONSULT,
    RESET_CONSULT_MODAL,
} from './consultNotes.type'

const INITIAL_STATE = {
    lud: '',
    consultListData: {},
    consultListLoading: false,
    consultDocuments: [],
    consultDocsLoading: false,
}

const consultNotesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CONSULT_LIST:
            return {
                ...state,
                consultListData: action?.payload,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }

        case GET_CONSULT_DOCUMENTS:
            return {
                ...state,
                consultDocuments: action?.payload,
                lud: action?.payload?.lastRefreshDate ? action?.payload?.lastRefreshDate : '',
            }
        case SET_CONSULT_LIST_LOADING:
            return {
                ...state,
                consultListLoading: action.payload,
            }

        case SET_CONSULT_DOCS_LOADING:
            return {
                ...state,
                consultDocsLoading: action.payload,
            }

        case RESET_CONSULT_MODAL:
            return {
                ...state,
                consultDocuments: [],
            }
        case CLEAR_CONSULT:
            return {
                lud: '',
                consultListData: {},
                consultListLoading: false,
                consultDocuments: [],
                consultDocsLoading: false,
            }
        default:
            return state
    }
}

export default consultNotesReducer
