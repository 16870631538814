import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import HRChart from './HR-chart'
import DummyChart from '../../dummy-chart'
import LoadingChart from '../../labs-component/labs-charts/loading-chart'
import BaseChart from './Base-chart'

export const Index = ({ currentHolter, holterLoading, holterData, type }) => {
    /* for setting desired title/name for chart  */
    const placeholderHelper = () => {
        const palceholderObj = [
            { name: 'HR', placeholder: 'Max HR / Mean HR / Min HR' },
            { name: 'Max HR', placeholder: 'Max HR' },
            { name: 'Min HR', placeholder: 'Min HR' },
            { name: 'Mean HR', placeholder: 'Mean HR' },
            { name: 'PAC', placeholder: 'PAC' },
            { name: 'PacLong', placeholder: 'Pac Long' },
            { name: 'PacFast', placeholder: 'Pac Fast' },
            { name: 'PVC', placeholder: 'PVC' },
            { name: 'PvcLong', placeholder: 'Pvc Long' },
            { name: 'PvcFast', placeholder: 'Pvc Fast' },
            { name: 'pauses', placeholder: 'pauses' },
            { name: 'long', placeholder: 'Pauses long' },
        ]
        return _.find(palceholderObj, ['name', currentHolter])?.placeholder ?? ''
    }

    const holterChart = () => {
        if (
            [
                'Max HR',
                'Min HR',
                'Mean HR',
                'PAC',
                'PacLong',
                'PacFast',
                'PVC',
                'PvcLong',
                'PvcFast',
                'pauses',
                'long',
            ].includes(currentHolter)
        ) {
            return <BaseChart type={type} name={placeholderHelper()} />
        } else if (currentHolter === 'HR') {
            return <HRChart type={type} />
        } else {
            return <DummyChart name={`${currentHolter} holter chart not supported `} />
        }
    }

    const showChart = () => {
        if (holterData?.length > 0) {
            return holterChart()
        } else {
            return <DummyChart name={placeholderHelper()} />
        }
    }
    return <div>{!!currentHolter && <>{holterLoading ? <LoadingChart type={type} /> : showChart()}</>}</div>
}

const mapStateToProps = (state) => ({
    currentHolter: state.holter.currentHolter,
    holterData: state.holterList.holterData,
    holterLoading: state.holterList.holterLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
