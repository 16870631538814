import * as React from 'react'
import clsx from 'clsx'
import './Accordian.scss'
import { styled } from '@mui/material/styles'
import variables from '../../../../theme/variables.scss'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({})
)

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: variables.white100,

    border: `1px solid ${variables.lightGray1}`,
    borderRadius: '6px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ':hover , :focus': {
        border: `1px solid ${variables.purple}`,
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}))

export default function CustomizedAccordions({
    expanded,
    setExpanded,
    handleAccordianChange,
    title,
    toggleBtn,
    children,
    summaryStyle = null,
}) {
    return (
        <div className="py-2">
            <Accordion expanded={expanded === title} onChange={handleAccordianChange(title)}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={clsx(summaryStyle === 'primary' ? 'accordion-summary-primary' : '')}
                >
                    <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography
                            className={clsx(summaryStyle === 'primary' ? 'fw-bold text-capitalize' : '')}
                        >
                            {title ?? 'Title'}
                        </Typography>
                        {toggleBtn}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </div>
    )
}
