import React from 'react'
import _ from 'lodash'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import CustomizedAccordions from '../../../interval-history/interval-modal/interval-components/Accordian'
import ColorToggleButton from '../../../interval-history/interval-modal/interval-components/Toggler'
import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const ProceduralAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Procedural'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`physicalExam[4].active`}
                        btnVal={values?.physicalExam[4]?.active}
                    />
                }
            >
                <Typography className="fs-8 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                    Access site
                </Typography>

                {/* Location */}
                <StackWrapper title="Location" direction="row" spacing={2}>
                    <BoxWrapper
                        styles={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '30px' }}>
                                Side
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[4].diagnosisType.accessSite.location.side`}
                                inputVal={values?.physicalExam[4]?.diagnosisType?.accessSite?.location?.side}
                                toggleBtnArr={['Right', 'Left']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '30px' }}>
                                Area
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="xl"
                                inputName={`physicalExam[4].diagnosisType.accessSite.location.area`}
                                inputVal={values?.physicalExam[4]?.diagnosisType?.accessSite?.location?.area}
                                toggleBtnArr={['Radial', 'Femoral', 'Subclavicular', 'Sternal']}
                            />
                        </BoxWrapper>
                    </BoxWrapper>
                </StackWrapper>

                {/* Appearance */}
                <StackWrapper title="Appearance" direction="row" spacing={2}>
                    <BoxWrapper
                        styles={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '30px' }}>
                                Type
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[4].diagnosisType.accessSite.appearance.type`}
                                inputVal={
                                    values?.physicalExam[4]?.diagnosisType?.accessSite?.appearance?.type
                                }
                                toggleBtnArr={['Dry', 'Oozing']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '30px' }}>
                                Injury
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[4].diagnosisType.accessSite.appearance.injury`}
                                inputVal={
                                    values?.physicalExam[4]?.diagnosisType?.accessSite?.appearance?.injury
                                }
                                toggleBtnArr={['Healed', 'Bruised', 'Swollen', 'Tender']}
                            />
                        </BoxWrapper>
                    </BoxWrapper>
                </StackWrapper>

                {/* Additional Details */}
                <StackWrapper title="Additional Details" direction="row" spacing={2}>
                    <Inputs
                        inputType={'textArea'}
                        inputName={`physicalExam[4].diagnosisType.additionalDetails`}
                        inputVal={values?.physicalExam[4]?.diagnosisType?.additionalDetails}
                    />
                </StackWrapper>
                <Divider
                    color="#000"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        mx: 4,
                        mt: 2,
                    }}
                >
                    x
                </Divider>
            </CustomizedAccordions>
        </>
    )
}

export default ProceduralAccordion
