import {
    GET_MEDICAL_HISTORY_LIST_DATA,
    SET_MEDICAL_HISTORY_LIST_LOADING,
    GET_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
    CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    CLEAR_MEDICAL_HISTORY_LIST_DATA,
    CLEAR_MEDICAL_HISTORY_DATA,
    SET_CURRENT_MEDICAL_HISTORY,
    DELETE_MEDICAL_HISTORY,
    GET_PAST_MEDICAL_HISTORY_LIST_DATA,
    SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
    GET_PAST_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    SET_PAST_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
} from './medicalHistory.type'

const INITIAL_STATE = {
    medicalHistoryListData: [],
    medicalHistoryListLoading: false,
    medicalHistoryDiagnosisData: [],
    medicalHistoryDiagnosisLoading: false,
    currentMedicalHistory: 'other',
    pastMedicalHistoryListData: [],
    pastMedicalHistoryListLoading: false,
    pastMedicalHistoryDiagnosisData: [],
    pastMedicalHistoryDiagnosisLoading: false,
}

const medicalHistoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MEDICAL_HISTORY_LIST_DATA:
            return {
                ...state,
                medicalHistoryListData: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
            }
        case GET_MEDICAL_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                medicalHistoryDiagnosisData: action?.payload,
            }

        case SET_MEDICAL_HISTORY_LIST_LOADING:
            return {
                ...state,
                medicalHistoryListLoading: action.payload,
            }
        case SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING:
            return {
                ...state,
                medicalHistoryDiagnosisLoading: action.payload,
            }

        case SET_CURRENT_MEDICAL_HISTORY:
            return {
                ...state,
                currentMedicalHistory: action?.payload,
            }
        case CLEAR_MEDICAL_HISTORY_LIST_DATA:
            return {
                ...state,
                medicalHistoryListData: [],
            }
        case CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                medicalHistoryDiagnosisData: [],
            }
        case CLEAR_MEDICAL_HISTORY_DATA:
            return {
                medicalHistoryListData: [],
                medicalHistoryListLoading: false,
                medicalHistoryDiagnosisData: [],
                medicalHistoryDiagnosisLoading: false,
                lud: '',
                currentMedicalHistory: 'other',
                pastMedicalHistoryListData: [],
                pastMedicalHistoryListLoading: false,
                pastMedicalHistoryDiagnosisData: [],
                pastMedicalHistoryDiagnosisLoading: false,
            }
        case GET_PAST_MEDICAL_HISTORY_LIST_DATA:
            return {
                ...state,
                pastMedicalHistoryListData: action?.payload,
                plud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
            }
        case GET_PAST_MEDICAL_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                pastMedicalHistoryDiagnosisData: action?.payload,
            }

        case SET_PAST_MEDICAL_HISTORY_LIST_LOADING:
            return {
                ...state,
                pastMedicalHistoryListLoading: action.payload,
            }
        case SET_PAST_MEDICAL_HISTORY_DIAGNOSIS_LOADING:
            return {
                ...state,
                pastMedicalHistoryDiagnosisLoading: action.payload,
            }
        case DELETE_MEDICAL_HISTORY:
            return state
        default:
            return state
    }
}

export default medicalHistoryReducer
