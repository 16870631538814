import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../theme/variables.scss'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import Tooltip from '@mui/material/Tooltip'
import { toggleCardiacHistoryModal, toggleCardiacTimelineModal } from '../../redux/modals/toggleModal.actions'
import {
    getCardiacHistoryListData,
    getPastCardiacHistoryListData,
    setCurrentCardiacHistory,
    setCardiacHistoryModalType,
} from '../../redux/cardiacHistory/cardiacHistory.action'

import CardiacDetails from './cardiac-details'

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: variables.purple,
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: variables.gray600,
    fontFamily: 'Inter',
    '&:hover': {
        color: variables.purple,
        opacity: 1,
    },
    '&.Mui-selected': {
        color: variables.purple,
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: variables.blue100,
    },
}))
export const Index = ({
    patientId,
    token,
    currentCardiacHistory,
    cardiacHistoryListData,
    pastCardiacHistoryListData,
    cardiacHistoryListLoading,
}) => {
    const dispatch = useDispatch()

    const [value, setValue] = React.useState(currentCardiacHistory ?? 'cardiac')

    useEffect(() => {
        setValue(currentCardiacHistory)
    }, [currentCardiacHistory])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case 'cardiac':
                const payloadMedicalHistoryList = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'active',
                }
                dispatch(getCardiacHistoryListData(payloadMedicalHistoryList, token))
                dispatch(setCurrentCardiacHistory('cardiac'))
                break
            case 'past':
                const payloadPastMedicalHistoryList = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'past',
                }
                dispatch(getPastCardiacHistoryListData(payloadPastMedicalHistoryList, token))
                dispatch(setCurrentCardiacHistory('past'))
                break

            default:
                return null
        }
    }
    return (
        <>
            <Grid
                container
                direction="column"
                columns={{ xs: 12, md: 12 }}
                sx={{
                    background: '#fff !important',
                    boxShadow: 'none',
                    borderRadius: '0.25rem',
                    padding: '8px 6px 8px !important',
                }}
            >
                <Grid item>
                    <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mb: 1 }}>
                        <Typography className=" cursor-default gray-900 fw-normal">
                            Cardiac History
                        </Typography>

                        <Tooltip title="Click to see Generated Letter (Timeline)">
                            <IconButton
                                aria-label="Show latest letter timeline"
                                variant="contained"
                                sx={{
                                    color: variables.purple,
                                    '&:hover, &.Mui-focusVisible': {
                                        borderRadius: '2px !important',
                                    },
                                    '&:disabled': {
                                        cursor: 'not-allowed',
                                        pointerEvents: 'all !important',
                                    },
                                }}
                                size="small"
                                onClick={() => {
                                    dispatch(toggleCardiacTimelineModal(true))
                                }}
                                disabled={!!cardiacHistoryListLoading || _.isEmpty(cardiacHistoryListData)}
                            >
                                <ViewTimelineIcon fontSize="inherit" />
                                <Typography className="cursor-default fw-bold fs-9 text-capitalize">
                                    Timeline
                                </Typography>
                            </IconButton>
                        </Tooltip>
                        {!_.isEmpty(cardiacHistoryListData) && (
                            <Tooltip title="Click to add Cardiac Diagnosis">
                                <IconButton
                                    aria-label="Add"
                                    variant="contained"
                                    sx={{ color: variables.purple }}
                                    size="small"
                                    onClick={() => {
                                        dispatch(toggleCardiacHistoryModal(true))
                                        dispatch(setCurrentCardiacHistory('cardiac'))
                                        dispatch(setCardiacHistoryModalType('Add'))
                                    }}
                                >
                                    <ControlPointIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </Grid>
                <Grid item alignItems="center">
                    <Typography
                        className="gray-600 cursor-default"
                        sx={{
                            background: variables.gray100,
                            boxShadow: 'none',
                            borderRadius: '0.25rem',
                            padding: '0px 4px 0px !important',
                        }}
                    >
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="Active" value="cardiac" />
                            <AntTab label="Past" value="past" />
                        </AntTabs>
                    </Typography>
                </Grid>
                <Grid item alignItems="center">
                    <Box>
                        {value === 'cardiac' && (
                            <>
                                <CardiacDetails detail="cardiac" />
                            </>
                        )}
                        {value === 'past' && (
                            <>
                                <CardiacDetails detail="past" />
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    currentCardiacHistory: state?.cardiacHistory?.currentCardiacHistory,
    cardiacHistoryListData: state?.cardiacHistory?.cardiacHistoryListData,
    pastCardiacHistoryListData: state?.cardiacHistory?.pastCardiacHistoryListData,
    cardiacHistoryListLoading: state?.cardiacHistory?.cardiacHistoryListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacHistoryModal: (payload) => dispatch(toggleCardiacHistoryModal(payload)),
        toggleCardiacTimelineModal: (payload) => dispatch(toggleCardiacTimelineModal(payload)),
        getCardiacHistoryListData: (payload, token) => dispatch(getCardiacHistoryListData(payload, token)),
        setCurrentCardiacHistory: (payload) => dispatch(setCurrentCardiacHistory(payload)),
        getPastCardiacHistoryListData: (payload, token) =>
            dispatch(getPastCardiacHistoryListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
