import {
    CLEAR_CARDIAC,
    GET_CARDIAC_DATA,
    GET_CARDIAC_LIST_DATA,
    RESET_CARDIAC_MODAL,
    SET_CARDIAC_LOADING,
    SET_CARDIAC_MED_LOADING,
    GET_MEASUREMENT_DATA,
    SET_MEASUREMENT_LOADING,
    CLEAR_MEASUREMENT_DATA,
    GET_OTHER_LIST_DATA,
    SET_OTHER_LOADING,
    GET_PAST_LIST_DATA,
    SET_PAST_LOADING,
    SET_CURRENT_MEDICATION,
} from './cardiacList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'
import { setAlert } from '../../alert/alert.action'
import { STATUS_CODE } from '../../../util'
import { commonErrorHandler, networkErrorHandler } from '../../../util'

const BASE_URL = process.env.REACT_APP_CARD_URL
const MEDICATION_LIST_ENDPOINT = '/medication/list'
function constructUrl(endpoint) {
    return `${BASE_URL}${endpoint}`
}

const getListData = (setLoadingType, getListType) => (payload, token) => async (dispatch) => {
    dispatch({ type: setLoadingType, payload: true })
    try {
        const response = await axios.post(constructUrl(MEDICATION_LIST_ENDPOINT), payload, {
            headers: { accessToken: token },
        })
        const { status, output, error } = response.data
        if (status === STATUS_CODE.SUCCESS) {
            dispatch({ type: getListType, payload: output })
        } else {
            commonErrorHandler(dispatch, status, error, 'Medication list')
        }
    } catch (err) {
        networkErrorHandler(dispatch, err)
    } finally {
        dispatch({ type: setLoadingType, payload: false })
    }
}

export const getCardiacListData = getListData(SET_CARDIAC_LOADING, GET_CARDIAC_LIST_DATA)
export const getOtherListData = getListData(SET_OTHER_LOADING, GET_OTHER_LIST_DATA)
export const getPastListData = getListData(SET_PAST_LOADING, GET_PAST_LIST_DATA)

export const getCardiacData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_CARDIAC_MED_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/medication/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CARDIAC_DATA,
                    payload: response.data.output,
                })

                dispatch({
                    type: SET_CARDIAC_MED_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Medication. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_CARDIAC_MED_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_CARDIAC_MED_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_CARDIAC_MED_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getMeasurementData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_MEASUREMENT_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/medication/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_MEASUREMENT_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_MEASUREMENT_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching measurement (effect) data. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_MEASUREMENT_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))

                dispatch({
                    type: SET_MEASUREMENT_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))

                dispatch({
                    type: SET_MEASUREMENT_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const setCurrentMedication = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_MEDICATION,
        payload: data,
    })
}

export const resetCardiacModal = () => (dispatch) => {
    dispatch({
        type: RESET_CARDIAC_MODAL,
    })
}

export const clearMeasurement = () => (dispatch) => {
    dispatch({
        type: CLEAR_MEASUREMENT_DATA,
    })
}

export const clearCardiac = () => (dispatch) => {
    dispatch({
        type: CLEAR_CARDIAC,
    })
}
