import React, { useEffect, useState } from 'react'
import 'chartjs-adapter-moment'
import variables from '../../../theme/variables.scss'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as ChartJS from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import classnames from 'classnames'
import annotationPlugin from 'chartjs-plugin-annotation'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
ChartJS.Chart.register(
    ChartJS.CategoryScale,
    ChartJS.LinearScale,
    ChartJS.PointElement,
    ChartJS.LineElement,
    ChartJS.Title,
    ChartJS.Tooltip,
    ChartJS.Legend,
    ChartJS.Filler,
    annotationPlugin
)

export const Index = ({ labsData, labsModalData, currentLabs, type, title, annotationRef = [] }) => {
    const [state, setState] = useState([])
    const [labels, setLabels] = useState([])
    const [currentLabData, setCurrentLabData] = useState(0)
    const [currentDate, setCurrentDate] = useState('')
    useEffect(() => {
        getInitialData(labsModalData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labsData, labsModalData, currentLabs, type])

    const getInitialData = (dataVal) => {
        if (!!dataVal) {
            const arrData = dataVal.map((data) => data?.value)
            const newLabel = dataVal.map((data) => {
                return moment.parseZone(data.date).format(DATE_FORMAT.MMM_DD_YYYY)
            })

            setLabels(newLabel)
            setState(arrData)
            setCurrentLabData(arrData?.length && arrData[arrData?.length - 1])
            setCurrentDate(newLabel?.length && newLabel[newLabel?.length - 1])
        } else {
            setLabels([])
            setState([])
            setCurrentLabData('')
            setCurrentDate('')
        }
    }

    const getParsedValue = (ctx, val1, val2) => {
        let index = ctx.dataIndex
        let value = ctx.dataset.data[index]
        return value === currentLabData && index === state.length - 1 ? val1 : val2
    }

    const options = {
        responsive: true,
        // Set the border and background colors
        borderColor: '#4F46E5',
        backgroundColor: '#4F46E5',

        // Set the tension for the line chart
        tension: 0.4,

        // Define the click handler for the chart
        onClick: (evt, element) => {
            // Check if an element was clicked
            if (element.length > 0) {
                // Get the index of the clicked element
                const ind = element[0].index

                // Get the value and label of the clicked element
                const value = data.datasets[0].data[ind]
                const label = data.labels[ind]

                // Update the current data and date
                setCurrentLabData(value)
                setCurrentDate(label)
            }
        },
        // Set the chart axes options
        scales: {
            xAxes: {
                display: true, //this will show all the x-axis grid lines,
                type: 'time',
                time: {
                    displayFormats: {
                        year: type === 'desktop' ? 'MMM YYYY' : `YY'`,
                    },
                    unit: type === 'desktop' ? 'month' : 'year',
                },
                suggestedMax: Date.now(),
                // Set the grid options
                grid: {
                    drawBorder: true,
                    display: true,
                    color: '#F3F4F6',
                },
                // Set the tick options
                ticks: {
                    beginAtZero: true,
                    color: '#4B5563',
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
            },
            yAxes: {
                // Only display the y-axis grid lines on desktop
                display: type === 'desktop',
                // Set the grid options
                grid: {
                    drawBorder: true,
                    display: true,
                    color: '#F3F4F6',
                    // borderColor: '#F3F4F6',
                },
                // Set the tick options
                ticks: {
                    beginAtZero: true,
                    color: '#000',
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
                // Always start the y-axis at zero
                beginAtZero: true,
                // For showing y-axis marking one above the max value, Callback that runs after data limits are determined
                afterDataLimits(scale) {
                    if (type === 'desktop') {
                        scale.max += 1
                        scale.min -= 0
                    }
                },
            },
        },
        // Set the chart plugin options
        plugins: {
            // Set the tooltip options
            tooltip: {
                // Do not display colors in the tooltip
                displayColors: false,
                // Set the padding and background color
                padding: 8,
                backgroundColor: '#1c1e21bd',
                // Set the tooltip corner radius
                cornerRadius: 5,
                // Set the body spacing
                bodySpacing: 10,
                // Align the tooltip title
                titleAlign: 'center',
                callbacks: {
                    label: function (ctx) {
                        const data = moment(ctx.label).format(DATE_FORMAT.MMM_DD_YYYY)
                        return data === 'Invalid date' ? '0' : data
                    },
                    title: function (ctx) {
                        const { formattedValue } = ctx[0]
                        return formattedValue
                    },
                },
            },
            legend: {
                position: 'top',
                display: false,
            },
            annotation: {
                clip: false,
                annotations: annotationRef,
            },
        },
    }

    const data = {
        labels,
        datasets: [
            {
                label: title,
                data: state,
                pointBackgroundColor:
                    type === 'desktop' ? (ctx) => getParsedValue(ctx, '#fff', '#EDECFCCC') : '#EDECFCCC',
                hoverRadius: type === 'desktop' ? 6 : 3,
                pointRadius:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, 6, 3)
                        : (ctx) => getParsedValue(ctx, 6, 3),
                pointBorderWidth: type === 'desktop' ? 1 : (ctx) => getParsedValue(ctx, 4, 1),
                pointHoverBackgroundColor: '#FFF',
                pointHoverBorderColor: type === 'desktop' ? '#4f46e552' : '#4F46E5',
                pointBorderColor:
                    type === 'desktop' ? (ctx) => getParsedValue(ctx, '#4f46e552', '#4F46E5') : '#4F46E5',
                pointHoverBorderWidth: type === 'desktop' ? 1 : 4,
                fill: true,
                backgroundColor: (context) => {
                    const { ctx } = context.chart
                    const gradient = ctx.createLinearGradient(0, 0, 0, type === 'desktop' ? 300 : 100)
                    gradient.addColorStop(0, 'rgba(79,70,229,0.2)')
                    gradient.addColorStop(1, 'rgba(255,255,255,0)')
                    return gradient
                },
            },
        ],
    }

    const chartBoxClasses = classnames('chart-box', {
        'p-2 pb-3 pt-0': type === 'desktop',
    })

    return (
        <div className={chartBoxClasses}>
            <div className="p-2 pb-0">
                <div className="d-flex justify-content-between">
                    <Stack spacing={{ xs: 0.5 }} direction="row">
                        <Typography className="fw-normal fs-9 gray-600">{title}</Typography>
                        <Typography className="fw-bold mb-0 fs-9">{currentLabData}</Typography>
                    </Stack>
                    <span className="fs-9 gray-400">
                        {type === 'desktop' ? '' : moment(currentDate).format(DATE_FORMAT.DD_MMM_YY)}
                    </span>
                </div>
            </div>
            <div className="pe-1 pt-2">
                <Line options={options} data={data} />
            </div>
        </div>
    )
}
