import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../theme/variables.scss'
import * as Chart from './Base-chart'

export const Index = ({ labsData, labsModalData, currentLabs, type }) => {
    return (
        <div>
            <Chart.Index
                title="Cr"
                labsData={labsData}
                labsModalData={labsModalData}
                currentLabs={currentLabs}
                type={type}
                annotationRef={[
                    {
                        type: 'line',
                        mode: 'horizontal',
                        yMin: 46,
                        yMax: 46,
                        borderWidth: 1,
                        borderColor: variables.purple,
                        borderDash: [1, 1],
                        label: {
                            position: 'start',
                            display: true,
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            displayColors: false,
                            color: variables.gray700,
                            content: ['46'],
                            textAlign: 'start',
                            font: {
                                size: 10,
                            },
                        },
                    },
                    {
                        type: 'line',
                        mode: 'horizontal',
                        yMin: 92,
                        yMax: 92,
                        borderWidth: 1,
                        borderColor: variables.purple,
                        borderDash: [1, 1],
                        label: {
                            position: 'start',
                            display: true,
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            displayColors: false,
                            color: variables.gray700,
                            content: ['92'],
                            textAlign: 'start',
                            font: {
                                size: 10,
                            },
                        },
                    },
                ]}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    labsData: state.labsList.labsData,
    labsModalData: state.labsList.labsModalData,
    currentLabs: state.labs.currentLabs,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
