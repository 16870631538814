import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { toggleCtModal, toggleConsultModal } from '../../../redux/modals/toggleModal.actions'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import PdfViewer from '../../pdf-viewer'
import FeedIcon from '@mui/icons-material/Feed'
import { blobToBase64, getS3File, getTrimedFilename, hasTifExtension } from '../../../util'
import { getConsultDocuments, resetConsultDocument } from '../../../redux/consultNotes/consultNotes.action'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ patientId, token, consultModal, consultDocuments, consultDocsLoading }) => {
    const [notesCategory, setNotesCategory] = React.useState('MY LETTERS')
    const [showPdf, setShowPdf] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [isTif, setIsTif] = React.useState(false)
    const [fileID, setFileID] = React.useState('')
    const [pdfLoading, setPdfLoading] = React.useState(false)
    const [isGenerated, setIsGenerated] = React.useState(false)
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        dispatch(toggleConsultModal(false))
        dispatch(resetConsultDocument())
    }

    const handleChangeCategory = (value) => {
        setShowPdf(false)
        setFileID('')
        setNotesCategory(value)
        setUrl('')

        const payload = {
            patientId: patientId,
            type: value,
        }
        dispatch(getConsultDocuments(payload, token))
    }

    const handleDocument = (report) => {
        setIsGenerated((report?.compressedKey || report?.key).indexOf('generatedletters') > -1)
        setPdfLoading(true)
        setUrl('')
        setShowPdf(true)
        setFileID(report?._id)
        setIsTif(hasTifExtension(report?.compressedKey || report?.key))
        getS3File(report?.compressedKey || report?.key)
            .then((res) => {
                blobToBase64(res?.Body).then((usablePdf) => {
                    setUrl(usablePdf)
                    setShowPdf(true)
                    setPdfLoading(false)
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                setPdfLoading(false)
            })
    }

    useEffect(() => {
        if (consultDocuments?.length > 0) {
            handleDocument(consultDocuments[0])
        }
    }, [consultDocuments])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={consultModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">
                            Consult Notes
                        </Typography>

                        <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                            Select Notes Category
                        </Typography>
                        <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={notesCategory}
                                defaultValue="Select Notes Category"
                                onChange={(e) => handleChangeCategory(e.target.value)}
                                input={<BootstrapInput />}
                            >
                                <MenuItem className="fs-9" value="MY LETTERS">
                                    My Letters
                                </MenuItem>
                                <MenuItem className="fs-9" value="CLINIC">
                                    Clinic Notes
                                </MenuItem>
                                <MenuItem className="fs-9" value="HOSPITAL">
                                    Hospital Notes
                                </MenuItem>
                                <MenuItem className="fs-9" value="OTHER">
                                    Other
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {consultDocuments?.length > 0 && consultDocsLoading === false ? (
                            <Typography className="gray-600 fs-9 mt-3">
                                {' '}
                                Found {consultDocuments?.length} Note(s)
                            </Typography>
                        ) : null}
                        <div
                            className="overflow-auto mt-2"
                            style={{
                                maxHeight: '400px',
                                backgroundColor: variables.white100,
                                borderRadius: '5px',
                                border: '1px solid',
                                borderColor: variables.lightGray1,
                                minHeight: '200px',
                                overflow: 'auto',
                            }}
                        >
                            {consultDocsLoading === false ? (
                                consultDocuments?.length > 0 ? (
                                    consultDocuments?.map((report, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                className={clsx(
                                                    fileID === report?._id && 'bg-indigo-50 fw-bold',
                                                    'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer'
                                                )}
                                            >
                                                <Box
                                                    className="cursor-pointer"
                                                    display="flex"
                                                    alignItems={'center'}
                                                    gap={1}
                                                    onClick={() => handleDocument(report)}
                                                >
                                                    <FeedIcon className="fs-8" />
                                                    {getTrimedFilename(report?.name)}
                                                </Box>
                                            </Box>
                                        )
                                    })
                                ) : (
                                    <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                )
                            ) : (
                                <div className="loader">
                                    <img src="/loader.gif" alt="loader" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-75 p-3 overflow-auto">
                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">Details</Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* pdf */}
                        {/* No Data Found Right side */}
                        {consultDocuments?.length === 0 && !showPdf && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {consultDocsLoading === true ? (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {consultDocuments?.length > 0 && !url && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {pdfLoading === true ? (
                                        <div>
                                            <img src="/loader.gif" width="35" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {!!url && !pdfLoading && !!showPdf && !consultDocsLoading && (
                            <PdfViewer isGenerated={isGenerated} url={url} isTif={isTif} />
                        )}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        consultModal: state.toggleModals.consultModal,
        consultDocuments: state.consultNotes.consultDocuments,
        consultDocsLoading: state.consultNotes.consultDocsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCtModal: (payload) => dispatch(toggleCtModal(payload)),
        toggleConsultModal: (payload) => dispatch(toggleConsultModal(payload)),
        getConsultDocuments: (payload, token) => dispatch(getConsultDocuments(payload, token)),
        resetConsultDocument: () => dispatch(resetConsultDocument()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
