import * as React from 'react'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import variables from '../../../theme/variables.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteDialog from '../../adverse-event/adverse-modal/DeleteDialog'

import {
    getFamilyListData,
    getFamilyViewData,
    deleteFamilyMember,
} from '../../../redux/familyList/familyList.action'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        backgroundColor: variables.purple,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: variables.purple100,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const FamilyTable = ({ tableData, token, patientId, eventID }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState('')

    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setDeleteId('')
    }

    const handleDelete = () => {
        const deletePayload = {
            id: deleteId,
        }

        dispatch(deleteFamilyMember(deletePayload, token))
        const currViewPayload = {
            patientId: patientId,
            type: eventID,
        }
        dispatch(getFamilyViewData(currViewPayload, token))
        const FamilyListPayload = {
            patientId: patientId,
        }
        dispatch(getFamilyListData(FamilyListPayload, token))
        setOpen(false)
        setDeleteId('')
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Relative</StyledTableCell>
                            <StyledTableCell align="left">Dx Age</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData?.map((row) => (
                            <StyledTableRow key={row?._id}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.relative ?? 'N.A'}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {_.isNil(row?.diagnosisAge) ? 'N.A' : `${row?.diagnosisAge} yr(s)`}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.status ?? 'N.A'}</StyledTableCell>
                                <StyledTableCell align="left">
                                    {' '}
                                    <IconButton aria-label="delete" onClick={() => handleClickOpen(row?._id)}>
                                        <DeleteIcon
                                            sx={{
                                                color: variables.red600,
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteDialog
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
                handleClose={handleClose}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // setAlert: (payload) => dispatch(setAlert(payload)),
        getFamilyListData: (payload, token) => dispatch(getFamilyListData(payload, token)),
        getFamilyViewData: (payload, token) => dispatch(getFamilyViewData(payload, token)),
        deleteFamilyMember: (payload, token) => dispatch(deleteFamilyMember(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyTable)
