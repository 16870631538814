import {
    GET_MEDICAL_HISTORY_LIST_DATA,
    SET_MEDICAL_HISTORY_LIST_LOADING,
    GET_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
    CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    CLEAR_MEDICAL_HISTORY_LIST_DATA,
    CLEAR_MEDICAL_HISTORY_DATA,
    SET_CURRENT_MEDICAL_HISTORY,
    DELETE_MEDICAL_HISTORY,
    GET_PAST_MEDICAL_HISTORY_LIST_DATA,
    SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
} from './medicalHistory.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const getMedicalHistoryListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_MEDICAL_HISTORY_LIST_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/diagnosis-history?patientId=${payload?.patientId}&type=${payload?.type}&status=${payload?.status}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_MEDICAL_HISTORY_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Active Medical History. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getPastMedicalHistoryListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/diagnosis-history?patientId=${payload?.patientId}&type=${payload?.type}&status=${payload?.status}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_PAST_MEDICAL_HISTORY_LIST_DATA,

                    payload: response.data.output,
                })
                dispatch({
                    type: SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Past Medical History. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_PAST_MEDICAL_HISTORY_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getMedicalHistoryDiagnosisData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
        payload: true,
    })

    axios
        .post(`${process.env.REACT_APP_CARD_URL}/diagnosis-history/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_MEDICAL_HISTORY_DIAGNOSIS_DATA,
                    payload: response.data.output,
                })

                dispatch({
                    type: SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Medical History ${
                            payload?.name ?? ''
                        }. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
                dispatch({
                    type: CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
                dispatch({
                    type: CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
                })
            } else if (response.data.status === 403) {
                dispatch({
                    type: CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
                })
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_MEDICAL_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
            })
            // console.log(err)
        })
}

export const clearMedicalHistoryDiagnosisData = () => (dispatch) => {
    dispatch({
        type: CLEAR_MEDICAL_HISTORY_DIAGNOSIS_DATA,
    })
}
export const clearMedicalHistoryListData = () => (dispatch) => {
    dispatch({
        type: CLEAR_MEDICAL_HISTORY_LIST_DATA,
    })
}
export const clearMedicalHistoryData = () => (dispatch) => {
    dispatch({
        type: CLEAR_MEDICAL_HISTORY_DATA,
    })
}

export const setCurrentMedicalHistory = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_MEDICAL_HISTORY,
        payload: data,
    })
}

export const deleteMedicalHistory = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/diagnosis-history?id=${payload?.id}&type=presentations`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_MEDICAL_HISTORY,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Presentation Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const deleteMedicalHistoryDiagnosis = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/diagnosis-history`, {
            data: payload,
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_MEDICAL_HISTORY,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Diagnosis Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
