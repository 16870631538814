import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Cardiac from '../medications/cardiac-component/cardiac'
import { toggleCardiacModal } from '../../redux/modals/toggleModal.actions'
import {
    resetCardiacModal,
    setCurrentMedication,
    getCardiacListData,
    getPastListData,
    getOtherListData,
} from '../../redux/COP/cardiacList/cardiacList.action'
import { MEDICATION_TYPE } from '../../util'

const AntTabs = styled(Tabs)({
    marginTop: '-0.625rem',
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    // lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

export const Index = ({ patientId, token, currentMedication }) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(currentMedication ?? MEDICATION_TYPE.CARDIAC)
    const [expanded, setExpanded] = React.useState(true)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    useEffect(() => {
        setValue(currentMedication)
    }, [currentMedication])

    const getInitialValue = (val) => {
        switch (val) {
            case MEDICATION_TYPE.CARDIAC:
                const payloadCardiacList = {
                    patientId: patientId,
                    type: MEDICATION_TYPE.CARDIAC,
                    status: 'active',
                }
                dispatch(getCardiacListData(payloadCardiacList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.CARDIAC))
                break
            case MEDICATION_TYPE.OTHER:
                const payloadOtherList = {
                    patientId: patientId,
                    type: MEDICATION_TYPE.OTHER,
                    status: 'active',
                }
                dispatch(getOtherListData(payloadOtherList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.OTHER))
                break
            case MEDICATION_TYPE.PAST:
                const payloadPastList = {
                    patientId: patientId,
                    status: MEDICATION_TYPE.PAST,
                }
                dispatch(getPastListData(payloadPastList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.PAST))
                break
            default:
                return null
        }
    }

    const OpenModal = (val) => {
        switch (val) {
            case MEDICATION_TYPE.CARDIAC:
                dispatch(setCurrentMedication(MEDICATION_TYPE.CARDIAC))
                dispatch(toggleCardiacModal(true))
                dispatch(resetCardiacModal())

                break
            case MEDICATION_TYPE.OTHER:
                dispatch(setCurrentMedication(MEDICATION_TYPE.OTHER))
                dispatch(toggleCardiacModal(true))
                dispatch(resetCardiacModal())
                break
            case MEDICATION_TYPE.PAST:
                dispatch(setCurrentMedication(MEDICATION_TYPE.PAST))
                dispatch(toggleCardiacModal(true))
                dispatch(resetCardiacModal())
                break
            default:
                dispatch(setCurrentMedication(MEDICATION_TYPE.CARDIAC))
                dispatch(toggleCardiacModal(true))
                dispatch(resetCardiacModal())
        }
    }

    return (
        <div>
            <Accordion className="dashboard-accordion" sx={{ boxShadow: 'none' }} expanded={expanded}>
                <AccordionSummary
                    sx={{
                        background: '#F3F4F6',
                        boxShadow: 'none',
                        borderRadius: '0.25rem',
                        borderBottomLeftRadius: !!expanded ? '0rem' : '0.25rem',
                        borderBottomRightRadius: !!expanded ? '0rem' : '0.25rem',
                        padding: '0px 2px 0px !important',
                        cursor: 'unset !important',
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}
                        />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-900 cursor-default fs-8 fw-normal"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            // textAlign: 'right',
                            fontSize: '0.85rem',
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            padding: '4px 2px 0px  !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontWeight: 600,
                        }}
                    >
                        Medication
                        <span className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={(event) => {
                                    OpenModal(value)
                                    event.stopPropagation()
                                }}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0px 0px 4px !important',
                    }}
                >
                    <Typography
                        className="gray-600 cursor-default"
                        sx={{
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            // borderRadius: '0.25rem',
                            borderBottomLeftRadius: '0.25rem',
                            borderBottomRightRadius: '0.25rem',
                            padding: '0px 2px 0px !important',
                        }}
                    >
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="Cardiac" value={MEDICATION_TYPE.CARDIAC} />
                            <AntTab label="Other" value={MEDICATION_TYPE.OTHER} />
                            <AntTab label="Past" value={MEDICATION_TYPE.PAST} />
                        </AntTabs>
                    </Typography>

                    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        {[MEDICATION_TYPE.CARDIAC, MEDICATION_TYPE.OTHER, MEDICATION_TYPE.PAST].includes(
                            value
                        ) && <Cardiac />}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    currentMedication: state.cardiacList.currentMedication,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacModal: (payload) => dispatch(toggleCardiacModal(payload)),
        resetCardiacModal: () => dispatch(resetCardiacModal()),
        getCardiacListData: (payload, token) => dispatch(getCardiacListData(payload, token)),
        getOtherListData: (payload, token) => dispatch(getOtherListData(payload, token)),
        getOtherListData: (payload, token) => dispatch(getOtherListData(payload, token)),
        setCurrentMedication: (payload) => dispatch(setCurrentMedication(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
