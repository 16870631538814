import PropTypes from 'prop-types'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { InputAdornment, TextField } from '@material-ui/core'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
    closeChangePasswordModal,
    toggleDeactivateModal,
    toggleSessionModal,
} from '../../../redux/modals/toggleModal.actions'
import axios from 'axios'
import './change-password.scss'
import { setAlert } from '../../../redux/alert/alert.action'
import { Auth } from 'aws-amplify'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2, 4),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 4),
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(2, 4),
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
    oldPassword: yup
        .string('Enter your old password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Old password is required'),
    password: yup
        .string('Enter your new password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('New Password is required'),
    confirmPassword: yup
        .string('Confirm your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
})

export const Index = ({ changePasswordModal, login, profile }) => {
    const [showPassword, setShowPassword] = React.useState(false)
    const [showOldPassword, setShowOldPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const dispatch = useDispatch()
    const handleClose = () => {
        formik.setFieldValue('oldPassword', '')
        formik.setFieldValue('password', '')
        formik.setFieldValue('confirmPassword', '')
        dispatch(closeChangePasswordModal(false))
    }

    const handleLogout = () => {
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/logout`, '', {
                headers: {
                    accessToken: login.accessToken,
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    localStorage.clear()
                    window.location.replace('/')
                } else if (response.data.status === 401) {
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                }
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    const validatePassword = (value) => {
        const isContainsUppercase = /^(?=.*[A-Z]).*$/
        const isContainsLowercase = /^(?=.*[a-z]).*$/
        const isContainsNumber = /^(?=.*[0-9]).*$/
        const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/
        if (
            !isContainsNumber.test(value) ||
            !isContainsLowercase.test(value) ||
            !isContainsSymbol.test(value) ||
            !isContainsUppercase.test(value)
        ) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg:
                        'Password must be a combination of uppercase, lowercase, special character, and number.',
                    alertOpen: true,
                })
            )
            return false
        }

        if (testSeqential(value) === false) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Password must not contain sequential character. e.g 12345, abcde.',
                    alertOpen: true,
                })
            )
            return false
        }
        const isRepatativeChar = /(.)\1\1/
        if (isRepatativeChar.test(value)) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Password must not include repetitive characters e.g. 11111 or aaaaa..',
                    alertOpen: true,
                })
            )
            return false
        }
        if (testSpecialWords(value) === false) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Password must not contain name of the website, user name or the email.',
                    alertOpen: true,
                })
            )
            return false
        }
        return true
    }

    function testSeqential(s) {
        // Check for sequential numerical characters
        for (let i in s) if (+s[+i + 1] === +s[i] + 1 && +s[+i + 2] === +s[i] + 2) return false
        // Check for sequential alphabetical characters
        for (let i in s)
            if (
                String.fromCharCode(s.charCodeAt(i) + 1) === s[+i + 1] &&
                String.fromCharCode(s.charCodeAt(i) + 2) === s[+i + 2]
            )
                return false
        return true
    }

    function testSpecialWords(s) {
        if (
            s.includes('Flow') ||
            s.includes('Cardea') ||
            s.includes('Cardeahealth') ||
            s.includes('CardeaHealth') ||
            s.includes('cardeaHealth') ||
            s.includes(profile?.firstName) ||
            s.includes(profile?.lastName) ||
            s.includes(profile?.email) ||
            s.includes(capitalize(profile?.email?.split('@')[0]))
        ) {
            return false
        } else {
            return true
        }
    }

    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1)
    }

    const handleChangePass = () => {
        setLoading(true)
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, formik.values.oldPassword, formik.values.password)
            })
            .then((data) => {
                dispatch(closeChangePasswordModal(false))
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Password updated successfully!',
                        alertOpen: true,
                    })
                )
                handleLogout()

                setLoading(false)
            })
            .catch((err) => {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: err,
                        alertOpen: true,
                    })
                )
                console.log(err)
                setLoading(false)
            })
    }

    const handleUpdatePassword = () => {
        formik.handleSubmit()
        setLoading(true)
        if (formik.values.oldPassword === formik.values.password) {
            setLoading(false)
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Old password should not be same as new password!',
                    alertOpen: true,
                })
            )
        } else if (formik.values.password !== formik.values.confirmPassword) {
            setLoading(false)
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Confirm password should be same as new password!',
                    alertOpen: true,
                })
            )
        } else {
            if (validatePassword(formik.values.password)) {
                handleChangePass()
            } else {
                setLoading(false)
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {},
    })

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="small-modal" open={changePasswordModal}>
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Update Password
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <label className="change-pass-label" htmlFor="oldPassword">
                            Old Password
                        </label>
                        <TextField
                            fullWidth
                            hiddenLabel
                            id="oldPassword"
                            name="oldPassword"
                            label="Old Password"
                            type={showOldPassword ? 'text' : 'password'} // <-- This is where the magic happens
                            variant="filled"
                            className="mb-4 login-field change-password"
                            inputProps={{
                                maxLength: 64,
                            }}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                            onMouseDown={() => setShowOldPassword(!showOldPassword)}
                                        >
                                            {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.oldPassword.trim()}
                            onChange={formik.handleChange}
                            onKeyDown={() => setShowOldPassword(false)}
                            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                        />
                        <label className="change-pass-label" htmlFor="password">
                            New Password
                        </label>
                        <TextField
                            fullWidth
                            hiddenLabel
                            id="password"
                            name="password"
                            label="New Password"
                            type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                            variant="filled"
                            className="mb-4 login-field change-password"
                            inputProps={{
                                maxLength: 64,
                            }}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                disableUnderline: true,

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.password.trim()}
                            onChange={formik.handleChange}
                            onKeyDown={() => setShowPassword(false)}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <label className="change-pass-label" htmlFor="confirmPassword">
                            Confirm Password
                        </label>
                        <TextField
                            fullWidth
                            hiddenLabel
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'} // <-- This is where the magic happens
                            variant="filled"
                            className="mb-4 login-field change-password"
                            inputProps={{
                                maxLength: 64,
                            }}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                disableUnderline: true,

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.confirmPassword.trim()}
                            onChange={formik.handleChange}
                            onKeyDown={() => setShowConfirmPassword(false)}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={
                            !(
                                formik.values.oldPassword !== '' &&
                                formik.values.password !== '' &&
                                formik.values.confirmPassword !== '' &&
                                formik.values.password?.length > 7 &&
                                formik.values.confirmPassword?.length > 7
                            )
                        }
                        onClick={handleUpdatePassword}
                        variant="contained"
                        className="btn-primary"
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        changePasswordModal: state.toggleModals.changePasswordModal,
        login: state.loginData.loginResponse,
        profile: state.toggleModals.profileData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeChangePasswordModal: (payload) => dispatch(closeChangePasswordModal(payload)),
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
