import React from 'react'
import '../interval-components/inputs.scss'
import _ from 'lodash'
import variables from '../../../../theme/variables.scss'
import Inputs from '../interval-components/Inputs'
import BoxWrapper from '../interval-components/BoxWrapper'
import ToggleButton from '@mui/material/ToggleButton'
import StackWrapper from '../interval-components/StackWrapper'
import TranscribeIcon from '@mui/icons-material/Transcribe'
import { BlinkingTranscribeIcon } from '../../../icon-wrapper'

const AdditionalNotes = ({
    title = 'Additional Notes',
    fieldName,
    fieldVal,
    isRecording,
    handleRecording,
}) => {
    return (
        <>
            <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px', mb: 4, mt: 0.5 }}>
                <StackWrapper
                    title={title ?? 'Add Details'}
                    titleWidth="fit-content"
                    titleFontSize=".9rem"
                    direction="row"
                    alignItems="center"
                    titleColor={variables.gray600}
                >
                    <ToggleButton
                        sx={{ height: 1.5, width: 1.5 }}
                        value={isRecording ? 'check' : 'uncheck'}
                        selected={isRecording}
                        onChange={(e, value) => {
                            handleRecording(value)
                        }}
                    >
                        {!isRecording ? (
                            <TranscribeIcon
                                sx={{
                                    objectFit: 'contain',
                                    p: '4px',
                                }}
                            />
                        ) : (
                            <BlinkingTranscribeIcon
                                sx={{
                                    objectFit: 'contain',
                                    p: '4px',
                                }}
                            />
                        )}
                    </ToggleButton>
                </StackWrapper>
                <Inputs
                    inputType={'textArea'}
                    inputName={fieldName}
                    inputVal={fieldVal}
                    minRow={4}
                    maxRow={4}
                    resize={false}
                />
            </BoxWrapper>
        </>
    )
}

export default AdditionalNotes
