import React from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import moment from 'moment'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleVitalModal,
} from '../../../redux/modals/toggleModal.actions'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { setAlert } from '../../../redux/alert/alert.action'
import { getVitalData, getVitalListData } from '../../../redux/vitalList/vitalList.action'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'

export const Index = ({ submit, token, patientId, vitalData, currentVital, setAlignment, alignment }) => {
    const fillValueHelper = (objectValue) => {
        if (alignment === 'Update') {
            switch (objectValue) {
                case 'BPsys':
                    return _.find(vitalData, ['name', 'bp'])?.value ?? ''
                case 'BPdia':
                    return _.find(vitalData, ['name', 'bp'])?.diaValue ?? ''
                default:
                    return _.find(vitalData, ['name', `${objectValue}`])?.value ?? ''
            }
        }
        return ''
    }

    const dispatch = useDispatch()
    let arr = []
    const [sizeType] = React.useState('cm')
    const [weightType] = React.useState('kg')
    const [currentWeight, setCurrentWeight] = React.useState(fillValueHelper('currentWeight'))
    const [dryWeight, setDryWeight] = React.useState(fillValueHelper('dryWeight'))
    const [BPsys, setBPsys] = React.useState(fillValueHelper('BPsys'))
    const [BPdia, setBPdia] = React.useState(fillValueHelper('BPdia'))
    const [hr, setHr] = React.useState(fillValueHelper('hr'))
    const [o2, setO2] = React.useState(fillValueHelper('o2'))
    const [height, setHeight] = React.useState(fillValueHelper('height'))
    const [bmi, setBmi] = React.useState(0)
    const [bsa, setBsa] = React.useState(0)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        submit.current = OnSubmit
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        handleBSABMI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, currentWeight])

    const handleBSABMI = () => {
        // console.log(currentWeight, height)
        if (weightType === 'kg' && sizeType === 'cm') {
            const bmSI = currentWeight / Math.pow(height / 100, 2)
            const bsaSI = 0.007184 * Math.pow(currentWeight, 0.425) * Math.pow(height, 0.725)
            // console.log(bmSI)
            // console.log(bsaSI)
            setBmi(bmSI)
            setBsa(bsaSI)
        } else if (weightType === 'lbs' && sizeType === 'Inch') {
            const bmUS = currentWeight / 2.205 / Math.pow(height / 39.371, 2)
            const bsaSI = 0.007184 * Math.pow(currentWeight / 2.205, 0.425) * Math.pow(height / 2.54, 0.725)
            setBmi(bmUS)
            setBsa(bsaSI)
        } else if (weightType === 'kg' && sizeType === 'Inch') {
            const bmSI = currentWeight / Math.pow(height / 39.371, 2)
            const bsaSI = 0.007184 * Math.pow(currentWeight, 0.425) * Math.pow(height / 2.54, 0.725)
            setBmi(bmSI)
            setBsa(bsaSI)
        } else if (weightType === 'lbs' && sizeType === 'cm') {
            const bmSI = currentWeight / 2.205 / Math.pow(height / 100, 2)
            const bsaSI = 0.007184 * Math.pow(currentWeight / 2.205, 0.425) * Math.pow(height, 0.725)
            // console.log(bmSI)
            setBmi(bmSI)
            setBsa(bsaSI)
        }
        // console.log(bmi, bsa)
    }

    const handleBuildPayload = (label, value, type) => {
        /* add vital id for update */
        const vitalID = _.find(vitalData, ['name', label])?.vitalId ?? ''

        if (type === null && label !== 'bp') {
            const data =
                alignment === 'Update'
                    ? {
                          name: label,
                          value: value,
                          vitalId: vitalID,
                      }
                    : {
                          name: label,
                          value: value,
                      }
            arr.push(data)
        } else if (type === null && label === 'bp') {
            const data =
                alignment === 'Update'
                    ? {
                          name: label,
                          value: BPsys,
                          diaValue: BPdia,
                          vitalId: vitalID,
                      }
                    : {
                          name: label,
                          value: BPsys,
                          diaValue: BPdia,
                      }
            arr.push(data)
        } else {
            const data =
                alignment === 'Update'
                    ? {
                          name: label,
                          value: value,
                          type: type,
                          vitalId: vitalID,
                      }
                    : {
                          name: label,
                          value: value,
                          type: type,
                      }
            arr.push(data)
        }
    }

    // const validateVitals = () => {
    //          if(BPsys !== '' && (BPsys < 20 || BPsys > 250)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"BPsys value should be within 20 - 250!",
    //             'alertOpen':true
    //           }))
    //           return false
    //          }else if(BPdia !== '' && (BPdia < 20 || BPdia > 250)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"BPdia value should be within 20 - 250!",
    //             'alertOpen':true
    //           }))
    //           return false
    //       }else if(hr !== '' && (hr < 40 || hr > 200)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"HR value should be within 40 - 200!",
    //             'alertOpen':true
    //           }))
    //           return false
    //       }else if(o2 !== '' && (o2 < 50 || o2 > 100)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"SPo2 value should be within 50 - 100!",
    //             'alertOpen':true
    //           }))
    //           return false
    //         }else if(currentWeight !== '' && (currentWeight < 2 || currentWeight > 500)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"Current Weight value should be within 2 - 500!",
    //             'alertOpen':true
    //           }))
    //           return false
    //         }else if((dryWeight !== 0 && dryWeight !== '' && currentWeight !== '') && (dryWeight < 2 || dryWeight > 500 || parseFloat(dryWeight) > parseFloat(currentWeight))){
    //           // console.log("dryWeight",dryWeight)
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"Dry Weight value should be within 2 - 500 or Less then current weight!",
    //             'alertOpen':true
    //           }))
    //           return false
    //         }else if(height !== '' && (parseFloat(height) < 45 || parseFloat(height) > 275)){
    //           dispatch(setAlert({
    //             'alertType':"error",
    //             'alertMsg':"Height value should be within 45 - 275!",
    //             'alertOpen':true
    //           }))
    //           return false
    //         }else {
    //         return true
    //       }
    // }

    const OnSubmit = () => {
        // setLoading(true)
        if (BPsys === '' && BPdia !== '') {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter BP systolic value also!',
                    alertOpen: true,
                })
            )
        } else if (BPsys !== '' && BPdia === '') {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter BP diastolic value also!',
                    alertOpen: true,
                })
            )
        } else {
            handleBuildPayload('bp', BPsys, null)
            handleBuildPayload('hr', hr, null)
            handleBuildPayload('o2', o2, null)
            handleBuildPayload('currentWeight', currentWeight, weightType)
            handleBuildPayload('dryWeight', dryWeight, weightType)
            handleBuildPayload(
                'diffWeight',
                currentWeight !== '' && dryWeight !== '' ? dryWeight - currentWeight : '',
                weightType
            )
            handleBuildPayload('height', height, sizeType)
            if (currentWeight !== 0 && currentWeight !== '' && height !== 0 && height !== '') {
                handleBuildPayload('bmi', bmi?.toFixed(2), null)
                handleBuildPayload('bsa', bsa?.toFixed(2), null)
            }
            const filterItem = arr.filter((element) => {
                if (element.value !== '') {
                    return true
                }
                return false
            })
            // console.log("filterItem", filterItem)
            if (filterItem.length === 0) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Please add minimum one value!',
                        alertOpen: true,
                    })
                )
                setLoading(false)
            } else {
                // const data = validateVitals()
                // if(data){
                processVitals(filterItem)
                // }
            }
        }
    }

    const processVitals = (filterItem) => {
        setLoading(true)
        const path = alignment === 'Update' ? 'edit' : 'add'
        const payload = {
            patientId: patientId,
            items: filterItem,
        }

        axios({
            method: alignment === 'Update' ? 'put' : 'post',
            url: `${process.env.REACT_APP_CARD_URL}/vitals/${path}`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: `Vitals ${alignment === 'Update' ? 'Updated' : 'Added'} Successfully`,
                        alertOpen: true,
                    })
                )
                const payload = {
                    patientId: patientId,
                }
                const sixtyMonth = moment().subtract(60, 'months').calendar()
                const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
                const payloadCurr = {
                    patientId: patientId,
                    type: currentVital,
                    date: proposedDate,
                }
                dispatch(getVitalData(payloadCurr, token))
                dispatch(getVitalListData(payload, token))
                dispatch(toggleVitalModal(false))
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
        })
    }

    const setRange = (e, max, fuc) => {
        var regExp = new RegExp(/^\d*\.?\d*$/)
        var isValid = regExp.test(e.target.value)

        // console.log(e.keyCode)
        // if(e.keyCode !== 69){
        // if(e.target.value <= max){
        // console.log("isValid", isValid)
        if (isValid || e.target.value === '') {
            // console.log("e.target.value.length", e.target.value.length)
            fuc(e.target.value)
        }
        // }
        // }
    }

    // const validateInput = (el, min, max, name) => {
    //   if(el.target.value !== ""){
    //     if(el.value >= min || el.value <= max){
    //       setInputState(name, val);
    //     }
    //     if(el.value > max){
    //       el.value = el.max;
    //     }
    //   }
    // }

    // const setInputState = (name, val) => {
    //    switch (name) {
    //      case 'BPsys':
    //      setBPsys(val)

    //        break;

    //      default:
    //        break;
    //    }
    // }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                    {/* bp start */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        BP
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={BPsys}
                            min="10"
                            max="290"
                            onChange={(e) => setRange(e, 250, setBPsys)}
                            //  onChange={(e) => setBPsys(e.target.value)}
                            placeholder="sys"
                            className="small-rounded"
                            type="text"
                        />
                        <input
                            value={BPdia}
                            min="10"
                            max="290"
                            // onKeyUp={(e) =>validateInput(e, 10, 290, 'BPsys')}
                            // onChange={(e) => setBPdia(e.target.value)}
                            // onKeyDown={return (event.keyCode !== 69)}
                            onChange={(e) => setRange(e, 250, setBPdia)}
                            placeholder="dia"
                            className="small-rounded ms-1"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 cursor-default fw-normal">mmHg</span>
                    </div>
                    {/* bp end */}
                    {/* hr start */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        HR
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={hr}
                            // onChange={(e) => setHr(e.target.value)}
                            onChange={(e) => setRange(e, 200, setHr)}
                            className="medium-rounded"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 cursor-default fw-normal">bpm</span>
                    </div>
                    {/* hr end */}
                    {/* spo2 start */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        SpO2
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={o2}
                            // onChange={(e) => setO2(e.target.value)}
                            onChange={(e) => setRange(e, 100, setO2)}
                            className="medium-rounded"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 cursor-default fw-normal">%</span>
                    </div>
                    {/* spo2 end */}
                </Grid>
                <Grid className="ps-4" item xs={6} md={4}>
                    {/* current weight start  */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        Current Weight
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={currentWeight}
                            onChange={(e) => {
                                setRange(e, 500, setCurrentWeight)
                            }}
                            className="medium-rounded"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 cursor-default fw-normal">kg</span>
                    </div>
                    {/* current weight End  */}
                    {/* dry weight start  */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        Dry Weight
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={dryWeight}
                            // onChange={(e) => setDryWeight(e.target.value)}
                            onChange={(e) => setRange(e, 500, setDryWeight)}
                            className="medium-rounded"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 cursor-default fw-normal">kg</span>
                    </div>
                    {/* dry weight end  */}

                    {/* weight diff start  */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        Difference in Weight
                    </Typography>
                    <div className="d-flex">
                        <input
                            disabled={true}
                            defaultValue={dryWeight - currentWeight}
                            value={currentWeight === '' || dryWeight === '' ? '' : dryWeight - currentWeight}
                            className="medium-rounded cursor-not-allowed"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 fw-normal cursor-default">kg</span>
                    </div>
                    {/* weight diff end  */}
                </Grid>
                <Grid item xs={6} md={4}>
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        Height
                    </Typography>
                    <div className="d-flex">
                        <input
                            value={height}
                            onChange={(e) => setRange(e, 275, setHeight)}
                            className="medium-rounded"
                            type="text"
                        />
                        <span className="name-chip ms-1 gray-900 fw-normal cursor-default">cm</span>
                    </div>
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        BMI
                    </Typography>
                    {!isNaN(bmi) ? (
                        isFinite(bmi) && currentWeight !== '' && height !== '' ? (
                            <span className="cursor-not-allowed name-chip ms-1  pe-2 ps-2 pt-1 pb-1 gray-900 fw-normal">
                                {' '}
                                {bmi.toFixed(2)}{' '}
                            </span>
                        ) : (
                            <span className="cursor-not-allowed name-chip ms-1  pe-4 ps-4 pt-1 pb-1 gray-900 fw-normal">
                                {' '}
                            </span>
                        )
                    ) : (
                        <span className="cursor-not-allowed name-chip ms-1  pe-4 ps-4 pt-1 pb-1 gray-900 fw-normal">
                            {' '}
                        </span>
                    )}
                    {/* <Tooltip className='ms-2 cursor-pointer' title="BMI details"> */}
                    {/* <img className='ms-2 ' src="/info-icon.svg" alt="info" /> */}
                    {/* </Tooltip> */}
                    <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                        BSA
                    </Typography>
                    {bsa === 0 ? (
                        <span className="name-chip cursor-not-allowed ms-1 pe-4 ps-4 pt-1 pb-1 gray-900 fw-normal">
                            {' '}
                        </span>
                    ) : (
                        <span className="name-chip cursor-not-allowed ms-1 pe-2 ps-2 pt-1 pb-1 gray-900 fw-normal">
                            {bsa.toFixed(2)}
                        </span>
                    )}
                    {/* <Tooltip className='ms-2 cursor-pointer' title="BSA details"> */}
                    {/* <img className='ms-2 ' src="/info-icon.svg" alt="info" /> */}
                    {/* </Tooltip> */}
                </Grid>
            </Grid>
            <DialogActions>
                <Button
                    onClick={() => setAlignment('Details')}
                    variant="contained"
                    className="btn-white-outline"
                    type="submit"
                >
                    Cancel
                </Button>
                <Button
                    disabled={
                        loading ||
                        !(
                            BPsys !== '' ||
                            BPdia !== '' ||
                            hr !== '' ||
                            o2 !== '' ||
                            currentWeight !== '' ||
                            dryWeight !== '' ||
                            height !== ''
                        )
                    }
                    onClick={() => OnSubmit()}
                    //  onClick={handleOpenSignIn}
                    variant="contained"
                    className="btn-primary"
                >
                    Save
                </Button>
            </DialogActions>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        vitalData: state.vitalList.list,
        currentVital: state.vital.currentVital,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        toggleVitalModal: (payload) => dispatch(toggleVitalModal(payload)),
        getVitalData: (payload, token) => dispatch(getVitalData(payload, token)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
