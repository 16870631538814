import React from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { toggleNuclearModal } from '../../redux/modals/toggleModal.actions'
import { getNuclearDocument } from '../../redux/ESN/nuclearList/nuclearList.action'

export const Index = ({ nuclearDocuments, nuclearLoading, nuclearLUD, patientId, token }) => {
    const dispatch = useDispatch()
    const [fileName, setFileName] = React.useState('')

    return (
        <div>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item>
                    <img
                        style={{
                            width: '148px',
                            height: '104px',
                        }}
                        src="/images/pdf-icon.svg"
                        alt="pdf icon"
                    />
                </Grid>
                <Grid item>
                    <Typography className="gray-900 fw-normal fs-8 mt-3">Nuclear Report</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        className="btn-secondary mt-1"
                        onClick={() => {
                            const sixtyMonth = moment().subtract(60, 'months').calendar()
                            const proposedDate = moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
                            const payload = {
                                patientId: patientId,
                                date: proposedDate,
                            }
                            dispatch(getNuclearDocument(payload, token))
                            dispatch(toggleNuclearModal(true))
                        }}
                    >
                        View Report
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    nuclearLUD: state.nuclear.lud,
    nuclearDocuments: state.nuclear.nuclearDocuments,
    nuclearLoading: state.nuclear.nuclearLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNuclearModal: (payload) => dispatch(toggleNuclearModal(payload)),
        getNuclearDocument: (payload, token) => dispatch(getNuclearDocument(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
