import {
    CLEAR_ECGS,
    ECGS_DOC_LOADING,
    GET_ECGS_DATA,
    GET_ECGS_DOCUMENT,
    GET_ECGS_LIST_DATA,
    RESET_ECG_MODAL,
    SET_ECG_LOADING,
} from './ecgList.type'

const INITIAL_STATE = {
    list: [],
    ecgData: [],
    ecgLoading: false,
    lud: '',
    ecgDocuments: {
        list: [],
        lastRefreshDate: '',
    },
    ecgDocLoading: false,
}

const ecgListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ECGS_LIST_DATA:
            return {
                ...state,
                list: action?.payload?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }
        case GET_ECGS_DATA:
            return {
                ...state,
                ecgData: action?.payload,
            }
        case GET_ECGS_DOCUMENT:
            return {
                ...state,
                ecgDocuments: action?.payload,
            }
        case SET_ECG_LOADING:
            return {
                ...state,
                ecgLoading: action.payload,
            }
        case ECGS_DOC_LOADING:
            return {
                ...state,
                ecgDocLoading: action.payload,
            }
        case RESET_ECG_MODAL:
            return {
                ...state,
                ecgDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
            }
        case CLEAR_ECGS:
            return {
                list: [],
                ecgData: [],
                ecgLoading: false,
                lud: '',
                ecgDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
            }
        default:
            return state
    }
}

export default ecgListReducer
