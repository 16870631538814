import React, { useEffect } from 'react'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import { setCurrentVital } from '../../../redux/vitals/vitals.action'
import './action-pills.scss'
import { getVitalData } from '../../../redux/vitalList/vitalList.action'
import moment from 'moment'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import _ from 'lodash'

export const Index = ({
    currentVital,
    vital,
    patientId,
    token,
    vitalLoading,
    time,
    name,
    consultLetterFlag = false,
    patient,
}) => {
    const dispatch = useDispatch()
    const handleCurrentVital = (current) => {
        updateGraphs(current)
        dispatch(setCurrentVital(current))
    }

    const search = (nameKey, myArray) => {
        let temp = ''
        for (var i = 0; i < myArray.length; i++) {
            if (nameKey === 'BPdia') {
                if (myArray[i].name === 'bp') {
                    temp = myArray[i].diaValue
                    return myArray[i].diaValue
                }
            } else {
                if (myArray[i].name === nameKey) {
                    temp = myArray[i].value
                    return myArray[i].value
                }
            }
        }
        if (temp === '') {
            return ''
        }
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getVitalData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getVitalData(payload, token))
        }
    }

    useEffect(() => {
        time !== '' && updateGraphs(currentVital)
    }, [time])

    function searchType(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i].type
            }
        }
    }

    function searchFlag(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i].flag
            }
        }
    }
    /*  style classes for absolute ranges  */
    const pillStyle = (range) => {
        switch (range) {
            case 'mild':
                return 'mild action-pills'
            case 'moderate':
                return 'moderate action-pills'
            case 'severe':
                return 'severe action-pills'
            default:
                return 'action-pills'
        }
    }

    /* border and shading style function based on absolute range calculation */
    const pillRangeStyle = (pillName) => {
        const pillValue = search(pillName, vital.list)
        if (_.isNil(pillValue) || pillValue === '') return pillStyle()
        switch (pillName) {
            case 'hr':
                if (_.inRange(pillValue, 81, 90)) return pillStyle('mild')
                if (_.inRange(pillValue, 90, 125)) return pillStyle('moderate')
                if (pillValue >= 125) return pillStyle('severe')
                return pillStyle()

            case 'o2':
                if (_.inRange(pillValue, 92, 95)) return pillStyle('mild')
                if (_.inRange(pillValue, 88, 92)) return pillStyle('moderate')
                if (_.inRange(pillValue, 1, 88)) return pillStyle('severe')
                return pillStyle()

            case 'bmi':
                if (pillValue > 25 && pillValue <= 30) return pillStyle('mild')
                if (pillValue > 30 && pillValue <= 35) return pillStyle('moderate')
                if (pillValue > 35) return pillStyle('severe')
                return pillStyle()

            case 'bsa':
                if (
                    ((pillValue < 1.53 || pillValue > 2.0) && patient?.gender === 1) ||
                    ((pillValue < 1.41 || pillValue > 1.87) && patient?.gender === 2)
                )
                    return pillStyle('mild')
                return pillStyle()

            case 'bp':
                if (
                    (pillValue >= 140 && pillValue < 160) ||
                    (search('BPdia', vital.list) >= 85 && search('BPdia', vital.list) < 90)
                )
                    return pillStyle('mild')
                if (
                    (pillValue >= 161 && pillValue < 180) ||
                    (search('BPdia', vital.list) >= 90 && search('BPdia', vital.list) <= 100)
                )
                    return pillStyle('moderate')
                if (pillValue >= 180 && search('BPdia', vital.list) > 100) return pillStyle('severe')
                return pillStyle()

            default:
                return pillStyle()
        }
    }
    /* pill shading flag based on % worsening sent by backend */
    const pillflagStyle = (pillName) => {
        const pillFlag = searchFlag(pillName, vital.list)
        if (['hr', 'o2', 'bmi', 'bsa', 'bp'].includes(pillName)) {
            return `flag-${pillFlag}`
        } else {
            return ''
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={3.5}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('bp')
                        }}
                        className={clsx(
                            currentVital === 'bp' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('bp'),
                            pillflagStyle('bp')
                        )}
                    >
                        BP {search('bp', vital.list) !== '' && search('bp', vital.list)}{' '}
                        {search('bp', vital.list) !== '' ? '/' : ''}{' '}
                        {search('BPdia', vital.list) !== '' && search('BPdia', vital.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('hr')
                        }}
                        className={clsx(
                            currentVital === 'hr' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('hr'),
                            pillflagStyle('hr')
                        )}
                    >
                        HR {search('hr', vital.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('o2')
                        }}
                        className={clsx(
                            currentVital === 'o2' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('o2'),
                            pillflagStyle('o2')
                        )}
                    >
                        <span>
                            O<sub>2</sub> {search('o2', vital.list)}
                            {search('o2', vital.list) === '' ? null : '%'}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('currentWeight')
                        }}
                        className={clsx(
                            currentVital === 'currentWeight' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Curr Wt {search('currentWeight', vital.list)}
                        {searchType('currentWeight', vital.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('dryWeight')
                        }}
                        className={clsx(
                            currentVital === 'dryWeight' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Dry Wt {search('dryWeight', vital.list)}
                        {searchType('dryWeight', vital.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('diffWeight')
                        }}
                        className={clsx(
                            currentVital === 'diffWeight' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Diff in Wt {search('diffWeight', vital.list)}
                        {searchType('diffWeight', vital.list)}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('height')
                        }}
                        className={clsx(
                            currentVital === 'height' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Ht {search('height', vital.list)}
                        {searchType('height', vital.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('bmi')
                        }}
                        className={clsx(
                            currentVital === 'bmi' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('bmi'),
                            pillflagStyle('bmi')
                        )}
                    >
                        BMI {search('bmi', vital.list) !== '' ? search('bmi', vital.list).toFixed(2) : ''}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentVital('bsa')
                        }}
                        className={clsx(
                            currentVital === 'bsa' && !consultLetterFlag ? 'active' : null,
                            pillRangeStyle('bsa'),
                            pillflagStyle('bsa')
                        )}
                    >
                        BSA {search('bsa', vital.list) !== '' ? search('bsa', vital.list).toFixed(2) : ''}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVital: state.vital.currentVital,
    vital: state.vitalList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    vitalLoading: state.vitalList.vitalLoading,
    patient: state.patient.patientData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentVital: (payload) => dispatch(setCurrentVital(payload)),
        getVitalData: (payload, token) => dispatch(getVitalData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
