import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import '../../components/interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../theme/variables.scss'
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FlowTabs from './internal-popup/FlowTabs'
import FlowTable from './internal-popup/FlowTable'

import { toggleEligibilityCriteriaModal } from '../../redux/modals/toggleModal.actions'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const EligibilityCriteriaModal = ({ eligibilityCriteriaModal, eligibilityCriteriaDetails }) => {
    const [tabValue, setTabValue] = React.useState('inc')
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        dispatch(toggleEligibilityCriteriaModal(false))
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="lg"
                fullWidth={true}
                open={!!eligibilityCriteriaModal}
                PaperProps={{
                    style: {
                        height: '90%',
                    },
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className="fs-7 gray-600 fw-light">Trial Critera</Typography>
                    <Typography className="fs-6 fw-bold text-uppercase">
                        {eligibilityCriteriaDetails?.name}
                    </Typography>
                </BootstrapDialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        padding: '0 !important',
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            px: { xs: 2, sm: 4 },

                            width: '100%',
                        }}
                    >
                        <FlowTabs
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            incValue={eligibilityCriteriaDetails?.inclusionNo}
                            excValue={eligibilityCriteriaDetails?.exclusionNo}
                        />
                        <Box sx={{ py: 2 }}>
                            <FlowTable
                                inCriteria={eligibilityCriteriaDetails?.inclusionCriteria}
                                exCriteria={eligibilityCriteriaDetails?.exclusionCriteria}
                                tabValue={tabValue}
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eligibilityCriteriaModal: state?.toggleModals?.eligibilityCriteriaModal,
        eligibilityCriteriaDetails: state.screenFlowPortalClinicalTrial?.eligibilityCriteriaDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleEligibilityCriteriaModal: (payload) => dispatch(toggleEligibilityCriteriaModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityCriteriaModal)
