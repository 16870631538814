import { CLEAR_CT, CT_DOC_LOADING, GET_CT_DOCUMENT, RESET_CT_MODAL } from './CTList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'

export const getCtDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: CT_DOC_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/ct/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CT_DOCUMENT,
                    payload: response.data.output,
                })
                dispatch({
                    type: CT_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: CT_DOC_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: CT_DOC_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
export const resetCtDocument = () => (dispatch) => {
    dispatch({
        type: RESET_CT_MODAL,
    })
}

export const clearCt = () => async (dispatch) => {
    dispatch({
        type: CLEAR_CT,
        payload: true,
    })
}
