import {
    CLEAR_TRIAL,
    SET_CURRENT_TRIAL,
    GET_TRIAL_LIST_DATA,
    SET_TRIAL_LIST_LOADING,
    GET_TRIAL_CRITERIA_DATA,
    SET_TRIAL_CRITERIA_LOADING,
} from './screenFlow.type'

const INITIAL_STATE = {
    currentTrial: null,
    trialList: [],
    lud: '',
    trialCriteria: [],
    trialListLoading: false,
    trialCriteriaLoading: false,
}

const getLastUpdatedDate = (payload) =>
    payload?.hasOwnProperty('lastUpdatedDate') ? payload?.lastUpdatedDate : ''

const screenFlowListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_TRIAL:
            return {
                ...INITIAL_STATE,
            }

        case SET_CURRENT_TRIAL:
            return {
                ...state,
                currentTrial: action?.payload,
            }

        case GET_TRIAL_LIST_DATA:
            return {
                ...state,
                trialList: action?.payload,
                lud: getLastUpdatedDate(action.payload),
            }

        case GET_TRIAL_CRITERIA_DATA:
            return {
                ...state,
                trialCriteria: action?.payload,
            }

        case SET_TRIAL_LIST_LOADING:
            return {
                ...state,
                trialListLoading: action.payload,
            }

        case SET_TRIAL_CRITERIA_LOADING:
            return {
                ...state,
                trialCriteriaLoading: action.payload,
            }

        default:
            return state
    }
}

export default screenFlowListReducer
