import * as React from 'react'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import DeleteDialog from './DeleteDialog'
import {
    getAdverseEventSubstanceData,
    deleteAdverseEvent,
} from '../../../redux/adverseEvent/adverseEvent.action'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: variables.purple,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textTransform: 'capitalize',
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: variables.purple100,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const AdverseEventTable = ({ tableData, token, patientId, eventID, currentAdverseEvent }) => {
    const [open, setOpen] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState('')

    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setDeleteId('')
    }
    const dispatch = useDispatch()

    const handleDelete = () => {
        const deletePayload = {
            id: deleteId,
        }

        dispatch(deleteAdverseEvent(deletePayload, token))

        const payload = {
            patientId: patientId,
            type: currentAdverseEvent ?? 'allergy',
            substance: tableData?.substance,
        }
        dispatch(getAdverseEventSubstanceData(payload, token))
        setOpen(false)
        setDeleteId('')
    }

    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 450,
                        marginBottom: 0.5,
                    }}
                    size="small"
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                            >
                                {tableData?.substance ?? 'Allergy/Intolerance'}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {tableData?.reaction}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {!!tableData?.diagnoseDate
                                    ? `${moment(tableData?.diagnoseDate).format(DATE_FORMAT.MMM_YYYY)}`
                                    : ''}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {tableData?.treatments}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {tableData?.severity}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            {_.isEmpty(tableData?.presentations) ? null : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Presentation</StyledTableCell>
                                <StyledTableCell align="left">Date</StyledTableCell>
                                <StyledTableCell align="left">Treatment</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData?.presentations?.map((row) => (
                                <StyledTableRow key={row?._id}>
                                    <StyledTableCell component="th" scope="row">
                                        {_.isEmpty(row?.name) ? 'N.A' : row?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {!!row?.date
                                            ? `${moment(row?.date).format(DATE_FORMAT.MMM_DD_YYYY)}`
                                            : 'N.A'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {_.isEmpty(row?.treatment) ? 'N.A' : row?.treatment}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {' '}
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleClickOpen(row?._id)}
                                        >
                                            <DeleteIcon
                                                sx={{
                                                    color: variables.red600,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <DeleteDialog
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
                handleClose={handleClose}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // setAlert: (payload) => dispatch(setAlert(payload)),
        getAdverseEventSubstanceData: (payload, token) =>
            dispatch(getAdverseEventSubstanceData(payload, token)),
        deleteAdverseEvent: (payload, token) => dispatch(deleteAdverseEvent(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdverseEventTable)
