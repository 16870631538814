import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { toggleGxtModal } from '../../../redux/modals/toggleModal.actions'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import PdfViewer from '../../pdf-viewer'
import { getLabsData, getLabsReportCategories, getLabsReports } from '../../../redux/labsList/labsList.action'
import FeedIcon from '@mui/icons-material/Feed'
import {
    getGxtListData,
    getGxtData,
    getGxtDocument,
    resetGxtDocument,
} from '../../../redux/ECH/gxtList/gxtList.action'
import {
    blobToBase64,
    getS3File,
    getTrimedFilename,
    dispatchResetActionWrapper,
    hasTifExtension,
} from '../../../util'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import GxtChart from '../gxt-charts'
import GxtPills from '../gxt-pills'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ gxtModal, gxtDocuments, currentGxt, gxtLUD, patientId, loading, token }) => {
    const [alignment, setAlignment] = React.useState('Details')
    const [time, setTime] = React.useState('60')
    const [showPdf, setShowPdf] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [isTif, setIsTif] = React.useState(false)
    const [fileName, setFileName] = React.useState('')
    const [detailsTime, setDetailsTime] = React.useState('60')
    const [fileID, setFileID] = React.useState('')
    const [pdfLoading, setPdfLoading] = React.useState(false)

    const dispatch = useDispatch()

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        {
            dispatchResetActionWrapper(
                detailsTime,
                currentGxt,
                patientId,
                token,
                dispatch,
                toggleGxtModal,
                getGxtData,
                resetGxtDocument
            )
        }
    }

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            if (newAlignment === 'Details') {
                setShowPdf(false)
                setUrl('')
            }
            setAlignment(newAlignment)
        }
    }

    const handleChangeTime = (value) => {
        setShowPdf(false)
        setFileID('')
        setTime(value)

        if (value !== 'lastvisit') {
            setShowPdf(false)
            setFileID('')
            setUrl('')
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                date: proposedDate,
            }
            dispatch(getGxtDocument(payload, token))
        } else {
            const payload = {
                patientId: patientId,
                date: value,
            }
            dispatch(getGxtDocument(payload, token))
        }
    }

    /* handle doc */
    const handleDocument = (report) => {
        setPdfLoading(true)
        setUrl('')
        setShowPdf(true)
        setFileID(report?._id)
        setIsTif(hasTifExtension(report?.compressedKey || report?.key))
        getS3File(report?.compressedKey || report?.key)
            .then((res) => {
                blobToBase64(res?.Body).then((usablePdf) => {
                    setUrl(usablePdf)
                    setShowPdf(true)
                    setPdfLoading(false)
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                setPdfLoading(false)
            })
    }

    const handleChangeDetailsTime = (value) => {
        setShowPdf(false)
        setFileID('')
        setDetailsTime(value)
        if (value !== 'lastvisit') {
            const Months = moment().subtract(value, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: currentGxt,
                date: proposedDate,
            }
            dispatch(getGxtData(payload, token))
        } else {
            const payload = {
                patientId: patientId,
                type: currentGxt,
                date: value,
            }
            dispatch(getGxtData(payload, token))
        }
    }

    useEffect(() => {
        handleChangeDetailsTime(detailsTime)
        handleChangeTime(time)
    }, [currentGxt])

    /* Load inital report pdf */
    useEffect(() => {
        if (gxtDocuments?.length > 0 && alignment === 'Reports') {
            handleDocument(gxtDocuments[0])
        }
    }, [gxtDocuments, alignment])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={gxtModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">GXT</Typography>
                        <Typography className="gray-600 fs-9" gutterBottom>
                            {gxtLUD !== ''
                                ? `Last refreshed ${moment.parseZone(gxtLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
                                : null}
                        </Typography>
                        <ToggleButtonGroup
                            color="primary"
                            className="vital-switch-btn mt-3"
                            value={alignment}
                            size="large"
                            exclusive
                            onChange={handleChange}
                            fullWidth
                        >
                            <ToggleButton
                                sx={{ textTransform: 'capitalize', width: '100%!important' }}
                                value="Details"
                            >
                                Details
                            </ToggleButton>
                            <ToggleButton
                                sx={{ textTransform: 'capitalize', width: '100%!important' }}
                                value="Reports"
                            >
                                Reports
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography className="gray-600 fs-9 mt-3" gutterBottom>
                            Timeline
                        </Typography>
                        <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                            {alignment === 'Reports' && (
                                <>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={time}
                                        defaultValue="Select Timeline"
                                        onChange={(e) => handleChangeTime(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="lastvisit">
                                            Since Last Visit
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="1">
                                            Last 1 Month
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="6">
                                            Last 6 Months
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="12">
                                            Last 1 Year
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="60">
                                            Last 5 Years
                                        </MenuItem>
                                    </Select>
                                </>
                            )}
                            {/* Details */}
                            {alignment === 'Details' && (
                                <>
                                    <Select
                                        labelId="demo-customized-select-label0"
                                        id="demo-customized-select0"
                                        value={detailsTime}
                                        defaultValue="Select Timeline"
                                        onChange={(e) => handleChangeDetailsTime(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem className="fs-9" value="lastvisit">
                                            Since Last Visit
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="1">
                                            Last 1 Month
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="6">
                                            Last 6 Months
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="12">
                                            Last 1 Year
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="60">
                                            Last 5 Years
                                        </MenuItem>
                                    </Select>
                                </>
                            )}
                            {/*  */}
                        </FormControl>

                        {alignment === 'Reports' && (
                            <>
                                {gxtDocuments?.length > 0 && loading === false ? (
                                    <Typography className="gray-600 fs-9 mt-3">
                                        {' '}
                                        Found {gxtDocuments?.length} result(s)
                                    </Typography>
                                ) : null}
                                <div
                                    className="overflow-auto mt-2"
                                    style={{
                                        maxHeight: '160px',
                                        backgroundColor: variables.white100,
                                        borderRadius: '5px',
                                        border: '1px solid',
                                        borderColor: variables.lightGray1,
                                        minHeight: '100px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {loading === false ? (
                                        gxtDocuments?.length > 0 ? (
                                            gxtDocuments?.map((report, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        className={clsx(
                                                            fileID === report?._id && 'bg-indigo-50 fw-bold',
                                                            'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer'
                                                        )}
                                                    >
                                                        <Box
                                                            className="cursor-pointer"
                                                            display="flex"
                                                            alignItems={'center'}
                                                            gap={1}
                                                            onClick={() => handleDocument(report)}
                                                        >
                                                            <FeedIcon className="fs-8" />
                                                            {getTrimedFilename(report?.name)}
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        ) : (
                                            <p className="mt-3 text-center p-1 fs-8 gray-900">
                                                No records found
                                            </p>
                                        )
                                    ) : (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="w-75 p-3 overflow-auto">
                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">
                            {alignment === 'Details'
                                ? currentGxt ?? 'NA'
                                : alignment === 'Reports'
                                ? 'Report'
                                : 'NA'}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {/* details pills */}
                        {alignment === 'Details' && (
                            <>
                                <GxtChart type="desktop" />
                                <div className="p-1 ps-0 pe-0">
                                    <p
                                        className="color-primary fw-bold"
                                        style={{ marginBottom: '5px', marginTop: '8px' }}
                                    >
                                        Select GXT
                                    </p>
                                    <GxtPills name="Modal-pills" time={detailsTime} />
                                </div>
                            </>
                        )}

                        {/* pdf */}
                        {/* No Data Found Right side */}
                        {alignment === 'Reports' && gxtDocuments?.length === 0 && !showPdf && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {loading === true ? (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {alignment === 'Reports' && gxtDocuments?.length > 0 && !url && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: '1px solid',
                                    borderColor: variables.gray200,
                                    borderRadius: '5px',
                                    height: '550px',
                                }}
                            >
                                <div>
                                    {pdfLoading === true ? (
                                        <div>
                                            <img src="/loader.gif" width="35" alt="loader" />
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {alignment === 'Reports' && !!showPdf && !!url && !pdfLoading && (
                            <PdfViewer url={url} isTif={isTif} />
                        )}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        gxtModal: state.toggleModals.gxtModal,
        currentGxt: state.gxt.currentGxt,
        gxtDocuments: state.gxtList.gxtDocuments.list,
        loading: state.gxtList.gxtDocLoading,
        gxtLUD: state.gxtList.gxtDocuments.lastRefreshDate,
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleGxtModal: (payload) => dispatch(toggleGxtModal(payload)),
        getGxtListData: (payload, token) => dispatch(getGxtListData(payload, token)),
        getLabsReportCategories: (payload, token) => dispatch(getLabsReportCategories(payload, token)),
        getLabsReports: (payload, token) => dispatch(getLabsReports(payload, token)),
        resetGxtDocument: () => dispatch(resetGxtDocument()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
