import {
    CLEAR_LAB_REPORTS,
    CLEAR_LABS,
    GET_LABS_DATA,
    GET_LABS_LIST_DATA,
    GET_LABS_MODAL_CHART_LODING,
    GET_LABS_MODAL_DATA,
    SET_LABS_CATEGORY,
    SET_LABS_CATEGORY_LOADING,
    SET_LABS_LOADING,
    SET_LABS_REPORTS,
    SET_LABS_REPORTS_LOADING,
} from './labsList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'

export const getLabsListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_LABS_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/labs/list`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_LABS_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getLabsData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_LABS_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/labs/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_LABS_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_LABS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}
export const getModalLabsData = (payload, token) => async (dispatch) => {
    dispatch({
        type: GET_LABS_MODAL_CHART_LODING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/labs/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_LABS_MODAL_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: GET_LABS_MODAL_CHART_LODING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: GET_LABS_MODAL_CHART_LODING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: GET_LABS_MODAL_CHART_LODING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getLabsReportCategories = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_LABS_CATEGORY_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/labs/list-subCategory`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: SET_LABS_CATEGORY,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_LABS_CATEGORY_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_LABS_CATEGORY_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_LABS_CATEGORY_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const getLabsReports = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_LABS_REPORTS_LOADING,
        payload: true,
    })
    axios
        .post(`${process.env.REACT_APP_CARD_URL}/labs/list-documents`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: SET_LABS_REPORTS,
                    payload: response.data.output.list,
                })
                dispatch({
                    type: SET_LABS_REPORTS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_LABS_REPORTS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_LABS_REPORTS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const clearLabReports = () => (dispatch) => {
    dispatch({
        type: CLEAR_LAB_REPORTS,
    })
}
export const clearLabs = () => (dispatch) => {
    dispatch({
        type: CLEAR_LABS,
    })
}
