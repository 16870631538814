import {
    GET_CARDIAC_HISTORY_LIST_DATA,
    SET_CARDIAC_HISTORY_LIST_LOADING,
    GET_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
    CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    CLEAR_CARDIAC_HISTORY_LIST_DATA,
    CLEAR_CARDIAC_HISTORY_DATA,
    SET_CURRENT_CARDIAC_HISTORY,
    DELETE_CARDIAC_HISTORY,
    SET_CURRENT_SUB_DIAGNOSIS,
    GET_PAST_CARDIAC_HISTORY_LIST_DATA,
    SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
    GET_PAST_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    SET_PAST_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
    SET_CARDIAC_HISTORY_MODAL_TYPE,
} from './cardiacHistory.type'

const INITIAL_STATE = {
    cardiacHistoryListData: [],
    cardiacHistoryListLoading: false,
    cardiacHistoryDiagnosisData: [],
    cardiacHistoryDiagnosisLoading: false,
    currentCardiacHistory: 'cardiac',
    currentSubDiagnosis: '',
    pastCardiacHistoryListData: [],
    pastCardiacHistoryListLoading: false,
    pastCardiacHistoryDiagnosisData: [],
    pastCardiacHistoryDiagnosisLoading: false,
    cardiacHistoryModalType: 'Details',
}

const cardiacHistoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CARDIAC_HISTORY_LIST_DATA:
            return {
                ...state,
                cardiacHistoryListData: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
            }
        case GET_CARDIAC_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                cardiacHistoryDiagnosisData: action?.payload,
            }

        case SET_CARDIAC_HISTORY_LIST_LOADING:
            return {
                ...state,
                cardiacHistoryListLoading: action.payload,
            }
        case SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING:
            return {
                ...state,
                cardiacHistoryDiagnosisLoading: action.payload,
            }

        case SET_CURRENT_CARDIAC_HISTORY:
            return {
                ...state,
                currentCardiacHistory: action?.payload,
            }

        case SET_CURRENT_SUB_DIAGNOSIS:
            return {
                ...state,
                currentSubDiagnosis: action?.payload,
            }
        case SET_CARDIAC_HISTORY_MODAL_TYPE:
            return {
                ...state,
                cardiacHistoryModalType: action?.payload,
            }
        case CLEAR_CARDIAC_HISTORY_LIST_DATA:
            return {
                ...state,
                cardiacHistoryListData: [],
            }
        case CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                cardiacHistoryDiagnosisData: [],
            }
        case CLEAR_CARDIAC_HISTORY_DATA:
            return {
                cardiacHistoryListData: [],
                cardiacHistoryListLoading: false,
                cardiacHistoryDiagnosisData: [],
                cardiacHistoryDiagnosisLoading: false,
                currentCardiacHistory: 'cardiac',
                currentSubDiagnosis: '',
                pastCardiacHistoryListData: [],
                pastCardiacHistoryListLoading: false,
                pastCardiacHistoryDiagnosisData: [],
                pastCardiacHistoryDiagnosisLoading: false,
                cardiacHistoryModalType: 'Details',
            }

        case GET_PAST_CARDIAC_HISTORY_LIST_DATA:
            return {
                ...state,
                pastCardiacHistoryListData: action?.payload,
                plud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
            }
        case GET_PAST_CARDIAC_HISTORY_DIAGNOSIS_DATA:
            return {
                ...state,
                pastCardiacHistoryDiagnosisData: action?.payload,
            }

        case SET_PAST_CARDIAC_HISTORY_LIST_LOADING:
            return {
                ...state,
                pastCardiacHistoryListLoading: action.payload,
            }
        case SET_PAST_CARDIAC_HISTORY_DIAGNOSIS_LOADING:
            return {
                ...state,
                pastCardiacHistoryDiagnosisLoading: action.payload,
            }

        case DELETE_CARDIAC_HISTORY:
            return state
        default:
            return state
    }
}

export default cardiacHistoryReducer
