import React from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import variables from '../../theme/variables.scss'
import { DATE_FORMAT } from '../../util/dateTimeFormats'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Timeline from '@mui/lab/Timeline'
import Stepper from '@mui/material/Stepper'
import StepConnector from '@mui/material/StepConnector'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ScheduleIcon from '@mui/icons-material/Schedule'
import Stack from '@mui/material/Stack'
import { togglePatientProfileModal } from '../../redux/modals/toggleModal.actions'
import Vitals from '../vital-component/vitals'
import Labs from '../labs-component/labs'
import EHG from '../EHG'
import './patient-profile.scss'
import CCM from '../CCM'
import ESN from '../ESN'
import MedicationCOP from '../medications-cop'
import SocialHistory from '../social-history/social'
import FamilyHistory from '../family-history/family'
import ConsultNotes from '../consult-notes/consult'
import AdverseEvent from '../adverse-events-ai/'
import IntervalHistory from '../interval-history/interval'
import PhysicalExam from '../physical-exam/physical'
import MedicalHistory from '../medical-history'
import CardiacHistory from '../cardiac-history'
import ConsultLetter from '../consult-letter/consult-letter'
import Recommendations from '../recommendations/Recommendations'
import ScreenFlow from '../screen-flow/screen-flow-widget'
import { clearVitals } from '../../redux/vitalList/vitalList.action'
import { clearLabs } from '../../redux/labsList/labsList.action'
import { clearEcgs } from '../../redux/ECH/ecgList/ecgList.action'
import { clearEcho } from '../../redux/ESN/echoList/echoList.action'
import { clearStressEcho } from '../../redux/ESN/stressEchoList/stressEchoList.action'
import { clearCath } from '../../redux/CCM/cathList/cathList.action'
import { clearHolter } from '../../redux/ECH/holterList/holterList.action'
import { clearGxt } from '../../redux/ECH/gxtList/gxtList.action'
import { clearNuclear } from '../../redux/ESN/nuclearList/nuclearList.action'
import { clearCt } from '../../redux/CCM/CTList/CTList.action'
import { clearMri } from '../../redux/CCM/MRIList/MRIList.action'
import { clearCardiac } from '../../redux/COP/cardiacList/cardiacList.action'
import { clearSocial } from '../../redux/socialList/socialList.action'
import { clearFamilyData } from '../../redux/familyList/familyList.action'
import { clearConsult } from '../../redux/consultNotes/consultNotes.action'
import { clearAdverseEventData } from '../../redux/adverseEvent/adverseEvent.action'
import { clearInterval } from '../../redux/intervalHistory/intervalHistory.action'
import { clearPhysical } from '../../redux/physicalExam/physicalExam.action'
import { clearMedicalHistoryData } from '../../redux/medicalHistory/medicalHistory.action'
import { clearCardiacHistoryData } from '../../redux/cardiacHistory/cardiacHistory.action'
import { clearRecommendations } from '../../redux/recommendations/recommendations.action'
import { clearTrial } from '../../redux/screenFlow/screenFlow.action'
import { initialValueFetchHandler } from '../appointment-list/index'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const Index = ({
    token,
    patientProfileModal,
    patient,
    loading,
    allAppointmentWoPagination,
    appointmentLoading,
}) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(togglePatientProfileModal(false))
        dispatch(clearVitals())
        dispatch(clearLabs())
        dispatch(clearEcgs())
        dispatch(clearEcho())
        dispatch(clearStressEcho())
        dispatch(clearCath())
        dispatch(clearHolter())
        dispatch(clearGxt())
        dispatch(clearNuclear())
        dispatch(clearCt())
        dispatch(clearMri())
        dispatch(clearCardiac())
        dispatch(clearSocial())
        dispatch(clearFamilyData())
        dispatch(clearConsult())
        dispatch(clearAdverseEventData())
        dispatch(clearInterval())
        dispatch(clearPhysical())
        dispatch(clearMedicalHistoryData())
        dispatch(clearCardiacHistoryData())
        dispatch(clearRecommendations())
        dispatch(clearTrial())
    }

    const handlePatientChange = (pid, id) => {
        handleClose() //clearing old state
        initialValueFetchHandler(pid, id, dispatch, token)
    }
    return (
        <div>
            <Dialog
                fullScreen
                open={patientProfileModal}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div className="ps-2 pe-1 pt-2 pb-2">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={5} lg={3} xl={3.6} spacing={1}>
                            <div className="pe-1 ">
                                {loading ? (
                                    <div className="text-center">
                                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="d-flex">
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={handleClose}
                                                aria-label="close"
                                            >
                                                <img
                                                    style={{ padding: '10px 5px' }}
                                                    src="/images/left-arrow-icon.svg"
                                                    alt="left icon"
                                                />
                                            </IconButton>
                                            <Autocomplete
                                                loading={appointmentLoading}
                                                getOptionLabel={(option) => option?.name}
                                                renderOption={(props, option) => (
                                                    <Stack
                                                        {...props}
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Chip
                                                            style={{
                                                                backgroundColor: variables.purple,
                                                                color: variables.white100,
                                                            }}
                                                            size="small"
                                                            className="fs-10 fw-normal"
                                                            icon={
                                                                <ScheduleIcon
                                                                    style={{
                                                                        backgroundColor: variables.purple,
                                                                        color: variables.white100,
                                                                    }}
                                                                />
                                                            }
                                                            label={` ${
                                                                !!option?.time ? `${option?.time}` : 'N/A'
                                                            }`}
                                                        />
                                                        <Box className="fs-9 fw-normal text-uppercase">
                                                            {option?.name ?? 'N/A'}
                                                        </Box>
                                                    </Stack>
                                                )}
                                                value={patient}
                                                onChange={(event, newValue) => {
                                                    handlePatientChange(newValue?.patientId, newValue?.id)
                                                }}
                                                size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={allAppointmentWoPagination}
                                                sx={{
                                                    pr: 1,
                                                    cursor: 'pointer !important',
                                                }}
                                                disabled={!!_.isEmpty(allAppointmentWoPagination)}
                                                disableClearable
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'Select Patient',
                                                            readOnly: true,
                                                            style: {
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                                textTransform: 'uppercase',
                                                            },
                                                        }}
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) =>
                                                    option?.patientId === value?.patientId
                                                }
                                                loadingText={'Searching...'}
                                            />
                                        </div>
                                        <div>
                                            <span className="profile-head cursor-default">DOB: </span>
                                            <span className="profile-value cursor-default">
                                                {patient?.birthday !== ''
                                                    ? moment(patient?.birthday).format(
                                                          DATE_FORMAT.MMM_DD_YYYY
                                                      )
                                                    : 'N/A'}
                                            </span>
                                            <span className="profile-head ms-2 cursor-default">Age: </span>
                                            <span className="profile-value cursor-default">
                                                {patient?.age !== '' ? patient?.age : 'N/A'}
                                            </span>
                                            <span className="profile-head ms-2 cursor-default">Gender: </span>
                                            <span className="profile-value cursor-default">
                                                {patient?.gender !== ''
                                                    ? patient?.gender === 1
                                                        ? 'Male'
                                                        : 'Female'
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <p className="mb-0 cursor-default">
                                            <span className="profile-head">Phone (Mobile): </span>{' '}
                                            <span className="profile-value">
                                                {patient?.phone !== '' ? patient?.phone : 'N/A'}
                                            </span>
                                        </p>
                                        <p className="mb-0 cursor-default">
                                            <span className="profile-head">Address: </span>{' '}
                                            <span className="profile-value">
                                                {patient?.address?.street !== ''
                                                    ? patient?.address?.street
                                                    : 'N/A'}
                                                ,{' '}
                                                {patient?.address?.city !== ''
                                                    ? patient?.address?.city
                                                    : 'N/A'}
                                                ,{' '}
                                                {patient?.address?.postalZip !== ''
                                                    ? patient?.address?.postalZip
                                                    : 'N/A'}
                                            </span>
                                        </p>
                                        <p className="mb-0 cursor-default">
                                            <span className="profile-head">Primary MD: </span>{' '}
                                            <span className="profile-value">
                                                {patient?.primaryMd !== '' ? patient?.primaryMd : 'N/A'}
                                            </span>
                                        </p>
                                        <p className="mb-0 cursor-default">
                                            <span className="profile-head">Referring MD: </span>{' '}
                                            <span className="profile-value">
                                                {patient?.referringMd !== '' ? patient?.referringMd : 'N/A'}
                                            </span>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="pe-1 mb-1 mt-1">
                                <ConsultNotes />
                            </div>
                            <div className="pe-1 mb-1 mt-1">
                                <MedicalHistory />
                            </div>
                            <div className="pe-1 mb-1">
                                <MedicationCOP />
                            </div>
                            <div className="pe-1 mb-1">
                                <AdverseEvent />
                            </div>
                            <div className="pe-1 mb-1">
                                <FamilyHistory />
                            </div>
                            <div className="pe-1 mb-1">
                                <SocialHistory />
                            </div>
                        </Grid>
                        <Grid
                            sx={{
                                background: '#e6e2e26b',
                                minHeight: '100vH',
                                height: 'auto',
                                marginBottom: '-15px',
                                paddingLeft: '5px !important',
                                paddingRight: '2px !important',
                                // paddingTop: '5px !important',
                                marginTop: '-1px !important',
                            }}
                            item
                            xs={12}
                            md={7}
                            lg={4.0}
                            xl={4.2}
                            spacing={1}
                        >
                            <Timeline>
                                <div className="pe-1 mb-3">
                                    <CardiacHistory />
                                </div>
                                <Stepper orientation="vertical">
                                    <div className="pe-1 ">
                                        <IntervalHistory />
                                    </div>
                                    <StepConnector sx={{ paddingLeft: 1.4 }}></StepConnector>
                                    <div className="pe-1 ">
                                        <PhysicalExam />
                                    </div>
                                    <StepConnector sx={{ paddingLeft: 1.4 }}></StepConnector>
                                    <div className="pe-1 ">
                                        <Recommendations />
                                    </div>
                                    <StepConnector sx={{ paddingLeft: 1.4 }}></StepConnector>
                                    <div className="pe-1 ">
                                        <ConsultLetter />
                                    </div>
                                </Stepper>
                                <div className="pe-1 mb-1 mt-3">
                                    <ScreenFlow />
                                </div>
                            </Timeline>
                        </Grid>
                        <Grid
                            item
                            //  xs={5.1}
                            xs={12}
                            lg={5}
                            xl={4.2}
                            sx={{ paddingLeft: '10px !important' }}
                        >
                            <Vitals />
                            <Labs />
                            <EHG />
                            <ESN />
                            <CCM />
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientProfileModal: state.toggleModals.patientProfileModal,
        patient: state.patient.patientData,
        loading: state.patient.loading,
        allAppointmentWoPagination: state.appointment.appointmentDataWoPagination,
        appointmentLoading: state.appointment.loading,
        token: state.loginData.loginResponse.accessToken,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        togglePatientProfileModal: (payload) => dispatch(togglePatientProfileModal(payload)),
        clearVitals: () => dispatch(clearVitals()),
        clearLabs: () => dispatch(clearLabs()),
        clearEcgs: () => dispatch(clearEcgs()),
        clearEcho: () => dispatch(clearEcho()),
        clearStressEcho: () => dispatch(clearStressEcho()),
        clearNuclear: () => dispatch(clearNuclear()),
        clearCath: () => dispatch(clearCath()),
        clearCt: () => dispatch(clearCt()),
        clearMri: () => dispatch(clearMri()),
        clearCardiac: () => dispatch(clearCardiac()),
        clearSocial: () => dispatch(clearSocial()),
        clearFamilyData: () => dispatch(clearFamilyData()),
        clearConsult: () => dispatch(clearConsult()),
        clearAdverseEventData: () => dispatch(clearAdverseEventData()),
        clearInterval: () => dispatch(clearInterval()),
        clearPhysical: () => dispatch(clearPhysical()),
        clearMedicalHistoryData: () => dispatch(clearMedicalHistoryData()),
        clearCardiacHistoryData: () => dispatch(clearCardiacHistoryData()),
        clearRecommendations: () => dispatch(clearRecommendations()),
        clearTrial: () => dispatch(clearTrial()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
