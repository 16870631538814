import {
    CLEAR_TRIAL,
    SET_CURRENT_TRIAL,
    GET_TRIAL_LIST_DATA,
    SET_TRIAL_LIST_LOADING,
    GET_TRIAL_CRITERIA_DATA,
    SET_TRIAL_CRITERIA_LOADING,
} from './screenFlow.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const setCurrentTrial = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_TRIAL,
        payload: data,
    })
}

export const clearTrial = () => (dispatch) => {
    dispatch({
        type: CLEAR_TRIAL,
    })
}

export const getTrialListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_TRIAL_LIST_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/patient-studies/list?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_TRIAL_LIST_DATA,
                    payload: response.data.output,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Active Cardiac History. Please! try Again`,
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${err?.message ?? 'Error'} in fetching clinical trials. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
        .finally(() => {
            dispatch({
                type: SET_TRIAL_LIST_LOADING,
                payload: false,
            })
        })
}

export const getTrialCriteriaData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_TRIAL_CRITERIA_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/patient-studies/view?id=${payload?.id}&patientCollectionName=${payload?.patientCollectionName}&patientId=${payload?.patientId}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_TRIAL_CRITERIA_DATA,
                    payload: response.data.output,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Medication. Please! try Again`,
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${
                        err?.message ?? 'Error'
                    } in fetching clinical trial detail. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
        .finally(() => {
            dispatch({
                type: SET_TRIAL_CRITERIA_LOADING,
                payload: false,
            })
        })
}
