import React from 'react'
import '../vital-pills/action-pills.scss'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const PillBox = ({ consultLetterFlag = false }) => {
    return (
        <Box sx={{ px: 2, pt: 0.5 }}>
            {!consultLetterFlag ? (
                <Typography className="mt-1 gray-600 fs-9 fw-bold" gutterBottom>
                    <u>Legend</u>
                </Typography>
            ) : null}
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                        Outline:
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box className="action-pills" sx={{ minWidth: '0.875rem', minHeight: '1rem' }}></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            normal
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box
                            className="mild action-pills"
                            sx={{ minWidth: '0.875rem', minHeight: '1rem' }}
                        ></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            mild
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box
                            className="moderate action-pills"
                            sx={{ minWidth: '0.875rem', minHeight: '1rem' }}
                        ></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            moderate
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box
                            className="severe action-pills"
                            sx={{ minWidth: '0.875rem', minHeight: '1rem' }}
                        ></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            Severe
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                        Background:
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box className="action-pills" sx={{ minWidth: '0.875rem', minHeight: '1rem' }}></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            stable
                        </Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="flex-start">
                        <Box
                            className="flag-moderate moderate action-pills"
                            sx={{ minWidth: '0.875rem', minHeight: '1rem' }}
                        ></Box>
                        <Typography className="gray-600 fs-9 fw-normal text-capitalize" gutterBottom>
                            unstable
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

const InfoBox = () => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Legend">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ maxWidth: '1rem', maxHeight: '1rem', mt: 0.3 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <InfoOutlinedIcon sx={{ width: '0.85rem', height: '0.85rem' }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        //width control
                        maxWidth: '800px',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 115,
                            width: 8,
                            height: 8,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <PillBox />
            </Menu>
        </>
    )
}

export default InfoBox
