import PropTypes from 'prop-types'
import clsx from 'clsx'
import axios from 'axios'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleSocialModal,
} from '../../../redux/modals/toggleModal.actions'
import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import SocialForm from '../social-form'

import _ from 'lodash'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ socialModal, patientId, token }) => {
    const submit = React.useRef(null)
    const [alignment, setAlignment] = React.useState('Update')

    const [search, setSearch] = React.useState('')
    // const [searchValue, setSearchValue] = React.useState(options[0]);
    const [searchOptions, setSearchOptions] = React.useState([])
    const [searchValue, setSearchValue] = React.useState(searchOptions[0] ?? null)
    const [inputSearchValue, setInputSearchValue] = React.useState('')
    const [searchLoading, setSearchLoading] = React.useState(false)

    const inputSearchHandler = (event, newInputValue) => {
        setInputSearchValue(newInputValue)
    }
    const searchValueHandler = (event, newValue) => {
        setSearchValue(newValue)
    }

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return
        dispatch(toggleSocialModal(false))
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={socialModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">
                            Social History
                        </Typography>
                    </div>
                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                            <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">
                                {alignment === 'Update' ? 'Update' : 'NA'}
                            </Typography>
                        </Stack>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* Right hand modal form */}
                        {alignment === 'Update' ? (
                            <SocialForm
                                submit={submit}
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        socialModal: state?.toggleModals?.socialModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSocialModal: (payload) => dispatch(toggleSocialModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
