import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import variables from '../../../theme/variables.scss'
import { tableCellClasses } from '@mui/material/TableCell'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: '0.75rem',
        // backgroundColor: variables.purple,
        color: variables.gray500,
        fontWeight: 400,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.875rem',
        // color: variables.gray900,
        // fontWeight: 400,
    },
}))

export default function AdverseEventListTable({ adverseEventListData, consultLetterFlag = false }) {
    return (
        <TableContainer>
            <Table sx={{ overflow: 'scroll' }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Substance</StyledTableCell>
                        <StyledTableCell align="left">Reaction</StyledTableCell>
                        <StyledTableCell align="left">Severity</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {adverseEventListData?.map((row) => (
                        <TableRow
                            key={row._id}
                            sx={{ '& td': { border: 0 } }}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { border: 0 } }}
                        >
                            <StyledTableCell
                                component="td"
                                scope="row"
                                sx={{
                                    fontWeight: consultLetterFlag ? '400 !important' : '500 !important',
                                    fontSize: consultLetterFlag ? '0.75rem !important' : '0.80rem !important',
                                    color: variables.gray900,
                                }}
                            >
                                {row?.substance ?? '-'}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={{
                                    fontWeight: '400 !important',
                                    fontSize: consultLetterFlag ? '0.75rem !important' : '0.80rem !important',
                                    color: variables.gray600,
                                }}
                            >
                                {row?.reaction ?? '-'}{' '}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={{
                                    fontWeight: '400 !important',
                                    fontSize: consultLetterFlag ? '0.75rem !important' : '0.80rem !important',
                                    color: variables.orange500,
                                }}
                            >
                                {row?.severity ?? '-'}{' '}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
