import { LOGIN_SUCCESS, LOGIN_LOADING } from './auth.type'

const INITIAL_STATE = {
    isLoggedIn: false,
    loginResponse: {
        accessToken: '',
        userId: '',
        email: '',
    },
}

const Loginreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                loginResponse: action.payload,
            }
        case LOGIN_LOADING:
            return {
                ...INITIAL_STATE
            }
        default:
            return state
    }
}

export default Loginreducer
