import React from 'react'
import '../interval-components/inputs.scss'
import variables from '../../../../theme/variables.scss'
import _ from 'lodash'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import CustomizedAccordions from '../interval-components/Accordian'
import ColorToggleButton from '../interval-components/Toggler'
import Inputs from '../interval-components/Inputs'
import BoxWrapper from '../interval-components/BoxWrapper'
import StackWrapper from '../interval-components/StackWrapper'

const PainAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Pain'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`intervalHistory[0].active`}
                        btnVal={values?.intervalHistory[0]?.active}
                    />
                }
            >
                <StackWrapper title="Location of pain">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[0].location`}
                            inputVal={values?.intervalHistory[0]?.location}
                            inputSize="large"
                            toggleBtnArr={['Chest', 'Jaw', 'Epigastrium', 'Back', 'Left Arm', 'Right Arm']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="CCS Class" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[0].ccsClass`}
                            inputVal={values?.intervalHistory[0].ccsClass}
                            toggleBtnArr={_.range(5)}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Since" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[0].since.value`}
                            inputVal={values?.intervalHistory[0].since.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[0].since.unit`}
                            inputVal={values?.intervalHistory[0].since.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Severity" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[0].severity`}
                            inputVal={values?.intervalHistory[0].severity}
                            toggleBtnArr={_.range(1, 11)}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Typical Duration" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[0].lastLong.value`}
                            inputVal={values?.intervalHistory[0]?.lastLong?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[0].lastLong.unit`}
                            inputVal={values?.intervalHistory[0]?.lastLong?.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Frequency" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper styles={{ alignItems: 'center', gap: '2px', overflow: 'visible' }}>
                        <Inputs
                            inputType={'textInput'}
                            inputName={`intervalHistory[0].frequency.text`}
                            inputVal={values?.intervalHistory[0]?.frequency.text}
                            placeholderText="Total Frequency"
                        />

                        <Divider
                            sx={{ background: variables.gray600, mx: '2px' }}
                            orientation="vertical"
                            flexItem
                        />
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                            }}
                        >
                            Duration
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[0].frequency.value`}
                            inputVal={values?.intervalHistory[0]?.frequency.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[0].frequency.unit`}
                            inputVal={values?.intervalHistory[0]?.frequency.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Exacerbated By">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[0].exacerbatedBy`}
                            inputVal={values?.intervalHistory[0]?.exacerbatedBy}
                            inputSize="xl"
                            toggleBtnArr={[
                                'Exertion',
                                'Palpitation',
                                'Laying Down',
                                'Sitting Down',
                                'Spontaneous',
                                'Other',
                            ]}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Alleviated By">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[0].alleviatedBy`}
                            inputVal={values?.intervalHistory[0]?.alleviatedBy}
                            inputSize="xl"
                            toggleBtnArr={[
                                'Exertion',
                                'Palpitation',
                                'Laying Down',
                                'Sitting Up',
                                'Spontaneous',
                                'Rest',
                            ]}
                        />
                    </BoxWrapper>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default PainAccordion
