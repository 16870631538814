import {
    GET_GXT_DATA,
    GET_GXT_DOCUMENT,
    GET_GXT_LIST_DATA,
    GXT_DOC_LOADING,
    RESET_GXT_MODAL,
    SET_GXT_LOADING,
    CLEAR_GXT,
} from './gxtList.type'

const INITIAL_STATE = {
    list: [],
    gxtData: [],
    gxtLoading: false,
    lud: '',
    gxtDocuments: {
        list: [],
        lastRefreshDate: '',
    },
    gxtDocLoading: false,
}

const gxtListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_GXT_LIST_DATA:
            return {
                ...state,
                list: action?.payload?.list,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : '',
                loading: false,
            }
        case GET_GXT_DATA:
            return {
                ...state,
                gxtData: action?.payload,
            }
        case GET_GXT_DOCUMENT:
            return {
                ...state,
                gxtDocuments: action?.payload,
            }
        case SET_GXT_LOADING:
            return {
                ...state,
                gxtLoading: action.payload,
            }
        case GXT_DOC_LOADING:
            return {
                ...state,
                gxtDocLoading: action.payload,
            }
        case RESET_GXT_MODAL:
            return {
                ...state,
                gxtDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
            }
        case CLEAR_GXT:
            return {
                list: [],
                gxtData: [],
                gxtLoading: false,
                lud: '',
                gxtDocuments: {
                    list: [],
                    lastRefreshDate: '',
                },
                gxtDocLoading: false,
            }

        default:
            return state
    }
}

export default gxtListReducer
