import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import BPChart from './BP-chart'
import DummyChart from '../../dummy-chart'
import BaseChart from './Base-chart'
import LoadingChart from '../../labs-component/labs-charts/loading-chart'

export const Index = ({ currentVital, vitalLoading, vitalData, type }) => {
    const placeholderHelper = () => {
        const palceholderObj = [
            { name: 'bp', placeholder: 'BP' },
            { name: 'hr', placeholder: 'HR' },
            { name: 'o2', placeholder: 'O2' },
            { name: 'currentWeight', placeholder: 'Wt' },
            { name: 'dryWeight', placeholder: 'Wt Dry' },
            { name: 'diffWeight', placeholder: 'Wt Chg' },
            { name: 'height', placeholder: 'Height' },
            { name: 'bmi', placeholder: 'BMI' },
            { name: 'bsa', placeholder: 'BSA' },
        ]
        return _.find(palceholderObj, ['name', currentVital])?.placeholder ?? ''
    }

    const vitalChart = () => {
        if (
            ['hr', 'o2', 'currentWeight', 'dryWeight', 'diffWeight', 'height', 'bmi', 'bsa'].includes(
                currentVital
            )
        ) {
            return <BaseChart type={type} name={placeholderHelper()} />
        } else if (currentVital === 'bp') {
            return <BPChart type={type} name={placeholderHelper()} />
        } else {
            return <DummyChart name={`${currentVital} vital chart not supported `} />
        }
    }

    const showChart = () => {
        if (vitalData.length > 0) {
            return vitalChart()
        } else {
            return <DummyChart type={type} name={placeholderHelper()} />
        }
    }
    return <div>{!!currentVital && <>{vitalLoading ? <LoadingChart type={type} /> : showChart()}</>}</div>
}

const mapStateToProps = (state) => ({
    currentVital: state.vital.currentVital,
    vitalLoading: state.vitalList.vitalLoading,
    vitalData: state.vitalList.vitalData,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
