import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const DeleteDialog = ({ open, handleDelete, handleClose, loading = false }) => {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this entry?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={!!loading}>
                        Close
                    </Button>
                    <Button color="error" onClick={handleDelete} autoFocus disabled={!!loading}>
                        {!!loading ? 'Processing...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default DeleteDialog
