import { GET_SOCIAL_LIST_DATA, SET_SOCIAL_LOADING, CLEAR_SOCIAL } from './socialList.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'

export const getSocialListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_SOCIAL_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/social-info?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_SOCIAL_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_SOCIAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_SOCIAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_SOCIAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_SOCIAL_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const clearSocial = () => (dispatch) => {
    dispatch({
        type: CLEAR_SOCIAL,
    })
}
