import {
    GET_CARDIAC_HISTORY_LIST_DATA,
    SET_CARDIAC_HISTORY_LIST_LOADING,
    GET_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
    CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    CLEAR_CARDIAC_HISTORY_LIST_DATA,
    CLEAR_CARDIAC_HISTORY_DATA,
    SET_CURRENT_CARDIAC_HISTORY,
    DELETE_CARDIAC_HISTORY,
    SET_CURRENT_SUB_DIAGNOSIS,
    GET_PAST_CARDIAC_HISTORY_LIST_DATA,
    SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
    SET_CARDIAC_HISTORY_MODAL_TYPE,
} from './cardiacHistory.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'
import { setAlert } from '../alert/alert.action'

export const getCardiacHistoryListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_CARDIAC_HISTORY_LIST_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/diagnosis-history?patientId=${payload?.patientId}&type=${payload?.type}&status=${payload?.status}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CARDIAC_HISTORY_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Active Cardiac History. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${
                        err?.message ?? 'Error'
                    } in fetching Active Cardiac History. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
}

export const getPastCardiacHistoryListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
        payload: true,
    })
    axios
        .get(
            `${process.env.REACT_APP_CARD_URL}/diagnosis-history?patientId=${payload?.patientId}&type=${payload?.type}&status=${payload?.status}`,
            {
                headers: {
                    accessToken: token,
                },
            }
        )
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_PAST_CARDIAC_HISTORY_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Past Cardiac History. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_PAST_CARDIAC_HISTORY_LIST_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${
                        err?.message ?? 'Error'
                    } in fetching Past Cardiac History. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
}

export const getCardiacHistoryDiagnosisData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
        payload: true,
    })

    axios
        .post(`${process.env.REACT_APP_CARD_URL}/diagnosis-history/view`, payload, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_CARDIAC_HISTORY_DIAGNOSIS_DATA,
                    payload: response.data.output,
                })

                dispatch({
                    type: SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in fetching Cardiac History ${
                            payload?.name ?? ''
                        }. Please! try Again`,
                        alertOpen: true,
                    })
                )
                dispatch({
                    type: SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_CARDIAC_HISTORY_DIAGNOSIS_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${err?.message ?? 'Error'} in fetching Cardiac History ${
                        payload?.name ?? ''
                    }. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
}

export const clearCardiacHistoryDiagnosisData = () => (dispatch) => {
    dispatch({
        type: CLEAR_CARDIAC_HISTORY_DIAGNOSIS_DATA,
    })
}
export const clearCardiacHistoryListData = () => (dispatch) => {
    dispatch({
        type: CLEAR_CARDIAC_HISTORY_LIST_DATA,
    })
}
export const clearCardiacHistoryData = () => (dispatch) => {
    dispatch({
        type: CLEAR_CARDIAC_HISTORY_DATA,
    })
}

export const setCurrentCardiacHistory = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_CARDIAC_HISTORY,
        payload: data,
    })
}

export const setCurrentSubDiagnosis = (data) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_SUB_DIAGNOSIS,
        payload: data,
    })
}

export const deleteCardiacHistory = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/diagnosis-history?id=${payload?.id}&type=presentations`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_CARDIAC_HISTORY,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Presentation Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `${err?.message ?? 'Error'} in deleting presentation. ${
                        payload?.name ?? ''
                    }. Please! try Again`,
                    alertOpen: true,
                })
            )
        })
}

export const deleteCardiaclHistoryDiagnosis = (payload, token) => async (dispatch) => {
    axios
        .delete(`${process.env.REACT_APP_CARD_URL}/diagnosis-history`, {
            data: payload,
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: DELETE_CARDIAC_HISTORY,
                    payload: response.data.output,
                })
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: 'Cardiac Diagnosis Deleted Successfully',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 400) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: 'Bad Request! Please try Again',
                        alertOpen: true,
                    })
                )
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const setCardiacHistoryModalType = (data) => (dispatch) => {
    dispatch({
        type: SET_CARDIAC_HISTORY_MODAL_TYPE,
        payload: data,
    })
}
