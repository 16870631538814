import React from 'react'
import _ from 'lodash'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'

import CustomizedAccordions from '../../../interval-history/interval-modal/interval-components/Accordian'
import ColorToggleButton from '../../../interval-history/interval-modal/interval-components/Toggler'

import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const RespiratoryAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Respiratory'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`physicalExam[1].active`}
                        btnVal={values?.physicalExam[1]?.active}
                    />
                }
            >
                <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="large"
                            inputName={`physicalExam[1].diagnosisType.side`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.side}
                            toggleBtnArr={['Right', 'Left', 'Bilateral']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Area" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="large"
                            inputName={`physicalExam[1].diagnosisType.area`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.area}
                            toggleBtnArr={['Base', 'Mid', 'Upper', 'Entire']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Intensity" direction="row" spacing={2}>
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="xxl"
                            inputName={`physicalExam[1].diagnosisType.intensity`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.intensity}
                            toggleBtnArr={['Clear', 'Decreased', 'Absent']}
                        />
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="xxxl"
                            inputName={`physicalExam[1].diagnosisType.intensity`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.intensity}
                            toggleBtnArr={['Opening Snap', 'Mid-systolic Click']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Character" direction="row" spacing={2}>
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="xxxl"
                            inputName={`physicalExam[1].diagnosisType.charater`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.charater}
                            toggleBtnArr={['Vascular', 'Bronchial', 'Stridor']}
                        />
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="xxxl"
                            inputName={`physicalExam[1].diagnosisType.charater`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.charater}
                            toggleBtnArr={['Bronchovesicular']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Other Sounds" direction="row" alignItems="center" spacing={2}>
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputSize="large"
                            inputName={`physicalExam[1].diagnosisType.otherSounds`}
                            inputVal={values?.physicalExam[1]?.diagnosisType?.otherSounds}
                            toggleBtnArr={['Crackle', 'Wheeze']}
                        />
                    </BoxWrapper>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default RespiratoryAccordion
