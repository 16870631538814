import { SET_CURRENT_VITALS, SET_LOADING } from './vitals.type'

const INITIAL_STATE = {
    currentVital: 'bp',
    loading: true,
}

const vitalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_VITALS:
            return {
                ...state,
                currentVital: action.payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default vitalReducer
