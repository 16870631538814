import {
    SET_PROFILE_LOADING,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    SET_CATH_MODAL,
    SET_CT_MODAL,
    SET_ECG_MODAL,
    SET_ECHO_MODAL,
    SET_STRESS_ECHO_MODAL,
    SET_GXT_MODAL,
    SET_HOLTER_MODAL,
    SET_LABS_MODAL,
    SET_MRI_MODAL,
    SET_NUCLEAR_MODAL,
    SET_VITALS_MODAL,
    SET_CARDIAC_MODAL,
    SET_SOCIAL_MODAL,
    SET_FAMILY_MODAL,
    SET_CONSULT_MODAL,
    SET_ADVERSE_EVENT_MODAL,
    SET_INTERVAL_MODAL,
    SET_PHYSICAL_MODAL,
    SET_MEDICAL_HISTORY_MODAL,
    SET_CARDIAC_HISTORY_MODAL,
    SET_CARDIAC_TIMELINE_MODAL,
    SET_RECOMMENDATIONS_MODAL,
    SET_CONSULT_LETTER_MODAL,
    SET_SCREEN_FLOW_MODAL,
    SET_ELIGIBILITY_CRITERIA_MODAL,
    SET_APPOINTMENT_MODAL,
    SET_PATIENT_INFO_MODAL,
    TOGGLE_ACCOUNT_DEACTIVATE_MODAL,
    TOGGLE_CHANGE_PASSWORD_MODAL,
    TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL,
    TOGGLE_OTP_MODAL,
    TOGGLE_PATIENT_PROFILE_MODAL,
    TOGGLE_PROFILE_MODAL,
    TOGGLE_RESET_PASSWORD_MODAL,
    TOGGLE_SESSION_MODAL,
} from './toggleModal.types'
import axios from 'axios'

export const toggleOtpModal = (payload) => {
    return {
        type: TOGGLE_OTP_MODAL,
        payload,
    }
}

export const toggleResetPasswordModal = (payload) => {
    return {
        type: TOGGLE_RESET_PASSWORD_MODAL,
        payload,
    }
}

export const toggleResetPasswordEmailSentModal = (payload) => {
    return {
        type: TOGGLE_EMAIL_SENT_RESET_PASSWORD_MODAL,
        payload,
    }
}

export const toggleSessionModal = (payload, data) => {
    return {
        type: TOGGLE_SESSION_MODAL,
        payload: { payload, data },
    }
}

export const toggleDeactivateModal = (payload) => {
    return {
        type: TOGGLE_ACCOUNT_DEACTIVATE_MODAL,
        payload,
    }
}

export const CheckSessionExpiry = (token) => async (dispatch) => {
    if (token) {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cardiologist/get-profile`, {
                headers: {
                    accessToken: token,
                },
            })
            .then((response) => {
                if (response.data.status === 401) {
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                }
            })
    } else if (window.location.pathname !== '/') window.location.replace('/')
}

export const openChangePasswordModal = (payload, token) => async (dispatch) => {
    if (token) {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cardiologist/get-profile`, {
                headers: {
                    accessToken: token,
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch({
                        type: TOGGLE_CHANGE_PASSWORD_MODAL,
                        payload: payload,
                    })
                } else if (response.data.status === 401) {
                    dispatch(toggleSessionModal(true, response?.data?.error))
                    dispatch({
                        type: PROFILE_FAILED,
                    })
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                    dispatch({
                        type: PROFILE_FAILED,
                    })
                }
            })
    } else if (window.location.pathname !== '/') window.location.replace('/')
}

export const closeChangePasswordModal = (payload) => {
    return {
        type: TOGGLE_CHANGE_PASSWORD_MODAL,
        payload,
    }
}

export const fetchProfileModal = (payload, token) => async (dispatch) => {
    if (payload && token) {
        dispatch({
            type: SET_PROFILE_LOADING,
            payload: true,
        })
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cardiologist/get-profile`, {
                headers: {
                    accessToken: token,
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch({
                        type: PROFILE_SUCCESS,
                        payload: response.data.output,
                    })
                } else if (response.data.status === 401) {
                    dispatch(toggleSessionModal(true, response?.data?.error))
                    dispatch({
                        type: PROFILE_FAILED,
                    })
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                    dispatch({
                        type: PROFILE_FAILED,
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
            })
            .finally(() => {
                dispatch({
                    type: SET_PROFILE_LOADING,
                    payload: false,
                })
            })
    } else {
        dispatch({
            type: PROFILE_FAILED,
        })
    }
}
export const toggleProfileModal = (payload) => async (dispatch) => {
    dispatch({
        type: TOGGLE_PROFILE_MODAL,
        payload: payload,
    })
}
export const togglePatientProfileModal = (payload) => {
    return {
        type: TOGGLE_PATIENT_PROFILE_MODAL,
        payload,
    }
}

export const toggleVitalModal = (payload) => {
    return {
        type: SET_VITALS_MODAL,
        payload,
    }
}

export const toggleLabsModal = (payload) => {
    return {
        type: SET_LABS_MODAL,
        payload,
    }
}

export const toggleEcgModal = (payload) => {
    return {
        type: SET_ECG_MODAL,
        payload,
    }
}

export const toggleHolterModal = (payload) => {
    return {
        type: SET_HOLTER_MODAL,
        payload,
    }
}
export const toggleGxtModal = (payload) => {
    return {
        type: SET_GXT_MODAL,
        payload,
    }
}
export const toggleEchoModal = (payload) => {
    return {
        type: SET_ECHO_MODAL,
        payload,
    }
}

export const toggleStressEchoModal = (payload) => {
    return {
        type: SET_STRESS_ECHO_MODAL,
        payload,
    }
}

export const toggleNuclearModal = (payload) => {
    return {
        type: SET_NUCLEAR_MODAL,
        payload,
    }
}

export const toggleCathModal = (payload) => {
    return {
        type: SET_CATH_MODAL,
        payload,
    }
}
export const toggleCtModal = (payload) => {
    return {
        type: SET_CT_MODAL,
        payload,
    }
}
export const toggleMriModal = (payload) => {
    return {
        type: SET_MRI_MODAL,
        payload,
    }
}

export const toggleCardiacModal = (payload) => {
    return {
        type: SET_CARDIAC_MODAL,
        payload,
    }
}

export const toggleSocialModal = (payload) => {
    return {
        type: SET_SOCIAL_MODAL,
        payload,
    }
}

export const toggleFamilyModal = (payload) => {
    return {
        type: SET_FAMILY_MODAL,
        payload,
    }
}
export const toggleConsultModal = (payload) => {
    return {
        type: SET_CONSULT_MODAL,
        payload,
    }
}

export const toggleAdverseEventModal = (payload) => {
    return {
        type: SET_ADVERSE_EVENT_MODAL,
        payload,
    }
}

export const toggleIntervalModal = (payload) => {
    return {
        type: SET_INTERVAL_MODAL,
        payload,
    }
}

export const togglePhysicalModal = (payload) => {
    return {
        type: SET_PHYSICAL_MODAL,
        payload,
    }
}

export const toggleMedicalHistoryModal = (payload) => {
    return {
        type: SET_MEDICAL_HISTORY_MODAL,
        payload,
    }
}

export const toggleCardiacHistoryModal = (payload) => {
    return {
        type: SET_CARDIAC_HISTORY_MODAL,
        payload,
    }
}
export const toggleCardiacTimelineModal = (payload) => {
    return {
        type: SET_CARDIAC_TIMELINE_MODAL,
        payload,
    }
}

export const toggleRecommendationsModal = (payload) => {
    return {
        type: SET_RECOMMENDATIONS_MODAL,
        payload,
    }
}

export const toggleConsultLetterModal = (payload) => {
    return {
        type: SET_CONSULT_LETTER_MODAL,
        payload,
    }
}
export const toggleScreenFlowModal = (payload) => {
    return {
        type: SET_SCREEN_FLOW_MODAL,
        payload,
    }
}
export const toggleEligibilityCriteriaModal = (payload) => {
    return {
        type: SET_ELIGIBILITY_CRITERIA_MODAL,
        payload,
    }
}

export const toggleAppointmentModal = (payload) => {
    return {
        type: SET_APPOINTMENT_MODAL,
        payload,
    }
}

export const togglePatientInfoModal = (payload) => {
    return {
        type: SET_PATIENT_INFO_MODAL,
        payload,
    }
}
