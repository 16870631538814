import React from 'react'
import { Link } from 'react-router-dom'
import variables from '../theme/variables.scss'
import './login/login.scss'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const NotFound = () => {
    return (
        <Box className="login-bg px-3">
            <Box className="d-flex flex-column justify-content-center align-items-center gap-2">
                <SearchOffIcon
                    sx={{
                        width: '72px',
                        height: '72px',
                        color: variables.purple,
                    }}
                />
                <Typography className="fs-5 fw-bold text-center">
                    Page not found. Please <Link to="/portals">click here</Link> to return to homepage.
                </Typography>
            </Box>
        </Box>
    )
}

export default NotFound
