import React from 'react'
import '../interval-components/inputs.scss'
import variables from '../../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
const StackWrapper = ({
    title,
    direction = 'column',
    alignItems = 'start',
    spacing = 1,
    children,
    titleWidth = '150px',
    titleFontSize = '0.875rem',
    titleFontWeight = '500',
    titleColor = variables.gray500,
    ...rest
}) => {
    return (
        <Stack
            sx={{ width: '100%', padding: '6px 2px 6px', ...rest.styles }}
            direction={direction}
            justifyContent="flex-start"
            alignItems={alignItems}
            spacing={spacing}
        >
            <Typography
                className="mt-1"
                sx={{
                    color: titleColor,
                    minWidth: titleWidth,
                    fontSize: titleFontSize,
                    fontWeight: titleFontWeight,
                }}
            >
                {title}
            </Typography>
            {children}
        </Stack>
    )
}

export default StackWrapper
