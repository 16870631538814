import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import variables from '../../../theme/variables.scss'
import DoneIcon from '@mui/icons-material/Done'
import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'

const AntTabs = styled(Tabs)({
    // borderBottom: 'none',

    '& .MuiTabs-indicator': {
        backgroundColor: variables.purple,
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    fontSize: '0.875rem',
    marginRight: theme.spacing(2),
    padding: 0,
    minHeight: '44px',
    height: '44px',
    color: variables.gray600,
    fontFamily: 'Inter',
    '&:hover': {
        color: variables.purple,
        opacity: 1,
    },
    '&.Mui-selected': {
        color: variables.purple,
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: variables.blue100,
    },
}))

export default function FlowTabs({ tabValue, setTabValue, incValue, excValue }) {
    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <Box sx={{ minWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <AntTabs value={tabValue} onChange={handleChange} aria-label="ant example">
                <AntTab
                    label={
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
                            <Typography sx={{ p: 0.2 }}>Inclusion Criteria</Typography>
                            <Chip variant="square" label={incValue} size="small" />
                        </Stack>
                    }
                    value="inc"
                    icon={<DoneIcon sx={{ color: variables.green500, width: '16px', height: '16px' }} />}
                    iconPosition="start"
                />
                <AntTab
                    label={
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
                            <Typography sx={{ p: 0.2 }}>Exclusion Criteria</Typography>
                            <Chip variant="square" label={excValue} size="small" />
                        </Stack>
                    }
                    value="exc"
                    icon={<DoneIcon sx={{ color: variables.green500, width: '16px', height: '16px' }} />}
                    iconPosition="start"
                />
            </AntTabs>
        </Box>
    )
}
