import React from 'react'
import variables from '../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { PillBox } from '../../vital-component/vitals/InfoBox'

const PillBoxInfo = () => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-2">Legend</Typography>

            <Box
                sx={{
                    border: 1,
                    borderRadius: '0.25rem',
                    borderColor: variables.lightGray1,
                    overflow: 'hidden',
                }}
            >
                <PillBox consultLetterFlag={true} />
            </Box>
        </>
    )
}

export default PillBoxInfo
