import { LOGIN_SUCCESS, LOGIN_LOADING } from './auth.type'

export const login = (payload) => async (dispatch) => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: payload,
    })
}

export const loginClear = () => async (dispatch) => {
    dispatch({
        type: LOGIN_LOADING,
    })
}
