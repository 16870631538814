import React from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { togglePhysicalModal } from '../../../redux/modals/toggleModal.actions'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'

export const RecommendationDetails = ({
    patientId,
    token,
    recommendationList,
    recommendationLoading,
    lastUpdatedRecommendations,
    consultLetterFlag = false,
}) => {
    const dispatch = useDispatch()
    const [expand, setExpand] = React.useState(false)
    const modifiedRecommendationList =
        !!expand || !!consultLetterFlag ? recommendationList : recommendationList.slice(0, 2)

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="stretch"
                sx={{
                    width: '100%',
                    my: '4px',
                    mx: '1px',
                }}
            >
                {_.isEmpty(recommendationList) && !!consultLetterFlag ? (
                    <Typography className="gray-900 fw-light fs-9 ">No recommendations Available</Typography>
                ) : null}
                {modifiedRecommendationList?.map((recommendation, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            sx={{
                                background: variables.gray100,
                                borderRadius: '0.25rem',
                                p: 1,
                                mt: 1,
                                width: '100%',
                            }}
                        >
                            <Tooltip
                                title={
                                    !consultLetterFlag && recommendation?.diagnosis?.length > 45
                                        ? recommendation?.diagnosis
                                        : null
                                }
                            >
                                <Typography
                                    sx={{
                                        width: '95%',
                                        whiteSpace: !consultLetterFlag ? 'nowrap' : 'normal',
                                        textOverflow: 'ellipsis',
                                        overflow: 'clip !important',
                                        textTransform: 'capitalize',
                                    }}
                                    className="cursor-default gray-900 fs-8 fw-normal"
                                >
                                    {recommendation?.diagnosis ?? '-N/A-'}
                                </Typography>
                            </Tooltip>
                            <Grid item sx={{ background: variables.gray100, borderRadius: '0.25rem' }}>
                                <Stack
                                    sx={{ width: '100%', padding: '6px 2px 6px' }}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Typography
                                        sx={{ minWidth: '6rem !important' }}
                                        className="cursor-default gray-900 fs-8 fw-light"
                                    >
                                        Assessment
                                    </Typography>
                                    <Typography className="cursor-default gray-900 fs-8 fw-light">
                                        {recommendation?.assessment ?? 'N/A'}
                                    </Typography>
                                </Stack>
                                <Stack
                                    sx={{ width: '100%', padding: '6px 2px 6px' }}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Typography
                                        sx={{ minWidth: '6rem !important' }}
                                        className="cursor-default gray-900 fs-8 fw-light"
                                    >
                                        Plan
                                    </Typography>
                                    <Box sx={{ overflow: 'clip' }}>
                                        <Typography className="cursor-default gray-900 fs-8 fw-light">
                                            {recommendation?.plan ?? 'N/A'}
                                        </Typography>
                                        <Typography
                                            className="cursor-default gray-900 fs-9 fw-light"
                                            noWrap={!consultLetterFlag}
                                        >
                                            {recommendation?.plan === 'Monitor'
                                                ? 'Continue Current Management'
                                                : recommendation?.plan === 'Modify'
                                                ? `${
                                                      recommendation?.notes ??
                                                      (!!consultLetterFlag
                                                          ? null
                                                          : 'Refer to Additional Notes')
                                                  }`
                                                : null ?? ''}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    )
                })}
                {!!consultLetterFlag || !!!!(recommendationList.length <= 2) ? null : (
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <Button
                            onClick={() => setExpand((expand) => !expand)}
                            size="small"
                            variant="contained"
                            className="btn-white-outline"
                            fullWidth={true}
                            startIcon={!!expand ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                        >
                            {!!expand ? 'Collapse Recommendations' : 'Expand Recommendations'}
                        </Button>
                    </Box>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    recommendationList: state?.recommendations?.list?.recommendation,
    recommendationLoading: state?.recommendations?.recommendationLoading,
    lastUpdatedRecommendations: state?.recommendations?.lud,
})

const mapDispatchToProps = (dispatch) => {
    return {
        togglePhysicalModal: (payload) => dispatch(togglePhysicalModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationDetails)
