import React from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import { toggleScreenFlowModal } from '../../../redux/modals/toggleModal.actions'
import { setCurrentTrial } from '../../../redux/screenFlow/screenFlow.action'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import CircleIcon from '@mui/icons-material/Circle'
import Stack from '@mui/material/Stack'

export const Index = ({ patientId, trialListData, trialListLoading }) => {
    const dispatch = useDispatch()

    return (
        <>
            <Grid
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
            >
                {!!trialListLoading ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(trialListData) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">No trial data found</Typography>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                            disablePadding={true}
                            dense={true}
                        >
                            {trialListData?.map((val, index) => (
                                <ListItem
                                    sx={{
                                        cursor: !!(val?.status === 'declined' || val?.status === 'eligible')
                                            ? 'auto'
                                            : 'not-allowed !important',
                                    }}
                                    key={index}
                                    disablePadding={true}
                                    secondaryAction={
                                        <IconButton
                                            sx={{ padding: '0px !important' }}
                                            edge="end"
                                            disabled={
                                                !(val?.status === 'declined' || val?.status === 'eligible')
                                            }
                                            onClick={(event) => {
                                                dispatch(toggleScreenFlowModal(true))
                                                dispatch(setCurrentTrial(val))
                                                event.stopPropagation()
                                            }}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={1.5}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform: 'uppercase',
                                                        color: variables.gray800,
                                                        fontSize: '0.875rem',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {val?.name ?? '-'}
                                                </Typography>
                                                <Chip
                                                    variant="outlined"
                                                    color={
                                                        val?.status === 'eligible'
                                                            ? 'success'
                                                            : val?.status === 'ineligible'
                                                            ? 'error'
                                                            : 'default'
                                                    }
                                                    size="small"
                                                    icon={
                                                        <CircleIcon sx={{ width: '10px', height: '10px' }} />
                                                    }
                                                    label={val?.status ?? 'Not available'}
                                                    sx={{
                                                        textTransform: 'capitalize',

                                                        fontSize: '0.875rem',
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    trialListData: state?.screenFlow?.trialList,
    trialListLoading: state?.screenFlow?.trialListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleScreenFlowModal: (payload) => dispatch(toggleScreenFlowModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
