import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

const MedicationInfo = ({ cardiacData }) => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-2">Medication</Typography>

            <Box
                sx={{
                    border: 1,
                    borderRadius: '0.25rem',
                    borderColor: variables.lightGray1,
                    overflow: 'hidden',
                }}
            >
                <Typography
                    className="fs-9 fw-normal"
                    sx={{
                        background: variables.purple,
                        p: 0.5,
                        textAlign: 'center',
                        color: variables.white100,
                    }}
                >
                    Cardiac
                </Typography>
                <Divider
                    sx={{
                        borderColor: variables.lightGray1,
                        border: 0.5,
                    }}
                />
                <Box sx={{ p: 0.5, overflowWrap: 'break-word' }}>
                    {_.isEmpty(cardiacData) ? (
                        <Typography className="gray-900 fw-light fs-9 text-center">
                            Medication Data Not Available
                        </Typography>
                    ) : (
                        <>
                            {cardiacData?.map((med, index) => {
                                return (
                                    <>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            key={index}
                                            className="py-1 fs-9 fw-light gray-800"
                                            sx={{
                                                textTransform: 'capitalize !important',
                                            }}
                                        >
                                            <Grid item xs={7.5}>
                                                <Box>{med?.name ?? '-'}</Box>
                                            </Grid>
                                            <Grid item xs={4.5}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={{
                                                        textTransform: 'uppercase !important',
                                                        px: 1,
                                                    }}
                                                >
                                                    <Typography className="fs-9">
                                                        {(med?.strength || med?.dosage) ?? 'N/A'}
                                                    </Typography>
                                                    <Typography className="fs-9">
                                                        {med?.frequency ?? 'N/A'}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })}
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        cardiacData: state?.cardiacList?.list,
        cardiacLoading: state?.cardiacList?.cardiacLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationInfo)

/* 

{cardiacData?.map((med, idx) => 
                            (
                                <Typography
                                    className="fs-9 fw-light py-1"
                                    sx={{ color: variables.purple }}
                                    key={idx}
                                >
                                    {!!med?.customLabel
                                        ? med?.customLabel ?? '-'
                                        : med?.name ?? '-'}
                                </Typography>
                            )
                            )}

*/
