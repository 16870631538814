import _ from 'lodash'
export const DATE_FORMAT = {
    MMM_DD_YYYY: 'MMM DD, YYYY', // Nov 30, 2022
    DD_MM_YY: 'DD.MM.YY', //30.11.22
    DD_MM_YYYY: 'DD-MM-YYYY', //30-11-2022
    YYYY_MM_DD: 'YYYY-MM-DD', //2022-11-30
    MMM_YYYY: 'MMM, YYYY', // Nov, 2022
    DD_MMM_YY: 'DD-MMM-YY', //30-Nov-22
}

// Convert hh:mm:ss / h:m:s /mm:ss / m:s string formats to seconds
export const strToSeconds = (stime) => {
    if (_.isNil(stime)) return null
    return +stime.split(':').reduce(function (acc, time) {
        return +(60 * acc) + +time
    })
}
