import { GET_PHYSICAL_LIST_DATA, SET_PHYSICAL_LOADING, CLEAR_PHYSICAL } from './physicalExam.type'

const INITIAL_STATE = {
    list: [],
    physicalLoading: false,
    lud: null,
}

const physicalListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PHYSICAL_LIST_DATA:
            return {
                ...state,
                list: action?.payload,
                lud: action?.payload?.hasOwnProperty('lastUpdatedDate')
                    ? action?.payload?.lastUpdatedDate
                    : null,
                loading: false,
            }

        case SET_PHYSICAL_LOADING:
            return {
                ...state,
                physicalLoading: action.payload,
            }

        case CLEAR_PHYSICAL:
            return {
                list: [],
                physicalLoading: false,
                lud: null,
            }
        default:
            return state
    }
}

export default physicalListReducer
