import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import variables from '../../../theme/variables.scss'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleMedicalHistoryModal,
} from '../../../redux/modals/toggleModal.actions'

import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { setAlert } from '../../../redux/alert/alert.action'

import AddIcon from '@mui/icons-material/Add'
import LabelIcon from '@mui/icons-material/Label'

import {
    getMedicalHistoryListData,
    getMedicalHistoryDiagnosisData,
    setCurrentMedicalHistory,
} from '../../../redux/medicalHistory/medicalHistory.action'

export const MedicalHistoryForm = ({
    token,
    patientId,
    setAlignment,
    alignment,
    searchValue,
    setSearchValue,
    advFormData,
    advType,
    currentMedicalHistory,
}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    /* custom label */
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [labelVal, setLabelVal] = React.useState(advFormData?.customLabel ?? '')
    const open = Boolean(anchorEl)

    // Label Popup
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    // Label Popup
    const handleClose = () => {
        setAnchorEl(null)
    }
    /* -x-custom label-x- */

    //Validation
    const validationSchema = Yup.object().shape({
        /* commented to skip validation and allow for direct submission */
        // diagnosis: Yup.object().shape({
        //     subDiagnosis: Yup.string().required('Sub-diagnosis is required').nullable(),
        // }),
    })

    /* onSubmit Handler */
    const onSumbitHandler = (values) => {
        setLoading(true)
        const payload =
            alignment === 'Add'
                ? { patientId: patientId, type: 'other', diagnosis: values?.diagnosis }
                : alignment === 'Update'
                ? { patientId: patientId, type: 'other', diagnosis: values?.diagnosis }
                : null

        /* axios */
        axios({
            method: alignment === 'Update' ? 'put' : 'post',

            url: `${process.env.REACT_APP_CARD_URL}/diagnosis-history`,
            data: payload,
            headers: {
                accessToken: token,
            },
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'success',
                            alertMsg: `Medical history ${
                                alignment === 'Update' ? 'Updated' : 'Added'
                            } Successfully`,
                            alertOpen: true,
                        })
                    )

                    const payloadMedicalHistoryList = {
                        patientId: patientId,
                        type: 'other',
                        status: 'active',
                    }
                    dispatch(getMedicalHistoryListData(payloadMedicalHistoryList, token))
                    dispatch(setCurrentMedicalHistory('other'))
                    dispatch(toggleMedicalHistoryModal(false))
                } else if (response?.data?.status === 400) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'error',

                            alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                                response?.data?.error?.message
                            }`,
                            alertOpen: true,
                        })
                    )
                } else if (response?.data?.status === 401) {
                    setLoading(false)
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response?.data?.status === 403) {
                    setLoading(false)
                    dispatch(toggleDeactivateModal(true))
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="medication-class adverse-class">
            <Formik
                enableReinitialize={true}
                initialValues={
                    alignment === 'Add'
                        ? {
                              diagnosis: {
                                  name: searchValue?.name,
                                  subDiagnosis: null,
                                  diagnoseDate: null,
                                  treatment: '',
                                  severity: '',
                                  customLabel: '',
                                  icd10Code: searchValue?.code ?? '',
                                  isPast: false,
                              },
                          }
                        : alignment === 'Update'
                        ? { diagnosis: advFormData }
                        : {}
                }
                validationSchema={validationSchema}
                onSubmit={onSumbitHandler}
                validateOnChange={false}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    isValid,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                    setTouched,
                }) => (
                    <Form noValidate autoComplete="off">
                        {!!searchValue?.name || alignment === 'Update' ? (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{ flexWrap: 'wrap' }}
                            >
                                <Typography className="fs-5 mt-2 mb-2 fw-bold " sx={{ pr: 2 }} gutterBottom>
                                    {alignment === 'Add'
                                        ? `${searchValue?.name ?? ''} ${
                                              !!searchValue?.code ? `(${searchValue?.code})` : ''
                                          }`
                                        : alignment === 'Update'
                                        ? `${advType} ${
                                              !!advFormData?.icd10Code ? `(${advFormData?.icd10Code})` : ''
                                          }`
                                        : null}
                                </Typography>
                                <Box>
                                    <Tooltip
                                        title={
                                            !!values?.diagnosis?.customLabel ? 'Update Label' : 'Add Label'
                                        }
                                    >
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            sx={{ color: variables.purple }}
                                        >
                                            {!!values?.diagnosis?.customLabel ? 'Update' : 'Add '}
                                            <LabelIcon />
                                        </Button>
                                    </Tooltip>
                                    {!!values?.diagnosis?.customLabel ? (
                                        <Chip
                                            label={values?.diagnosis?.customLabel}
                                            sx={{
                                                minWidth: '60px',
                                                mx: 1,
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                color: variables.white100,
                                                backgroundColor: variables.purple,
                                            }}
                                        />
                                    ) : null}
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                gap: 1,
                                                padding: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <TextField
                                                id={'diagnosis.customLabel'}
                                                name={'diagnosis.customLabel'}
                                                onChange={(e) => setLabelVal(e.target.value)}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                placeholder={
                                                    !!values?.diagnosis?.customLabel
                                                        ? 'Update Label'
                                                        : 'Add Label'
                                                }
                                                InputProps={{
                                                    style: {
                                                        fontSize: '14px',
                                                        height: '30px',
                                                        borderColor: variables.lightGray1,
                                                    },
                                                }}
                                                value={labelVal}
                                            />
                                            <Stack
                                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    className="btn-primary"
                                                    onClick={() => {
                                                        setFieldValue('diagnosis.customLabel', labelVal)
                                                        handleClose()
                                                    }}
                                                >
                                                    {!!values?.diagnosis?.customLabel
                                                        ? 'Update Label'
                                                        : 'Add Label'}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => {
                                                        setFieldValue('diagnosis.customLabel', '')
                                                        setLabelVal('')
                                                        handleClose()
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Menu>
                                </Box>
                            </Stack>
                        ) : null}

                        {!!searchValue?.name || alignment === 'Update' ? (
                            <>
                                <Grid
                                    className="mt-2"
                                    container
                                    direction="row"
                                    rowSpacing={2}
                                    columnSpacing={4}
                                >
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Sub-Diagnosis
                                        </Typography>
                                        <div className="d-flex">
                                            {/*  */}
                                            <TextField
                                                id="diagnosis.subDiagnosis"
                                                name="diagnosis.subDiagnosis"
                                                value={values?.diagnosis?.subDiagnosis}
                                                onChange={handleChange}
                                                size="small"
                                                onBlur={handleBlur}
                                                error={
                                                    touched?.diagnosis?.subDiagnosis &&
                                                    Boolean(errors?.diagnosis?.subDiagnosis)
                                                }
                                                helperText={
                                                    touched?.diagnosis?.subDiagnosis &&
                                                    errors?.diagnosis?.subDiagnosis
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                                Dx Date
                                            </Typography>

                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    disableFuture={true}
                                                    inputFormat="MMM, YYYY"
                                                    views={['year', 'month']}
                                                    openTo="month"
                                                    disableMaskedInput
                                                    name={'diagnosis.diagnoseDate'}
                                                    value={values?.diagnosis?.diagnoseDate}
                                                    onChange={(newValue) => {
                                                        setFieldValue(
                                                            'diagnosis.diagnoseDate',
                                                            new Date(newValue)
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{
                                                                maxWidth: '12.18rem !important',
                                                            }}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: '',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Treatments
                                        </Typography>
                                        <div className="d-flex">
                                            <ToggleButtonGroup
                                                orientation="vertical"
                                                exclusive
                                                fullWidth={true}
                                                name={'diagnosis.treatment'}
                                                value={values?.diagnosis?.treatment}
                                                onChange={(e, nextView) =>
                                                    setFieldValue('diagnosis.treatment', nextView)
                                                }
                                            >
                                                {['Monitor', 'Medications', 'Surgery'].map((btn, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        name={btn}
                                                        value={btn}
                                                        aria-label={btn}
                                                        sx={{
                                                            textTransform: 'capitalize !important',
                                                            minWidth: '100px !important',
                                                        }}
                                                    >
                                                        {btn}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {
                                            <ErrorMessage
                                                name={'diagnosis?.treatment'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Severity
                                        </Typography>
                                        <div className="d-flex">
                                            <ToggleButtonGroup
                                                orientation="vertical"
                                                exclusive
                                                fullWidth={true}
                                                name={'diagnosis.severity'}
                                                value={values?.diagnosis?.severity}
                                                onChange={(e, nextView) =>
                                                    setFieldValue('diagnosis.severity', nextView)
                                                }
                                            >
                                                {['Mild', 'Moderate', 'Severe'].map((btn, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        name={btn}
                                                        value={btn}
                                                        aria-label={btn}
                                                        sx={{
                                                            textTransform: 'capitalize !important',
                                                            minWidth: '100px !important',
                                                        }}
                                                    >
                                                        {btn}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {
                                            <ErrorMessage
                                                name={'diagnosis.severity'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>

                                <div>
                                    <FormGroup>
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    sx={{
                                                        pointerEvents: 'auto !important',
                                                    }}
                                                    className={clsx(
                                                        'fs-8 fw-normal',
                                                        !!(currentMedicalHistory === 'past') ||
                                                            !!(alignment === 'Add')
                                                            ? 'gray-400 cursor-not-allowed'
                                                            : 'gray-600'
                                                    )}
                                                >
                                                    Mark as past
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    name={'diagnosis.isPast'}
                                                    value={!!values?.diagnosis?.isPast}
                                                    checked={!!values?.diagnosis?.isPast}
                                                    onChange={(e, nextView) =>
                                                        setFieldValue('diagnosis.isPast', nextView)
                                                    }
                                                    disabled={
                                                        !!(currentMedicalHistory === 'past') ||
                                                        !!(alignment === 'Add')
                                                    }
                                                    disableRipple={true}
                                                    sx={{
                                                        color: variables.gray400,
                                                        '&.Mui-checked': {
                                                            color: variables.purple,
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: variables.gray200,
                                                            pointerEvents: 'auto !important',
                                                            cursor: 'not-allowed !important',
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <Button
                                        size="small"
                                        onClick={() => {}}
                                        variant="contained"
                                        className="btn-secondary"
                                        fullWidth={true}
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: 'fit-content',
                                            mt: 1,
                                            mb: 2,
                                        }}
                                        disabled={true}
                                    >
                                        Add New Presentation
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {!!values?.diagnosis && (
                            <>
                                {!!searchValue?.name || alignment === 'Update' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: 4,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="btn-primary"
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                        <Button
                                            onClick={() => setAlignment('Details')}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : null}
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentMedicalHistory: state?.medicalHistory?.currentMedicalHistory,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleMedicalHistoryModal: (payload) => dispatch(toggleMedicalHistoryModal(payload)),
        getMedicalHistoryListData: (payload, token) => dispatch(getMedicalHistoryListData(payload, token)),
        getMedicalHistoryDiagnosisData: (payload, token) =>
            dispatch(getMedicalHistoryDiagnosisData(payload, token)),
        setCurrentMedicalHistory: (payload) => dispatch(setCurrentMedicalHistory(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryForm)
