import React from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { toggleStressEchoModal } from '../../redux/modals/toggleModal.actions'
import { getStressEchoDocument } from '../../redux/ESN/stressEchoList/stressEchoList.action'

export const Index = ({ stressEchoLUD, stressEchoDocuments, stressEchoLoading, patientId, token }) => {
    const dispatch = useDispatch()

    return (
        <div>
            {stressEchoLoading ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <img src="/loader.gif" width="35" alt="loader" />
                </div>
            ) : (
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item>
                        <img
                            style={{
                                width: '148px',
                                height: '104px',
                            }}
                            src="/images/pdf-icon.svg"
                            alt="pdf icon"
                        />
                    </Grid>
                    <Grid item>
                        <Typography className="gray-900 fw-normal fs-8 mt-3">Stress Echo Report</Typography>
                        <Button
                            size="small"
                            variant="contained"
                            className="btn-secondary mt-1"
                            onClick={() => {
                                const sixtyMonth = moment().subtract(60, 'months').calendar()
                                const proposedDate =
                                    moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
                                const payload = {
                                    patientId: patientId,
                                    date: proposedDate,
                                }
                                dispatch(getStressEchoDocument(payload, token))
                                dispatch(toggleStressEchoModal(true))
                            }}
                        >
                            View Report
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    stressEchoLUD: state.stressEcho.lud,
    stressEchoDocuments: state.stressEcho.stressEchoDocuments,
    stressEchoLoading: state.stressEcho.stressEchoLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleStressEchoModal: (payload) => dispatch(toggleStressEchoModal(payload)),
        getStressEchoDocument: (payload, token) => dispatch(getStressEchoDocument(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
