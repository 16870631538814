import { GET_INTERVAL_LIST_DATA, SET_INTERVAL_LOADING, CLEAR_INTERVAL } from './intervalHistory.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../modals/toggleModal.actions'

export const getIntervalListData = (payload, token) => async (dispatch) => {
    dispatch({
        type: SET_INTERVAL_LOADING,
        payload: true,
    })
    axios
        .get(`${process.env.REACT_APP_CARD_URL}/interval-history?patientId=${payload?.patientId}`, {
            headers: {
                accessToken: token,
            },
        })
        .then((response) => {
            if (response.data.status === 200) {
                dispatch({
                    type: GET_INTERVAL_LIST_DATA,
                    payload: response.data.output,
                })
                dispatch({
                    type: SET_INTERVAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 400) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_INTERVAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 401) {
                dispatch(toggleSessionModal(true, response?.data?.error))
                dispatch({
                    type: SET_INTERVAL_LOADING,
                    payload: false,
                })
            } else if (response.data.status === 403) {
                dispatch(toggleDeactivateModal(true))
                dispatch({
                    type: SET_INTERVAL_LOADING,
                    payload: false,
                })
            }
        })
        .catch((err) => {
            // console.log(err)
        })
}

export const clearInterval = () => (dispatch) => {
    dispatch({
        type: CLEAR_INTERVAL,
    })
}
