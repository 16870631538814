import {
    GET_STRESS_ECHO_DOCUMENT,
    STRESS_ECHO_DOC_LOADING,
    RESET_STRESS_ECHO_MODAL,
    CLEAR_STRESS_ECHO,
    STRESS_ECHO_GXT_TRIGGER,
} from './stressEchoList.type'
import axios from 'axios'
import { STATUS_CODE, commonErrorHandler, networkErrorHandler } from '../../../util'

const BASE_URL = process.env.REACT_APP_CARD_URL
const STRESS_ECHO_LIST_ENDPOINT = '/gxt/list-documents'
function constructUrl(endpoint) {
    return `${BASE_URL}${endpoint}`
}

export const getStressEchoDocument = (payload, token) => async (dispatch) => {
    dispatch({
        type: STRESS_ECHO_DOC_LOADING,
        payload: true,
    })
    try {
        const response = await axios.post(constructUrl(STRESS_ECHO_LIST_ENDPOINT), payload, {
            headers: {
                accessToken: token,
            },
        })
        const { status, output, error } = response.data
        if (status === STATUS_CODE.SUCCESS) {
            dispatch({
                type: GET_STRESS_ECHO_DOCUMENT,
                payload: output,
            })
        } else {
            commonErrorHandler(dispatch, status, error, 'Stress Echo list')
        }
    } catch (err) {
        networkErrorHandler(dispatch, err)
    } finally {
        dispatch({ type: STRESS_ECHO_DOC_LOADING, payload: false })
    }
}

export const setStressEchoGxtTrigger = (payload) => (dispatch) => {
    dispatch({
        type: STRESS_ECHO_GXT_TRIGGER,
        payload: payload,
    })
}
export const resetStressEchoDocument = () => (dispatch) => {
    dispatch({
        type: RESET_STRESS_ECHO_MODAL,
    })
}

export const clearStressEcho = () => (dispatch) => {
    dispatch({
        type: CLEAR_STRESS_ECHO,
    })
}
