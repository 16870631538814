import React from 'react'
import '../interval-components/inputs.scss'
import variables from '../../../../theme/variables.scss'
import _ from 'lodash'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import CustomizedAccordions from '../interval-components/Accordian'
import ColorToggleButton from '../interval-components/Toggler'
import Inputs from '../interval-components/Inputs'
import BoxWrapper from '../interval-components/BoxWrapper'
import StackWrapper from '../interval-components/StackWrapper'

const PalpitationsAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Palpitations'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`intervalHistory[2].active`}
                        btnVal={values?.intervalHistory[2]?.active}
                    />
                }
            >
                <StackWrapper title="When did it start">
                    <BoxWrapper styles={{ gap: '2px', alignItems: 'center' }}>
                        <Inputs
                            inputType={'dateInput'}
                            inputName={`intervalHistory[2].since.date`}
                            inputVal={values?.intervalHistory[2]?.since?.date}
                        />
                    </BoxWrapper>

                    <BoxWrapper styles={{ gap: '2px', alignItems: 'center' }}>
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Duration
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[2].since.value`}
                            inputVal={values?.intervalHistory[2]?.since?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[2].since.unit`}
                            inputVal={values?.intervalHistory[2]?.since?.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What does it feel like">
                    <BoxWrapper styles={{ gap: '2px', alignItems: 'center' }}>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[2].feeling`}
                            inputVal={values?.intervalHistory[2]?.feeling}
                            inputSize="xxl"
                            toggleBtnArr={['Racing', 'Extra Beats', 'Skipped Beats', 'Thumping', 'Harder']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What makes it worse">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[2].exacerbatedBy`}
                            inputVal={values?.intervalHistory[2]?.exacerbatedBy}
                            inputSize="xl"
                            toggleBtnArr={['Nothing', 'Exertion', 'Laying Down', 'Sleep', 'Eating']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What makes it better">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[2].alleviatedBy`}
                            inputVal={values?.intervalHistory[2]?.alleviatedBy}
                            inputSize="xl"
                            toggleBtnArr={['Nothing', 'Rest', 'Laying Down', 'Inhalers']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="How often does it happen">
                    <BoxWrapper
                        styles={{
                            alignItems: 'center',
                            gap: '2px',
                        }}
                    >
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Frequency
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[2].frequency.times`}
                            inputVal={values?.intervalHistory[2]?.frequency?.times}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[2].frequency.totalTime`}
                            inputVal={values?.intervalHistory[2]?.frequency.totalTime}
                            inputSize="small"
                            toggleBtnArr={['daily', 'weekly', 'monthly', 'yearly']}
                        />
                    </BoxWrapper>

                    <BoxWrapper
                        styles={{
                            alignItems: 'center',
                            gap: '2px',
                        }}
                    >
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Duration
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[2].frequency.value`}
                            inputVal={values?.intervalHistory[2]?.frequency?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[2].frequency.unit`}
                            inputVal={values?.intervalHistory[2]?.frequency.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Other associated symptoms">
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[2].associatedSymptoms`}
                            inputVal={values?.intervalHistory[2]?.associatedSymptoms}
                            inputSize="xxxl"
                            toggleBtnArr={['Chest Pain', 'Unconscious', 'Cough', 'Throat Discomfort']}
                        />
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[2].associatedSymptoms`}
                            inputVal={values?.intervalHistory[2]?.associatedSymptoms}
                            inputSize="xxxl"
                            toggleBtnArr={['Palpitations', 'Lightheadedness']}
                        />
                    </BoxWrapper>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default PalpitationsAccordion
