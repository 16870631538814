import React from 'react'
import Typography from '@mui/material/Typography'
import CardiacDetails from '../../cardiac-history/cardiac-details'

const CardiacInfo = () => {
    return (
        <>
            <Typography className="gray-600 fs-7 fw-normal pb-1">Cardiac History</Typography>
            <CardiacDetails consultLetterFlag={true} />
        </>
    )
}

export default CardiacInfo
