/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LabsChart from '../labs-charts/index'
import LabsPills from '../labs-pills'
import { toggleLabsModal } from '../../../redux/modals/toggleModal.actions'
import { getLabsListData } from '../../../redux/labsList/labsList.action'
import moment from 'moment'
import './labs.scss'
import InfoBox from '../../vital-component/vitals/InfoBox'

export const Index = ({ labs, patientId, token, labsLoading }) => {
    const dispatch = useDispatch()

    return (
        <div>
            <Accordion className="dashboard-accordion" sx={{ boxShadow: 'none' }} expanded={true}>
                <AccordionSummary
                    sx={{ background: '#F3F4F6', boxShadow: 'none', borderRadius: '0.25rem' }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-900 fs-8 fw-normal cursor-default"
                        sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}
                    >
                        Labs
                    </Typography>
                    <Box
                        className="d-flex justify-content-end gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.85rem',
                        }}
                    >
                        <Box className="ms-2 me-1 cursor-pointer">
                            <InfoBox />
                        </Box>
                        {labs?.lud !== '' ? `Refreshed ${moment(labs?.lud).format('MMM DD, YYYY')}` : null}
                        <span className="ms-2 me-1 cursor-pointer">
                            <img
                                onClick={() => dispatch(toggleLabsModal(true))}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex">
                        <div className="small-chart">
                            {labsLoading ? (
                                <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                            ) : (
                                <LabsChart />
                            )}
                        </div>
                        <div className="ms-3 w-100">
                            <LabsPills name="dashboard-pills" time="" />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentLabs: state.labs.currentLabs,
    labs: state.labsList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    labsLoading: state.labsList.labsLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLabsModal: (payload) => dispatch(toggleLabsModal(payload)),
        getLabsListData: (payload, token) => dispatch(getLabsListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
