import { SET_CURRENT_ECHO, SET_ECHO_LOADING } from './echo.type'

const INITIAL_STATE = {
    currentEcho: 'EF (%)',
    loading: true,
}

const echoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_ECHO:
            return {
                ...state,
                currentEcho: action.payload,
                loading: false,
            }
        case SET_ECHO_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}

export default echoReducer
