import React from 'react'
import _ from 'lodash'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import CustomizedAccordions from '../../../interval-history/interval-modal/interval-components/Accordian'
import ColorToggleButton from '../../../interval-history/interval-modal/interval-components/Toggler'
import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const CardiacAccordion = ({
    values,
    handleAccordianChange,
    expanded,
    setExpanded,
    handleHeartAccordionChange,
    heartAccordion,
    setHeartAccordion,
    murmurAccordion,
    setMurmurAccordion,
    handleMurmurAccordionChange,
}) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Cardiac'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`physicalExam[0].active`}
                        btnVal={values?.physicalExam[0]?.active}
                    />
                }
            >
                <StackWrapper title="Auscultation">
                    {/* heart accoridian */}
                    <Box className="w-100">
                        <CustomizedAccordions
                            expanded={heartAccordion}
                            setExpanded={setHeartAccordion}
                            handleAccordianChange={handleHeartAccordionChange}
                            title={'Heart Sounds'}
                        >
                            <StackWrapper
                                title="S1"
                                direction="row"
                                titleWidth="40px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.heartSounds.s1`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.heartSounds
                                                ?.s1
                                        }
                                        toggleBtnArr={['Normal', 'Loud', 'Soft', 'Absent']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="S2"
                                direction="row"
                                titleWidth="40px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.heartSounds.s2`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.heartSounds
                                                ?.s2
                                        }
                                        toggleBtnArr={['Normal', 'Loud', 'Soft', 'Absent']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            {/* others */}
                            <StackWrapper title="Other" direction="row" titleWidth="40px" spacing={2}>
                                <BoxWrapper styles={{ gap: 1 }}>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="xxl"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.heartSounds.other`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.heartSounds
                                                ?.other
                                        }
                                        toggleBtnArr={['None', 'S3', 'S4', 'Ejection Click']}
                                    />
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="xxxl"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.heartSounds.other`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.heartSounds
                                                ?.other
                                        }
                                        toggleBtnArr={['Opening Snap', 'Mid-systolic Click']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                        </CustomizedAccordions>
                    </Box>
                    {/* Heart Accordion */}

                    {/* Murmur Accordion */}
                    <Box className="w-100">
                        <CustomizedAccordions
                            expanded={murmurAccordion}
                            setExpanded={setMurmurAccordion}
                            handleAccordianChange={handleMurmurAccordionChange}
                            title={'Murmur'}
                            toggleBtn={
                                <ColorToggleButton
                                    btnName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].active`}
                                    btnVal={
                                        values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                            ?.active
                                    }
                                />
                            }
                        >
                            <StackWrapper
                                title="Intensity"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].intesity`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.intesity
                                        }
                                        toggleBtnArr={_.range(1, 6)}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Pitch"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].pitch`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.pitch
                                        }
                                        toggleBtnArr={['Low', 'Mild', 'High']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Timing"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].timing`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.timing
                                        }
                                        toggleBtnArr={['Systolic', 'Diastolic', 'Continuous']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Peak"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].peak`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.peak
                                        }
                                        toggleBtnArr={['Early', 'Mid', 'Late']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Profile"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="xxxl"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].profile`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.profile
                                        }
                                        toggleBtnArr={[
                                            'Crescendo',
                                            'Decrescendo',
                                            'Cres/Decrescendo',
                                            'Uniform',
                                        ]}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Location"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].location`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.location
                                        }
                                        toggleBtnArr={['RUSB', 'LUSB', 'LLSB', 'Apex']}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper
                                title="Radiation"
                                direction="row"
                                titleWidth="60px"
                                alignItems="center"
                                spacing={2}
                            >
                                <BoxWrapper>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].radiation`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.radiation
                                        }
                                        toggleBtnArr={[
                                            'RUSB',
                                            'Apex',
                                            'Clavicle',
                                            'Carotids',
                                            'Axilla',
                                            'Back',
                                        ]}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            <StackWrapper title="Maneuvers">
                                <BoxWrapper
                                    styles={{ alignItems: 'center', gap: '2px', padding: '0 2px 0px' }}
                                >
                                    <Typography
                                        className="fs-9 mx-1 fw-normal gray-500"
                                        sx={{
                                            minWidth: '60px',
                                        }}
                                    >
                                        Degree
                                    </Typography>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].maneuvers.degree`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.maneuvers?.degree
                                        }
                                        toggleBtnArr={['Increase', 'Decrease']}
                                    />
                                </BoxWrapper>

                                <BoxWrapper
                                    styles={{ alignItems: 'center', gap: '2px', padding: '0 2px 0px' }}
                                >
                                    <Typography
                                        className="fs-9 mx-1 fw-normal gray-500"
                                        sx={{
                                            minWidth: '60px',
                                        }}
                                    >
                                        Value
                                    </Typography>
                                    <Inputs
                                        inputType={'toggleBtn'}
                                        inputSize="large"
                                        inputName={`physicalExam[0].diagnosisType.auscultaion.murmurs[0].maneuvers.value`}
                                        inputVal={
                                            values?.physicalExam[0]?.diagnosisType?.auscultaion?.murmurs[0]
                                                ?.maneuvers?.value
                                        }
                                        toggleBtnArr={[
                                            'Handgrip',
                                            'Valsalva',
                                            'Standing',
                                            'Squatting',
                                            'Nitro',
                                        ]}
                                    />
                                </BoxWrapper>
                            </StackWrapper>
                            {/*  */}
                        </CustomizedAccordions>
                    </Box>
                    {/* Murmor Accordion */}

                    {/* Palpitations  */}
                    <Typography className="fs-8 mt-2 fw-normal gray-500" sx={{ minWidth: '150px' }}>
                        Palpitations
                    </Typography>

                    {/* parasternum */}
                    <Box sx={{ padding: '0 4px 0' }}>
                        <Typography className="fs-9 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                            Parasternum
                        </Typography>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Character
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.parasternum.character`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.parasternum
                                        ?.character
                                }
                                toggleBtnArr={['Single', 'Double', 'Triple']}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Distribution
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.parasternum.distribution`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.parasternum
                                        ?.distribution
                                }
                                toggleBtnArr={['Focal', 'Diffuse']}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Place
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="xxl"
                                inputName={`physicalExam[0].diagnosisType.palpitations.parasternum.place`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.parasternum?.place
                                }
                                toggleBtnArr={['Mid-Clavicular', 'Lateral']}
                            />
                        </BoxWrapper>
                    </Box>

                    {/* apex */}
                    <Box sx={{ padding: '0 4px 0' }}>
                        <Typography className="fs-9 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                            Apex
                        </Typography>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Impulse
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.apex.impulse`}
                                inputVal={values?.physicalExam[0]?.diagnosisType?.palpitations?.apex?.impulse}
                                toggleBtnArr={['Normal', 'Sustained', 'Decreased']}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Position
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="xxl"
                                inputName={`physicalExam[0].diagnosisType.palpitations.apex.position`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.apex?.position
                                }
                                toggleBtnArr={['Non-Displaced', 'Lateral']}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Character
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.apex.character`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.apex?.character
                                }
                                toggleBtnArr={['Single', 'Double', 'Triple']}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Distribution
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.apex.distribution`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.apex?.distribution
                                }
                                toggleBtnArr={['Focal', 'Diffuse']}
                            />
                        </BoxWrapper>
                    </Box>
                    {/* tenderness */}
                    <Box sx={{ padding: '0 4px 0' }}>
                        <Typography className="fs-9 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                            Tenderness
                        </Typography>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Value
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="large"
                                inputName={`physicalExam[0].diagnosisType.palpitations.tenderness.value`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.tenderness?.value
                                }
                                toggleBtnArr={[
                                    'Sternal',
                                    'Parasternal',
                                    'Upper',
                                    'Lower',
                                    'Axillary',
                                    'Epigastric',
                                ]}
                            />
                        </BoxWrapper>
                        <BoxWrapper styles={{ alignItems: 'center', gap: '2px', padding: '2px 4px 2px' }}>
                            <Typography
                                className="fs-9 mx-1 fw-normal gray-500"
                                sx={{
                                    minWidth: '80px',
                                }}
                            >
                                Additional Details
                            </Typography>
                            <Inputs
                                inputType={'textArea'}
                                inputName={`physicalExam[0].diagnosisType.palpitations.tenderness.additionalNotes`}
                                inputVal={
                                    values?.physicalExam[0]?.diagnosisType?.palpitations?.tenderness
                                        ?.additionalNotes
                                }
                            />
                        </BoxWrapper>
                    </Box>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default CardiacAccordion
