import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useMatch, Link } from 'react-router-dom'
import '../login/login.scss'
import variables from '../../theme/variables.scss'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Card from '../../components/portal-components/Card'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import ProfileDropdownMenu from '../../components/portal-components/ProfileDropdownMenu'

export const PortalDeck = (props) => {
    const navigate = useNavigate()
    const match = useMatch('/portals/:id')

    return (
        <>
            <Box className="login-bg px-3">
                <Box classname="position-absolute">
                    <Box class="position-absolute top-0 start-0 py-3 px-4">
                        <Link to="/portals">
                            <img style={{ width: '30px' }} src="/images/logo.svg" alt="small icon" />
                        </Link>
                    </Box>
                    <Box class="position-absolute top-0 end-0 px-4">
                        <ProfileDropdownMenu />
                    </Box>
                </Box>
                <Box>
                    <Box className="d-flex justify-content-center align-items-center py-4">
                        {match?.params?.id === 'searchflow' ? (
                            <IconButton aria-label="back" onClick={() => navigate('/portals')}>
                                <KeyboardBackspaceIcon
                                    sx={{
                                        color: variables.purple,
                                        cursor: 'pointer',
                                    }}
                                />
                            </IconButton>
                        ) : null}
                        <Typography className="fs-5 fw-bold">Please select an option to proceed.</Typography>
                    </Box>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="center"
                    >
                        {match?.params?.id === 'searchflow' ? (
                            <>
                                <Card
                                    title="screenFLOW"
                                    icon="/images/screenFlow-icon.svg"
                                    onClickHandler={() => navigate('/screenflow')}
                                />
                                <Card
                                    disableCard={true}
                                    title="Cohort Builder"
                                    icon="/images/cohort-icon.svg"
                                    onClickHandler={() => navigate('/cohort-builder')}
                                />
                            </>
                        ) : (
                            <>
                                <Card
                                    title="WorkFLOW"
                                    icon="/images/workFlow-icon.svg"
                                    onClickHandler={() => navigate('/home')}
                                />
                                <Card
                                    title="SearchFLOW"
                                    icon="/images/searchFlow-icon.svg"
                                    onClickHandler={() => navigate('/portals/searchflow')}
                                />
                            </>
                        )}
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PortalDeck)
